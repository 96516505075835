import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
} from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button } from '../../general/button/Button';
import * as styles from './Card.styles';
import { VARIANTS } from './constants';
import { CardFooter } from './footer/CardFooter';
import { CardMedia } from './media/CardMedia';

export type CardProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  color?: string;
  progress?: number;
  variant?: VARIANTS;
};

export const CardBase = forwardRef(
  (
    { color, progress, variant, className, children, ...props }: CardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    return (
      <div
        ref={ref}
        className={className}
        css={[
          styles.cardStyle,
          color && styles.cardBorderStyle(theme, color, progress),
        ]}
        {...props}
      >
        {children}
      </div>
    );
  },
);

CardBase.displayName = 'Card';

CardBase.propTypes = {
  /** Hex color of border color. */
  color: PropTypes.string,
  /** Between 0 and 1, makes the top border act like a progress bar. */
  progress: PropTypes.number,
  /** Card variant. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** Root element class name. */
  className: PropTypes.string,
  /** Dialog content. */
  children: PropTypes.node,
};

CardBase.defaultProps = {
  color: null,
  progress: 1,
  variant: VARIANTS.PRIMARY,
  className: null,
  children: null,
};

const CardBody = styled.div`
  padding: 20px;
  overflow-wrap: break-word;
`;

const CardActions = styled(CardFooter)`
  justify-content: flex-end;
`;

const CardTitle = styled.div`
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[100]};
`;

export const Card: MemoExoticComponent<typeof CardBase> & {
  Title?: typeof CardTitle;
  Body?: typeof CardBody;
  Footer?: typeof CardFooter;
  Actions?: typeof CardActions;
  Action?: typeof Button;
  Media?: typeof CardMedia;
  VARIANTS?: typeof VARIANTS;
} = memo(CardBase);

Card.Title = CardTitle;
Card.Body = CardBody;
Card.Footer = CardFooter;
Card.Actions = CardActions;
Card.Action = Button;
Card.Media = CardMedia;
Card.VARIANTS = VARIANTS;
