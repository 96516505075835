export enum CROPPER_VIEW_MODES {
  NO_RESTRICTIONS = 'NO_RESTRICTIONS',
  CROPBOX_IN_CANVAS = 'CROPBOX_IN_CANVAS',
  CANVAS_FITS_IN_CONTAINER = 'CANVAS_FITS_IN_CONTAINER',
  CANVAS_FILLS_CONTAINER = 'CANVAS_FILLS_CONTAINER',
}

/** View mode. https://github.com/fengyuanchen/cropperjs#viewmode. */
export const CROPPER_VIEW_MODES_MAPPING: Record<
  CROPPER_VIEW_MODES,
  0 | 1 | 2 | 3
> = {
  NO_RESTRICTIONS: 0,
  // Cropbox cannot leave canvas.
  CROPBOX_IN_CANVAS: 1,
  // Canvas must be entirely visible in the container.
  CANVAS_FITS_IN_CONTAINER: 2,
  // Canvas takes all available space in container.
  CANVAS_FILLS_CONTAINER: 3,
} as const;
