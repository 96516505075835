import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
  type ReactNode,
} from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { MENU_ITEM_DRAGGABLE_CLASSNAME } from './constants';
import { MenuItemButton } from './item/MenuItemButton';
import { MenuItemLink } from './item/MenuItemLink';
import { MenuContext } from './Menu.context';

type MenuProps = {
  isCollapsed?: boolean;
  children?: ReactNode;
};

export const MenuBase = forwardRef(
  (
    { isCollapsed, children }: MenuProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const contextValue = useMemoizedBundle({ isCollapsed });

    return (
      <MenuContext.Provider value={contextValue}>
        <ul
          ref={ref}
          className={cn({ isCollapsed })}
          css={css`
            list-style-type: none;

            display: flex;
            flex-direction: column;
            gap: 10px;

            padding-left: 10px;

            &.isCollapsed {
              padding: 0 10px;
            }
          `}
        >
          {children}
        </ul>
      </MenuContext.Provider>
    );
  },
);

MenuBase.displayName = 'Menu';

MenuBase.propTypes = {
  /** Show a collapsed version of the menu (items label become tooltips). */
  isCollapsed: PropTypes.bool,
  /** Menu items. */
  children: PropTypes.node,
};

MenuBase.defaultProps = {
  isCollapsed: false,
  children: null,
};

export const Menu: MemoExoticComponent<typeof MenuBase> & {
  ItemLink?: typeof MenuItemLink;
  ItemButton?: typeof MenuItemButton;
  ITEM_DRAGGABLE_CLASSNAME?: typeof MENU_ITEM_DRAGGABLE_CLASSNAME;
} = memo(MenuBase);

Menu.ItemLink = MenuItemLink;
Menu.ItemButton = MenuItemButton;
Menu.ITEM_DRAGGABLE_CLASSNAME = MENU_ITEM_DRAGGABLE_CLASSNAME;
