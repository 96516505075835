import { defineMessages } from 'react-intl';

export default defineMessages({
  PAGE_NOT_FOUND_TITLE: {
    defaultMessage: 'Page non trouvée...',
  },

  PAGE_NOT_FOUND_DESCRIPTION: {
    defaultMessage: 'La page est manquante ou vous avez mal assemblé le lien.',
  },

  RETURN_TO_HOME: {
    defaultMessage: "Retourner à l'accueil",
  },
});
