import { cloneElement, memo, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Check, Close } from '@icon-park/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Tag } from '../../../../general/tag/Tag';
import { Typography } from '../../../../general/typography/Typography';
import { VARIANTS } from '../../constants';
import messages from './StepperItemContent.messages';
import * as styles from './StepperItemContent.styles';

const getTagLabel = (
  isCurrent: boolean,
  isDone: boolean,
  isIncomplete: boolean,
) => {
  if (isDone) {
    return <FormattedMessage {...messages.STATUS_DONE} />;
  }

  if (isCurrent) {
    return <FormattedMessage {...messages.STATUS_CURRENT} />;
  }

  if (isIncomplete) {
    return <FormattedMessage {...messages.STATUS_UNCOMPLETE} />;
  }

  return <FormattedMessage {...messages.STATUS_UPCOMING} />;
};

const getTagVariant = (
  isCurrent: boolean,
  isDone: boolean,
  isIncomplete: boolean,
) => {
  if (isDone) {
    return Tag.VARIANTS.SUCCESS;
  }

  if (isCurrent) {
    return Tag.VARIANTS.PRIMARY;
  }

  if (isIncomplete) {
    return Tag.VARIANTS.DANGER;
  }

  return Tag.VARIANTS.CLEAR;
};

export type StepperItemContentProps = {
  index?: number;
  variant?: VARIANTS;
  isDone?: boolean;
  isCurrent?: boolean;
  isIncomplete?: boolean;
  isDisabled?: boolean;
  isLast?: boolean;
  icon?: ReactElement;
  children?: ReactNode;
};

export const StepperItemContentBase = ({
  index = 0,
  variant = VARIANTS.VERTICAL,
  isDone = false,
  isCurrent = false,
  isDisabled = false,
  isIncomplete = false,
  isLast = false,
  icon = undefined,
  children = undefined,
}: StepperItemContentProps) => (
  <div
    css={styles.container}
    className={variant}
  >
    <div
      css={styles.progress}
      className={variant}
    >
      <div
        css={styles.badge}
        className={cn({
          isDone,
          isCurrent,
          isDisabled,
          isIncomplete,
        })}
      >
        {isDone && <Check size="16" />}

        {isIncomplete && <Close size="16" />}

        {!icon && !isDone && !isIncomplete && (
          <Typography
            variant={Typography.VARIANTS.BODY_LARGE_BOLD}
            element="span"
          >
            {index}
          </Typography>
        )}

        {!!icon &&
          !isDone &&
          !isIncomplete &&
          cloneElement(icon, {
            size: 16,
            theme: 'outline',
          })}
      </div>

      {!isLast && (
        <div
          css={styles.separator}
          className={cn({ isCurrent, isDone, isIncomplete }, variant)}
        />
      )}
    </div>

    <div
      css={styles.text}
      className={cn({
        isDone,
        isCurrent,
        isDisabled,
        isIncomplete,
      })}
    >
      <Typography
        variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
        css={styles.stepIndex}
      >
        <FormattedMessage
          {...messages.STEP_INDEX}
          values={{ index }}
        />
      </Typography>

      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        {children}
      </Typography>

      <Tag
        label={getTagLabel(isCurrent, isDone, isIncomplete)}
        variant={getTagVariant(isCurrent, isDone, isIncomplete)}
        light
        css={styles.tag}
      />
    </div>
  </div>
);

StepperItemContentBase.displayName = 'StepperItemContent';

StepperItemContentBase.propTypes = {
  /** Stepper variant (horizontal/vertical). */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** Step number. Starts at 1. */
  index: PropTypes.number.isRequired,
  /** Is last step. */
  isLast: PropTypes.bool,
  /** Step is completed. Replaces number with a checkmark. */
  isDone: PropTypes.bool,
  /** Step is currently active. */
  isCurrent: PropTypes.bool,
  /** Step is disabled. */
  isDisabled: PropTypes.bool,
  /** Step icon. */
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default memo(StepperItemContentBase);
