import { memo, type ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';

import { MCQ_ANSWER_STYLES } from '@eversity/domain/constants';
import {
  RadioControl,
  type RadioControlProps,
  RadioGroupContext,
} from '@eversity/ui/design-system';
import { useFocus } from '@eversity/ui/utils';

import { McqAnswer, type McqAnswerProps } from '../McqAnswer';

export type McqAnswerRadioProps = Omit<
  RadioControlProps,
  'onChange' | 'checked' | 'value' | 'media'
> & {
  value: string;
  label: McqAnswerProps['label'];
  answerStyle?: McqAnswerProps['answerStyle'];
  media?: McqAnswerProps['media'];
  isCorrected?: boolean;
  isCorrectAnswer?: boolean;
  successText?: ReactNode;
  errorText?: ReactNode;
};

export const McqAnswerRadioBase = ({
  label,
  value,
  answerStyle = MCQ_ANSWER_STYLES.TEXT,
  media = null,
  isCorrected = false,
  isCorrectAnswer = false,
  disabled = false,
  successText = null,
  errorText = null,
  ...props
}: McqAnswerRadioProps) => {
  const { isFocused, ...focusProps } = useFocus();

  const { value: selectedValue, onChange } = useContext(RadioGroupContext);

  const isChecked = selectedValue === value;
  const isDisabled = disabled || isCorrected;

  const isSuccess = !!isCorrected && !!isCorrectAnswer;
  const isError = !!isCorrected && !isCorrectAnswer && !!isChecked;

  const feedbackText = isSuccess ? successText : errorText;

  return (
    <McqAnswer
      label={label}
      answerStyle={answerStyle}
      media={media}
      isSelected={isChecked}
      isFocused={isFocused}
      isCorrected={isCorrected}
      isCorrectAnswer={isCorrectAnswer}
      isSuccess={isSuccess}
      isError={isError}
      isDisabled={isDisabled}
      feedbackText={feedbackText}
    >
      <RadioControl
        {...props}
        {...focusProps}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        checked={isChecked}
      />
    </McqAnswer>
  );
};

McqAnswerRadioBase.displayName = 'McqAnswerRadio';

McqAnswerRadioBase.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  answerStyle: PropTypes.oneOf(Object.values(MCQ_ANSWER_STYLES)),
  media: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  isCorrected: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  successText: PropTypes.node,
  errorText: PropTypes.node,
};

export const McqAnswerRadio = memo(McqAnswerRadioBase);
