/**
 * Returns browser defined locale if the browser allows it.
 *
 * @returns The browser defined locale.
 */
export const getBrowserLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0].substring(0, 2);
  }

  if (navigator.language) {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    return navigator.language.substring(0, 2);
  }

  return '';
};

/**
 * Set the lang attribute on the <html> tag.
 *
 * @param locale - Locale to set.
 * @returns The locale.
 */
export const setHtmlLangAttribute = (locale: string) => {
  const element = document?.getElementsByTagName('html')?.[0];
  element.setAttribute('lang', locale);
};
