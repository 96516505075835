import { type Schema } from 'hast-util-sanitize';
import hastSanitizeGithubConfig from 'hast-util-sanitize/lib/github.json';
import { without } from 'lodash';

import { CUSTOM_HTML } from '@eversity/domain/constants';

import {
  CUSTOM_HTML_ALLOWED_DOMAINS,
  CUSTOM_HTML_ATTRIBUTES,
} from '../constants';

export type SanitizeSchema = Schema & {
  allowedDomains?: { [key: string]: Record<string, string | string[]> };
};

// Schema used to sanitize course / teaching unit / lesson descriptions texts.
export const commonDescriptionSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': [],

    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
    'h3',
    CUSTOM_HTML.LINKEDIN_EMBEDDED,
  ),

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};

// Schema used to sanitize a lesson sequence text.
export const lessonSequenceSanitizeSchema: SanitizeSchema = {
  attributes: {
    // Allow ids and classes.
    '*': ['id', 'className', 'style'],
    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
  ),

  // Don't prefix ids.
  clobber: [],

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};

// Schema used to sanitize news text content.
export const newsSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': [],

    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
    CUSTOM_HTML.LINKEDIN_EMBEDDED,
  ),

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};

// Schema used to sanitize course internships & exams pages.
export const courseInformationSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': ['id'],

    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
    CUSTOM_HTML.LINKEDIN_EMBEDDED,
  ),

  // Don't prefix ids.
  clobber: [],

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};

// Schema used to sanitize legal documents.
export const legalDocumentSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': ['id'],

    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
    CUSTOM_HTML.LINKEDIN_EMBEDDED,
  ),

  // Don't prefix ids.
  clobber: [],

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};

// Schema used to sanitize calendar events description.
export const calendarEventDescriptionSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': [],
    a: ['href', 'target'],
  },

  tagNames: ['a', 'p'],

  protocols: {
    src: ['https'],
    href: ['https'],
  },
};

// Schema used to sanitize ai answer.
export const aiAnswerSanitizeSchema: SanitizeSchema = {
  attributes: {
    '*': [],
  },

  // tagNames: ['strong', 'p'],
};

// Schema used to sanitize course internships & exams pages.
export const assignmentMcqPageContentSanitizeSchema = {
  attributes: {
    '*': ['id'],

    // Allow src on images.
    img: ['src', 'alt'],

    span: ['dataColor'],

    a: ['href'],

    table: ['dataColRatios'],
    tr: ['dataBackgroundColor'],
    td: ['dataBackgroundColor'],

    // Custom tags.
    ...Object.values(CUSTOM_HTML).reduce(
      (acc, tagName) => ({
        ...acc,
        [tagName]: CUSTOM_HTML_ATTRIBUTES[tagName],
      }),
      {},
    ),
  },

  // Only allow images/videos to be loaded via HTTPS.
  protocols: {
    src: ['https'],
    href: ['https'],
  },

  tagNames: without(
    [
      ...hastSanitizeGithubConfig.tagNames,
      ...Object.values(CUSTOM_HTML),
      'u', // Underline.
    ],
    'h1',
    'h2',
    CUSTOM_HTML.LINKEDIN_EMBEDDED,
  ),

  // Don't prefix ids.
  clobber: [],

  // This is a custom attribute defining allowed domains for some attributes.
  // For example, CUSTOM_HTML.LINKEDIN_EMBEDDED only accepts srcs from www.linkedin.com.
  allowedDomains: CUSTOM_HTML_ALLOWED_DOMAINS,
};
