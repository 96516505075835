import React, { Fragment, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@eversity/ui/design-system';

import highlightingGif from '../../../../../assets/gif/highlighting.gif';
import { messages } from './Highlighting.messages';
import * as styles from './Highlighting.styles';

export const Highlighting = memo(() => {
  const intl = useIntl();
  return (
    <Fragment>
      <div css={styles.imgContainer}>
        <img
          css={styles.img}
          width={410}
          height={165}
          src={highlightingGif}
          alt={intl.formatMessage(messages.HIGHLIGHTING)}
        />
      </div>
      <div css={styles.content}>
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
          <FormattedMessage {...messages.CONTENT} />
        </Typography>
      </div>
    </Fragment>
  );
});
