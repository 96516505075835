import React, {
  createContext,
  type Dispatch,
  memo,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Joyride, { type CallBackProps, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Global, useTheme } from '@emotion/react';
import { noop } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { useAppLocalStorage } from '../../../hooks/local-storage/useAppLocalStorage';
import { CustomBeacon } from './components/beacon/CustomBeacon';
import { CustomTooltip } from './components/tooltip/CustomTooltip';
import { override } from './ProductTourManager.styles';
import {
  type ProductTour,
  type ProductTourContextProviderProps,
  type StoredProductToursState,
} from './types';
import {
  isTourActiveForRoute,
  isTourEnabledForMobileDevice,
  isTourWithinDateRange,
} from './utils';

export type ProductTourContextValue = {
  isRunning: boolean;
  setIsRunning: Dispatch<SetStateAction<boolean>>;
  currentTour: ProductTour;
};

export const ProductTourContext = createContext<ProductTourContextValue>({
  isRunning: false,
  setIsRunning: noop,
  currentTour: null,
});

export const ProductTourContextProvider = memo(
  ({ children, productTours }: ProductTourContextProviderProps) => {
    const { pathname } = useLocation();

    const theme = useTheme();

    const [isRunning, setIsRunning] = useState(false);

    const [storedToursState, setStoredToursState] =
      useAppLocalStorage<StoredProductToursState>('product-tours-state', {});

    const isMobile = useMedia(`(max-width: ${theme.breakpoints.medium})`);

    const currentTour = useMemo(() => {
      const activeTour = productTours.find(
        (tour) =>
          isTourActiveForRoute(tour.pathname, pathname) &&
          isTourWithinDateRange(tour),
      );

      if (activeTour && !isTourEnabledForMobileDevice(isMobile, activeTour)) {
        return null;
      }

      return activeTour;
    }, [isMobile, pathname, productTours]);

    const handleJoyrideCallback = useCallback(
      (data: CallBackProps) => {
        const { status } = data;
        if (currentTour) {
          if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            setIsRunning(false);

            setStoredToursState((prevToursState) => ({
              ...prevToursState,
              [currentTour.id]: { state: 'closed' },
            }));
          }
        }
      },
      [currentTour, setStoredToursState],
    );

    const isTourOpen = useMemo(
      () =>
        currentTour &&
        storedToursState?.[currentTour.id]?.state !== 'closed' &&
        isRunning,
      [currentTour, isRunning, storedToursState],
    );

    useEffect(() => {
      if (isTourOpen) {
        currentTour.steps.forEach((step) => {
          const element = document.querySelector(step.target as string);
          if (element) {
            element.classList.add('joyride-target-highlight');
          }
        });
      }

      return () => {
        currentTour?.steps.forEach((step) => {
          const element = document.querySelector(step.target as string);
          if (element) {
            element.classList.remove('joyride-target-highlight');
          }
        });
      };
    }, [currentTour, isTourOpen]);

    const contextValue = useMemoizedBundle<ProductTourContextValue>({
      isRunning,
      setIsRunning,
      currentTour,
    });

    return (
      <ProductTourContext.Provider value={contextValue}>
        {children}
        {isTourOpen && (
          <Joyride
            steps={currentTour.steps}
            run={isRunning}
            callback={handleJoyrideCallback}
            disableOverlay={currentTour.disableOverlay}
            tooltipComponent={CustomTooltip}
            beaconComponent={CustomBeacon}
            continuous
            showSkipButton
            hideCloseButton
            disableOverlayClose
            disableScrolling
          />
        )}
        <Global styles={override} />
      </ProductTourContext.Provider>
    );
  },
);
