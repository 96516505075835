import { memo, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import {
  hastNodePropTypes,
  HastRenderer,
  type HastRendererDefaultRenderElementProps,
} from '@eversity/ui/design-system';

import { ContentScrollSpyContext } from '../../contexts/ContentScrollSpy.context';

export type ContentRenderRootElementProps = Omit<
  HastRendererDefaultRenderElementProps,
  'depth'
> & {
  headerHeightInPx?: number;
};

export const ContentRenderRootElementBase = ({
  node,
  path,
  headerHeightInPx = 0,
  renderChildren,
}: ContentRenderRootElementProps) => {
  const { pageTitles, onTitleEnterView, onTitleExitView, scrollContainerRef } =
    useContext(ContentScrollSpyContext);

  const { ref, inView } = useInView({
    threshold: 1,

    /**
     * Since the header is sticky, we need to specify its height to insersection-observer.
     * By default, intersection-observer uses the window as reference so we need to give it the
     * closest scroll container, otherwise we would need to also offset the app navbar.
     */
    root: scrollContainerRef.current,
    rootMargin: `${headerHeightInPx * -1}px 0px 0px 0px`,
  });

  useEffect(() => {
    if ('properties' in node && 'id' in node.properties) {
      if (pageTitles.includes(node.properties.id as string)) {
        if (inView) {
          onTitleEnterView(node.properties.id as string);
        } else {
          onTitleExitView(node.properties.id as string);
        }
      }
    }
  }, [pageTitles, inView, node, onTitleEnterView, onTitleExitView]);

  return (
    <HastRenderer.DefaultRenderElement
      ref={ref}
      node={node}
      path={path}
      depth={0}
      renderChildren={renderChildren}
    />
  );
};

ContentRenderRootElementBase.displayName = 'ContentRenderRootElement';

ContentRenderRootElementBase.propTypes = {
  /** Node to render. */
  node: hastNodePropTypes.isRequired,
  /** Path to the node in the tree. */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Header height in px, used to offset the inView threshold. */
  headerHeightInPx: PropTypes.number,
  /** A function to render children. Ignore it when the node handles its children manually. */
  renderChildren: PropTypes.func.isRequired,
};

export default memo(ContentRenderRootElementBase);
