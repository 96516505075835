import { useCallback, useState } from 'react';
import { uniqueId } from 'lodash';
import { set as setImmutable } from 'object-path-immutable';

import { type Upload } from '@eversity/types/domain';
import { type FileUploadingShape } from '@eversity/types/web';

export const useFileUpload = ({
  onUpload,
}: {
  onUpload: (
    file: File,
    onProgress: (progress: number) => void,
  ) => Promise<Upload>;
}) => {
  const [filesUploading, onChangeFilesUploading] = useState<
    FileUploadingShape[]
  >([]);

  const onSetFileUploading = useCallback(
    (
      id: string,
      setUpdates: (fileUploading: FileUploadingShape) => FileUploadingShape,
    ) =>
      onChangeFilesUploading((currentValue) =>
        currentValue.map((fileUploading) =>
          fileUploading.id !== id ? fileUploading : setUpdates(fileUploading),
        ),
      ),
    [onChangeFilesUploading],
  );

  const onRemoveFileUploading = useCallback(
    (id: string) =>
      onChangeFilesUploading((currentValue) =>
        currentValue.filter((file) => file.id !== id),
      ),
    [onChangeFilesUploading],
  );

  const onUploadFile = useCallback(
    (file: File) => {
      const id = uniqueId('fileUploading_');

      const newFileUploading: FileUploadingShape = {
        id,
        file,
        error: null,
        progress: 0,
        uploadPromise: null,
      };

      onChangeFilesUploading((currentFilesUploading) => [
        ...currentFilesUploading,
        newFileUploading,
      ]);

      const promise = Promise.resolve()
        // Start uploading the file.
        .then(() =>
          onUpload(
            file,
            // On progress updates, update the state.
            (progress) =>
              onSetFileUploading(id, (fileUploading) =>
                setImmutable(fileUploading, 'progress', progress),
              ),
          ),
        )
        .then(
          // On upload success, remove the file from the files uploading, and resolve with the
          // uploaded file.
          (uploadedAsset) => {
            onRemoveFileUploading(id);
            return uploadedAsset;
          },
          // On errors, set the error in the file uploading state, and reject the promise.
          // The user will need to call onRemoveFileUploading to remove it from the UI.
          (err) => {
            // Add the error type to the error object to wrap the http call.
            err.more = {
              type: err.response?.body?.type,
              args: err.response?.body?.more,
            };

            onSetFileUploading(id, (fileUploading) =>
              setImmutable(fileUploading, 'error', err),
            );

            return Promise.reject(err);
          },
        );

      newFileUploading.uploadPromise = promise;

      return newFileUploading;
    },
    [onUpload, onRemoveFileUploading, onSetFileUploading],
  );

  // If there are files without error, they are still uploading.
  const hasFileUploading = filesUploading.some(
    (fileUploading) => !!fileUploading.error,
  );

  return {
    filesUploading,
    onSetFileUploading,
    onRemoveFileUploading,
    onUploadFile,
    hasFileUploading,
  };
};
