import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  useCallback,
} from 'react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useFocus } from '@eversity/ui/utils';

import { TEXT_MAX_LENGTH_SIZES } from '../text-max-length/constants';
import { TextMaxLength } from '../text-max-length/TextMaxLength';
import { TEXT_AREA_SIZES } from './constants';
import * as styles from './TextArea.styles';

const TEXT_AREA_MAX_LENGTH_SIZE_MAPPING = {
  [TEXT_AREA_SIZES.SMALL]: TEXT_MAX_LENGTH_SIZES.SMALL,
  [TEXT_AREA_SIZES.MEDIUM]: TEXT_MAX_LENGTH_SIZES.MEDIUM,
  [TEXT_AREA_SIZES.LARGE]: TEXT_MAX_LENGTH_SIZES.LARGE,
};

export type TextAreaProps = Omit<
  HTMLProps<HTMLTextAreaElement>,
  'onChange' | 'size' | 'value' | 'ref'
> & {
  value?: string;
  showMaxLength?: boolean;
  size?: TEXT_AREA_SIZES;
  minLines?: number;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  onChange?: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
  monospace?: boolean;
};

export const TextAreaBase = forwardRef(
  (
    {
      size,
      maxLength,
      showMaxLength,
      minLines,
      hasError,
      hasWarning,
      onChange,
      className,
      monospace,
      ...props
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const { isFocused, ...focusProps } = useFocus(props);

    const onChangeProxy = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) =>
        onChange(event.target.value, event),
      [onChange],
    );

    return (
      <div css={styles.container}>
        <textarea
          {...props}
          {...focusProps}
          ref={ref}
          maxLength={maxLength}
          onChange={onChangeProxy}
          className={cn(className, size, {
            hasError,
            monospace,
            hasWarning: !hasError && !!hasWarning,
          })}
          css={[
            styles.textArea,
            minLines ? styles.minLines(minLines, size, monospace) : null,
          ]}
        />

        {!!maxLength && showMaxLength && (
          <TextMaxLength
            size={TEXT_AREA_MAX_LENGTH_SIZE_MAPPING[size]}
            maxLength={maxLength}
            currentLength={props.value?.length || 0}
            isFocused={isFocused}
            css={styles.maxLength}
          />
        )}
      </div>
    );
  },
);

TextAreaBase.displayName = 'TextArea';

TextAreaBase.propTypes = {
  /** Current input value. */
  value: PropTypes.string,
  /** Max number of characters in the input. */
  maxLength: PropTypes.number,
  /** If maxLength is > 0, show the maxLength counter. */
  showMaxLength: PropTypes.bool,
  /** Text area size. */
  size: PropTypes.oneOf(Object.values(TEXT_AREA_SIZES)),
  /** Min number of lines to display without scroll bar. */
  minLines: PropTypes.number,
  /** Is the textarea disabled. */
  disabled: PropTypes.bool,
  /** The textarea has an error. */
  hasError: PropTypes.bool,
  /** The textarea has a warning. */
  hasWarning: PropTypes.bool,
  /** Event handler. Called with the new value directly. */
  onChange: PropTypes.func,
  /** Class name. */
  className: PropTypes.string,
  /** Use a monospace font. */
  monospace: PropTypes.bool,
};

TextAreaBase.defaultProps = {
  value: undefined,
  maxLength: null,
  showMaxLength: true,
  size: TEXT_AREA_SIZES.MEDIUM,
  minLines: null,
  disabled: false,
  hasError: false,
  hasWarning: false,
  onChange: noop,
  className: null,
  monospace: false,
};

export const TextArea: MemoExoticComponent<typeof TextAreaBase> & {
  SIZES?: typeof TEXT_AREA_SIZES;
  MAX_LENGTH_SIZE_MAPPING?: typeof TEXT_AREA_MAX_LENGTH_SIZE_MAPPING;
} = memo(TextAreaBase);

TextArea.SIZES = TEXT_AREA_SIZES;
TextArea.MAX_LENGTH_SIZE_MAPPING = TEXT_AREA_MAX_LENGTH_SIZE_MAPPING;
