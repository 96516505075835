import { useEffect } from 'react';
import { useUpdate } from 'react-use';

import dayjs from '@eversity/services/dayjs';

/**
 * Given the difference in seconds, return the time to wait before rerendering in ms.
 *
 * @param diffInSeconds - Difference between date and now.
 * @returns The time to wait before rerendering in ms.
 */
const getUpdateTimeout = (diffInSeconds: number) => {
  const absDiff = Math.abs(diffInSeconds);

  // If diff is less that a minute, update every second.
  if (absDiff < 60) {
    return 1000;
  }

  // If diff is less than an hour, update every minute.
  if (absDiff < 60 * 60) {
    return Math.max(
      // E.g. if the diff is 100 seconds, re-render in 20 seconds.
      1000 * (60 - (absDiff % 60)),
      // Re-render in no less than 1 second to make sure that this does not update too frequently.
      1000,
    );
  }

  // If diff is less than a day, update every hour.
  if (absDiff < 60 * 60 * 24) {
    return Math.max(
      // E.g. if the diff is 6000 seconds (1h40min), re-render in 20 minutes (1200s).
      1000 * (60 * 60 - (absDiff % (60 * 60))),
      // Re-render in no less than 1 minute to make sure that this does not update too frequently.
      1000 * 60,
    );
  }

  return null;
};

export const useRelativeTimeFromNow = (
  date: string | Date,
  {
    /** Limit in seconds of the time difference before rendering absolute instead of relative. */
    limitRelative = 7 * 24 * 60 * 60,
    absoluteFormat = 'LL',
    enabled = true,
  }: {
    limitRelative?: number;
    absoluteFormat?: string;
    enabled?: boolean;
  },
) => {
  const update = useUpdate();

  const hasDate = !!date;
  const diff = dayjs(date).diff(dayjs(), 'seconds');

  useEffect(() => {
    if (!hasDate || !enabled) {
      return undefined;
    }

    const timeout = getUpdateTimeout(diff);

    if (timeout) {
      const timeoutHandle = setTimeout(update, timeout);

      return () => clearTimeout(timeoutHandle);
    }

    return undefined;
  }, [hasDate, enabled, update, diff]);

  const isRelative = diff >= limitRelative * -1;

  return {
    isRelative,
    diffInSeconds: diff,
    formattedDate: isRelative
      ? dayjs(date).fromNow()
      : dayjs(date).format(absoluteFormat),
  };
};
