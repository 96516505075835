import { defineMessages } from 'react-intl';

export default defineMessages({
  STEP_INDEX: {
    defaultMessage: 'étape {index}',
  },

  STATUS_UPCOMING: {
    defaultMessage: 'À venir',
  },

  STATUS_CURRENT: {
    defaultMessage: 'En cours',
  },

  STATUS_UNCOMPLETE: {
    defaultMessage: 'Incomplet',
  },

  STATUS_DONE: {
    defaultMessage: 'Complété',
  },
});
