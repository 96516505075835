import { memo, type MouseEventHandler, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type TooltipRenderProps } from 'react-joyride';
import { useTheme } from '@emotion/react';
import { Close } from '@icon-park/react';

import { TRACKING_EVENTS_TYPES } from '@eversity/domain/constants';
import { Button, Card, Typography, WaveCard } from '@eversity/ui/design-system';
import { globalMessages } from '@eversity/ui/intl';

import { useTrackingEvent } from '../../../../../hooks/mutations/trackingEvent';
import * as styles from './CustomTooltip.styles';

type TourActions = 'close' | 'finish';

export const CustomTooltip = memo(
  ({
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps) => {
    const theme = useTheme();

    const intl = useIntl();

    const { mutate: addTrackingEvent } = useTrackingEvent();

    const handleButtonClick = useCallback(
      ({
        mouseEvent,
        onClick,
        tourId,
        action,
      }: {
        mouseEvent: React.MouseEvent<HTMLButtonElement>;
        onClick: MouseEventHandler<HTMLButtonElement>;
        tourId: string;
        action?: TourActions;
      }) => {
        onClick(mouseEvent);

        if (action) {
          addTrackingEvent({
            type:
              action === 'close'
                ? TRACKING_EVENTS_TYPES.CLOSE_TOUR
                : TRACKING_EVENTS_TYPES.FINISH_TOUR,
            references: {
              productTourId: tourId,
            },
            eventDate: new Date().toISOString(),
          });
        }
      },
      [addTrackingEvent],
    );

    return (
      <Card
        color={theme.colors.primary[500]}
        css={styles.container}
        {...tooltipProps}
      >
        <div css={styles.content}>
          <Button
            size={Button.SIZES.SMALL}
            {...skipProps}
            onClick={(e) =>
              handleButtonClick({
                mouseEvent: e,
                onClick: skipProps.onClick,
                tourId: step.data?.id,
                action: 'close',
              })
            }
            aria-label={intl.formatMessage(globalMessages.LEAVE)}
            icon={<Close />}
            css={styles.closeButton}
          />
          <Typography variant={Typography.VARIANTS.HEADING_3}>
            {step.title}
          </Typography>
          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            {step.content}
          </Typography>
        </div>
        <WaveCard
          hue={WaveCard.HUES.BLUE}
          css={styles.waveCard}
        >
          <div css={styles.footer}>
            {index > 0 && (
              <Button
                outline
                variant={Button.VARIANTS.PRIMARY}
                size={Button.SIZES.SMALL}
                {...backProps}
                onClick={(e) =>
                  handleButtonClick({
                    mouseEvent: e,
                    onClick: backProps.onClick,
                    tourId: step.data?.id,
                  })
                }
              >
                <FormattedMessage {...globalMessages.PREVIOUS} />
              </Button>
            )}
            <Button
              {...primaryProps}
              variant={Button.VARIANTS.PRIMARY}
              onClick={(e) =>
                handleButtonClick({
                  mouseEvent: e,
                  onClick: primaryProps.onClick,
                  tourId: step.data?.id,
                  action: isLastStep ? 'finish' : undefined,
                })
              }
            >
              <FormattedMessage
                {...(isLastStep ? globalMessages.FINISH : globalMessages.NEXT)}
              />
            </Button>
          </div>
        </WaveCard>
      </Card>
    );
  },
);
