import { type Context, createContext, useContext } from 'react';

export const LayerContext: Context<{
  isInLayer: boolean;
  layerZIndex: number;
  onRequestClose?: () => void;
  shouldCloseOnClickOverlay?: boolean;
  preventClosing?: boolean;
}> = createContext({
  isInLayer: false,
  layerZIndex: null,
  onRequestClose: undefined,
  shouldCloseOnClickOverlay: true,
  preventClosing: false,
});

export const useLayerContext = () => useContext(LayerContext);
