import { defineMessages } from 'react-intl';

import { LEGAL_DOCUMENT_TYPES } from '@eversity/domain/constants';

export const legalDocumentTypesMessages = defineMessages<LEGAL_DOCUMENT_TYPES>({
  [LEGAL_DOCUMENT_TYPES.SCHOOL_RULES]: {
    defaultMessage: 'Règlement intérieur',
  },

  [LEGAL_DOCUMENT_TYPES.PRIVACY_POLICY]: {
    defaultMessage: 'Politique de confidentialité',
  },
});
