import { createContext } from 'react';

import { EMPTY_STATE_SIZES } from './constants';

type EmptyStateContextValue = {
  size: EMPTY_STATE_SIZES;
};

export const EmptyStateContext = createContext<EmptyStateContextValue>({
  size: EMPTY_STATE_SIZES.MEDIUM,
});
