import { defineMessages } from 'react-intl';

export default defineMessages({
  IMPORT_TITLE: {
    defaultMessage: 'Importer une photo',
  },
  HISTORY_TITLE: {
    defaultMessage: 'Sélectionner une photo',
  },
  NO_PICTURE_SELECTED: {
    defaultMessage: 'Aucune photo sélectionnée',
  },
});
