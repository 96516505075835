import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import { type GroupBase, type SelectInstance } from 'react-select';
import ReactAsyncCreatableSelect, {
  type AsyncCreatableProps,
} from 'react-select/async-creatable';

import { type SelectOption } from '@eversity/types/web';

import { type CommonSelectProps, SELECT_SIZES } from './constants';
import {
  commonSelectDefaultProps,
  creatableSelectDefaultProps,
} from './utils/defaultProps';
import {
  commonSelectPropTypes,
  creatableSelectPropTypes,
} from './utils/propTypes';
import { useReactSelectCustomization } from './utils/useReactSelectCustomizations';
import { type useReactSelectValue } from './utils/useReactSelectValueWrapper';

export type AsyncCreatableSelectProps<
  TOption extends SelectOption<any> = SelectOption<any>,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = Omit<
  AsyncCreatableProps<TOption, TIsMulti, TGroup>,
  'isDisabled' | 'formatOptionLabel' | 'value' | 'onChange' | 'options'
> &
  CommonSelectProps & {
    onChange: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['onChange'];
    value: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['value'];
  };

const AsyncCreatableSelectInner = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  {
    id,
    size,
    disabled,
    hasError,
    hasWarning,
    ...props
  }: AsyncCreatableSelectProps<TOption, TIsMulti, TGroup>,
  ref: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>,
) => {
  const { onChange, onChangeMulti, ...customProps } =
    useReactSelectCustomization<TOption, TIsMulti, TGroup, true>({
      hasError,
      hasWarning,
      size,
      useOptionAsValue: true,
      ...props,
    });

  return (
    <ReactAsyncCreatableSelect<TOption, TIsMulti, TGroup>
      {...props}
      {...customProps}
      ref={ref}
      inputId={id}
      aria-labelledby={id}
      onChange={props.isMulti ? onChangeMulti : onChange}
      isDisabled={disabled}
    />
  );
};

export const AsyncCreatableSelectBase = forwardRef(
  AsyncCreatableSelectInner,
) as {
  <
    TOption extends SelectOption<any> = SelectOption<any>,
    TIsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  >(
    props: AsyncCreatableSelectProps<TOption, TIsMulti, TGroup> & {
      ref?: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>;
    },
  ): ReactElement | null;

  propTypes?: any;
  defaultProps?: any;
  displayName?: any;
};

AsyncCreatableSelectBase.displayName = 'AsyncCreatableSelect';

AsyncCreatableSelectBase.propTypes = {
  ...commonSelectPropTypes,
  ...creatableSelectPropTypes,
};

AsyncCreatableSelectBase.defaultProps = {
  ...commonSelectDefaultProps,
  ...creatableSelectDefaultProps,
};

export const AsyncCreatableSelect = memo(
  AsyncCreatableSelectBase,
) as typeof AsyncCreatableSelectBase & {
  SIZES?: typeof SELECT_SIZES;
};

AsyncCreatableSelect.SIZES = SELECT_SIZES;
