export enum NOTIFICATION_TYPES {
  COURSE_EXAMS_INFORMATION_CHANGED = 'COURSE_EXAMS_INFORMATION_CHANGED',
  COURSE_GUIDE_INFORMATION_CHANGED = 'COURSE_GUIDE_INFORMATION_CHANGED',
  COURSE_INTERNSHIPS_INFORMATION_CHANGED = 'COURSE_INTERNSHIPS_INFORMATION_CHANGED',
  COURSE_WORK_STUDY_INFORMATION_CHANGED = 'COURSE_WORK_STUDY_INFORMATION_CHANGED',
  ENCOURAGE_STUDENT_CONNECTION = 'ENCOURAGE_STUDENT_CONNECTION',
  NEWS_PUBLISHED = 'NEWS_PUBLISHED',
  ONBOARDING_MEETING_REQUIRED = 'ONBOARDING_MEETING_REQUIRED',
  RESET_STUDENT_CARD = 'RESET_STUDENT_CARD',
  STUDENT_DELAYED_PAYMENT = 'STUDENT_DELAYED_PAYMENT',
  STUDENT_PAID = 'STUDENT_PAID',
  SUBMISSION_GRADED = 'SUBMISSION_GRADED',
  SUBMISSION_COMMENTED = 'SUBMISSION_COMMENTED',
  VIRTUAL_CLASSROOM_UPCOMING = 'VIRTUAL_CLASSROOM_UPCOMING',
  WELCOME_PACK_ORDER = 'WELCOME_PACK_ORDER',
}

export const NOTIFICATION_EXPIRATION_DELAY = {
  AMOUNT: 6,
  UNIT: 'months',
} as const;
