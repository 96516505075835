import 'react-toastify/dist/ReactToastify.css';

import { memo, type ReactNode } from 'react';
import { type ToastContainerProps } from 'react-toastify';
import {
  type Theme,
  ThemeProvider as EmotionThemeProvider,
} from '@emotion/react';
import { IconProvider } from '@icon-park/react/es/runtime';

import { DEFAULT_THEME } from '../../../themes/default';
import { ToastContainer } from '../../feedback/toast/ToastContainer';
import { ToastGlobalStyles } from '../../feedback/toast/ToastGlobalStyles';
import { ICON_CONFIG } from '../../general/icon/constants';
import { IconGlobalStyles } from '../../general/icon/IconGlobalStyles';
import { DialogsProvider } from '../dialogs-provider/DialogsProvider';
import { LayersProvider } from '../layers-provider/LayersProvider';

export type DesignSystemProviderProps = {
  theme?: Theme;
  rootElementSelector?: string;
  minLayerZIndex?: number;
  layerZIndexInterval?: number;
  children?: ReactNode;
  toastContainerProps?: ToastContainerProps;
};

export const DesignSystemProviderBase = ({
  theme = DEFAULT_THEME,
  rootElementSelector = '#root',
  toastContainerProps = null,
  minLayerZIndex = undefined,
  layerZIndexInterval = undefined,
  children = null,
}: DesignSystemProviderProps) => {
  const { globalStylesComponent: ThemeGlobalStyles } = theme;

  return (
    <EmotionThemeProvider theme={theme}>
      <LayersProvider
        minLayerZIndex={minLayerZIndex}
        layerZIndexInterval={layerZIndexInterval}
      >
        <DialogsProvider rootElementSelector={rootElementSelector}>
          <IconProvider value={ICON_CONFIG}>
            {ThemeGlobalStyles && <ThemeGlobalStyles />}
            <IconGlobalStyles />
            <ToastGlobalStyles />

            {children}

            <ToastContainer {...toastContainerProps} />
          </IconProvider>
        </DialogsProvider>
      </LayersProvider>
    </EmotionThemeProvider>
  );
};

DesignSystemProviderBase.displayName = 'DesignSystemProvider';

export const DesignSystemProvider = memo(DesignSystemProviderBase);
