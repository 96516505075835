import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

export enum SWITCH_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export const SWITCH_SIZE_TYPOGRAPHY_MAPPING: Record<
  SWITCH_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [SWITCH_SIZES.SMALL]: TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR,
  [SWITCH_SIZES.MEDIUM]: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
};

export enum SWITCH_INPUT_SIDES {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
