import { css } from '@emotion/react';

export const container = css`
  border: none;
  width: min-content;
  min-width: 300px;
`;

export const content = css`
  padding: 20px;
  position: relative;
`;

export const closeButton = css`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const waveCard = css`
  border-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const footer = css`
  display: flex;
  justify-content: end;
  gap: 5px;
`;
