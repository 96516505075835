import { type HTMLProps, memo, type ReactNode } from 'react';
import PropTypes from 'prop-types';

import { VARIANTS } from '../constants';
import * as styles from './CardFooter.styles';

type CardFooterProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  variant?: VARIANTS;
  children?: ReactNode;
};

export const CardFooterBase = ({
  variant = VARIANTS.PRIMARY,
  children = null,
  ...props
}: CardFooterProps) => (
  <div
    css={(theme) => styles.container(theme, variant)}
    {...props}
  >
    <div css={(theme) => styles.waveThree(theme, variant)} />

    <div css={(theme) => styles.waveTwo(theme, variant)} />

    <div css={(theme) => styles.waveOne(theme, variant)} />

    {children}
  </div>
);

CardFooterBase.displayName = 'CardFooter';

CardFooterBase.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  children: PropTypes.node,
};

export const CardFooter = memo(CardFooterBase);
