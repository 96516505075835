import {
  type PostCurrentUserSessionPingActiveBody,
  type Session,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

export class SessionsRepository extends HttpRepository {
  /**
   * Set the active ping on current session.
   *
   * @returns The current session.
   */
  async setActivePing({
    courseId,
    classId,
  }: PostCurrentUserSessionPingActiveBody) {
    const { body: session } = await this.http
      .post('/api/v1/users/users/me/sessions/ping/active')
      .send({ courseId, classId });

    return session as Session;
  }
}
