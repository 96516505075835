import { memo, type ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import { VideoTwo } from '@icon-park/react';

import * as styles from './LessonResourcePicture.styles';

export type LessonResourcePictureProps = {
  picture?: { href: string } | null;
  children?: ReactNode;
  width?: number;
};

export const LessonResourcePictureBase = ({
  picture = null,
  width = 235,
  children = null,
}: LessonResourcePictureProps) => {
  const theme = useTheme();

  return (
    <div css={styles.container(width, picture)}>
      {!picture && (
        <VideoTwo
          size={48}
          strokeWidth={2}
          fill={[theme.colors.primary[500], theme.colors.primary[50]]}
        />
      )}
      {children}
    </div>
  );
};

LessonResourcePictureBase.displayName = 'LessonResourcePicture';

export const LessonResourcePicture = memo(LessonResourcePictureBase);
