import { type ForwardedRef, forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  type SanitizeSchema,
  transformHtmlToHast,
} from '@eversity/domain/html';
import { sanitizeSchemaPropTypes } from '@eversity/ui/utils';

import {
  HastRenderer,
  type HastRendererProps,
} from '../hast-renderer/HastRenderer';

export type HtmlRendererProps = Omit<HastRendererProps, 'hast'> & {
  html?: string;
  sanitizeSchema: SanitizeSchema;
};

export const HtmlRendererBase = forwardRef(
  (
    { html, sanitizeSchema, ...props }: HtmlRendererProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const hast = useMemo(
      () => transformHtmlToHast(html, { sanitizeSchema }),
      [html, sanitizeSchema],
    );

    return (
      <HastRenderer
        {...props}
        ref={ref}
        hast={hast}
      />
    );
  },
);

HtmlRendererBase.displayName = 'HtmlRenderer';

HtmlRendererBase.propTypes = {
  html: PropTypes.string,
  sanitizeSchema: sanitizeSchemaPropTypes.isRequired,
};

HtmlRendererBase.defaultProps = {
  html: '',
};

export const HtmlRenderer = memo(HtmlRendererBase);
