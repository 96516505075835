import { useCallback } from 'react';
import { isFunction } from 'lodash';

import { type LastVisitedCourse, type UserSettings } from '../../../types';
import { useAppLocalStorage } from './useAppLocalStorage';

export const useUserLastVisitedCourse = () =>
  useAppLocalStorage<LastVisitedCourse>('lastVisitedCourse');

type UpdateSettingsFn = (
  updates:
    | Partial<UserSettings>
    | ((currentSettings: UserSettings) => Partial<UserSettings>),
) => void;

export const useUserSettings = (): [
  value: UserSettings,
  onChange: UpdateSettingsFn,
] => {
  const [value, setValue] = useAppLocalStorage<UserSettings>('settings');

  const setValueProxy: UpdateSettingsFn = useCallback(
    (settings) =>
      setValue((currentSettings) => ({
        ...currentSettings,
        ...(isFunction(settings) ? settings(currentSettings) : settings),
      })),
    [setValue],
  );

  return [value, setValueProxy];
};
