import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
  type ReactNode,
} from 'react';
import { useTheme } from '@emotion/react';
import PropTypes, { type ReactComponentLike } from 'prop-types';

import {
  TYPOGRAPHY_DEFAULT_ELEMENTS,
  TYPOGRAPHY_VARIANTS,
} from '../../../config/typography/constants';

export type TypographyProps = {
  variant: string;
  element?: ReactComponentLike;
  className?: string;
  children?: ReactNode;
};

export const TypographyBase = forwardRef(
  (
    {
      variant,
      element = undefined,
      children = undefined,
      ...props
    }: TypographyProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const theme = useTheme();

    const TypographyComponent = element || TYPOGRAPHY_DEFAULT_ELEMENTS[variant];

    return (
      <TypographyComponent
        {...props}
        ref={ref}
        css={theme.typography[variant]}
      >
        {children}
      </TypographyComponent>
    );
  },
);

TypographyBase.displayName = 'Typography';

TypographyBase.propTypes = {
  /** Variant from the TYPOGRAPHY_VARIANTS enum (accessible via Typography.VARIANTS). */
  variant: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)).isRequired,
  /** Override the default element. */
  element: PropTypes.elementType,
};

TypographyBase.defaultProps = {
  element: undefined,
  className: undefined,
  children: undefined,
};

export const Typography: MemoExoticComponent<typeof TypographyBase> & {
  VARIANTS?: typeof TYPOGRAPHY_VARIANTS;
} = memo(TypographyBase);

Typography.VARIANTS = TYPOGRAPHY_VARIANTS;
