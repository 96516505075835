import {
  type ForwardedRef,
  type MutableRefObject,
  useEffect,
  useRef,
} from 'react';

/**
 * Use this hook if you need to use the forwarded ref in a component that forwards its ref.
 *
 * @example
 * const MyComponent = forwardRef((props, ref) => {
 *   const actualRef = useForwardedRef(ref);
 *
 *   useEffect(
 *     () => console.log(actualRef.getBoundingClientRect()),
 *   );
 *
 *   return (
 *     <div ref={actualRef} />
 *   );
 * });
 *
 * @param ref - Reference.
 * @returns Reference.
 */
export const useForwardedRef = <T>(
  ref?: ForwardedRef<T | undefined>,
): MutableRefObject<T | undefined> => {
  const innerRef = useRef<T>();

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
