import {
  type PutUserTrackingEventBody,
  type TrackingEvent,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

export class TrackingEventsRepository extends HttpRepository {
  /**
   * Add tracking event for the current user..
   *
   * @returns The tracking event created.
   */
  async addTrackingEvent({
    type,
    eventDate,
    references,
  }: PutUserTrackingEventBody): Promise<TrackingEvent> {
    const { body: trackingEvent } = await this.http
      .put('/api/v1/users/users/me/activities/event')
      .send({
        type,
        eventDate,
        references,
      });

    return trackingEvent as TrackingEvent;
  }
}
