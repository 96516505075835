import { cloneElement, memo, type ReactElement } from 'react';
import { css, useTheme } from '@emotion/react';
import { Right } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { Dot } from '../dot/Dot';
import { ICON_SIZES } from '../icon/constants';
import { Typography } from '../typography/Typography';
import { headerStyle } from './Accordion.styles';

export type AccordionHeaderProps = {
  title: string;
  subtitle?: string;
  icon?: ReactElement;
  isOpen?: boolean;
  onClick?: () => void;
  withDot?: boolean;
};

export const AccordionHeaderBase = ({
  title,
  subtitle = undefined,
  icon = undefined,
  isOpen = false,
  onClick = noop,
  withDot = false,
}: AccordionHeaderProps) => {
  const theme = useTheme();

  return (
    <button
      type="button"
      css={headerStyle}
      onClick={onClick}
    >
      {icon && (
        <span className="iconLeft">
          <div
            css={css`
              position: relative;
            `}
          >
            {withDot && <Dot className="dot" />}
            {cloneElement(icon, {
              size: ICON_SIZES.LARGE,
              fill: [theme.colors.primary[500], theme.colors.primary[50]],
            })}
          </div>
        </span>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 4px 10px;
          flex: 1;
        `}
      >
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
          {title}
        </Typography>
        <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
          {subtitle}
        </Typography>
      </div>
      <Right
        size={ICON_SIZES.MEDIUM}
        className={cn('iconRight', isOpen && 'OPEN')}
      />
    </button>
  );
};

AccordionHeaderBase.displayName = 'AccordionHeader';

AccordionHeaderBase.propTypes = {
  /** Title of the group of items. */
  title: PropTypes.node.isRequired,
  /** Subtitle of the group of items. */
  subtitle: PropTypes.node,
  /** Icon displayed on the left of the header. */
  icon: PropTypes.node,
  /** Define if the list of item is currently displayed. */
  isOpen: PropTypes.bool,
  /** Callback triggered on the click of the header. */
  onClick: PropTypes.func,
  /** Add a dot on the top right corner of the left icon. */
  withDot: PropTypes.bool,
};

export const AccordionHeader = memo(AccordionHeaderBase);
