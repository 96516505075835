import { defineMessages } from 'react-intl';

export default defineMessages({
  PROMPT: {
    defaultMessage:
      "<h4>Bonjour ! Nous avons besoin de ton avis !</h4><p>Sans ta <b>contribution</b>, impossible de construire un service qui place véritablement l'apprenant au centre !</p><p>Nous avons <b>quelques questions</b> à te poser avant de te laisser retourner à tes activités !</p>",
  },

  ANSWER: {
    defaultMessage: 'Répondre aux questions',
  },

  ASK_LATER: {
    defaultMessage: 'Répondre plus tard',
  },

  NEVER_ASK_AGAIN: {
    defaultMessage: 'Ne plus être sollicité pour cette enquête de satisfaction',
  },
} as const);
