import { useMemo } from 'react';

/**
 * Use this hook to replace useMemo(() => ({ a, b }), [a, b]) with useMemoizedBundle({ a, b }).
 *
 * @param bundle - Object whose properties will be memoized.
 * @returns The bundle.
 */
export const useMemoizedBundle = <
  T extends Parameters<(typeof Object)['values']>[0],
>(
  bundle: T,
): T =>
  useMemo(
    () => bundle,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is the point of the hook.
    Object.values<unknown>(bundle),
  );
