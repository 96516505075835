import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { css, useTheme } from '@emotion/react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { type TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { Typography } from '../../general/typography/Typography';
import { HtmlFormatter } from '../html-formatter/HtmlFormatter';
import { EMPTY_STATE_SIZES, EMPTY_STATE_VARIANTS } from './constants';
import { EmptyStateContext } from './EmptyStateContext';
import {
  EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING,
  Paragraph,
  type TypographyMapping,
} from './paragraph/Paragraph';

const EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING: Record<
  EMPTY_STATE_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [EMPTY_STATE_SIZES.SMALL]: Typography.VARIANTS.HEADING_4,
  [EMPTY_STATE_SIZES.MEDIUM]: Typography.VARIANTS.HEADING_3,
  [EMPTY_STATE_SIZES.LARGE]: Typography.VARIANTS.HEADING_2,
};

const EMPTY_STATE_SIZE_ICON_SIZE_MAPPING: Record<EMPTY_STATE_SIZES, number> = {
  [EMPTY_STATE_SIZES.SMALL]: 50,
  [EMPTY_STATE_SIZES.MEDIUM]: 80,
  [EMPTY_STATE_SIZES.LARGE]: 120,
};

const EMPTY_STATE_SIZE_ICON_PADDING_MAPPING: Record<EMPTY_STATE_SIZES, number> =
  {
    [EMPTY_STATE_SIZES.SMALL]: 20,
    [EMPTY_STATE_SIZES.MEDIUM]: 30,
    [EMPTY_STATE_SIZES.LARGE]: 50,
  };

const EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING: Record<
  EMPTY_STATE_SIZES,
  number
> = {
  [EMPTY_STATE_SIZES.SMALL]: 2,
  [EMPTY_STATE_SIZES.MEDIUM]: 1.5,
  [EMPTY_STATE_SIZES.LARGE]: 1,
};

export type EmptyStateProps = {
  title: ReactNode;
  icon?: ReactElement;
  size?: EMPTY_STATE_SIZES;
  children?:
    | ReactNode
    | ((props: { typography: TypographyMapping }) => ReactNode);
  variant?: EMPTY_STATE_VARIANTS;
};

export const EmptyStateBase = ({
  title,
  icon = null,
  size = EMPTY_STATE_SIZES.MEDIUM,
  variant = EMPTY_STATE_VARIANTS.PRIMARY,
  children = null,
}: EmptyStateProps) => {
  const theme = useTheme();

  const contextValue = useMemoizedBundle({
    size,
  });

  return (
    <EmptyStateContext.Provider value={contextValue}>
      <div
        className={variant}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;

          &.${EMPTY_STATE_VARIANTS.PRIMARY} {
            color: ${theme.colors.primary[700]};
          }

          &.${EMPTY_STATE_VARIANTS.TERTIARY} {
            color: ${theme.colors.tertiary[700]};
          }
        `}
      >
        {icon && (
          <div
            className={variant}
            css={css`
              padding: ${EMPTY_STATE_SIZE_ICON_PADDING_MAPPING[size]}px;
              border-radius: 100%;

              &.${EMPTY_STATE_VARIANTS.PRIMARY} {
                background-color: ${theme.colors.primary[25]};
              }

              &.${EMPTY_STATE_VARIANTS.TERTIARY} {
                background-color: ${theme.colors.tertiary[25]};
              }
            `}
          >
            {cloneElement(icon, {
              size: EMPTY_STATE_SIZE_ICON_SIZE_MAPPING[size],
              ...(variant === EMPTY_STATE_VARIANTS.PRIMARY && {
                fill: [theme.colors.primary[400], theme.colors.primary[50]],
              }),
              ...(variant === EMPTY_STATE_VARIANTS.TERTIARY && {
                fill: [theme.colors.tertiary[300], theme.colors.tertiary[50]],
              }),
              strokeWidth: EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING[size],
            })}
          </div>
        )}

        <HtmlFormatter
          css={css`
            margin-top: 36px;
            text-align: center;
          `}
        >
          <Typography
            className={variant}
            variant={EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING[size]}
            css={css`
              &.${EMPTY_STATE_VARIANTS.PRIMARY} {
                color: ${theme.colors.primary[500]};
              }

              &.${EMPTY_STATE_VARIANTS.TERTIARY} {
                color: ${theme.colors.tertiary[300]};
              }
            `}
          >
            {title}
          </Typography>

          {isFunction(children)
            ? children({
                typography: EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING[size],
              })
            : children}
        </HtmlFormatter>
      </div>
    </EmptyStateContext.Provider>
  );
};

EmptyStateBase.displayName = 'EmptyState';

EmptyStateBase.propTypes = {
  /** Icon from @icon-park/react. */
  icon: PropTypes.node,
  /** Empty state size to scale all components. */
  size: PropTypes.oneOf(Object.values(EMPTY_STATE_SIZES)),
  /** Title text. */
  title: PropTypes.node.isRequired,
  /** Content. Do not wrap inside a div (or other), it will be wrapped by HtmlFormatter. */
  children: PropTypes.oneOfType([
    PropTypes.node,
    // ({ typography: object }) => React.Node.
    PropTypes.func,
  ]),
};

export const EmptyState: MemoExoticComponent<typeof EmptyStateBase> & {
  SIZES?: typeof EMPTY_STATE_SIZES;
  Paragraph?: typeof Paragraph;
  VARIANTS?: typeof EMPTY_STATE_VARIANTS;
} = memo(EmptyStateBase);

EmptyState.SIZES = EMPTY_STATE_SIZES;
EmptyState.Paragraph = Paragraph;
EmptyState.VARIANTS = EMPTY_STATE_VARIANTS;

/**
 * Note to self: we will use new svgs in the future. In order not to break existing empty states,
 * just add another prop (picture) and render that instead of the icon.
 */
