import { type ForwardedRef, forwardRef, memo } from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { Link, type LinkProps } from '../link/Link';

export type TextLinkProps = LinkProps;

export const TextLinkBase = forwardRef(
  (
    { className, ...props }: TextLinkProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLElement>,
  ) => (
    <Link
      {...props}
      ref={ref}
      className={cn(className, { isDisabled: props.isDisabled })}
      css={(theme) => css`
        text-decoration: underline;
        color: ${theme.colors.primary[500]};

        ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]};

        transition: ${theme.transitions.default('color')};

        &.isDisabled {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          color: ${theme.colors.primary[400]};
        }

        &:visited {
          color: ${theme.colors.tertiary[500]};
        }
      `}
    />
  ),
);

TextLinkBase.displayName = 'TextLink';

TextLinkBase.propTypes = {
  /** Root element class name. */
  className: PropTypes.string,
  /** Is disabled. */
  isDisabled: PropTypes.bool,
  /** Link text. */
  children: PropTypes.node.isRequired,
};

TextLinkBase.defaultProps = {
  className: null,
  isDisabled: false,
};

export const TextLink = memo(TextLinkBase);
