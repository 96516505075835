import { useMemo } from 'react';
import mimeDb from 'mime-db';

// Override default extensions if they don't help the user.
const extensionOverrides: Record<string, string> = {
  // MimeDb has .mpga as first extension of audio/mpeg mime type.
  'audio/mpeg': 'mp3',
};

export const useFileUploadAccept = (accept?: string) => {
  const acceptedExtensions = useMemo(
    () =>
      (accept || '')
        .split(',')
        .map(
          (mimeType) =>
            extensionOverrides[mimeType] || mimeDb[mimeType]?.extensions?.[0],
        )
        .filter(Boolean) as string[],
    [accept],
  );

  return acceptedExtensions;
};
