import { defineMessages } from 'react-intl';

export const controlsTooltipsMessages = defineMessages({
  CLOSE_AUDIO_PLAYER: {
    defaultMessage: 'Fermer le lecteur',
  },

  PLAY: {
    defaultMessage: 'Lire',
  },

  PAUSE: {
    defaultMessage: 'Pause',
  },
});
