export type Tree = {
  children?: Tree[];
  [key: string]: any;
};

export type FilterPredicate<T extends Tree> = (
  node: T,
  path: number[],
  parent: T,
  tree: T,
) => boolean;

/**
 * Given a tree with the format { children: [] }, find the node at the given path.
 *
 * @param tree - Tree.
 * @param path - Path to node.
 * @returns Node.
 */
export function getNodeAtPath<T extends Tree>(tree: T, path: number[]): T {
  return path.reduce((acc, index) => acc.children[index] as T, tree);
}

/**
 * Copied from https://github.com/syntax-tree/unist-util-map/blob/main/index.js.
 * The lib is written in ESM and that required us to change the webpack config.
 * Seeing that the lib is about 10 lines of code it seemed easier to just copy it.
 *
 * @param tree - UNIST tree.
 * @param iteratee - Map function called with (node, index, parentNode).
 * @returns New tree.
 */
export function mapTree<T extends Tree, U extends Tree>(
  tree: T,
  iteratee: (node: T, index?: number, parentNode?: T) => U,
): U {
  function preorder(node: T, index?: number, parent?: T) {
    const newNode = { ...iteratee(node, index, parent) };

    if ('children' in node) {
      newNode.children = node.children.map((child, childIdx) =>
        preorder(child as T, childIdx, node),
      );
    }

    return newNode;
  }

  return preorder(tree, null, null);
}

/**
 * Filter all the node's children recursively.
 *
 * @param predicate Predicate to deep filter.
 * @param tree Tree to filter.
 * @param node Current node.
 * @param nodePath Path to current node.
 * @param parent Current node parent.
 * @returns Filtered node.
 */
function filterRecursive<T extends Tree>(
  predicate: FilterPredicate<T>,
  tree: T,
  node: T,
  nodePath: number[],
  parent?: T,
): T | null {
  const nodeCopy = { ...node };

  if (nodeCopy.children) {
    nodeCopy.children = nodeCopy.children
      .map((child, index) =>
        filterRecursive(predicate, tree, child, [...nodePath, index], nodeCopy),
      )
      .filter(Boolean);
  }

  return predicate(nodeCopy, nodePath, parent, tree) ? nodeCopy : null;
}

/**
 * Filter a tree, starting from the bottom.
 *
 * @param tree - Root node of the tree.
 * @param predicate - Callback to validate each node.
 */
export function filterTree<T extends Tree>(
  tree: T,
  predicate: FilterPredicate<T>,
) {
  return filterRecursive(predicate, tree, tree, []);
}

/**
 * Given a node path in a tree, find the first parent that answers a predicate.
 *
 * @param tree - Tree.
 * @param path - Path to origin node.
 * @param predicate - Predicate (node => boolean).
 * @returns Node or undefined.
 */
export function findParent<T extends Tree>(
  tree: T,
  path: number[],
  predicate: (node: T) => boolean,
): [T, number[]] | undefined {
  for (let i = 1; i <= path.length; ++i) {
    const pathSlice = path.slice(0, i * -1);

    const node = getNodeAtPath(tree, pathSlice);
    if (predicate(node)) {
      return [node, pathSlice];
    }
  }

  return undefined;
}

/**
 * Find a node a tree based on a predicate.
 * Walk the tree from top to bottom.
 *
 * @param tree - Unist tree.
 * @param predicate - Function taking a node and returning a boolean.
 * @returns Found node or undefined.
 */
export function findInTree<T extends Tree>(
  tree: T,
  predicate: (node: T) => boolean,
): T | undefined {
  if (predicate(tree)) {
    return tree;
  }

  for (let i = 0; i < tree.children?.length || 0; ++i) {
    const result = findInTree(tree.children[i], predicate);

    if (result) {
      return result as T;
    }
  }

  return undefined;
}
