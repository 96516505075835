export enum BADGE_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEUTRAL = 'neutral',
}

export enum BADGE_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}
