import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';

import {
  DEFAULT_VIDEO_ASPECT_RATIO,
  DEFAULT_VIDEO_WIDTH,
} from '@eversity/domain/constants';

export type VideoProps = Omit<HTMLProps<HTMLElement>, 'ref'> & {
  src: string;
  caption?: string;
};

export const VideoBase = forwardRef(
  ({ src, caption, ...props }: VideoProps, ref: ForwardedRef<HTMLElement>) => (
    <figure
      {...props}
      ref={ref}
    >
      <ReactPlayer
        url={src}
        width={DEFAULT_VIDEO_WIDTH}
        height={DEFAULT_VIDEO_WIDTH / DEFAULT_VIDEO_ASPECT_RATIO}
        controls
      />

      {!!caption && <figcaption>{caption}</figcaption>}
    </figure>
  ),
);

VideoBase.displayName = 'Video';

VideoBase.propTypes = {
  src: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

VideoBase.defaultProps = {
  caption: null,
};

export const Video = memo(VideoBase);
