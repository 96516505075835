import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { type WidgetProps } from '@typeform/embed-react';

import { TRACKING_EVENTS_TYPES } from '@eversity/domain/constants';
import { TrackingEventsRepository } from '@eversity/domain/data/repositories';
import { type FeedbackCampaign } from '@eversity/types/domain';

import http from '../../../services/http/api';

const trackingEventsRepository = new TrackingEventsRepository(http);

export const useTypeformCallbacks = (feedbackCampaign: FeedbackCampaign) => {
  const [hasSentSuggestion, setHasSentSuggestion] = useState(false);

  const onSubmitTypeform: WidgetProps['onSubmit'] = useCallback(
    async (event) => {
      try {
        await trackingEventsRepository.addTrackingEvent({
          type: TRACKING_EVENTS_TYPES.FEEDBACK_CAMPAIGN_ANSWER,
          references: {
            feedbackCampaign: feedbackCampaign?.id,
            feedbackCampaignResponse: event.responseId,
          },
        });
      } catch (err) {
        Sentry.captureException(err);
      }

      setHasSentSuggestion(true);
    },
    [feedbackCampaign],
  );

  const onCloseTypeform: WidgetProps['onClose'] = useCallback(async () => {
    if (!hasSentSuggestion) {
      try {
        await trackingEventsRepository.addTrackingEvent({
          type: TRACKING_EVENTS_TYPES.FEEDBACK_CAMPAIGN_DISMISS,
          references: {
            feedbackCampaign: feedbackCampaign?.id,
          },
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }, [hasSentSuggestion, feedbackCampaign]);

  return {
    onSubmitTypeform,
    onCloseTypeform,
  };
};
