import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

export const dropzone = (theme: Theme) => css`
  transition: ${theme.transitions.default()};
  padding: 20px 40px;
  min-height: 150px;

  background-color: ${theme.colors.gray[0]};
  border: 2px dashed ${theme.colors.gray[200]};
  border-radius: 4px;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;

  &.isDropzoneDisabled {
    background-color: ${theme.colors.gray[25]};
    color: ${theme.colors.gray[300]};
    cursor: not-allowed;
  }

  &.isFocused,
  &.isDragActive {
    border-color: ${theme.colors.primary[400]};
  }

  &.isFocused {
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &.isDragAccept {
    border-color: ${theme.colors.success[400]};

    &.isFocused {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.success[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &.isDragReject {
    border-color: ${theme.colors.danger[400]};

    &.isFocused {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.danger[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &:not(.isDragAccept):not(.isDragReject):not(.isDragActive) {
    &.hasError {
      &,
      &.isFocused {
        border-color: ${theme.colors.danger[400]};
      }

      &.isFocused {
        box-shadow: 0 0 0 4px
          ${new TinyColor(theme.colors.danger[500])
            .setAlpha(0.15)
            .toRgbString()};
      }
    }

    &.hasWarning {
      &,
      &.isFocused {
        border-color: ${theme.colors.warning[400]};
      }

      &.isFocused {
        box-shadow: 0 0 0 4px
          ${new TinyColor(theme.colors.warning[500])
            .setAlpha(0.15)
            .toRgbString()};
      }
    }
  }
`;

export const noDisplayEmpty = css`
  &:empty {
    display: none;
  }
`;

export const assetList = css`
  list-style-type: none;
  margin-top: 10px;

  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;

export const getIconFills = (
  theme: Theme,
  isDisabled: boolean,
  isFocused: boolean,
  isReject: boolean,
  isAccept: boolean,
  hasError: boolean,
  hasWarning: boolean,
) => {
  if (isDisabled) {
    return theme.colors.gray[300];
  }

  if (isReject) {
    return theme.colors.danger[500];
  }

  if (isAccept) {
    return theme.colors.success[500];
  }

  if (hasError) {
    return theme.colors.danger[500];
  }

  if (hasWarning) {
    return theme.colors.warning[500];
  }

  if (isFocused) {
    return theme.colors.primary[500];
  }

  return theme.colors.gray[500];
};
