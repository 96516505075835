import { useLocalStorage } from '@eversity/ui/utils';

import config from '../../../config/env';
import { useUser } from '../queries/user';

export const useAppLocalStorage: typeof useLocalStorage = (
  localStorageKey: string,
  ...args
) => {
  const { data: user } = useUser();

  return useLocalStorage(
    `${config.app.name}:${user.id}:${localStorageKey}`,
    ...args,
  );
};
