import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

export type AudioPlayerProps = Omit<HTMLProps<HTMLAudioElement>, 'ref'> & {
  controlsList?: string;
};

export const AudioPlayerBase = forwardRef(
  (
    { src, ...props }: AudioPlayerProps,
    ref: ForwardedRef<HTMLAudioElement>,
  ) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio
      {...props}
      src={src}
      ref={ref}
      css={css`
        width: 100%;
        min-width: 185px;
        display: block;
      `}
    />
  ),
);

AudioPlayerBase.displayName = 'AudioPlayer';

AudioPlayerBase.propTypes = {
  /** Audio src. */
  src: PropTypes.string.isRequired,
  /** Should show controls (aka should show a player). */
  controls: PropTypes.bool,
  /** NOT STANDARD, only works on Chromium-based browsers. */
  controlsList: PropTypes.string,
};

AudioPlayerBase.defaultProps = {
  controls: true,
  // Disable download and playback speed controls on Chromium browsers.
  controlsList: 'nodownload noplaybackrate',
};

export const AudioPlayer = memo(AudioPlayerBase);
