import 'react-time-picker/dist/TimePicker.css';
// eslint-disable-next-line
import 'react-clock/dist/Clock.css';

import React, { Fragment, memo } from 'react';
import TimePickerComponent, { type TimePickerProps } from 'react-time-picker';
import { Global, useTheme } from '@emotion/react';
import { Delete, Time } from '@icon-park/react';

import * as styles from './Timepicker.styles';

const TimepickerBase = ({ value, onChange, ...props }: TimePickerProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Global styles={[styles.timePicker, styles.clock]} />
      <TimePickerComponent
        {...props}
        value={value}
        onChange={onChange}
        clockIcon={
          <Time
            size={18}
            fill={[
              theme.colors.primary[500],
              theme.colors.primary[50],
              theme.colors.primary[500],
            ]}
            theme="multi-color"
          />
        }
        clearIcon={
          value ? (
            <Delete
              size={18}
              fill={[
                theme.colors.danger[500],
                theme.colors.danger[50],
                theme.colors.danger[500],
              ]}
              theme="multi-color"
            />
          ) : null
        }
      />
    </Fragment>
  );
};

export const TimePicker = memo(TimepickerBase);
