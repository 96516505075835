import PropTypes from 'prop-types';

import { LEGAL_DOCUMENT_TYPES } from '@eversity/domain/constants';

export const legalDocumentPropTypes = PropTypes.shape({
  /** Technical unique id. */
  id: PropTypes.string.isRequired,
  /** Type of legal document. */
  type: PropTypes.oneOf(Object.values(LEGAL_DOCUMENT_TYPES)).isRequired,
  /** Major number of last version. */
  versionMajor: PropTypes.number.isRequired,
  /** Minor number of last version. */
  versionMinor: PropTypes.number.isRequired,
  /** HTML content of last version. */
  content: PropTypes.string.isRequired,
  /** Creation date (ISO string) of last version. */
  createdAt: PropTypes.string.isRequired,
});
