import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { type TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { Typography } from '../typography/Typography';

export type FormattedMessageTypographyProps = {
  typographies?: Record<string, TYPOGRAPHY_VARIANTS>;
  values?: Record<string, any>;
};

export const FormattedMessageTypographyBase = ({
  typographies = undefined,
  values = undefined,
  ...props
}: FormattedMessageTypographyProps) => {
  const typographyValues = typographies
    ? Object.keys(typographies).reduce(
        (acc, tagName) => ({
          ...acc,
          [tagName]: (chunks) => (
            <Typography variant={typographies[tagName]}>{chunks}</Typography>
          ),
        }),
        {} as { [key: string]: (chunks: ReactNode[]) => ReactNode },
      )
    : null;

  return (
    <FormattedMessage
      {...props}
      values={
        typographyValues || values
          ? {
              ...typographyValues,
              ...values,
            }
          : null
      }
    />
  );
};

FormattedMessageTypographyBase.displayName = 'FormattedMessage';

FormattedMessageTypographyBase.propTypes = {
  /** Typographies map from tags inside the given message. */
  typographies: PropTypes.shape({}),
  /** FormattedMessage usual values, without the typographies tags. */
  values: PropTypes.shape({}),
};

// Not using memo here because we never useMemo the values prop so it would just be useless.
export const FormattedMessageTypography = FormattedMessageTypographyBase;
