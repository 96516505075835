import { memo, type MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import { Back } from '@icon-park/react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Typography } from '@eversity/ui/design-system';

import NotFound from '../../../../assets/images/NotFound.svg';
import messages from './NotFoundError.messages';

export type NotFoundErrorProps = {
  onReturnToHome?: MouseEventHandler<HTMLButtonElement>;
};

export const NotFoundErrorBase = ({
  onReturnToHome = noop,
}: NotFoundErrorProps) => (
  <div
    css={(theme) => css`
      width: 100%;
      height: 100%;

      display: flex;

      background-color: ${theme.colors.primary[15]};
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 800px;

        margin: auto;
      `}
    >
      <NotFound />

      <Typography
        variant={Typography.VARIANTS.HEADING_3}
        css={(theme) => css`
          margin-top: 40px;

          color: ${theme.colors.primary[500]};
        `}
      >
        <FormattedMessage {...messages.PAGE_NOT_FOUND_TITLE} />
      </Typography>

      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        <FormattedMessage {...messages.PAGE_NOT_FOUND_DESCRIPTION} />
      </Typography>

      <Button
        variant={Button.VARIANTS.PRIMARY}
        icon={<Back />}
        onClick={onReturnToHome}
        css={css`
          margin-top: 20px;
        `}
      >
        <FormattedMessage {...messages.RETURN_TO_HOME} />
      </Button>
    </div>
  </div>
);

NotFoundErrorBase.displayName = 'NotFoundError';

NotFoundErrorBase.propTypes = {
  onReturnToHome: PropTypes.func,
};

export const NotFoundError = memo(NotFoundErrorBase);
