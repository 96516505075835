import {
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  type ReactNode,
} from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import { css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import { COLOR_GROUPS } from '../../../config/colors/constants';
import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { BADGE_SIZES, BADGE_VARIANTS } from './constants';

const BADGE_COLOR_GROUP_MAPPING: Record<BADGE_VARIANTS, COLOR_GROUPS> = {
  [BADGE_VARIANTS.NEUTRAL]: COLOR_GROUPS.GRAY,
  [BADGE_VARIANTS.PRIMARY]: COLOR_GROUPS.PRIMARY,
  [BADGE_VARIANTS.SECONDARY]: COLOR_GROUPS.DANGER,
};

const BADGE_SIZES_TYPOGRAPHY_MAPPING: Record<BADGE_SIZES, TYPOGRAPHY_VARIANTS> =
  {
    [BADGE_SIZES.SMALL]: TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD,
    [BADGE_SIZES.MEDIUM]: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD,
  };

const BADGE_SIZES_HEIGHT_MAPPING: Record<BADGE_SIZES, number> = {
  [BADGE_SIZES.SMALL]: 15,
  [BADGE_SIZES.MEDIUM]: 22,
};

export type BadgeProps = Omit<HTMLProps<HTMLDivElement>, 'size' | 'ref'> & {
  variant?: BADGE_VARIANTS;
  size?: BADGE_SIZES;
  children?: ReactNode;
};

export const BadgeBase = ({
  variant = BADGE_VARIANTS.NEUTRAL,
  size = BADGE_SIZES.MEDIUM,
  children = null,
  ...props
}: BadgeProps) => {
  const theme = useTheme();

  const colorGroup = BADGE_COLOR_GROUP_MAPPING[variant];

  return (
    <div
      {...props}
      css={[
        css(theme.typography[BADGE_SIZES_TYPOGRAPHY_MAPPING[size]]),
        css`
          display: inline-flex;
          justify-content: center;
          align-items: center;

          border-radius: 4px;
          padding: 0 4px;
          min-width: ${BADGE_SIZES_HEIGHT_MAPPING[size]}px;
          line-height: ${BADGE_SIZES_HEIGHT_MAPPING[size]}px;
          min-height: ${BADGE_SIZES_HEIGHT_MAPPING[size]}px;

          transition: ${theme.transitions.default()};

          background-color: ${theme.colors[colorGroup][500]};
          color: ${theme.contrastColors[colorGroup][500]};
          box-shadow: 0 0 0 4px
            ${new TinyColor(theme.colors[colorGroup][500])
              .setAlpha(0.15)
              .toRgbString()};
        `,
      ]}
    >
      {children}
    </div>
  );
};

BadgeBase.displayName = 'Badge';

BadgeBase.propTypes = {
  /** Color variant. */
  variant: PropTypes.oneOf(Object.values(BADGE_VARIANTS)),
  /** Text's size. */
  size: PropTypes.oneOf(Object.values(BADGE_SIZES)),
  /** Content. */
  children: PropTypes.node,
};

export const Badge: MemoExoticComponent<typeof BadgeBase> & {
  VARIANTS?: typeof BADGE_VARIANTS;
  SIZES?: typeof BADGE_SIZES;
} = memo(BadgeBase);

Badge.VARIANTS = BADGE_VARIANTS;
Badge.SIZES = BADGE_SIZES;
