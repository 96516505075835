import { USER_ROLES } from '../users';

export enum SUBMISSION_STATUSES {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED', // Equivalent to previous AWAITING_CORRECTION
  CORRECTION_IN_PROGRESS = 'CORRECTION_IN_PROGRESS',
  COMMENTED = 'COMMENTED',
  CORRECTED = 'CORRECTED',
}

export const ASSIGNMENT_MIN_GRADING_SCALE = 10;

export const ASSIGNMENT_MAX_GRADING_SCALE = 200;

export const ASSIGNMENT_DEFAULT_GRADING_SCALE = 20;

export const ASSIGNMENT_GRADING_STEP = 0.25;

export enum PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  EVALUATION = 'EVALUATION',
  COMMENT = 'COMMENT',
}

export enum PATCH_STUDENT_ASSIGNMENT_SUBMISSION_ACTIONS {
  UPDATE = 'UPDATE',
  SUBMIT = 'SUBMIT',
  CORRECTION_SEEN = 'CORRECTION_SEEN',
}

export enum ASSIGNMENT_CORRECTOR_ROLES {
  MAIN = 'MAIN',
  SECONDARY = 'SECONDARY',
}

export enum ASSIGNMENT_ASSESSMENTS {
  VERY_SATISFACTORY = 'VERY_SATISFACTORY',
  SATISFACTORY = 'SATISFACTORY',
  UNSATISFACTORY = 'UNSATISFACTORY',
  VERY_UNSATISFACTORY = 'VERY_UNSATISFACTORY',
}

// In days
export const SUBMISSION_AVAILABLE_TO_SECONDARY_CORRECTOR = 7;

export const SUBMISSION_SCOPES = {
  [USER_ROLES.ANIMATION]: USER_ROLES.ANIMATION,
  [USER_ROLES.CORRECTOR]: USER_ROLES.CORRECTOR,
} as const;

export enum ASSIGNMENT_COMMENT_REASON {
  TECHNICAL_ISSUE = 'TECHNICAL_ISSUE',
  DEPOSIT_ISSUE = 'DEPOSIT_ISSUE',
  MISUNDERSTANDING_OF_INSTRUCTIONS = 'MISUNDERSTANDING_OF_INSTRUCTIONS',
  OTHER_REASON = 'OTHER_REASON',
}

export enum ASSIGNMENT_CORRECTION_KIND {
  COMMENT = 'ASSIGNMENT_CORRECTION_COMMENT',
  EVALUATION = 'ASSIGNMENT_CORRECTION_EVALUATION',
  MCQ = 'ASSIGNMENT_CORRECTION_MCQ',
}
