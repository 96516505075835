import {
  memo,
  type MemoExoticComponent,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { type ReactCropperElement } from 'react-cropper';
import { useIntl } from 'react-intl';
import { css } from '@emotion/react';
import { Delete } from '@icon-park/react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { type FileUploadContextRules } from '@eversity/domain/constants';
import { type Upload } from '@eversity/types/domain';
import { refPropTypes } from '@eversity/ui/utils';

import {
  fileUploadContextRulesPropTypes,
  uploadPropTypes,
} from '../../../types';
import { useCropperFile } from '../../../utils/hooks/useCropperFile';
import { useCropperPreview } from '../../../utils/hooks/useCropperPreview';
import { Button } from '../../general/button/Button';
import { Cropper, type CropperProps } from '../cropper/Cropper';
import { FileUpload } from '../file-upload/FileUpload';
import messages from './CropperWithPreview.messages';

export type CropperWithPreviewValue = {
  cropperRef: MutableRefObject<ReactCropperElement>;
  remote?: Upload;
  local?: Blob;
};

export type CropperWithPreviewProps = Omit<
  CropperProps,
  'value' | 'onChange' | 'src' | 'zoomable'
> & {
  value?: CropperWithPreviewValue;
  onChange?: (newValue: CropperWithPreviewValue) => void;
  uploadRules: FileUploadContextRules;
  renderPreview: (props: { preview: { href: string } | null }) => ReactNode;
  disableReset?: boolean;
};

const DEFAULT_VALUE = {
  cropperRef: null,
  local: null,
  remote: null,
};

export const CropperWithPreviewBase = ({
  value = DEFAULT_VALUE,
  onChange = noop,
  uploadRules,
  renderPreview,
  disableReset = true,
  ...props
}: CropperWithPreviewProps) => {
  const intl = useIntl();

  const cropperUrl = useCropperFile(value.local);

  const {
    cropperRef,
    onChange: onChangeCropper,
    previewDataUrl,
  } = useCropperPreview({ ref: value.cropperRef });

  const preview = useMemo(
    () =>
      value.remote ||
      (value.local && previewDataUrl ? { href: previewDataUrl } : null),
    [value.remote, value.local, previewDataUrl],
  );

  const onChangeLocalFile = useCallback(
    (file: File) =>
      onChange({
        cropperRef: value.cropperRef,
        remote: value.remote,
        local: file,
      }),
    [onChange, value.remote, value.cropperRef],
  );

  const onRemoveRemoteUpload = useCallback(
    () =>
      onChange({
        cropperRef: value.cropperRef,
        remote: null,
        local: value.local,
      }),
    [onChange, value.local, value.cropperRef],
  );

  const onResetLocalFile = useCallback(
    () =>
      onChange({
        cropperRef: value.cropperRef,
        remote: value.remote,
        local: null,
      }),
    [onChange, value.remote, value.cropperRef],
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        gap: 20px;
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        {!!(value.remote || cropperUrl) && (
          <div
            css={css`
              position: relative;
            `}
          >
            {value.remote ? (
              <img
                src={value.remote.href}
                alt={intl.formatMessage(messages.PREVIEW_ALT)}
                css={css`
                  width: 100%;
                `}
              />
            ) : (
              <Cropper
                {...props}
                ref={cropperRef}
                onChange={onChangeCropper}
                src={cropperUrl}
                zoomable={false}
              />
            )}
            {disableReset && (
              <Button
                outline
                variant={Button.VARIANTS.DANGER}
                icon={<Delete />}
                aria-label={intl.formatMessage(messages.CLEAR)}
                onClick={value.remote ? onRemoveRemoteUpload : onResetLocalFile}
                css={css`
                  position: absolute;
                  top: 10px;
                  right: 10px;
                `}
              />
            )}
          </div>
        )}

        {!value.remote && !value.local && (
          <FileUpload
            maxSize={uploadRules.maxSize}
            accept={uploadRules.mimeTypes?.join(',')}
            maxFiles={1}
            onUploadFile={onChangeLocalFile}
            hasError={props.hasError}
            hasWarning={props.hasWarning}
          />
        )}
      </div>

      <div
        css={css`
          flex: none;
        `}
      >
        {renderPreview({ preview })}
      </div>
    </div>
  );
};

CropperWithPreviewBase.displayName = 'CropperWithPreview';

CropperWithPreviewBase.propTypes = {
  value: PropTypes.exact({
    cropperRef: refPropTypes.isRequired,
    remote: uploadPropTypes,
    local: PropTypes.instanceOf(Blob),
  }),
  onChange: PropTypes.func,
  renderPreview: PropTypes.func.isRequired,
  uploadRules: fileUploadContextRulesPropTypes.isRequired,
};

export const CropperWithPreview: MemoExoticComponent<
  typeof CropperWithPreviewBase
> & {
  VIEW_MODES?: typeof Cropper.VIEW_MODES;
} = memo(CropperWithPreviewBase);

CropperWithPreview.VIEW_MODES = Cropper.VIEW_MODES;
