export enum AI_INTERACTION_TYPES {
  SEARCH_LESSON = 'SEARCH_LESSON',
  SUMMARIZE_SEQUENCE = 'SUMMARIZE_SEQUENCE',
  GENERATE_QUESTIONS = 'GENERATE_QUESTIONS',
  GENERATE_TRUE_FALSE_QUESTION = 'GENERATE_TRUE_FALSE_QUESTION',
}

export const STUDENT_MAX_AI_INTERACTIONS_ALLOWED = 200;

export const EMPLOYEE_MAX_AI_INTERACTIONS_ALLOWED = 200;
