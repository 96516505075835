import {
  type FocusEventHandler,
  Fragment,
  memo,
  type MemoExoticComponent,
} from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { useFocus } from '@eversity/ui/utils';

import {
  EmptyState,
  type EmptyStateProps,
} from '../../data-display/empty-state/EmptyState';
import * as styles from './FileUpload.styles';
import {
  FileUploadContainer,
  type FileUploadContainerProps,
} from './FileUploadContainer';
import { FileUploadRules } from './rules/FileUploadRules';

export type FileUploadEmptyStateProps = Omit<
  FileUploadContainerProps,
  'children'
> &
  Pick<EmptyStateProps, 'icon' | 'size' | 'title' | 'children'> & {
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    onFocus?: FocusEventHandler<HTMLDivElement>;
    onBlur?: FocusEventHandler<HTMLDivElement>;
  };

export const FileUploadEmptyStateBase = ({
  title,
  icon = null,
  size = EmptyState.SIZES.MEDIUM,
  hasError = false,
  hasWarning = false,
  children = null,
  className = null,
  onFocus: onFocusProps = undefined,
  onBlur: onBlurProps = undefined,
  ...props
}: FileUploadEmptyStateProps) => {
  const { isFocused, onFocus, onBlur } = useFocus({
    onFocus: onFocusProps,
    onBlur: onBlurProps,
  });

  const rules = (
    <FileUploadRules
      accept={props.accept}
      maxSize={props.maxSize}
      maxFiles={props.maxFiles}
    >
      {({ extensionsRule, maxSizeRule, maxFilesRule }) =>
        !!(extensionsRule || maxSizeRule || maxFilesRule) && (
          <EmptyState.Paragraph>
            {!!extensionsRule && (
              <Fragment>
                {extensionsRule}
                <br />
              </Fragment>
            )}
            {!!maxSizeRule && (
              <Fragment>
                {maxSizeRule}
                <br />
              </Fragment>
            )}
            {maxFilesRule}
          </EmptyState.Paragraph>
        )
      }
    </FileUploadRules>
  );

  return (
    <FileUploadContainer {...props}>
      {({
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
        isDropzoneDisabled,
      }) => (
        <div
          {...getRootProps({ onFocus, onBlur })}
          className={cn(className, {
            isFocused,
            isDropzoneDisabled,
            isDragActive: !isDropzoneDisabled, // To make the border look active by default.
            isDragAccept,
            isDragReject,
            hasError,
            hasWarning: !hasError && !!hasWarning,
          })}
          css={styles.dropzone}
        >
          <input {...getInputProps()} />

          <div
            css={css`
              margin: auto;
            `}
          >
            <EmptyState
              icon={icon}
              title={title}
              size={size}
            >
              {isFunction(children) ? (
                (...args) => (
                  <Fragment>
                    {children(...args)}
                    {rules}
                  </Fragment>
                )
              ) : (
                <Fragment>
                  {children}
                  {rules}
                </Fragment>
              )}
            </EmptyState>
          </div>
        </div>
      )}
    </FileUploadContainer>
  );
};

FileUploadEmptyStateBase.displayName = 'FileUploadEmptyState';

FileUploadEmptyStateBase.propTypes = {
  /** Icon from @icon-park/react. */
  icon: PropTypes.node,
  /** Empty state size to scale all components. */
  size: PropTypes.oneOf(Object.values(EmptyState.SIZES)),
  /** Title text. */
  title: PropTypes.node.isRequired,
  /** Content. Do not wrap inside a div (or other), it will be wrapped by HtmlFormatter. */
  children: PropTypes.oneOfType([
    PropTypes.node,
    // ({ typography: object }) => React.Node.
    PropTypes.func,
  ]),
  /** Root element class name. */
  className: PropTypes.string,
  /** Max number of files that can be uploaded. */
  maxFiles: PropTypes.number,
  /** The dropzone has an error. */
  hasError: PropTypes.bool,
  /** The dropzone has a warning. */
  hasWarning: PropTypes.bool,
  /** List of accepted mime-types, separated by commas. */
  accept: PropTypes.string,
  /** Max size of each file, in bytes. */
  maxSize: PropTypes.number,
};

export const FileUploadEmptyState: MemoExoticComponent<
  typeof FileUploadEmptyStateBase
> & {
  SIZES?: typeof EmptyState.SIZES;
  Paragraph?: typeof EmptyState.Paragraph;
} = memo(FileUploadEmptyStateBase);

FileUploadEmptyState.SIZES = EmptyState.SIZES;
FileUploadEmptyState.Paragraph = EmptyState.Paragraph;
