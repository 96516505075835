import { type ForwardedRef, forwardRef, memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { TYPOGRAPHY_VARIANTS } from '../../../../config/typography/constants';
import {
  Typography,
  type TypographyProps,
} from '../../../general/typography/Typography';
import { EMPTY_STATE_SIZES } from '../constants';
import { EmptyStateContext } from '../EmptyStateContext';

export type TypographyMapping = {
  strong: TYPOGRAPHY_VARIANTS;
  regular: TYPOGRAPHY_VARIANTS;
};

export const EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING: Record<
  EMPTY_STATE_SIZES,
  TypographyMapping
> = {
  [EMPTY_STATE_SIZES.SMALL]: {
    strong: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD,
    regular: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  },

  [EMPTY_STATE_SIZES.MEDIUM]: {
    strong: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD,
    regular: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  },

  [EMPTY_STATE_SIZES.LARGE]: {
    strong: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD,
    regular: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  },
};

type ParagraphProps = Omit<TypographyProps, 'variant'> & {
  strong?: boolean;
};

export const ParagraphBase = forwardRef(
  (
    { strong, children, ...props }: ParagraphProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { size } = useContext(EmptyStateContext);

    const modifier = strong ? 'strong' : 'regular';

    return (
      <Typography
        {...props}
        ref={ref}
        variant={EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING[size][modifier]}
      >
        {children}
      </Typography>
    );
  },
);

ParagraphBase.displayName = 'Paragraph';

ParagraphBase.propTypes = {
  /** Bold text. */
  strong: PropTypes.bool,
  /** Override the default element. */
  element: PropTypes.elementType,
  /** Text content. */
  children: PropTypes.node,
};

ParagraphBase.defaultProps = {
  strong: false,
  element: 'p',
  children: null,
};

export const Paragraph = memo(ParagraphBase);
