import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  color: ${theme.colors.gray[700]};
`;

export const tag = css`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const descriptionContainer = css`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const durationContainer = (theme: Theme) => css`
  color: ${theme.colors.gray[700]};
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const startDate = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const duration = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;
