export enum TAG_VARIANTS {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  NEUTRAL = 'NEUTRAL',
  CLEAR = 'CLEAR',
}

export enum TAG_HUES {
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  GRAY = 'GRAY',
}

export enum TAG_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}
