import { css, type Theme } from '@emotion/react';

export const imgContainer = (theme: Theme) => css`
  display: flex;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.gray[100]};
`;

export const img = css`
  border-radius: 4px;
`;

export const content = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;
