import {
  type GetNotificationsQuery,
  type Notification,
  type UpdateNotificationBody,
  type UpdateNotificationsBody,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const BASE_NOTIFICATIONS_URI = '/api/v1/users/users/me/notifications';

const e = encodeURIComponent;

export class NotificationsRepository extends HttpRepository {
  /**
   * Get the list of user notifications.
   *
   * @returns List of notifications.
   */
  async getUserNotifications({
    isSeen,
    isRead,
    limit,
    offset,
    notificationTypes,
  }: GetNotificationsQuery): Promise<{
    count: number;
    notifications: Notification[];
  }> {
    const { body } = await this.http.get(BASE_NOTIFICATIONS_URI).query({
      isSeen,
      isRead,
      limit,
      offset,
      notificationTypes,
    });

    return body;
  }

  /**
   * Update notifications to mark as seen or read.
   *
   * @param params - Update parameters.
   * @returns Success status.
   */
  async updateUserNotifications({
    isSeen,
    isRead,
  }: UpdateNotificationsBody = {}): Promise<boolean> {
    const { status } = await this.http.patch(BASE_NOTIFICATIONS_URI).send({
      isSeen,
      isRead,
    });

    return status === 200;
  }

  /**
   * Update one notification to mark it as seen or read.
   *
   * @param params - Update parameters.
   * @returns Updated notification.
   */
  async updateNotification(
    notificationId: string,
    { isSeen, isRead }: UpdateNotificationBody = {},
  ): Promise<Notification> {
    const { body } = await this.http
      .patch(`${BASE_NOTIFICATIONS_URI}/${e(notificationId)}`)
      .send({
        isSeen,
        isRead,
      });

    return body;
  }
}
