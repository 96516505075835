import { memo, type ReactNode, useCallback, useRef } from 'react';
import { Collapse } from 'react-collapse';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import { Down } from '@icon-park/react';
import { intersection, noop } from 'lodash';
import PropTypes from 'prop-types';

import { BoolState } from '@eversity/ui/utils';

import { treeNodeKeyPropTypes, treeNodePropTypes } from '../../../types';
import { removeButtonAppearance } from '../../../utils/style';
import {
  getLeavesKeys,
  isLeaf,
  isNodeChecked,
  isNodeDisabled,
  isNodeIndeterminate,
  type ITree,
  toggleCheckNode,
  type TreeKey,
} from '../../../utils/tree';
import { Checkbox, type CheckboxProps } from '../../forms/checkbox/Checkbox';
import { LinkButton } from '../../general/link-button/LinkButton';
import { Typography } from '../../general/typography/Typography';
import messages from './Tree.messages';

export type { TreeKey } from '../../../utils/tree';

type RenderNode = (
  node: ITree<TreeKey>,
  params: { isChecked: boolean; isDisabled: boolean },
) => ReactNode;

type RenderTreeProps = {
  tree: ITree<TreeKey>[];
  renderNode: RenderNode;
  isCheckable: boolean;
  checkedKeys: TreeKey[];
  onToggleCheckKey: CheckboxProps['onChange'];
  disabledKeys: TreeKey[];
  depth: number;
  initialCollapsed?: boolean;
};

const renderTree = ({
  tree,
  renderNode,
  isCheckable,
  checkedKeys,
  onToggleCheckKey,
  disabledKeys,
  depth,
  initialCollapsed,
}: RenderTreeProps) => (
  <ul
    css={css`
      list-style-type: none;
    `}
  >
    {tree.map((node, index) => (
      <BoolState
        key={node.key}
        initial={initialCollapsed}
      >
        {([isCollapsed, , , onToggleCollapse]) => (
          <li
            css={css`
              margin-top: ${index > 0 ? 10 : 0}px;
              padding-left: ${isLeaf(node) ? 26 : 0}px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 10px;
                line-height: 28px;
              `}
            >
              {!isLeaf(node) && (
                <button
                  type="button"
                  aria-label="collapse"
                  onClick={onToggleCollapse}
                  css={(theme) => css`
                    ${removeButtonAppearance};

                    transform: rotate(${isCollapsed ? -90 : 0}deg);

                    transition: ${theme.transitions.default()};
                  `}
                >
                  <Down />
                </button>
              )}

              {isCheckable ? (
                <Checkbox
                  checked={isNodeChecked(node, checkedKeys)}
                  indeterminate={isNodeIndeterminate(node, checkedKeys)}
                  disabled={isNodeDisabled(node, disabledKeys)}
                  description={node.label}
                  value={node.key}
                  onChange={onToggleCheckKey}
                />
              ) : (
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
                  {node.label}
                </Typography>
              )}

              {renderNode(node, {
                isChecked: isNodeChecked(node, checkedKeys),
                isDisabled: isNodeDisabled(node, disabledKeys),
              })}
            </div>

            {!isLeaf(node) && (
              <Collapse isOpened={!isCollapsed}>
                <div
                  css={css`
                    padding-left: 26px;
                    margin-top: 10px;
                  `}
                >
                  {renderTree({
                    tree: node.children,
                    renderNode,
                    isCheckable,
                    checkedKeys,
                    onToggleCheckKey,
                    disabledKeys,
                    depth: depth + 1,
                    initialCollapsed,
                  })}
                </div>
              </Collapse>
            )}
          </li>
        )}
      </BoolState>
    ))}
  </ul>
);

export type TreeProps = {
  tree?: ITree<TreeKey>[];
  isCheckable?: boolean;
  checkedKeys?: TreeKey[];
  onChangeCheckedKeys?: (checkedKeys: TreeKey[]) => void;
  disabledKeys?: TreeKey[];
  initialCollapsed?: boolean;
  renderNode?: RenderNode;
};

const DEFAULT_TREE = [];
const DEFAULT_CHECKED_KEYS = [];
const DEFAULT_DISABLED_KEYS = [];
const DEFAULT_RENDER_NODE = () => null;

export const TreeBase = ({
  tree = DEFAULT_TREE,
  renderNode = DEFAULT_RENDER_NODE,
  isCheckable = false,
  checkedKeys = DEFAULT_CHECKED_KEYS,
  onChangeCheckedKeys = noop,
  disabledKeys = DEFAULT_DISABLED_KEYS,
  initialCollapsed = false,
}: TreeProps) => {
  // Keep the checked keys in a ref instance so the callback is not recreated every time.
  const instanceRef = useRef(checkedKeys);
  instanceRef.current = checkedKeys;

  const onToggleCheckKey: CheckboxProps['onChange'] = useCallback(
    (_, { target: { value: key } }) =>
      onChangeCheckedKeys(toggleCheckNode(tree, instanceRef.current, key)),
    [tree, onChangeCheckedKeys],
  );

  // Select all, except disabled keys that were not already checked.
  const onSelectAll = useCallback(
    () =>
      onChangeCheckedKeys(
        tree
          .flatMap((node) => getLeavesKeys(node))
          .filter(
            (leafKey) =>
              checkedKeys.includes(leafKey) || !disabledKeys.includes(leafKey),
          ),
      ),
    [onChangeCheckedKeys, tree, checkedKeys, disabledKeys],
  );

  // Unselect all, except disabled leaves that were already checked.
  const onUnselectAll = useCallback(
    () => onChangeCheckedKeys(intersection(checkedKeys, disabledKeys)),
    [onChangeCheckedKeys, checkedKeys, disabledKeys],
  );

  return (
    <div>
      {isCheckable && (
        <div
          css={(theme) => css`
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid ${theme.colors.gray[100]};
          `}
        >
          <LinkButton onClick={onSelectAll}>
            <FormattedMessage {...messages.SELECT_ALL} />
          </LinkButton>

          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            /
          </Typography>

          <LinkButton onClick={onUnselectAll}>
            <FormattedMessage {...messages.UNSELECT_ALL} />
          </LinkButton>
        </div>
      )}

      {renderTree({
        tree,
        renderNode,
        isCheckable,
        checkedKeys,
        disabledKeys,
        onToggleCheckKey,
        depth: 0,
        initialCollapsed,
      })}
    </div>
  );
};

TreeBase.displayName = 'Tree';

TreeBase.propTypes = {
  /** Tree to render. */
  tree: PropTypes.arrayOf(treeNodePropTypes),
  /** Method to render a node. Called with the node. (This is unused for now). */
  renderNode: PropTypes.func,
  /** Is the tree rendered with checkboxes on nodes. */
  isCheckable: PropTypes.bool,
  /** List of keys of checked leaves. */
  checkedKeys: PropTypes.arrayOf(treeNodeKeyPropTypes),
  /** Called with the key of the leave that is being toggled. */
  onChangeCheckedKeys: PropTypes.func,
  /** List of keys of disabled leaves. */
  disabledKeys: PropTypes.arrayOf(treeNodeKeyPropTypes),
  /** Should the nodes be collapsed at first render. */
  initialCollapsed: PropTypes.bool,
};

export const Tree = memo(TreeBase);
