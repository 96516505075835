import { uniq, without } from 'lodash';

export type TreeKey = string | number;

export type ITree<T extends TreeKey> = {
  key: T;
  children?: ITree<T>[];
  [key: string]: any;
};

/**
 * Return true if a node is a leaf.
 * A leaf is a node without children.
 *
 * @param node - Node.
 * @returns True if leaf.
 */
export const isLeaf = <T extends TreeKey>(node: ITree<T>) =>
  !node.children?.length;

/**
 * Return true if a node is considered checked.
 * A checked node is a checked leaf or a node whose all leaves are checked.
 *
 * @param node - Node.
 * @param checkedKeys - Leaves keys that are checked.
 * @returns True if node is checked.
 */
export const isNodeChecked = <T extends TreeKey>(
  node: ITree<T>,
  checkedKeys: T[],
): boolean =>
  !isLeaf(node)
    ? node.children.every((child) => isNodeChecked(child, checkedKeys))
    : checkedKeys.includes(node.key);

/**
 * Return true if a node is considered disabled.
 * A disabled node is a disabled leaf or a node with at least one disabled leaf.
 *
 * @param node - Node.
 * @param disabledKeys - Leaves keys that are disabled.
 * @returns True if node is disabled.
 */
export const isNodeDisabled = <T extends TreeKey>(
  node: ITree<T>,
  disabledKeys: T[],
): boolean =>
  !isLeaf(node)
    ? node.children.some((child) => isNodeDisabled(child, disabledKeys))
    : disabledKeys.includes(node.key);

/**
 * Return true if a node is considered indeterminate.
 * A node is indeterminate if some but not all its leaves are checked.
 *
 * @param node - Node.
 * @param checkedKeys - Leaves keys that are checked.
 * @returns True if node is indeterminate.
 */
export const isNodeIndeterminate = <T extends TreeKey>(
  node: ITree<T>,
  checkedKeys: T[],
) => {
  const isNodeIndeterminateRec = (currentNode: ITree<T>): boolean =>
    !isLeaf(currentNode) &&
    currentNode.children.some((child) =>
      !isLeaf(child)
        ? isNodeIndeterminateRec(child)
        : checkedKeys.includes(child.key),
    );

  return !isNodeChecked(node, checkedKeys) && isNodeIndeterminateRec(node);
};

/**
 * Return the list of keys of all leaves in the tree node.
 *
 * @param node - Node.
 * @returns Flat list of the keys of all the leaves in the node.
 */
export const getLeavesKeys = <T extends TreeKey>(node: ITree<T>): T[] =>
  isLeaf(node)
    ? [node.key]
    : node.children.flatMap((child) => getLeavesKeys(child));

/**
 * Find a node in a tree by its key.
 *
 * @param tree - Tree.
 * @param key - Node key.
 * @returns The node in the tree.
 */
export const getNodeByKey = <T extends TreeKey>(
  tree: ITree<T>[],
  key: T,
): ITree<T> | null => {
  for (let i = 0; i < tree.length; ++i) {
    if (tree[i].key === key) {
      return tree[i];
    }

    if (tree[i].children) {
      const nodeInChildren = getNodeByKey(tree[i].children, key);
      if (nodeInChildren) {
        return nodeInChildren;
      }
    }
  }

  return null;
};

/**
 * Toggle the checked state of a node.
 *
 * @param tree - The tree.
 * @param checkedKeys - Leaves keys that are checked.
 * @param toggledKey - Key of the node to toggle.
 * @returns New checked keys (immutable).
 */
export const toggleCheckNode = <T extends TreeKey>(
  tree: ITree<T>[],
  checkedKeys: T[],
  toggledKey: T,
): T[] => {
  // Find the node.
  const node = getNodeByKey(tree, toggledKey);

  // Get the list of keys of all leaves in the node.
  // If the node is a leaf this is a list with just its own key.
  const leavesKeys = getLeavesKeys(node);

  // If the node was checked (aka all its leaves were checked), uncheck them all.
  // Otherwise, check them all and remove duplicates.
  return isNodeChecked(node, checkedKeys)
    ? without(checkedKeys, ...leavesKeys)
    : uniq([...checkedKeys, ...leavesKeys]);
};
