import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

export enum TEXT_AREA_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

type TextAreaTypographyMapping = Record<TEXT_AREA_SIZES, TYPOGRAPHY_VARIANTS>;

export const TEXT_AREA_TYPOGRAPHY_VARIANT_MAPPINGS: TextAreaTypographyMapping =
  {
    [TEXT_AREA_SIZES.SMALL]: TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR,
    [TEXT_AREA_SIZES.MEDIUM]: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
    [TEXT_AREA_SIZES.LARGE]: TYPOGRAPHY_VARIANTS.BODY_LARGE_REGULAR,
  };

export const TEXT_AREA_MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS: TextAreaTypographyMapping =
  {
    [TEXT_AREA_SIZES.SMALL]: TYPOGRAPHY_VARIANTS.MONOSPACE_SMALL_REGULAR,
    [TEXT_AREA_SIZES.MEDIUM]: TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_REGULAR,
    [TEXT_AREA_SIZES.LARGE]: TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_REGULAR,
  };

export const TEXT_AREA_VERTICAL_PADDING_MAPPINGS: Record<
  TEXT_AREA_SIZES,
  number
> = {
  [TEXT_AREA_SIZES.SMALL]: 2,
  [TEXT_AREA_SIZES.MEDIUM]: 4.5,
  [TEXT_AREA_SIZES.LARGE]: 7,
};
