import { memo, type ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import { SignalStrength } from '@icon-park/react';
import { type Icon } from '@icon-park/react/lib/runtime';

import * as styles from './ResourcePicture.styles';

export type ResourcePictureProps = {
  picture?: { href: string } | null;
  icon?: Icon;
  children?: ReactNode;
  width?: number | string;
};

export const ResourcePictureBase = ({
  picture = null,
  width = 235,
  icon = null,
  children = null,
}: ResourcePictureProps) => {
  const theme = useTheme();

  const IconComponent = icon || SignalStrength;

  return (
    <div css={styles.container(width, picture?.href)}>
      {!picture && (
        <IconComponent
          size={48}
          strokeWidth={2}
          fill={[theme.colors.primary[500], theme.colors.primary[50]]}
        />
      )}

      {children}
    </div>
  );
};

ResourcePictureBase.displayName = 'ResourcePicture';

export const ResourcePicture = memo(ResourcePictureBase);
