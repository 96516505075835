import { type StudentCourse } from '@eversity/types/domain';

import { useUser } from './queries/user';

export const useUserCourse = (
  courseId: string,
  classId: string,
): { currentStudentCourse: StudentCourse; isLoading: boolean } => {
  const { data: user, isLoading } = useUser();
  return {
    currentStudentCourse: user.courses.find(
      (userCourse) =>
        userCourse.course.id === courseId && userCourse.class.id === classId,
    ),
    isLoading,
  };
};
