import { memo, useCallback } from 'react';
import { css, useTheme } from '@emotion/react';
import {
  Delete,
  FileGif,
  FileJpg,
  FilePdf,
  FileSuccess,
  FileTxt,
  FileWord,
} from '@icon-park/react';
import { noop } from 'lodash';
import PropTypes, { type ReactComponentLike } from 'prop-types';

import { type UploadWithoutHref } from '@eversity/types/domain';
import { FormattedFileSize } from '@eversity/ui/utils';

import { uploadPropTypes, uploadWithoutHrefPropTypes } from '../../../types';
import { Button } from '../../general/button/Button';
import { ICON_SIZES } from '../../general/icon/constants';
import { LinkButton } from '../../general/link-button/LinkButton';
import { Typography } from '../../general/typography/Typography';

export const MIME_TYPE_ICON_MAPPING: Record<string, ReactComponentLike> = {
  'application/pdf': FilePdf,
  'image/jpeg': FileJpg,
  'image/gif': FileGif,
  'text/plain': FileTxt,
  'application/msword': FileWord,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileWord,
};

export type FileDownloadProps<
  TUpload extends UploadWithoutHref = UploadWithoutHref,
> = {
  file: TUpload;
  onDownload?: (file: TUpload) => void;
  disabled?: boolean;
  isFaded?: boolean;
  onRemove?: (file: TUpload) => void;
};

export const FileDownloadBase = <
  TUpload extends UploadWithoutHref = UploadWithoutHref,
>({
  file,
  onDownload = noop,
  disabled = false,
  isFaded = false,
  onRemove = undefined,
}: FileDownloadProps<TUpload>) => {
  const theme = useTheme();

  const IconElement = MIME_TYPE_ICON_MAPPING[file.mimeType] || FileSuccess;

  const onDownloadProxy = useCallback(
    () => onDownload(file),
    [file, onDownload],
  );

  const onRemoveProxy = useCallback(() => onRemove(file), [file, onRemove]);

  return (
    <div
      css={css`
        display: flex;
        border: 1px solid ${theme.colors.gray[100]};
        border-radius: 4px;
      `}
    >
      <div
        css={css`
          padding: 9px;
          background-color: ${theme.colors.primary[15]};
          border-radius: 4px 0 0 4px;

          border-right: 1px solid ${theme.colors.gray[100]};
        `}
      >
        <IconElement
          size={ICON_SIZES.LARGE}
          fill={[
            disabled || isFaded
              ? theme.colors.primary[300]
              : theme.colors.primary[500],
            theme.colors.primary[15],
          ]}
        />
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 10px;

          width: 100%;

          padding: 9px;

          background-color: ${theme.colors.gray[0]};
          border-radius: 0 4px 4px 0;
        `}
      >
        <span
          css={css`
            flex: 1;
          `}
        >
          <LinkButton
            onClick={onDownloadProxy}
            disabled={disabled}
            css={css`
              color: ${isFaded
                ? theme.colors.gray[400]
                : theme.colors.primary[500]};
            `}
          >
            {file.fileName}
          </LinkButton>
        </span>

        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
          css={css`
            color: ${theme.colors.gray[700]};
          `}
        >
          <FormattedFileSize value={file.size} />
        </Typography>

        {!!onRemove && (
          <Button
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.DANGER}
            outline
            onClick={onRemoveProxy}
            icon={<Delete />}
          />
        )}
      </div>
    </div>
  );
};

FileDownloadBase.displayName = 'FileDownload';

FileDownloadBase.propTypes = {
  file: PropTypes.oneOfType([uploadWithoutHrefPropTypes, uploadPropTypes])
    .isRequired,
  onDownload: PropTypes.func,
  disabled: PropTypes.bool,
  /** Display faded download links not to catch user attention. */
  isFaded: PropTypes.bool,
  onRemove: PropTypes.func,
};

// Cast type to keep the generic on FileDownload (otherwise it is lost after wrapping with memo).
export const FileDownload = memo(
  FileDownloadBase,
) as unknown as typeof FileDownloadBase;
