import PropTypes from 'prop-types';

import { USER_COURSE_KPIS_TYPES } from '@eversity/domain/constants';

export const kpiPropTypes = PropTypes.shape({
  /** Course id. */
  course: PropTypes.string,
  /** Class id. */
  class: PropTypes.string,
  /** Number of days since user enroled for the course. */
  daysSinceRegistration: PropTypes.number,
  /** KPI type (progress, time spent, average grade). */
  type: PropTypes.oneOf(Object.values(USER_COURSE_KPIS_TYPES)),
  /** KPI value. */
  value: PropTypes.number,
});

export const userHistoryKpisPropTypes = PropTypes.shape({
  /** User kpis of a kpi type. */
  userKpis: PropTypes.arrayOf(kpiPropTypes),
  /** Average value of a kpi type for a course. */
  courseAverageKpi: PropTypes.number,
});

export const userKpisPropTypes = PropTypes.shape({
  /** User progress for a course. */
  userProgress: PropTypes.number,
  /** Time spent on a course. */
  userTimeSpent: PropTypes.number,
  /** Average grade for all graded assignments within a course. */
  userOverallAverage: PropTypes.number,
  /** Average course progress for all students. */
  courseProgress: PropTypes.number,
  /** Average time spent on the course for all students. */
  courseTimeSpent: PropTypes.number,
  /** Average grade of all students for a course. */
  courseOverallAverage: PropTypes.number,
});
