import { defineMessages } from 'react-intl';

export default defineMessages({
  ANSWERS_LABEL: {
    defaultMessage: 'Réponses',
  },

  ANSWERS_IMAGES: {
    defaultMessage: 'Images',
  },

  ANSWERS_AUDIOS: {
    defaultMessage: 'Audios',
  },

  SUCCESS_TEXT_LABEL: {
    defaultMessage: 'Message de réussite associé',
  },

  ERROR_TEXT_LABEL: {
    defaultMessage: "Message d'erreur associé",
  },
});
