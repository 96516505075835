import PropTypes from 'prop-types';

export const staticRefPropTypes = PropTypes.exact({
  // eslint-disable-next-line react/forbid-prop-types
  current: PropTypes.any,
});

export const refPropTypes = PropTypes.oneOfType([
  staticRefPropTypes,
  PropTypes.func,
]);

/**
 * Schema used to sanitize a hast.
 * Checkout https://github.com/syntax-tree/hast-util-sanitize/blob/main/lib/schema.js.
 */
export const sanitizeSchemaPropTypes = PropTypes.shape({
  /** List of allowed tag names. */
  tagNames: PropTypes.arrayOf(PropTypes.string),
  /** List of allowed attributes indexed by tag name. */
  attributes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** List of allowed protocols (http, https, etc.) indexed by attribute name. */
  protocols: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** List of attributes to prefix. */
  clobber: PropTypes.arrayOf(PropTypes.string),
  /** Prefix of clobbered attributes. */
  clobberPrefix: PropTypes.string,
  /** List of tags to remove. */
  strip: PropTypes.arrayOf(PropTypes.string),
  /** List of allowed ancestor tag names, indexed by tag names. */
  ancestors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** List of required attributes, indexed by tag names. */
  required: PropTypes.objectOf(PropTypes.shape({})),
  /**
   * Allowed domains for attributes of tags.
   * Ex: { 'eversity-linkedin-embedded': { src: 'www.linkedin.com' } }
   */
  allowedDomains: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
      ]),
    ),
  ),
});

export const linkPathPropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
]);

export const tippyPlacementPropTypes = PropTypes.oneOf([
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'bottom',
  'right',
  'left',
  'top-start',
  'top-end',
  'bottom-start',
  'bottom-end',
  'right-start',
  'right-end',
  'left-start',
  'left-end',
]);

export const tippyDelayPropTypes = (
  props: Record<string, any>,
  propName: string,
  componentName: string,
) => {
  const value = props[propName];

  // Validating type number | [number, number]
  if (
    // Not required.
    value !== null &&
    value !== undefined &&
    // Check for number.
    (!Number.isFinite(value) ||
      // Check for [number, number].
      !(
        Array.isArray(value) &&
        value.length !== 2 &&
        !value.every(Number.isFinite)
      ))
  ) {
    return new Error(
      `Invalid prop ${propName} in component ${componentName}. Expected number | [number, number].`,
    );
  }

  return null;
};

export const tippyPropTypes = PropTypes.shape({
  // tippy.js.
  animateFill: PropTypes.bool,
  appendTo: PropTypes.oneOfType([
    PropTypes.oneOf(['parent']),
    PropTypes.instanceOf(Element),
    PropTypes.func,
  ]),
  aria: PropTypes.shape({
    content: PropTypes.oneOf(['auto', 'describedby', 'labelledby']),
    expanded: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.bool]),
  }),
  delay: tippyDelayPropTypes,
  duration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  followCursor: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['horizontal', 'vertical', 'initial']),
  ]),
  getReferenceClientRect: PropTypes.func,
  hideOnClick: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['toggle']),
  ]),
  ignoreAttributes: PropTypes.bool,
  inlinePositioning: PropTypes.bool,
  interactive: PropTypes.bool,
  interactiveBorder: PropTypes.number,
  interactiveDebounce: PropTypes.number,
  moveTransition: PropTypes.string,

  offset: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.func,
  ]),
  placement: tippyPlacementPropTypes,
  plugins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types -- Defined by tippy.
      defaultValue: PropTypes.any,
      fn: PropTypes.func,
    }),
  ),
  popperOptions: PropTypes.shape({
    placement: tippyPlacementPropTypes,
    modifiers: PropTypes.arrayOf(PropTypes.shape({})),
    strategy: PropTypes.oneOf(['absolute', 'fixed']),
    onFirstUpdate: PropTypes.func,
  }),
  showOnCreate: PropTypes.bool,
  sticky: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['reference', 'popper']),
  ]),
  touch: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['hold']),
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.oneOf(['hold']), PropTypes.number]),
    ),
  ]),
  trigger: PropTypes.string,
  triggerTarget: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.arrayOf(PropTypes.instanceOf(Element)),
  ]),

  // @tippyjs/react.
  children: PropTypes.element,
  content: PropTypes.node,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  singleton: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- Defined by tippy.
    data: PropTypes.any,
    hook: PropTypes.func,
  }),
  reference: PropTypes.oneOfType([refPropTypes, PropTypes.instanceOf(Element)]),
  render: PropTypes.func,
});

export const chartjsPointPropTypes = PropTypes.exact({
  // eslint-disable-next-line react/forbid-prop-types -- can be anything.
  x: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- can be anything.
  y: PropTypes.any.isRequired,
});

export const emotionSingleCssPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({}),
]);

export const emotionCssPropTypes = PropTypes.oneOfType([
  emotionSingleCssPropTypes,
  PropTypes.arrayOf(emotionSingleCssPropTypes),
]);
