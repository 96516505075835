import {
  memo,
  type MemoExoticComponent,
  type ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from 'react-use';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import {
  type McqQuestion as McqQuestionShape,
  type McqQuestionViewCorrection,
  type McqQuestionViewForm,
} from '@eversity/types/domain';
import { Tag, Typography } from '@eversity/ui/design-system';
import { questionnaireMessages } from '@eversity/ui/intl';

import { questionViewAssignmentPropTypes } from '../../../../types/assignments';
import { McqAnswerCheckboxGroup } from '../mcq-answer-checkbox-group/McqAnswerCheckboxGroup';
import { McqAnswerRadioGroup } from '../mcq-answer-radio-group/McqAnswerRadioGroup';
import { expectedAnswersMessages } from './McqQuestion.messages';
import * as styles from './McqQuestion.styles';
import { renderResources } from './utils';

export type McqQuestionProps = {
  questionNumber: number;
  question: McqQuestionShape | McqQuestionViewForm | McqQuestionViewCorrection;
  isCorrected?: boolean;
  children: ReactNode;
};

export const McqQuestionBase = ({
  questionNumber,
  question: { label, points, answerType, resources },
  isCorrected = false,
  children,
}: McqQuestionProps) => {
  const ref = useRef(null);

  const { width: widthScreen } = useWindowSize();

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [widthScreen]);

  return (
    <div
      css={styles.container}
      ref={ref}
    >
      <div css={styles.header}>
        <div css={styles.type}>
          <Tag
            variant={Tag.VARIANTS.PRIMARY}
            label={<FormattedMessage {...questionnaireMessages[answerType]} />}
          />

          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            <FormattedMessage
              {...questionnaireMessages.QUESTION_NUMBER}
              values={{ questionNumber }}
            />
          </Typography>
        </div>

        {Number.isFinite(points) && (
          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
            css={styles.points}
          >
            <FormattedMessage
              {...questionnaireMessages.QUESTION_POINTS}
              values={{ questionPoints: points }}
            />
          </Typography>
        )}
      </div>

      <div css={styles.question}>
        <div>
          <Typography
            variant={Typography.VARIANTS.HEADING_4}
            css={styles.label}
          >
            {label}
          </Typography>

          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_ITALIC}
            element="div"
          >
            <FormattedMessage {...expectedAnswersMessages[answerType]} />
          </Typography>

          {resources?.map((resource) => (
            <div
              key={resource.id}
              css={css`
                width: 100%;
                padding-top: 20px;

                &:last-child {
                  padding-bottom: 10px;
                }
              `}
            >
              {renderResources(resource, { width })}
            </div>
          ))}
        </div>

        <div css={isCorrected ? styles.answersCorrected : styles.answersForm}>
          {children}
        </div>
      </div>
    </div>
  );
};

McqQuestionBase.displayName = 'McqQuestion';

McqQuestionBase.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  question: questionViewAssignmentPropTypes.isRequired,
  isCorrected: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export const McqQuestion: MemoExoticComponent<typeof McqQuestionBase> & {
  RadioGroup?: typeof McqAnswerRadioGroup;
  CheckboxGroup?: typeof McqAnswerCheckboxGroup;
} = memo(McqQuestionBase);

McqQuestion.RadioGroup = McqAnswerRadioGroup;
McqQuestion.CheckboxGroup = McqAnswerCheckboxGroup;
