import { memo, useCallback } from 'react';
import { css, useTheme } from '@emotion/react';
import { Drag } from '@icon-park/react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { tabPropTypes } from '../../../types';
import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import { Badge } from '../badge/Badge';
import { type TabKey, type TabShape } from './types';

const TAB_DRAGGABLE_CLASSNAME = 'draggableTab';

export type TabProps<TTabKey extends TabKey = TabKey> = {
  tab: TabShape<TTabKey>;
  isActive?: boolean;
  onClick?: (key: TTabKey) => void;
  vertical?: boolean;
  isDraggable?: boolean;
};

export const TabBase = <TTabKey extends TabKey = TabKey>({
  tab: { key, label, badge },
  isActive = false,
  onClick = noop,
  vertical = false,
  isDraggable = false,
}: TabProps<TTabKey>) => {
  const theme = useTheme();

  const onClickProxy = useCallback(() => onClick(key), [key, onClick]);

  const hasBadge = badge !== null && badge !== undefined;

  return (
    <div
      css={css`
        display: flex;
        align-items: ${vertical ? 'flex-start' : 'center'};
        justify-content: ${vertical ? 'flex-end' : 'initial'};
        width: ${vertical ? '100%' : 'auto'};

        background-color: inherit;

        border-bottom: 1px solid
          ${!vertical && isActive ? theme.colors.primary[500] : 'transparent'};
        border-right: 1px solid
          ${vertical && isActive ? theme.colors.primary[500] : 'transparent'};

        position: relative;
        top: ${!vertical ? 1 : 0}px;
        left: ${vertical ? 1 : 0}px;
      `}
    >
      <button
        type="button"
        onClick={onClickProxy}
        css={css`
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: ${vertical ? 'flex-end' : 'center'};
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          text-align: ${vertical ? 'right' : 'center'};

          color: ${isActive
            ? theme.colors.primary[500]
            : theme.colors.gray[700]};
          transition: ${theme.transitions.default()};

          padding: ${vertical ? 4 : 7}px ${vertical && !isDraggable ? 20 : 0}px
            ${vertical ? 4 : 7}px ${vertical ? 4 : 0}px;
        `}
      >
        {hasBadge && (
          <Badge
            variant={isActive ? Badge.VARIANTS.PRIMARY : Badge.VARIANTS.NEUTRAL}
          >
            {badge}
          </Badge>
        )}

        <Typography
          variant={Typography.VARIANTS.BUTTON_MEDIUM_REGULAR}
          css={css`
            margin-left: ${hasBadge ? '10px' : '0'};
          `}
        >
          {label}
        </Typography>
      </button>

      {isDraggable && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            cursor: grab;

            padding: 2px;

            margin: ${vertical ? '0 15px 0 5px' : '0'};
          `}
          className={TAB_DRAGGABLE_CLASSNAME}
        >
          <Drag
            size={ICON_SIZES.MEDIUM}
            fill={[theme.colors.gray[300]]}
          />
        </div>
      )}
    </div>
  );
};

TabBase.displayName = 'Tab';

TabBase.propTypes = {
  /** Tab definition. */
  tab: tabPropTypes.isRequired,
  /** Is the tab currently active. */
  isActive: PropTypes.bool,
  /** Callback with the tab key when clicking the tab. */
  onClick: PropTypes.func,
  /** Should display vertically. */
  vertical: PropTypes.bool,
  /** Is the tab group draggable. */
  isDraggable: PropTypes.bool,
};

export const Tab = memo(TabBase) as unknown as typeof TabBase & {
  DRAGGABLE_CLASSNAME?: string;
};

Tab.DRAGGABLE_CLASSNAME = TAB_DRAGGABLE_CLASSNAME;
