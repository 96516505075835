export enum REFERENCE_TAG_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum REFERENCE_TAG_VARIANTS {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  NEUTRAL = 'NEUTRAL',
  TERTIARY = 'TERTIARY',
}
