export enum MCQ_ANSWER_TYPES {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export const MCQ_MIN_QUESTION_POINTS = 0;

export const MCQ_DEFAULT_QUESTION_POINTS = 1;

export const MCQ_MIN_CORRECT_ANSWERS = {
  [MCQ_ANSWER_TYPES.SINGLE]: 1,
  [MCQ_ANSWER_TYPES.MULTIPLE]: 1,
};

export const MCQ_MAX_CORRECT_ANSWERS = {
  [MCQ_ANSWER_TYPES.SINGLE]: 1,
};

export const MCQ_MIN_INCORRECT_ANSWERS = {
  [MCQ_ANSWER_TYPES.SINGLE]: 1,
  [MCQ_ANSWER_TYPES.MULTIPLE]: 0,
};

export const MCQ_RESULT_THRESHOLDS = {
  GOOD: 0.5,
  GREAT: 0.75,
};

export enum ASSIGNMENT_QUESTION_VIEWS {
  FORM = 'FORM',
  CORRECTION = 'CORRECTION',
}

export const MCQ_MAX_MEDIA_PER_QUESTION = 2;

export enum MCQ_MEDIA_RESOURCES_TYPES {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export enum MCQ_PAGE_TYPES {
  MCQ = 'MCQ',
  MCQ_CONTENT = 'MCQ_CONTENT',
  CONTENT = 'CONTENT',
}

export enum MCQ_PAGE_BLOCKS {
  QUESTIONS = 'QUESTIONS',
  CONTENT = 'CONTENT',
}

export enum MCQ_ANSWER_STYLES {
  TEXT = 'TEXT',
  TEXT_IMAGE = 'TEXT_IMAGE',
  TEXT_AUDIO = 'TEXT_AUDIO',
}
