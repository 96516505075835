import { css, keyframes, type Theme } from '@emotion/react';

const overlayEntrance = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const overlayExit = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

// From animate.css
// https://github.com/animate-css/animate.css/blob/main/source/attention_seekers/headShake.css
const headShake = keyframes`
  0% {
    transform: translateX(0);
  }

  13% {
    transform: translateX(-12px) rotateY(-9deg);
  }

  37% {
    transform: translateX(10px) rotateY(7deg);
  }

  63% {
    transform: translateX(-6px) rotateY(-5deg);
  }

  87% {
    transform: translateX(4px) rotateY(3deg);
  }

  100% {
    transform: translateX(0);
  }
`;

export const layer = (
  theme: Theme,
  hasLayersAbove: boolean,
  zIndex: number,
) => css`
  position: fixed;
  z-index: ${zIndex};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: none;

  animation-fill-mode: forwards;
  animation-duration: ${theme.transitions.default.duration * 2}ms;
  animation-timing-function: ${theme.transitions.default.easing};

  &.isOpen {
    display: flex;
    overflow: ${hasLayersAbove ? 'hidden' : 'auto'};

    animation-name: ${overlayEntrance};

    &.triedToClickAway {
      overflow-x: hidden;
    }
  }

  &.isClosing {
    animation-name: ${overlayExit};
  }
`;

export const layerContentContainer =
  (hasLayersAbove: boolean) => (theme: Theme) => css`
    animation-fill-mode: forwards;
    animation-duration: ${theme.transitions.default.duration * 2}ms;
    animation-timing-function: ${theme.transitions.default.easing};

    overflow: ${hasLayersAbove ? 'hidden' : 'auto'};

    display: flex;
    height: 100%;
    width: 100%;

    &.triedToClickAway {
      animation-name: ${headShake};
    }
  `;

export const overlay = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  /**
   * Leave the scrollbar accessible.
   * Could not find another way to do this and it works 99% of the time everytime.
   */
  right: 17px;
`;
