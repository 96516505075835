import { makeLocalStorageAdapter } from '@eversity/utils/web';

import config from '../../config/env';

const LOCALE_STORAGE_KEY = 'locale';

const localStorageAdapter = makeLocalStorageAdapter(
  `${config.app.name}:${LOCALE_STORAGE_KEY}`,
);

/**
 * Looks for the presence of the locale in the storage.
 *
 * @returns The locale if found, undefined otherwise.
 */
export const getStoredLocale = () => localStorageAdapter.get();

/**
 * Stores locale in the storage.
 *
 * @param locale - The locale to store.
 */
export const storeLocale = (locale: string) => {
  localStorageAdapter.set(locale);
};
