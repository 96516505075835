import { type HTMLProps, memo, useEffect, useRef } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import {
  Typography,
  type TypographyProps,
} from '../../general/typography/Typography';

export type ContentEditableProps = Omit<HTMLProps<HTMLElement>, 'ref'> &
  TypographyProps & {
    autofocus?: boolean;
  };

export const ContentEditableBase = ({
  value = null,
  variant = Typography.VARIANTS.BODY_MEDIUM_REGULAR,
  onChange = noop,
  disabled = false,
  autofocus = true,
  ...props
}: ContentEditableProps) => {
  const ref = useRef<HTMLElement>();

  // Forces "input" focus.
  useEffect(() => {
    if (!disabled && autofocus && ref.current) {
      ref.current.focus();

      // Sets selection at the end of the text.
      const selection = window.getSelection();
      const range = document.createRange();

      range.selectNodeContents(ref.current);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [disabled, autofocus]);

  return (
    <Typography
      variant={variant}
      role="textbox"
      contentEditable={!disabled}
      suppressContentEditableWarning
      onKeyDown={onChange}
      {...props}
      ref={ref}
    >
      {value}
    </Typography>
  );
};

ContentEditableBase.displayName = 'ContentEditable';

ContentEditableBase.propTypes = {
  /** "Input" value. */
  value: PropTypes.string,
  /** Typography variant. */
  variant: PropTypes.oneOf(Object.values(Typography.VARIANTS)),
  /** Callback on change. */
  onChange: PropTypes.func,
  /** Disabled state. */
  disabled: PropTypes.bool,
  /** Should focus the "input" automatically (true by default). */
  autofocus: PropTypes.bool,
};

export const ContentEditable = memo(ContentEditableBase);
