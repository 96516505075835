import {
  memo,
  type NamedExoticComponent,
  type ReactNode,
  type Ref,
} from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Close } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { globalMessages } from '@eversity/ui/intl';

import { Card, type CardProps } from '../../data-display/card/Card';
import { Button } from '../../general/button/Button';
import { POPUP_POSITIONS, POPUP_SIZES } from './constants';
import * as styles from './Popup.styles';

export type PopupProps = Omit<CardProps, 'size'> & {
  isOpen?: boolean;
  size?: POPUP_SIZES;
  position?: POPUP_POSITIONS;
  shouldCloseOnEscape?: boolean;
  preventClosing?: boolean;
  className?: string;
  containerRef?: Ref<HTMLDivElement>;
  onRequestClose?: () => void;
  children?: ReactNode;
  outlineCloseButton?: boolean;
};

export const PopupBase = ({
  isOpen = false,
  size = POPUP_SIZES.MEDIUM,
  position = POPUP_POSITIONS.BOTTOM_LEFT,
  preventClosing = false,
  className = null,
  onRequestClose = noop,
  children = null,
  outlineCloseButton = false,
  ...props
}: PopupProps) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    isOpen && (
      <div
        css={styles.popupStyle}
        className={cn(className, size, position)}
      >
        {!preventClosing && (
          <Button
            outline={outlineCloseButton}
            size={Button.SIZES.SMALL}
            onClick={onRequestClose}
            icon={<Close />}
            aria-label={intl.formatMessage(globalMessages.CLOSE)}
            css={styles.closeButtonAbsoluteStyle}
          />
        )}
        <Card
          {...props}
          color={props.color || theme.colors.primary[500]}
          css={styles.cardContent}
        >
          {children}
        </Card>
      </div>
    )
  );
};

PopupBase.displayName = 'Popup';

export const Popup: NamedExoticComponent<PopupProps> & {
  SIZES?: typeof POPUP_SIZES;
  POSITIONS?: typeof POPUP_POSITIONS;
  Title?: typeof Card.Title;
  Body?: typeof Card.Body;
  Actions?: typeof Card.Actions;
  Action?: typeof Card.Action;
  VARIANTS?: typeof Card.VARIANTS;
} = memo(PopupBase);

Popup.SIZES = POPUP_SIZES;
Popup.POSITIONS = POPUP_POSITIONS;
Popup.VARIANTS = Card.VARIANTS;
Popup.Title = Card.Title;
Popup.Body = Card.Body;
Popup.Actions = Card.Actions;
Popup.Action = Card.Action;
