import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: ${theme.colors.gray[700]};

  border: 1px solid ${theme.colors.gray[100]};

  border-radius: 4px;

  background-color: ${theme.colors.gray[0]};

  transition: ${theme.transitions.default()};

  &.isSelected,
  &.isFocused {
    border-color: ${theme.colors.primary[400]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  .DRAGGABLE {
    cursor: grab;
  }
`;

export const textContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  word-break: break-word;
`;

export const button = css`
  width: 100%;
`;
