import { defineMessages } from 'react-intl';

export default defineMessages({
  PREVIOUS: {
    defaultMessage: 'Précédent',
  },

  NEXT: {
    defaultMessage: 'Suivant',
  },

  PLAY: {
    defaultMessage: 'Lecture',
  },

  PAUSE: {
    defaultMessage: 'Pause',
  },

  STOP: {
    defaultMessage: 'Arrêter',
  },

  CLOSE: {
    defaultMessage: 'Fermer',
  },
});
