import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { OVERALL_AVERAGE_GRADING_SCALE } from '@eversity/domain/constants';
import { getTranscriptTeachingUnitGradedAssignmentsCount } from '@eversity/domain/students/utils';
import { type StudentAcademicTranscript } from '@eversity/types/domain';
import { Typography } from '@eversity/ui/design-system';
import { globalMessages, gradeMessages } from '@eversity/ui/intl';

import { AcademicTranscriptAssignmentItem } from '../assignment-item/AcademicTranscriptAssignmentItem';
import * as styles from './AcademicTranscriptTeachingUnit.styles';

export type AcademicTranscriptTeachingUnitProps = {
  teachingUnit: StudentAcademicTranscript['course']['teachingUnits'][0];
};

export const AcademicTranscriptTeachingUnitBase = ({
  teachingUnit,
}: AcademicTranscriptTeachingUnitProps) => (
  <div>
    <div css={styles.teachingUnitHeader}>
      <div>
        <Typography
          variant={Typography.VARIANTS.HEADING_4}
          element="span"
        >
          {teachingUnit.code} - {teachingUnit.title}
        </Typography>

        <Typography
          variant={Typography.VARIANTS.HEADING_5}
          element="span"
          css={styles.coefficient}
        >
          <FormattedMessage
            {...(Number.isFinite(teachingUnit.overallAverage)
              ? gradeMessages.GRADE
              : globalMessages.NOT_AFFECTED)}
            values={{
              grade: teachingUnit.overallAverage,
              gradingScale: OVERALL_AVERAGE_GRADING_SCALE,
              Grade: (chunks) => (
                <Typography
                  variant={Typography.VARIANTS.HEADING_4}
                  element="span"
                >
                  {chunks}
                </Typography>
              ),
              GradingScale: (chunks) => chunks,
            }}
          />
        </Typography>
      </div>

      <div>
        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_ITALIC}
          css={styles.coefficient}
        >
          <FormattedMessage
            {...gradeMessages.COEFFICIENT}
            values={{ coefficient: teachingUnit.coefficient }}
          />
        </Typography>

        <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
          <FormattedMessage
            {...gradeMessages.GRADED_ASSIGNMENTS_COUNT}
            values={{
              ...getTranscriptTeachingUnitGradedAssignmentsCount(teachingUnit),
              b: (chunks) => (
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                  {chunks}
                </Typography>
              ),
            }}
          />
        </Typography>
      </div>
    </div>

    {teachingUnit.assignments?.length > 0 && (
      <ul
        css={styles.assignmentsList}
        className="examList"
      >
        {teachingUnit.assignments.map((transcriptAssignment) => (
          <AcademicTranscriptAssignmentItem
            key={transcriptAssignment.assignment.code}
            assignment={transcriptAssignment}
          />
        ))}
      </ul>
    )}

    {teachingUnit.lessons?.length > 0 && (
      <ul css={styles.lessonsList}>
        {teachingUnit.lessons.map((teachingUnitLesson) => (
          <li key={teachingUnitLesson.lesson.code}>
            <Typography
              variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}
              css={styles.lessonTitle}
            >
              {teachingUnitLesson.lesson.title}
            </Typography>

            <ul css={styles.assignmentsList}>
              {teachingUnitLesson.assignments?.map((transcriptAssignment) => (
                <AcademicTranscriptAssignmentItem
                  key={transcriptAssignment.assignment.code}
                  assignment={transcriptAssignment}
                />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )}
  </div>
);

AcademicTranscriptTeachingUnitBase.displayName =
  'AcademicTranscriptTeachingUnit';

AcademicTranscriptTeachingUnitBase.propTypes = {};

export const AcademicTranscriptTeachingUnit = memo(
  AcademicTranscriptTeachingUnitBase,
);
