import { defineMessages } from 'react-intl';

export const versionsMessages = defineMessages({
  SIMPLE: {
    defaultMessage: 'v{versionMajor}.{versionMinor}',
  },

  EDITABLE_MAJOR: {
    defaultMessage: 'v<b>{versionMajor}</b>.{versionMinor} (édition)',
  },

  EDITABLE_MINOR: {
    defaultMessage: 'v{versionMajor}.<b>{versionMinor}</b> (édition)',
  },
});
