import { useCallback, useEffect, useRef } from 'react';
import { useUnmount } from 'react-use';

import { useBoolState } from './useBoolState';

export const useDelayedClosingState = ({
  isOpen,
  onDidClose,
  animationDuration,
}: {
  isOpen: boolean;
  onDidClose: () => any;
  animationDuration: number;
}): [boolean, boolean] => {
  // To handle the exit animation, we need to keep a state on whether or not the dialog
  // is currently closing.
  // Opened if isOpen is true or the closing animation is not over.
  const [isVisible, onSetIsVisible, onSetIsNotVisible] = useBoolState(isOpen);
  // When isOpen becomes false, this is true when the animation is not over yet.
  const [isClosing, onStartClosing, onStopClosing] = useBoolState(false);

  const closingTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();
  const isMounted = useRef(true);

  const onOpen = useCallback(() => {
    onSetIsVisible();
    onStopClosing();
  }, [onSetIsVisible, onStopClosing]);

  const onClose = useCallback(() => {
    onStartClosing();

    clearTimeout(closingTimeoutHandle.current);
    closingTimeoutHandle.current = setTimeout(() => {
      if (isMounted.current) {
        onSetIsNotVisible();
        onStopClosing();
        onDidClose();
      }
    }, animationDuration);
  }, [
    onSetIsNotVisible,
    onStartClosing,
    onStopClosing,
    onDidClose,
    animationDuration,
  ]);

  // Opening / closing state.
  useEffect(() => {
    if (isOpen && !isVisible) {
      onOpen();
    } else if (!isOpen && isVisible && !isClosing) {
      onClose();
    }
  }, [isOpen, isVisible, isClosing, onOpen, onClose]);

  useUnmount(() => {
    isMounted.current = false;
  });

  return [isVisible, isClosing];
};
