import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { COLOR_GROUPS, HUE_GROUPS } from '../../../config/colors/constants';
import { BUTTON_HUES, BUTTON_SIZES, BUTTON_VARIANTS } from './constants';

const VARIANT_COLOR_GROUP_MAPPING = {
  [BUTTON_VARIANTS.PRIMARY]: COLOR_GROUPS.PRIMARY,
  [BUTTON_VARIANTS.TERTIARY]: COLOR_GROUPS.TERTIARY,
  [BUTTON_VARIANTS.SUCCESS]: COLOR_GROUPS.SUCCESS,
  [BUTTON_VARIANTS.WARNING]: COLOR_GROUPS.WARNING,
  [BUTTON_VARIANTS.DANGER]: COLOR_GROUPS.DANGER,
  [BUTTON_HUES.BLUE]: HUE_GROUPS.BLUE,
  [BUTTON_HUES.PURPLE]: HUE_GROUPS.PURPLE,
  [BUTTON_HUES.GREEN]: HUE_GROUPS.GREEN,
  [BUTTON_HUES.YELLOW]: HUE_GROUPS.YELLOW,
  [BUTTON_HUES.RED]: HUE_GROUPS.RED,
};

export const buttonStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;

  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  cursor: pointer;
  position: relative;

  transition: ${theme.transitions.default()};

  &:disabled {
    cursor: not-allowed;
  }

  &.${BUTTON_SIZES.SMALL} {
    padding: 3px 7px;
    min-height: 24px;

    &.onlyIcon {
      padding: 4px;
    }
  }

  &.${BUTTON_SIZES.MEDIUM} {
    padding: 5px 13px;
    min-height: 32px;

    &.onlyIcon {
      padding: 6px;
    }
  }

  &.${BUTTON_SIZES.LARGE} {
    padding: 7px 15px;
    min-height: 40px;

    &.onlyIcon {
      padding: 7px;
    }
  }

  &.${BUTTON_VARIANTS.NEUTRAL} {
    background-color: transparent;
    color: ${theme.colors.gray[700]};
    border-color: transparent;

    &.outline {
      background-color: ${theme.colors.gray[0]};
      border-color: ${theme.colors.gray[300]};
    }

    &:disabled {
      color: ${theme.colors.gray[300]};
      background-color: ${theme.colors.gray[25]};
      border-color: ${theme.colors.gray[25]};

      &.outline {
        background-color: ${theme.colors.gray[0]};
        border-color: ${theme.colors.gray[100]};
      }

      &:not(.outline).onlyIcon {
        background-color: transparent;
        border-color: transparent;
      }
    }

    &:not(:disabled) {
      &:hover {
        background-color: ${theme.colors.gray[50]};
        border-color: ${theme.colors.gray[50]};

        &.outline {
          background-color: ${theme.colors.gray[25]};
          border-color: ${theme.colors.gray[300]};
        }
      }

      &:active,
      &.isActive {
        background-color: ${theme.colors.gray[100]};
        border-color: ${theme.colors.gray[100]};

        &.outline {
          background-color: ${theme.colors.gray[50]};
          border-color: ${theme.colors.gray[300]};
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.gray[500]).setAlpha(0.15).toRgbString()};
    }
  }

  /* All variants (except neutral) use the same color strengths. */
  ${Object.keys(VARIANT_COLOR_GROUP_MAPPING).map((buttonVariant) => {
    const themeVariant =
      theme.colors[VARIANT_COLOR_GROUP_MAPPING[buttonVariant]] ||
      theme.hues[VARIANT_COLOR_GROUP_MAPPING[buttonVariant]];

    return css`
      &.${buttonVariant} {
        background-color: ${themeVariant[500]};
        color: ${theme.colors.gray[0]};
        border-color: ${themeVariant[500]};

        &.outline {
          background-color: ${theme.colors.gray[0]};
          color: ${themeVariant[500]};
          border-color: ${themeVariant[400]};
        }

        &:disabled {
          color: ${themeVariant[300]};
          background-color: ${themeVariant[25]};
          border-color: ${themeVariant[25]};

          &.outline {
            background-color: ${theme.colors.gray[0]};
            border-color: ${themeVariant[100]};
          }
        }

        &:not(:disabled) {
          &:hover {
            background-color: ${themeVariant[400]};
            border-color: ${themeVariant[400]};

            &.outline {
              background-color: ${themeVariant[25]};
              border-color: ${themeVariant[400]};
            }
          }

          &:active {
            background-color: ${themeVariant[600]};
            border-color: ${themeVariant[600]};

            &.outline {
              background-color: ${themeVariant[50]};
              border-color: ${themeVariant[400]};
            }
          }
        }

        &:focus {
          box-shadow: 0 0 0 4px
            ${new TinyColor(themeVariant[500]).setAlpha(0.15).toRgbString()};
        }
      }
    `;
  })}
`;

type IconFillFn = (
  theme: Theme,
  outline: boolean,
  isActive: boolean,
  isHovered: boolean,
  isDisabled: boolean,
) => [string, string];

export const getIconFills: Record<string, IconFillFn> = {
  [BUTTON_VARIANTS.NEUTRAL]: (
    theme,
    outline,
    isActive,
    isHovered,
    isDisabled,
  ) => {
    switch (true) {
      case !!isDisabled:
        return [theme.colors.gray[200], theme.colors.gray[25]];
      default:
        return [theme.colors.gray[500], theme.colors.gray[50]];
    }
  },

  /* All variants (except neutral) use the same color strengths. */
  ...Object.keys(VARIANT_COLOR_GROUP_MAPPING).reduce(
    (acc, buttonVariant) => {
      acc[buttonVariant] = (
        theme,
        outline,
        isActive,
        isHovered,
        isDisabled,
      ) => {
        const themeVariant =
          theme.colors[VARIANT_COLOR_GROUP_MAPPING[buttonVariant]] ||
          theme.hues[VARIANT_COLOR_GROUP_MAPPING[buttonVariant]];
        switch (true) {
          case !!isDisabled:
            return [themeVariant[200], themeVariant[25]];
          case !!outline:
            return [themeVariant[500], themeVariant[50]];
          case !!isActive:
            return [theme.colors.gray[0], themeVariant[500]];
          case !!isHovered:
            return [theme.colors.gray[0], themeVariant[300]];
          default:
            return [theme.colors.gray[0], themeVariant[400]];
        }
      };

      return acc;
    },
    {} as { [key: string]: IconFillFn },
  ),
};

export const badgeStyle = css`
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  right: 0;
`;
