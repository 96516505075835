import { defineMessages } from 'react-intl';

import {
  TEACHING_UNIT_LESSON_USER_ROLES,
  TEACHING_UNIT_USER_ROLES,
  USER_ROLES,
} from '@eversity/domain/constants';

export const userFormMessages = defineMessages({
  PERSON_TITLE: { defaultMessage: 'Civilité ' },
  DATE_OF_BIRTH: { defaultMessage: 'Date de naissance' },
  LAST_NAME: { defaultMessage: 'Nom' },
  FIRST_NAME: { defaultMessage: 'Prénom' },
  ADDRESS: { defaultMessage: 'Adresse' },
  ADDRESS_SUPPLEMENT: { defaultMessage: "Complément d'adresse" },
  EMERGENCY_CONTACT: {
    defaultMessage: "Personne à contacter en cas d'urgence (téléphone)",
  },
  CITY: { defaultMessage: 'Ville' },
  POSTAL_CODE: { defaultMessage: 'Code postal' },
  COUNTRY: { defaultMessage: 'Pays' },
  PHONE: { defaultMessage: 'Téléphone' },
  EMAIL: { defaultMessage: 'Email' },
});

export const userRolesMessages = defineMessages<USER_ROLES>({
  [USER_ROLES.TEACHER]: { defaultMessage: 'Professeur' },
  [USER_ROLES.CAMPUS_TESTER]: { defaultMessage: 'Testeur Campus' },
  [USER_ROLES.COACH]: { defaultMessage: 'Coach' },
  [USER_ROLES.CORRECTOR]: { defaultMessage: 'Correcteur' },
  [USER_ROLES.ANIMATION]: { defaultMessage: 'Animation Pédagogique' },
  [USER_ROLES.EDITOR]: { defaultMessage: 'Rédacteur' },
  [USER_ROLES.ADMIN]: { defaultMessage: 'Administrateur' },
  [USER_ROLES.ADMINISTRATIVE]: { defaultMessage: 'Administration' },
  [USER_ROLES.SALES]: { defaultMessage: 'Commercial' },
  [USER_ROLES.CONCEPTION]: { defaultMessage: 'Conception' },
  [USER_ROLES.SUPPORT]: { defaultMessage: 'Support' },
  [USER_ROLES.INTERNSHIPS]: { defaultMessage: 'Stage' },
});

export const specificUserRolesMessages = defineMessages({
  REFERENT: { defaultMessage: 'Référent' },
});

export const teachingUnitUserRolesMessages =
  defineMessages<TEACHING_UNIT_USER_ROLES>({
    [TEACHING_UNIT_USER_ROLES.MANAGER]: { defaultMessage: 'Responsable' },
  });

export const teachingUnitLessonUserRolesMessages =
  defineMessages<TEACHING_UNIT_LESSON_USER_ROLES>({
    [TEACHING_UNIT_LESSON_USER_ROLES.TEACHER]: {
      defaultMessage: 'Professeur ',
    },
  });
