import { createContext } from 'react';
import { noop } from 'lodash';

export interface ILayer {
  id?: string;
  zIndex?: number;
  shouldCloseOnEscape?: boolean;
  preventClosing?: boolean;
  onRequestClose?: () => void;
  type: Symbol;
}

export type LayersContextValue = {
  layers: ILayer[];
  onAddLayer: (layer: ILayer) => string;
  onUpdateLayer: (layerId: string, layer: ILayer) => void;
  onRemoveLayer: (layerId: string) => void;
};

export const LayersContext = createContext<LayersContextValue>({
  layers: [],
  onAddLayer: () => 'defaultLayer',
  onUpdateLayer: noop,
  onRemoveLayer: noop,
});
