import { memo } from 'react';
import { css, Global } from '@emotion/react';

import { spinAnimationKeyframes } from '../../../utils/style/animations';
import { ICON_CONFIG } from './constants';

export const IconGlobalStylesBase = () => (
  <Global
    styles={(theme) => css`
      .${ICON_CONFIG.prefix}-icon {
        display: flex;

        svg * {
          transition: ${theme.transitions.default()};
        }

        &-spin svg {
          animation: ${spinAnimationKeyframes} infinite 3s linear;
        }
      }
    `}
  />
);

IconGlobalStylesBase.displayName = 'IconGlobalStyles';

export const IconGlobalStyles = memo(IconGlobalStylesBase);
