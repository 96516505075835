import {
  type ControlProps,
  type CSSObjectWithLabel,
  type GroupBase,
  type OptionProps,
  type StylesConfig,
  type Theme as ReactSelectTheme,
  type ThemeConfig,
} from 'react-select';
import { TinyColor } from '@ctrl/tinycolor';
import { type Theme } from '@emotion/react';

import { type SelectOption } from '@eversity/types/web';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import {
  type MakeCustomStylesProps,
  type MakeCustomThemeProps,
  SELECT_SIZES,
} from './constants';

const SIZE_HEIGHTS: Record<SELECT_SIZES, number> = {
  [SELECT_SIZES.SMALL]: 24,
  [SELECT_SIZES.MEDIUM]: 32,
  [SELECT_SIZES.LARGE]: 40,
};

const SIZE_VALUE_CONTAINER_PADDINGS: Record<SELECT_SIZES, number> = {
  [SELECT_SIZES.SMALL]: 7,
  [SELECT_SIZES.MEDIUM]: 13,
  [SELECT_SIZES.LARGE]: 15,
};

const SIZE_MULTI_VALUE_CONTAINER_PADDINGS: Record<SELECT_SIZES, string> = {
  [SELECT_SIZES.SMALL]: '1px 3px',
  [SELECT_SIZES.MEDIUM]: '4px 5px',
  [SELECT_SIZES.LARGE]: '7px',
};

const SIZE_MULTI_VALUE_PADDINGS: Record<SELECT_SIZES, number> = {
  [SELECT_SIZES.SMALL]: 4,
  [SELECT_SIZES.MEDIUM]: 6,
  [SELECT_SIZES.LARGE]: 8,
};

const SIZE_MULTI_VALUE_REMOVE_PADDINGS: Record<SELECT_SIZES, number> = {
  [SELECT_SIZES.SMALL]: 2,
  [SELECT_SIZES.MEDIUM]: 3,
  [SELECT_SIZES.LARGE]: 4,
};

const SELECT_SIZE_TYPOGRAPHY_MAPPING: Record<
  SELECT_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [SELECT_SIZES.SMALL]: TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR,
  [SELECT_SIZES.MEDIUM]: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  [SELECT_SIZES.LARGE]: TYPOGRAPHY_VARIANTS.BODY_LARGE_REGULAR,
};

const SIZE_ICON_SIZES: Record<SELECT_SIZES, string> = {
  [SELECT_SIZES.SMALL]: '14px',
  [SELECT_SIZES.MEDIUM]: '18px',
  [SELECT_SIZES.LARGE]: '24px',
};

export const makeCustomTheme =
  ({ size }: MakeCustomThemeProps): ThemeConfig =>
  (defaultTheme: ReactSelectTheme) => ({
    ...defaultTheme,
    borderRadius: 4,

    spacing: {
      ...defaultTheme.spacing,
      controlHeight: SIZE_HEIGHTS[size],
    },
  });

const controlBorderColor = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  {
    theme,
    hasError,
    hasWarning,
  }: {
    theme: Theme;
    hasError: boolean;
    hasWarning: boolean;
  },
  state: ControlProps<Option, IsMulti, Group>,
) => {
  if (hasError) {
    return theme.colors.danger[400];
  }

  if (hasWarning) {
    return theme.colors.warning[400];
  }

  if (state.isFocused) {
    return theme.colors.primary[400];
  }

  return theme.colors.gray[200];
};

const controlBoxShadow = ({
  theme,
  hasError,
  hasWarning,
}: {
  theme: Theme;
  hasError: boolean;
  hasWarning: boolean;
}) => {
  if (hasError) {
    return new TinyColor(theme.colors.danger[500]).setAlpha(0.15).toRgbString();
  }

  if (hasWarning) {
    return new TinyColor(theme.colors.warning[500])
      .setAlpha(0.15)
      .toRgbString();
  }

  return new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString();
};

const optionColor = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  theme: Theme,
  state: OptionProps<Option, IsMulti, Group>,
) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return theme.colors.gray[300];
  }

  if (state.isSelected) {
    return theme.colors.primary[500];
  }

  return theme.colors.gray[700];
};

const indicatorColor = <
  IndicatorProps extends { isFocused?: boolean; isDisabled?: boolean },
>(
  theme: Theme,
  state: IndicatorProps,
) => {
  if (state.isFocused) {
    return theme.colors.primary[400];
  }

  if (state.isDisabled) {
    return theme.colors.gray[300];
  }

  return theme.colors.gray[400];
};

const indicator =
  <IndicatorProps extends { isFocused?: boolean; isDisabled?: boolean }>({
    theme,
    size,
  }: {
    theme: Theme;
    size: SELECT_SIZES;
  }) =>
  (provided: CSSObjectWithLabel, state: IndicatorProps) => ({
    ...provided,

    padding: 0,

    fontSize: SIZE_ICON_SIZES[size],

    color: indicatorColor(theme, state),

    '&:hover': {
      color: indicatorColor(theme, state),
    },
  });

export const makeCustomStyles = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>({
  theme,
  hasError,
  hasWarning,
  isInLayer,
  layerZIndex,
  size,
}: MakeCustomStylesProps): StylesConfig<TOption, TIsMulti, TGroup> => ({
  container: (provided) => ({
    ...provided,
    textAlign: 'initial',
  }),

  control: (provided, state) => ({
    ...provided,

    boxShadow: state.isFocused
      ? `0 0 0 4px ${controlBoxShadow({ theme, hasError, hasWarning })}`
      : 'none',

    cursor: state.isDisabled ? 'not-allowed' : 'pointer',

    backgroundColor: state.isDisabled
      ? theme.colors.gray[25]
      : theme.colors.gray[0],

    borderColor: controlBorderColor({ theme, hasError, hasWarning }, state),

    '&:hover': {
      borderColor: controlBorderColor({ theme, hasError, hasWarning }, state),
    },
  }),

  dropdownIndicator: indicator({ theme, size }),
  clearIndicator: indicator({ theme, size }),
  loadingIndicator: (provided, state) => ({
    ...indicator({ theme, size })(provided, state),
    fontSize: '4px',
    padding: '8px 4px 8px 0',
  }),

  indicatorsContainer: (provided) => ({
    ...provided,

    padding: `0 ${SIZE_VALUE_CONTAINER_PADDINGS[size]}px 0 8px`,
  }),

  indicatorSeparator: () => ({
    width: 0,
  }),

  singleValue: (provided, state) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    color: state.isDisabled ? theme.colors.gray[300] : theme.colors.gray[700],
  }),

  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.gray[0],
    boxShadow: `
      0px 2px 4px ${new TinyColor(theme.colors.gray[900])
        .setAlpha(0.1)
        .toRgbString()},
      0px 0px 11px 1px ${new TinyColor(theme.colors.gray[900])
        .setAlpha(0.1)
        .toRgbString()}`,
  }),

  menuList: (provided) => ({
    ...provided,
    textAlign: 'left',
    padding: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],

    whiteSpace: 'nowrap',
    color: theme.colors.gray[300],
  }),

  input: (provided) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,

    input: {
      ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,

    padding:
      state.isMulti && state.hasValue
        ? SIZE_MULTI_VALUE_CONTAINER_PADDINGS[size]
        : `0 ${SIZE_VALUE_CONTAINER_PADDINGS[size]}px`,

    gap: state.isMulti ? '2px 4px' : 'none',
  }),

  option: (provided, state) => ({
    ...provided,

    ...theme.typography[TYPOGRAPHY_VARIANTS.BUTTON_MEDIUM_BOLD],

    transition: theme.transitions.default(),

    padding: '5px 12px',

    cursor:
      state.selectProps.isDisabled || state.isDisabled
        ? 'not-allowed'
        : 'pointer',

    backgroundColor:
      state.isFocused && !state.isDisabled
        ? theme.colors.gray[25]
        : 'transparent',

    fontWeight: state.isSelected ? '700' : undefined,

    color: optionColor(theme, state),

    '&:hover': {
      backgroundColor:
        state.selectProps.isDisabled || state.isDisabled
          ? 'transparent'
          : theme.colors.gray[25],
    },

    '&:active': {
      backgroundColor: theme.colors.gray[25],
    },
  }),

  loadingMessage: (provided) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    textAlign: 'left',
    padding: '5px 12px',
    color: theme.colors.gray[300],
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    textAlign: 'left',
    padding: '5px 12px',
    color: theme.colors.gray[300],
  }),

  multiValue: (provided, state) => ({
    ...provided,
    margin: 0,
    backgroundColor: state.isDisabled
      ? theme.colors.gray[50]
      : state.data.multiValueBackgroundColor || theme.colors.gray[25],
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    ...theme.typography[SELECT_SIZE_TYPOGRAPHY_MAPPING[size]],
    padding: 0,
    paddingLeft: SIZE_MULTI_VALUE_PADDINGS[size],
    paddingRight: SIZE_MULTI_VALUE_PADDINGS[size],

    color: state.isDisabled
      ? theme.colors.gray[300]
      : state.data.multiValueTextColor || theme.colors.gray[700],
  }),

  multiValueRemove: (provided, state) =>
    // Hide if multi value is fixed.
    state.data.isFixed
      ? { ...provided, display: 'none' }
      : {
          ...provided,
          transition: theme.transitions.default(),

          color: state.data.multiValueTextColor || theme.colors.gray[500],

          paddingLeft: SIZE_MULTI_VALUE_REMOVE_PADDINGS[size],
          paddingRight: SIZE_MULTI_VALUE_REMOVE_PADDINGS[size],

          '&:hover': {
            backgroundColor: theme.colors.danger[100],
            color: theme.colors.danger[500],
          },
        },

  menuPortal: (provided) => ({
    ...provided,
    zIndex: isInLayer ? layerZIndex + 1 : provided.zIndex,
  }),
});
