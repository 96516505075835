import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import { type GroupBase, type SelectInstance } from 'react-select';
import ReactCreatableSelect, {
  type CreatableProps,
} from 'react-select/creatable';

import { type SelectOption } from '@eversity/types/web';

import { type CommonSelectProps, SELECT_SIZES } from './constants';
import {
  commonSelectDefaultProps,
  creatableSelectDefaultProps,
} from './utils/defaultProps';
import {
  commonSelectPropTypes,
  creatableSelectPropTypes,
} from './utils/propTypes';
import { useReactSelectCustomization } from './utils/useReactSelectCustomizations';
import { type useReactSelectValue } from './utils/useReactSelectValueWrapper';

export type CreatableSelectProps<
  TOption extends SelectOption<any> = SelectOption<any>,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = Omit<
  CreatableProps<TOption, TIsMulti, TGroup>,
  'isDisabled' | 'formatOptionLabel' | 'value' | 'onChange'
> &
  CommonSelectProps & {
    onChange: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['onChange'];
    value: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['value'];
  };

const CreatableSelectInner = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  {
    id,
    size,
    disabled,
    hasError,
    hasWarning,
    ...props
  }: CreatableSelectProps<TOption, TIsMulti, TGroup>,
  ref: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>,
) => {
  const { onChange, onChangeMulti, ...customProps } =
    useReactSelectCustomization<TOption, TIsMulti, TGroup, true>({
      hasError,
      hasWarning,
      size,
      useOptionAsValue: true,
      ...props,
    });

  return (
    <ReactCreatableSelect<TOption, TIsMulti, TGroup>
      {...props}
      {...customProps}
      ref={ref}
      inputId={id}
      aria-labelledby={id}
      onChange={props.isMulti ? onChangeMulti : onChange}
      isDisabled={disabled}
    />
  );
};

export const CreatableSelectBase = forwardRef(CreatableSelectInner) as {
  <
    TOption extends SelectOption<any> = SelectOption<any>,
    TIsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  >(
    props: CreatableSelectProps<TOption, TIsMulti, TGroup> & {
      ref?: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>;
    },
  ): ReactElement | null;

  propTypes?: any;
  defaultProps?: any;
  displayName?: any;
};

CreatableSelectBase.displayName = 'CreatableSelect';

CreatableSelectBase.propTypes = {
  ...commonSelectPropTypes,
  ...creatableSelectPropTypes,
};

CreatableSelectBase.defaultProps = {
  ...commonSelectDefaultProps,
  ...creatableSelectDefaultProps,
};

export const CreatableSelect = memo(
  CreatableSelectBase,
) as typeof CreatableSelectBase & {
  SIZES?: typeof SELECT_SIZES;
};

CreatableSelect.SIZES = SELECT_SIZES;
