import { Fragment, memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { globalMessages } from '@eversity/ui/intl';
import { Truncate, useTruncate } from '@eversity/ui/utils';

import { LinkButton } from '../link-button/LinkButton';
import { Typography } from '../typography/Typography';

type ShowMoreButtonProps = {
  isExpanded: boolean;
  onToggleExpand: () => void;
};

export type ExpandableTruncateProps = {
  maxLines: number;
  showMoreButton?: (props: ShowMoreButtonProps) => ReactNode;
  children?: ReactNode;
};

const defaultShowMoreButton = ({
  isExpanded,
  onToggleExpand,
}: ShowMoreButtonProps) => (
  <LinkButton onClick={onToggleExpand}>
    <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
      {isExpanded ? (
        <FormattedMessage {...globalMessages.COLLAPSE} />
      ) : (
        <FormattedMessage {...globalMessages.KNOW_MORE} />
      )}
    </Typography>
  </LinkButton>
);

export const ExpandableTruncateBase = ({
  maxLines,
  showMoreButton = defaultShowMoreButton,
  ...props
}: ExpandableTruncateProps) => {
  const {
    ref: truncateRef,
    isExpanded,
    contentOverflows,
    onToggleExpand,
  } = useTruncate({ maxLines });

  return (
    <Fragment>
      <Truncate
        {...props}
        ref={truncateRef}
        maxLines={maxLines}
        isExpanded={isExpanded}
      />

      {!!contentOverflows && showMoreButton({ isExpanded, onToggleExpand })}
    </Fragment>
  );
};

ExpandableTruncateBase.displayName = 'ExpandableTruncate';

ExpandableTruncateBase.propTypes = {
  /** Max number of lines to show before showing an ellipsis. */
  maxLines: PropTypes.number.isRequired,
  /** CSS text-overflow property. */
  textOverflow: PropTypes.string,
  /** Function to customize the "Show more" button. ({ isExpanded, onToggleExpand }) => Node. */
  showMoreButton: PropTypes.func,
  /** Content to truncate. */
  children: PropTypes.node,
};

export const ExpandableTruncate = memo(ExpandableTruncateBase);
