import { memo } from 'react';
import { components, type OptionProps } from 'react-select';
import { noop } from 'lodash';

import { type SelectOption } from '@eversity/types/web';

import { CheckboxControl } from '../../../checkbox-control/CheckboxControl';
import * as styles from './CustomOption.styles';

const CustomOptionBase = (props: OptionProps<SelectOption<string>>) => {
  const {
    isSelected,
    data: { value },
    label,
    innerProps: { id },
  } = props;
  return (
    <components.Option {...props}>
      <label
        htmlFor={id}
        css={styles.checkbox}
      >
        <CheckboxControl
          key={value}
          id={id}
          checked={isSelected}
          onChange={noop}
        />
        {label}
      </label>
    </components.Option>
  );
};

CustomOptionBase.displayName = 'CustomOption';

export default memo(CustomOptionBase);
