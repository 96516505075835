import { useCallback, useMemo } from 'react';
import {
  type ActionMeta,
  type GroupBase,
  type Props as ReactSelectProps,
} from 'react-select';

import { type SelectOption } from '@eversity/types/web';

// Get a flat array of options (easier to handle).
const flattenOptions = <
  TOption extends SelectOption<any>,
  TGroup extends GroupBase<TOption>,
>(
  options: readonly (TOption | TGroup)[],
): TOption[] =>
  (options || []).flatMap((option: TOption | TGroup) =>
    'options' in option ? option.options : option,
  );

// Find an option by its value.
const findOption = <TOption extends SelectOption<any>>(
  flattenedOptions: TOption[],
  optionValue: TOption['value'],
) => flattenedOptions.find((opt) => opt.value === optionValue);

export type UseReactSelectValue<
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
> = [
  value: TIsMulti extends true ? TOption[] : TOption,
  onChange: ReactSelectProps<TOption, TIsMulti, TGroup>['onChange'],
];

/**
 * This is a wrapper around react-select's value management.
 * React-select expects to have a an option (or list of options) as its value, but we only want to
 * pass the option value (or options values) to our component.
 *
 * NB: to use the default behaviour, pass useOptionAsValue to Select.
 *
 * @param params - Params.
 * @param params.value - Selected options values.
 * @param params.options - Options (react-select format).
 * @param params.onChange - Handler for onChange. (newOptionsValues) => void.
 * @param params.isMulti - If true, the value is a list of option values.
 * @param useOptionAsValue - If true, don't process anything.
 * @returns The value and onChange handler for react-select.
 */
export const useReactSelectValue = <
  TOption extends SelectOption<any>,
  TGroup extends GroupBase<TOption>,
  TIsMulti extends boolean,
  TUseOptionAsValue extends boolean,
>(
  {
    value,
    options,
    onChange,
    isMulti,
  }: {
    options?: readonly (TOption | TGroup)[];
    isMulti: TIsMulti;

    value?: TUseOptionAsValue extends true
      ? TIsMulti extends true
        ? TOption[]
        : TOption
      : TIsMulti extends true
        ? TOption['value'][]
        : TOption['value'];

    onChange?: (
      value: TUseOptionAsValue extends true
        ? TIsMulti extends true
          ? TOption[]
          : TOption | null
        : TIsMulti extends true
          ? TOption['value'][]
          : TOption['value'] | null,
      actionMeta?: ActionMeta<TOption>,
    ) => void;
  },
  useOptionAsValue: TUseOptionAsValue,
): UseReactSelectValue<TOption, TIsMulti, TGroup> => {
  const flattenedOptions = useMemo(
    () => (useOptionAsValue ? [] : flattenOptions<TOption, TGroup>(options)),
    [options, useOptionAsValue],
  );

  const reactSelectValue = useMemo(() => {
    if (useOptionAsValue) {
      return null;
    }

    return isMulti
      ? (value as TOption['value'][])?.map((v) =>
          findOption<TOption>(flattenedOptions, v),
        ) || null
      : findOption<TOption>(flattenedOptions, value as TOption['value']) ||
          null;
  }, [value, isMulti, flattenedOptions, useOptionAsValue]);

  const reactSelectOnChange = useCallback<
    UseReactSelectValue<TOption, TIsMulti, TGroup>[1]
  >(
    (newValue, actionMeta) =>
      useOptionAsValue
        ? null
        : (
            onChange as (
              newValue: TOption['value'] | TOption['value'][] | null,
              meta: typeof actionMeta,
            ) => void
          )(
            isMulti
              ? (newValue as TOption[])?.map((opt) => opt.value) || []
              : (newValue as TOption)?.value || null,
            actionMeta,
          ),
    [isMulti, onChange, useOptionAsValue],
  );

  return [
    useOptionAsValue ? value : reactSelectValue,
    useOptionAsValue ? onChange : reactSelectOnChange,
  ] as UseReactSelectValue<TOption, TIsMulti, TGroup>;
};
