import {
  cloneElement,
  type ForwardedRef,
  forwardRef,
  isValidElement,
  memo,
  type ReactElement,
  useCallback,
  useMemo,
} from 'react';
import {
  ErrorBoundary,
  type ErrorBoundaryProps,
  type FallbackRender,
} from '@sentry/react';

import { useLocaleContext } from '../locale-context/LocaleContext';

export type SentryErrorBoundaryProps = ErrorBoundaryProps;

export const SentryErrorBoundaryBase = forwardRef(
  (
    { fallback, ...props }: SentryErrorBoundaryProps,
    ref: ForwardedRef<ErrorBoundary>,
  ) => {
    const { locale } = useLocaleContext();

    const dialogOptions = useMemo(
      () => ({
        lang: locale,
        ...props.dialogOptions,
      }),
      [locale, props.dialogOptions],
    );

    // If fallback is an element, pass fallback props.
    const fallbackElement: FallbackRender = useCallback(
      (fallbackProps) => cloneElement(fallback as ReactElement, fallbackProps),
      [fallback],
    );

    return (
      <ErrorBoundary
        {...props}
        showDialog
        ref={ref}
        fallback={isValidElement(fallback) ? fallbackElement : fallback}
        dialogOptions={dialogOptions}
      />
    );
  },
);

SentryErrorBoundaryBase.displayName = 'SentryErrorBoundary';

export const SentryErrorBoundary = memo(SentryErrorBoundaryBase);
