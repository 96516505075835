import { css, type Theme } from '@emotion/react';

export const embedded = css`
  margin: auto;

  position: relative;

  > iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const loaderContainer = (theme: Theme) => css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: ${theme.colors.gray[25]};

  display: flex;
  align-items: center;
  justify-content: center;
`;
