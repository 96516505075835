import { type Theme } from '@emotion/react';

import {
  TABLE_BACKGROUND_COLORS,
  TEXT_COLORS,
} from '@eversity/domain/constants';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

export const TAGS_TYPOGRAPHY_MAPPING: Record<string, TYPOGRAPHY_VARIANTS> = {
  h1: TYPOGRAPHY_VARIANTS.HEADING_1,
  h2: TYPOGRAPHY_VARIANTS.HEADING_2,
  h3: TYPOGRAPHY_VARIANTS.HEADING_3,
  h4: TYPOGRAPHY_VARIANTS.HEADING_4,
  h5: TYPOGRAPHY_VARIANTS.HEADING_5,
  p: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  ol: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  ul: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
  blockquote: TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR,
};

export const getThemeTextColor = (
  textColor: TEXT_COLORS | undefined,
  theme: Theme,
) => {
  switch (textColor) {
    case TEXT_COLORS.RED:
      return theme.hues.red[500];
    case TEXT_COLORS.YELLOW:
      return theme.hues.yellow[400];
    case TEXT_COLORS.GREEN:
      return theme.hues.green[500];
    case TEXT_COLORS.BLUE:
      return theme.hues.blue[500];
    default:
      return 'inherit';
  }
};

export const getThemeTableBackgroundColor = (
  backgroundColor: TABLE_BACKGROUND_COLORS,
  theme: Theme,
) => {
  switch (backgroundColor) {
    case TABLE_BACKGROUND_COLORS.BLUE:
      return theme.hues.blue[50];
    case TABLE_BACKGROUND_COLORS.GREEN:
      return theme.hues.green[100];
    case TABLE_BACKGROUND_COLORS.PURPLE:
      return theme.hues.purple[100];
    case TABLE_BACKGROUND_COLORS.YELLOW:
      return theme.hues.yellow[100];
    case TABLE_BACKGROUND_COLORS.RED:
      return theme.hues.red[100];
    default:
      return 'inherit';
  }
};
