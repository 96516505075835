import { APP_ORIGINS } from '../common';

export enum SESSION_DEVICES {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export const SESSION_DEVICES_APP_ORIGIN_MAPPING = {
  [APP_ORIGINS.WEB]: SESSION_DEVICES.WEB,
  [APP_ORIGINS.MOBILE]: SESSION_DEVICES.MOBILE,
} as const;

export const SESSION_INACTIVITY_MAX_DURATION_IN_SECONDS = 600;
