import { css } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const box = css`
  margin: auto 0;
`;

export const input = css`
  /* We need to keep it in the DOM to make is focusable. */
  position: fixed;
  top: -1000px;
  left: -1000px;
`;
