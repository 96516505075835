// Custom html tags for the sequence content, to insert our own components.
export enum CUSTOM_HTML {
  VIDEO = 'eversity-video',
  CAROUSEL = 'eversity-carousel',
  CAROUSEL_SLIDE = 'eversity-slide',
  LINKEDIN_EMBEDDED = 'eversity-linkedin-embedded',
  ATTACHMENT = 'eversity-attachment',
  AUDIO = 'eversity-audio',
}

export enum TEXT_COLORS {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
}

export enum TABLE_BACKGROUND_COLORS {
  BLUE = 'blue',
  GREEN = 'green',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  RED = 'red',
}

export const DEFAULT_VIDEO_WIDTH = 426;
export const DEFAULT_VIDEO_ASPECT_RATIO = 16 / 9;

export const MAX_CAROUSEL_SLIDES = 2;

export enum CAROUSEL_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

// Carousel width in px.
export const CAROUSEL_SIZE_WIDTHS: Record<CAROUSEL_SIZES, number> = {
  [CAROUSEL_SIZES.SMALL]: 200,
  [CAROUSEL_SIZES.MEDIUM]: 500,
  [CAROUSEL_SIZES.LARGE]: 800,
};

// Gap between slides in px.
export const CAROUSEL_SLIDE_MARGIN = 10;
export const CAROUSEL_LIGHTBOX_HEIGHT = 768;

export const DEFAULT_EDITOR_OUTLINE_MIN_LEVEL = 3;
export const DEFAULT_EDITOR_OUTLINE_MAX_LEVEL = 5;
