import { css, type Theme } from '@emotion/react';

export const headerContainer = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;

  border-bottom: 1px solid ${theme.colors.gray[100]};
`;

export const header = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
`;
