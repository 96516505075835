import { createContext } from 'react';

import { SECTION_VARIANTS } from './constants';

export type TableOfContentsSectionContextValue = {
  variant: SECTION_VARIANTS;
};

export const TableOfContentsSectionContext =
  createContext<TableOfContentsSectionContextValue>({
    variant: SECTION_VARIANTS.NEUTRAL,
  });
