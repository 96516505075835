import PropTypes from 'prop-types';

export const assignmentCorrectionFeeAmountHistoryPropTypes = PropTypes.shape({
  /** Amount in € cents. */
  amount: PropTypes.number.isRequired,
  /** Activity start date. */
  startDate: PropTypes.string.isRequired,
});

export const assignmentCorrectionFeePropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Name. */
  name: PropTypes.string.isRequired,
  /** Description (plain text). */
  description: PropTypes.string.isRequired,
  /**
   * History of all amounts for this assignment correction fee.
   * Use getCorrectionFeeAmount to get the current active amount.
   */
  amountsHistory: PropTypes.arrayOf(
    assignmentCorrectionFeeAmountHistoryPropTypes,
  ).isRequired,
});
