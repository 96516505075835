import { type ComponentType, memo, Suspense, useCallback } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { type NOTIFICATION_TYPES } from '@eversity/domain/constants';
import { type Notification } from '@eversity/types/domain';

import { notificationPropTypes } from '../../../../types/notifications';
import { NotificationListItemSkeleton } from './skeleton/NotificationListItemSkeleton';

export type NotificationListRendererProps<
  TNotification extends Notification = Notification,
> = {
  notification: TNotification;
  onMarkAsRead?: () => void;
  onMarkAsUnread?: () => void;
  onCloseNotifications?: () => void;
  onClick?: () => void;
};

export type NotificationListItemProps = {
  notification: Notification;
  rendererMapping: Record<
    NOTIFICATION_TYPES,
    ComponentType<NotificationListRendererProps>
  >;
  onMarkAsRead?: (notificationId: string) => void;
  onMarkAsUnread?: (notificationId: string) => void;
  onCloseNotifications?: () => void;
  onClick?: () => void;
};

export const NotificationListItemBase = ({
  notification,
  rendererMapping,
  onMarkAsRead = noop,
  onMarkAsUnread = noop,
  onCloseNotifications = noop,
  onClick = noop,
}: NotificationListItemProps) => {
  const Component = rendererMapping[notification.notificationType];

  const onMarkAsReadProxy = useCallback(
    () => onMarkAsRead(notification.id),
    [onMarkAsRead, notification.id],
  );

  const onMarkAsUnreadProxy = useCallback(
    () => onMarkAsUnread(notification.id),
    [onMarkAsUnread, notification.id],
  );

  return Component ? (
    <Suspense fallback={<NotificationListItemSkeleton />}>
      <Component
        notification={notification}
        onMarkAsRead={onMarkAsReadProxy}
        onMarkAsUnread={onMarkAsUnreadProxy}
        onCloseNotifications={onCloseNotifications}
        onClick={onClick}
      />
    </Suspense>
  ) : null;
};

NotificationListItemBase.displayName = 'NotificationListItem';

NotificationListItemBase.propTypes = {
  /** Generic notification. */
  notification: notificationPropTypes.isRequired,
  /** Mapping from notification.notificationType to a component rendering the notification. */
  // @ts-ignore -- Can't specify the type of the key.
  rendererMapping: PropTypes.objectOf(PropTypes.elementType).isRequired,
  /** Callback when marking as read. */
  onMarkAsRead: PropTypes.func,
  /** Callback when marking as unread. */
  onMarkAsUnread: PropTypes.func,
  /** Closes the list of notifications. */
  onCloseNotifications: PropTypes.func,
};

export const NotificationListItem = memo(NotificationListItemBase);
