import { Fragment, memo, type MemoExoticComponent } from 'react';
import { type To } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { Right } from '@icon-park/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { ellipsis, shouldApplyEllipsis } from '../../../utils/string';
import { Tooltip } from '../../general/tooltip/Tooltip';
import { Typography } from '../../general/typography/Typography';
import { Link } from '../link/Link';
import { BREADCRUMBS_SIZES, DEFAULT_MAX_LENGTH } from './constants';

const getLabelVariant = (size: BREADCRUMBS_SIZES, isLinkActive: boolean) => {
  if (size === BREADCRUMBS_SIZES.SMALL && isLinkActive) {
    return Typography.VARIANTS.BODY_SMALL_BOLD;
  }
  if (size === BREADCRUMBS_SIZES.SMALL && !isLinkActive) {
    return Typography.VARIANTS.BODY_SMALL_REGULAR;
  }
  if (size === BREADCRUMBS_SIZES.LARGE && isLinkActive) {
    return Typography.VARIANTS.BODY_LARGE_BOLD;
  }
  return Typography.VARIANTS.BODY_LARGE_REGULAR;
};

export type BreadcrumbsProps = {
  links: {
    key?: string;
    title: string;
    href?: To;
  }[];
  className?: string;
  maxLength?: number;
  size?: BREADCRUMBS_SIZES;
  isLastLinkActive?: boolean;
};

const DEFAULT_LINKS = [];

export const BreadcrumbsBase = ({
  className = undefined,
  links = DEFAULT_LINKS,
  maxLength = DEFAULT_MAX_LENGTH,
  size = BREADCRUMBS_SIZES.LARGE,
  isLastLinkActive = false,
}: BreadcrumbsProps) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={css`
        display: inline-flex;
        align-items: center;
        gap: 2px 10px;
        flex-wrap: wrap;
        overflow-wrap: anywhere;
      `}
    >
      {links.map(({ key, title, href }, index) => {
        const isLast = index === links.length - 1;
        const isLinkActive = isLast && isLastLinkActive;
        const variant = getLabelVariant(size, isLinkActive);

        const label = (
          <Typography variant={variant}>
            {ellipsis(title, maxLength)}
          </Typography>
        );

        return (
          <Fragment key={key || (href as string)}>
            <Link
              to={href}
              isDisabled={!href}
              className={cn({ isLinkActive, isDisabled: !href })}
              css={css`
                color: ${theme.colors.gray[500]};
                outline: none;
                text-decoration: none;
                display: inline;

                transition: ${theme.transitions.default()};

                &.isLinkActive {
                  color: ${theme.colors.primary[500]};
                }

                &:hover:not(.isDisabled) {
                  text-decoration: underline;
                }
              `}
            >
              {shouldApplyEllipsis(title, maxLength) ? (
                <Tooltip
                  placement="top"
                  content={
                    <Typography
                      variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                    >
                      {title}
                    </Typography>
                  }
                >
                  {label}
                </Tooltip>
              ) : (
                label
              )}
            </Link>

            {!isLast && (
              <Right
                size={10}
                theme="filled"
                fill={theme.colors.gray[700]}
                css={css`
                  opacity: 0.5;
                `}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

BreadcrumbsBase.displayName = 'Breadcrumbs';

BreadcrumbsBase.propTypes = {
  /** Root node class name. */
  className: PropTypes.string,
  /** Links to be displayed. */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string.isRequired,
      href: linkPathPropTypes,
    }),
  ),
  /** Max length of a link, any character after that will be replaced by an ellipsis. */
  maxLength: PropTypes.number,
  /** Size of the breadcrumbs. */
  size: PropTypes.oneOf(Object.values(BREADCRUMBS_SIZES)),
  /** Display last link stronger. */
  isLastLinkActive: PropTypes.bool,
};

export const Breadcrumbs: MemoExoticComponent<typeof BreadcrumbsBase> & {
  DEFAULT_MAX_LENGTH?: number;
  SIZES?: typeof BREADCRUMBS_SIZES;
} = memo(BreadcrumbsBase);

Breadcrumbs.DEFAULT_MAX_LENGTH = DEFAULT_MAX_LENGTH;
Breadcrumbs.SIZES = BREADCRUMBS_SIZES;
