import { css, type Theme } from '@emotion/react';

export default {
  container: (theme: Theme, isDisabled: boolean) => css`
    color: ${!isDisabled ? theme.colors.gray[700] : theme.colors.gray[400]};
    position: relative;
    display: flex;
    gap: 8px;

    transition: ${theme.transitions.default()};
  `,

  radio: css`
    margin: auto 0;
  `,

  input: () => css`
    /* We need to keep it in the DOM to make is focusable. */
    position: fixed;
    top: -1000px;
    left: -1000px;

    display: flex;

    + *::before {
      content: '';
    }
  `,

  label: () => css`
    display: flex;
    align-items: baseline;
  `,
};
