import { defineMessages } from 'react-intl';

export default defineMessages({
  TODAY: {
    defaultMessage: "Aujourd'hui",
  },

  TOMORROW: {
    defaultMessage: 'Demain',
  },

  FORMAT_INTERVAL: {
    defaultMessage: '{date}, {start} - {end}',
  },
});
