import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '../../../utils/style';

export const itemBoxStyle = (theme: Theme) => css`
  background-color: ${theme.colors.primary[15]};
  box-shadow:
    inset 0 1px 2px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.1).toRgbString()},
    inset 0 0 2px 1px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.1).toRgbString()};
  border-radius: 4px;

  .ReactCollapse--collapse {
    transition: ${theme.transitions.default()};

    .ReactCollapse--content {
      padding: 10px;

      button {
        margin-top: 10px;
      }
    }
  }
`;

export const headerStyle = (theme: Theme) => css`
  display: flex;
  align-items: stretch;
  width: 100%;
  box-shadow:
    0 1px 1px ${new TinyColor('#000000').setAlpha(0.15).toRgbString()},
    0 0 5px 1px ${new TinyColor('#000000').setAlpha(0.1).toRgbString()};
  border-radius: 4px;

  ${removeButtonAppearance};

  background-color: ${theme.colors.gray[0]};
  text-align: left;

  .iconLeft {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 13px;
    border-right: 1px solid ${theme.colors.gray[100]};

    .dot {
      position: absolute;
      top: -7px;
      right: -7px;
      height: 14px;
      width: 14px;
    }
  }

  .iconRight {
    display: flex;
    align-items: center;

    padding: 0 16px;
    transform: rotate(0deg);
    transition: ${theme.transitions.default()};

    &.OPEN {
      transform: rotate(90deg);
    }
  }
`;

export const itemStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  text-align: left;

  ${removeButtonAppearance};

  transition: ${theme.transitions.default()};

  .icon {
    position: relative;
    padding: 11px;

    .dot {
      position: absolute;
      height: 12px;
      width: 12px;
      top: 4px;
      left: 21px;
      border-color: ${theme.colors.primary[15]};

      transition: ${theme.transitions.default()};
    }
  }

  &:hover {
    background-color: ${theme.colors.primary[25]};

    .dot {
      border-color: ${theme.colors.primary[25]};
    }
  }

  &.SELECTED {
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.primary[500]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};

    .dot {
      border-color: ${theme.colors.primary[500]};
    }
  }
`;
