export enum ASSIGNMENT_VIEWS {
  MINIMAL = 'MINIMAL',
  FULL = 'FULL',
  STUDENT = 'STUDENT',
}

export enum ASSIGNMENT_TYPES {
  HOMEWORK = 'HOMEWORK',
  EXAM = 'EXAM',
}

export enum ASSIGNMENT_GRADING_TYPES {
  ASSESSMENT = 'ASSESSMENT',
  GRADE = 'GRADE',
}

export const ASSIGNMENT_CODE_PREFIX = 'D_';

// Style of submissions for an assignment.
export enum ASSIGNMENT_STYLES {
  // Student will upload files.
  FILE = 'FILE',
  // Student will fill a form that will be automatically graded.
  MCQ = 'MCQ',
}

export enum ASSIGNMENT_STATUSES {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  GRADED = 'GRADED',
}

export const ASSIGNMENT_CODE_MAX_LENGTH = 22;

export const ASSIGNMENT_TITLE_MAX_LENGTH = 100;

export const ASSIGNMENT_DESCRIPTION_MAX_LENGTH = 1500;

export enum ASSIGNMENT_TAGS {
  CONTINUOUS_ASSESSMENT = 'CONTINUOUS_ASSESSMENT',
  PROFESSIONAL_FILE = 'PROFESSIONAL_FILE',
}
