import { css } from '@emotion/react';

export const removeButtonAppearance = css`
  padding: 0;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
`;

export const removeTextAreaAppearance = css`
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
`;

export const removeLinkAppearance = css`
  text-decoration: none;
  color: inherit;
`;
