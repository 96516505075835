import { memo, type MemoExoticComponent, useContext } from 'react';
import { type To } from 'react-router-dom';
import cn from 'classnames';

import { Tooltip } from '../../../general/tooltip/Tooltip';
import { Link } from '../../link/Link';
import { MenuContext } from '../Menu.context';
import { MenuItem, type MenuItemProps } from './MenuItem';
import { containerStyle } from './MenuItem.styles';

export type MenuItemLinkProps = MenuItemProps & {
  href?: To;
  isExternal?: boolean;
  className?: string;
};

export const MenuItemLinkBase = ({
  label,
  href = null,
  isExternal = false,
  isSelected = false,
  isDisabled = false,
  className = null,
  ...props
}: MenuItemLinkProps) => {
  const { isCollapsed } = useContext(MenuContext);

  const itemContent = (
    <li className={cn(className)}>
      <Link
        to={!isExternal ? href : undefined}
        href={isExternal ? (href as string) : undefined}
        isDisabled={!href || isDisabled}
        isExternal={isExternal}
        className={cn({ isSelected, isCollapsed, isDisabled })}
        css={containerStyle}
      >
        <MenuItem
          {...props}
          label={label}
          isSelected={isSelected}
          isDisabled={isDisabled}
        />
      </Link>
    </li>
  );

  return isCollapsed ? (
    <Tooltip
      content={label}
      placement="right"
    >
      {itemContent}
    </Tooltip>
  ) : (
    itemContent
  );
};

MenuItemLinkBase.displayName = 'MenuItemLink';

export const MenuItemLink: MemoExoticComponent<typeof MenuItemLinkBase> & {
  Tag?: typeof MenuItem.Tag;
} = memo(MenuItemLinkBase);

MenuItemLink.Tag = MenuItem.Tag;
