import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type ReactNode,
  useMemo,
} from 'react';
import {
  Link as ReactRouterLink,
  type LinkProps as ReactRouterLinkProps,
  type To,
} from 'react-router-dom';
import PropTypes, { type ReactComponentLike } from 'prop-types';

export type LinkProps = Omit<ReactRouterLinkProps, 'to' | 'ref'> &
  Omit<HTMLProps<HTMLAnchorElement>, 'ref'> & {
    to?: To;
    isDisabled?: boolean;
    isExternal?: boolean;
    disabledElement?: ReactComponentLike;
    children?: ReactNode;
  };

/* A Link that can be disabled. */
export const LinkBase = forwardRef(
  (
    { isDisabled, isExternal, disabledElement, children, ...props }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLElement>,
  ) => {
    const ExternalLinkComponent = isDisabled ? disabledElement : 'a';
    const InternalLinkComponent = isDisabled
      ? disabledElement
      : ReactRouterLink;

    const LinkComponent = isExternal
      ? ExternalLinkComponent
      : InternalLinkComponent;

    const rel = useMemo(
      () => (isExternal && !isDisabled ? 'noopener noreferrer' : undefined),
      [isExternal, isDisabled],
    );

    return (
      <LinkComponent
        {...props}
        rel={rel}
        ref={ref}
        target={isExternal ? '_blank' : ''}
      >
        {children}
      </LinkComponent>
    );
  },
);

LinkBase.displayName = 'LinkProxy';

LinkBase.propTypes = {
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool,
  /** HTML element instead of `<a>` when the link is disabled. */
  disabledElement: PropTypes.elementType,
  children: PropTypes.node,
};

LinkBase.defaultProps = {
  isDisabled: false,
  isExternal: false,
  disabledElement: 'span',
  children: null,
  to: undefined,
};

export const Link = memo(LinkBase);
