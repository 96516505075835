import { createContext } from 'react';

import { type ILayer } from '../layers-provider/LayersContext';
import { type DIALOG_LAYER_TYPE } from './constants';

export interface DialogLayer extends ILayer {
  type: typeof DIALOG_LAYER_TYPE;
}

export type DialogsContextValue = {
  dialogLayers: DialogLayer[];
};

export const DialogsContext = createContext<DialogsContextValue>({
  dialogLayers: [],
});
