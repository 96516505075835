import { memo, useContext, useMemo } from 'react';
import {
  Routes,
  type RoutesProps,
  UNSAFE_RouteContext as RouteContext,
} from 'react-router-dom';
import PropTypes from 'prop-types';

type RootRoutesProps = RoutesProps;

/**
 * For some reason, react-router@6 supports absolute nested routes for everything except for
 * declaring them. It is bad and they should feel bad.
 *
 * Here is the issue: https://github.com/remix-run/react-router/issues/8035.
 * When it is implemented, you should be able to use <Routes> directly instead of this hack.
 *
 * Hack copied from this comment:
 * https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565.
 */
export const RootRoutesBase = ({
  children = null,
  ...props
}: RootRoutesProps) => {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx],
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props}>{children}</Routes>
    </RouteContext.Provider>
  );
};

RootRoutesBase.displayName = 'RootRoutes';

RootRoutesBase.propTypes = {
  children: PropTypes.node,
};

export const RootRoutes = memo(RootRoutesBase);
