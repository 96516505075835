import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  CONTENT: {
    defaultMessage:
      'Vous pouvez désormais sélectionner du texte, puis le mettre en surbrillance. 🎨',
  },
  HIGHLIGHTING: {
    defaultMessage: 'Surlignage',
  },
} as const);
