import React from 'react';

import { type ProductTour } from '../manager/types';
import { Highlighting } from '../tours/highlighting/Highlighting';

export const productTours: ProductTour[] = [
  {
    id: 'highlighting',
    steps: [
      {
        title: 'Nouvelle fonctionnalité 🖌️',
        content: React.createElement(Highlighting),
        target: 'main p:first-of-type', // First paragraph of sequence
        disableBeacon: true,
      },
    ],
    isMobileDisabled: true,
    startDate: '2024-01-01',
    endDate: '2025-01-01',
    pathname:
      /^\/([0-9a-f]{24})\/([0-9a-f]{24})\/teaching-unit\/([0-9a-f]{24})\/lesson\/([0-9a-f]{24})$/,
  },
];
