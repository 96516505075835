import {
  createContext,
  memo,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { useTheme } from '@emotion/react';
import { noop } from 'lodash';

import { useBoolState, useMemoizedBundle } from '@eversity/ui/utils';

import { DEFAULT_AUDIO_VOLUME } from '../constants';

export type AudioBarContextValue = {
  isAudioPlaying: boolean;
  isDrawerOpen: boolean;
  isMobileView: boolean;
  isPortrait: boolean;
  isMuted: boolean;
  audioRef: MutableRefObject<HTMLAudioElement | null>;
  audioVolume: number;
  audioDuration: number;
  audioCurrentTime: number;
  onChangeAudioVolume: (newVolume: number) => void;
  onClickMute: () => void;
  onClickPlayPause: () => void;
  onOpenDrawer: () => void;
  onCloseDrawer: () => void;
  onClickPlayer: () => void;
};

export const AudioBarContext = createContext<AudioBarContextValue>({
  isAudioPlaying: false,
  isDrawerOpen: false,
  isMobileView: false,
  isPortrait: false,
  isMuted: false,
  audioRef: null,
  audioVolume: DEFAULT_AUDIO_VOLUME,
  audioDuration: -1,
  audioCurrentTime: -1,
  onChangeAudioVolume: noop,
  onClickMute: noop,
  onClickPlayPause: noop,
  onOpenDrawer: noop,
  onCloseDrawer: noop,
  onClickPlayer: noop,
});

export type AudioBarContextProviderProps = {
  onAudioEnd?: () => void;
  children?: ReactNode;
};

export const AudioBarContextProviderBase = ({
  onAudioEnd,
  children,
}: AudioBarContextProviderProps) => {
  const [isDrawerOpen, onOpenDrawer, onCloseDrawer] = useBoolState(false);
  const [isPortrait, setIsPortrait] = useState(true);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [isMuted, onMute, onUnmute] = useBoolState(false);
  const [audioVolume, setAudioVolume] = useState(DEFAULT_AUDIO_VOLUME);

  const [audioDuration, setAudioDuration] = useState(-1);
  const [audioCurrentTime, setAudioCurrentTime] = useState(-1);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const theme = useTheme();

  const isMobileView = useMedia(`(max-width: ${theme.breakpoints.small})`);

  // TODO: metadata hack (https://developer.chrome.com/blog/media-session?hl=fr)

  useEffect(() => {
    // We manage volume in a separate state so we can still change it while the src is loading
    const audio = audioRef.current;

    if (audio) {
      audio.volume = audioVolume;
    }
  }, [audioVolume, audioRef]);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return noop;

    const onDurationChange = () => {
      setAudioDuration(audio.duration);

      if (!audio.currentTime) {
        setAudioCurrentTime(0);
      }
    };

    const onAudioEndProxy = () => {
      setIsAudioPlaying(false);
      onAudioEnd?.();
    };

    const onTimeUpdate = () => {
      setAudioCurrentTime(audio.currentTime);
    };

    const onOrientationChange = () => {
      setIsPortrait(window.screen.orientation.type.includes('portrait'));
    };

    audio.addEventListener('ended', onAudioEndProxy);
    audio.addEventListener('loadedmetadata', onDurationChange);
    audio.addEventListener('timeupdate', onTimeUpdate);
    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      audio.removeEventListener('ended', onAudioEndProxy);
      audio.removeEventListener('loadedmetadata', onDurationChange);
      audio.removeEventListener('timeupdate', onTimeUpdate);
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [onAudioEnd]);

  const onClickPlayPause = useCallback(() => {
    if (!audioRef?.current) return;

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsAudioPlaying(true);
    } else {
      audioRef.current.pause();
      setIsAudioPlaying(false);
    }
  }, [audioRef]);

  const onClickPlayer = useCallback(() => {
    if (isMobileView) {
      onOpenDrawer();
    }
  }, [isMobileView, onOpenDrawer]);

  const onClickMute = useCallback(() => {
    if (isMuted) {
      onUnmute();
      if (!audioRef.current) return;
      audioRef.current.muted = false;
    } else {
      onMute();
      if (!audioRef.current) return;
      audioRef.current.muted = true;
    }
  }, [isMuted, onMute, onUnmute]);

  const onChangeAudioVolume = useCallback(
    (newVolume: number) => {
      setAudioVolume(newVolume);
    },
    [setAudioVolume],
  );

  const value = useMemoizedBundle<AudioBarContextValue>({
    isDrawerOpen,
    isAudioPlaying,
    isMobileView,
    isPortrait,
    isMuted,
    audioRef,
    audioDuration,
    audioVolume,
    audioCurrentTime,
    onChangeAudioVolume,
    onOpenDrawer,
    onClickMute,
    onCloseDrawer,
    onClickPlayPause,
    onClickPlayer,
  });

  return (
    <AudioBarContext.Provider value={value}>
      {children}
    </AudioBarContext.Provider>
  );
};

export default memo(AudioBarContextProviderBase);
