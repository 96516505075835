import { defineMessages } from 'react-intl';

export default defineMessages({
  PLACEHOLDER: {
    defaultMessage: 'Sélectionnez une valeur',
  },

  NO_OPTIONS: {
    defaultMessage:
      'Pas d\'option{hasValue, select, true { correspondant à "{inputValue}"} other {}}',
  },

  LOADING: {
    defaultMessage: 'Chargement...',
  },

  CREATE_VALUE: {
    defaultMessage: 'Créer "{inputValue}"',
  },
} as const);
