import { css } from '@emotion/react';

export const container = ({ hasStatus }: { hasStatus: boolean }) => css`
  display: flex;
  align-items: ${hasStatus ? 'start' : 'center'};
  gap: 10px;
`;

export const textContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  word-break: break-word;
`;
