import {
  type LEGAL_DOCUMENT_TYPES,
  type LEGAL_DOCUMENTS_VERSION_TYPES,
} from '@eversity/domain/constants';
import { type LegalDocument } from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class LegalDocumentsRepository extends HttpRepository {
  /**
   * Get all legal documents.
   *
   * @returns Legal documents.
   */
  async getLegalDocuments(): Promise<LegalDocument[]> {
    const { body: legalDocuments } = await this.http.get(
      '/api/v1/school/legal-documents',
    );

    return legalDocuments;
  }

  /**
   * Get a legal document by type.
   *
   * @param type - Document type.
   * @returns Legal document.
   */
  async getLegalDocument(type: LEGAL_DOCUMENT_TYPES): Promise<LegalDocument> {
    const { body: legalDocument } = await this.http.get(
      `/api/v1/school/legal-documents/${e(type)}`,
    );

    return legalDocument;
  }

  /**
   * Update a legal document.
   *
   * @param documentId - Document id.
   * @param params - Update parameters.
   * @param params.versionType - Version type (MAJOR/MINOR).
   * @param params.content - New file content (HTML string).
   * @returns Updated document.
   */
  async updateLegalDocument(
    documentId: string,
    {
      versionType,
      content,
    }: {
      versionType: LEGAL_DOCUMENTS_VERSION_TYPES;
      content: string;
    },
  ): Promise<LegalDocument> {
    const { body: legalDocument } = await this.http
      .patch(`/api/v1/school/legal-documents/${e(documentId)}`)
      .send({
        versionType,
        content,
      });

    return legalDocument;
  }
}
