import PropTypes from 'prop-types';

import {
  COURSE_DIPLOMA_TYPES,
  TEACHING_UNIT_LESSON_USER_ROLES,
  TEACHING_UNIT_USER_ROLES,
} from '@eversity/domain/constants';
import { uploadPropTypes } from '@eversity/ui/design-system';

import { assignmentViewMinimalPropTypes } from './assignments';
import { lessonVersionViewMinimalPropTypes } from './lessons';
import { commonUserPropTypes } from './users';

export const teachingUnitLessonUserPropTypes = PropTypes.shape({
  /** User role in the lesson in the teaching unit. */
  role: PropTypes.oneOf(Object.values(TEACHING_UNIT_LESSON_USER_ROLES))
    .isRequired,
  /** User. */
  user: commonUserPropTypes.isRequired,
});

export const teachingUnitLessonLessonPropTypes = PropTypes.shape({
  /** Lesson id. */
  id: PropTypes.string.isRequired,
  /** Lesson title. */
  title: PropTypes.string.isRequired,
  /** Lesson code. */
  code: PropTypes.string.isRequired,
  /** List of versions of the lesson. */
  versions: PropTypes.arrayOf(lessonVersionViewMinimalPropTypes),
  /** Assignments linked to the lesson. */
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      /** Assignment information. */
      assignment: assignmentViewMinimalPropTypes.isRequired,
      /** List of major versions of the lesson linked to this assignment. */
      forVersionsMajor: PropTypes.arrayOf(PropTypes.number),
    }),
  ).isRequired,
});

export const teachingUnitLessonPropTypes = PropTypes.shape({
  /** Lesson data. */
  lesson: teachingUnitLessonLessonPropTypes.isRequired,
  /** Description of the lesson inside the teaching unit (HTML). */
  description: PropTypes.string.isRequired,
  /** Short description of the lesson (plain text). */
  shortDescription: PropTypes.string.isRequired,
  /** Available after X days for the student. */
  availableAfterDays: PropTypes.number.isRequired,
  /** This lesson needs to be explicitly picked for the student. */
  isOptional: PropTypes.bool.isRequired,
  /** Thumbnail picture. */
  thumbnail: uploadPropTypes,
  /** Users linked to the lesson inside the teaching unit. */
  users: PropTypes.arrayOf(teachingUnitLessonUserPropTypes).isRequired,
  /** Assignments coefficients. Missing assignments have an implied coefficient of 1. */
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      /** Assignment id. */
      assignment: PropTypes.string.isRequired,
      /** Coefficient of the assignment inside this teaching unit. */
      coefficient: PropTypes.number.isRequired,
    }),
  ).isRequired,
});

export const teachingUnitUserPropTypes = PropTypes.shape({
  /** User role in the teaching unit. */
  role: PropTypes.oneOf(Object.values(TEACHING_UNIT_USER_ROLES)).isRequired,
  /** User. */
  user: commonUserPropTypes.isRequired,
});

export const teachingUnitPropTypes = PropTypes.shape({
  /** Teaching unit unique id. */
  id: PropTypes.string.isRequired,
  /** Teaching unit title. */
  title: PropTypes.string.isRequired,
  /** Teaching unit code. */
  code: PropTypes.string.isRequired,
  /** Coefficient inside the course. */
  coefficient: PropTypes.number.isRequired,
  /** Description (HTML). */
  description: PropTypes.string.isRequired,
  /** Users linked to the teaching unit. */
  users: PropTypes.arrayOf(teachingUnitUserPropTypes).isRequired,
  /** Lessons and their metadata. */
  lessons: PropTypes.arrayOf(teachingUnitLessonPropTypes).isRequired,
});

export const courseClassPropTypes = PropTypes.shape({
  /** Class unique id. */
  id: PropTypes.string.isRequired,
  /** Class title. */
  title: PropTypes.string.isRequired,
  /** Class code. */
  code: PropTypes.string.isRequired,
  /** Lessons selected in the class. */
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      /** Lesson id. */
      lesson: PropTypes.string.isRequired,
      /** Teaching unit id. */
      teachingUnit: PropTypes.string.isRequired,
      /** Selected major version of the lesson. */
      vMajor: PropTypes.number.isRequired,
    }),
  ).isRequired,
  /** Exams. */
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      /** Assignment data. */
      assignment: assignmentViewMinimalPropTypes.isRequired,
      /** Teaching unit id. */
      teachingUnit: PropTypes.string.isRequired,
      /** Coefficient of the exam in the teaching unit. */
      coefficient: PropTypes.number.isRequired,
    }),
  ).isRequired,
});

export const courseViewFullPropTypes = PropTypes.shape({
  /** Course unique id. */
  id: PropTypes.string.isRequired,
  /** Course title. */
  title: PropTypes.string.isRequired,
  /** Course code. */
  code: PropTypes.string.isRequired,
  /** Diploma type. */
  diplomaType: PropTypes.oneOf(Object.values(COURSE_DIPLOMA_TYPES)),
  /** Description (HTML). */
  description: PropTypes.string,
  /** Course teaching units. */
  teachingUnits: PropTypes.arrayOf(teachingUnitPropTypes).isRequired,
  /** Course teaching units. */
  classes: PropTypes.arrayOf(courseClassPropTypes).isRequired,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
});

export const teachingUnitLessonViewStudentPropTypes = PropTypes.shape({
  /** Lesson data. */
  lesson: PropTypes.shape({
    /** Lesson id. */
    id: PropTypes.string.isRequired,
    /** Lesson title. */
    title: PropTypes.string.isRequired,
    /** Lesson code. */
    code: PropTypes.string.isRequired,
    /** Assignments linked to the lesson. */
    assignments: PropTypes.arrayOf(
      PropTypes.shape({
        /** Assignment information. */
        assignment: assignmentViewMinimalPropTypes.isRequired,
      }),
    ).isRequired,
  }),
  /** Description of the lesson inside the teaching unit (HTML). */
  description: PropTypes.string.isRequired,
  /** Short description of the lesson (plain text). */
  shortDescription: PropTypes.string.isRequired,
  /** Available after X days for the student. */
  availableAfterDays: PropTypes.number.isRequired,
  /** This lesson needs to be explicitly picked for the student. */
  isOptional: PropTypes.bool.isRequired,
  /** Thumbnail picture. */
  thumbnail: uploadPropTypes,
  /** Users linked to the lesson inside the teaching unit. */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      /** User role in the lesson in the teaching unit. */
      role: PropTypes.oneOf(Object.values(TEACHING_UNIT_LESSON_USER_ROLES))
        .isRequired,
      /** User. */
      user: commonUserPropTypes.isRequired,
    }),
  ).isRequired,
  /** Assignments coefficients. Missing assignments have an implied coefficient of 1. */
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      /** Assignment id. */
      assignment: PropTypes.string.isRequired,
      /** Coefficient of the assignment inside this teaching unit. */
      coefficient: PropTypes.number.isRequired,
    }),
  ).isRequired,
});

export const teachingUnitViewStudentPropTypes = PropTypes.shape({
  /** Teaching unit unique id. */
  id: PropTypes.string.isRequired,
  /** Teaching unit title. */
  title: PropTypes.string.isRequired,
  /** Teaching unit code. */
  code: PropTypes.string.isRequired,
  /** Coefficient inside the course. */
  coefficient: PropTypes.number.isRequired,
  /** Description (HTML). */
  description: PropTypes.string.isRequired,
  /** Users linked to the teaching unit. */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      /** User role in the teaching unit. */
      role: PropTypes.oneOf(Object.values(TEACHING_UNIT_USER_ROLES)).isRequired,
      /** User. */
      user: commonUserPropTypes.isRequired,
    }),
  ).isRequired,
  /** Lessons and their metadata. */
  lessons: PropTypes.arrayOf(teachingUnitLessonViewStudentPropTypes).isRequired,
});

export const courseViewStudentPropTypes = PropTypes.shape({
  /** Course unique id. */
  id: PropTypes.string.isRequired,
  /** Course title. */
  title: PropTypes.string.isRequired,
  /** Course code. */
  code: PropTypes.string.isRequired,
  /** Diploma type. */
  diplomaType: PropTypes.oneOf(Object.values(COURSE_DIPLOMA_TYPES)),
  /** Description (HTML). */
  description: PropTypes.string,
  /** Course teaching units. */
  teachingUnits: PropTypes.arrayOf(teachingUnitViewStudentPropTypes).isRequired,
  /** Course teaching units. */
  class: courseClassPropTypes.isRequired,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
});

export const courseViewMinimalPropTypes = PropTypes.shape({
  /** Course unique id. */
  id: PropTypes.string.isRequired,
  /** Course title. */
  title: PropTypes.string.isRequired,
  /** Course code. */
  code: PropTypes.string.isRequired,
  /** Diploma type. */
  diplomaType: PropTypes.oneOf(Object.values(COURSE_DIPLOMA_TYPES)),
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
});
