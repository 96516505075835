import PropTypes from 'prop-types';

import { selectOptionShape, selectOptionValueShape } from '../../../../types';
import { SELECT_SIZES } from '../constants';

export const commonSelectPropTypes = {
  /**
   * Selected value, values, option or options,
   * depending on the value of isMulti and useOptionAsValue.
   */
  // @ts-ignore -- Should work, propTypes will be removed when 100% TS anyway.
  value: PropTypes.oneOfType([
    selectOptionValueShape,
    PropTypes.arrayOf(selectOptionValueShape),
    selectOptionShape,
    PropTypes.arrayOf(selectOptionShape),
  ] as const),
  /** Change handler. (newValue) -> void. */
  onChange: PropTypes.func,
  /** If the user can select multiple values. */
  isMulti: PropTypes.bool,
  /** Input size. */
  size: PropTypes.oneOf(Object.values(SELECT_SIZES)),
  /** Open the options menu when focusing the select. */
  openMenuOnFocus: PropTypes.bool,
  /** Menu position (props from react-select). Overridden to set to `auto` instead of `bottom`. */
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top'] as const),
  /** To keep it consistent, rename isDisabled to disabled. */
  disabled: PropTypes.bool,
  /** Has error. */
  hasError: PropTypes.bool,
  /** Has error. */
  hasWarning: PropTypes.bool,
  /** Form field id. */
  id: PropTypes.string,
  /** Placeholder. */
  placeholder: PropTypes.node,
  /** Message when no options or no options matching input. */
  noOptionsMessage: PropTypes.func,
  /** Message when loading options. */
  loadingMessage: PropTypes.func,
  /** React-select filterOption prop. Override default filtering. */
  filterOption: PropTypes.func,
  /** Where to render the menu. If undefined, will render on document.body if inside a layer. */
  menuPortalTarget: PropTypes.instanceOf(HTMLElement),
} as const;

export const selectPropTypes = {
  /** If true, will not try to find the value in the list of options. Values are options. */
  useOptionAsValue: PropTypes.bool,
};

export const creatableSelectPropTypes = {
  /** React-select formatCreateLabel prop. Override default label for create option. */
  formatCreateLabel: PropTypes.func,
} as const;
