import { type TypeOptions } from 'react-toastify';
import { type Theme } from '@emotion/react';

export const getIconFills = (theme: Theme, type: TypeOptions) => {
  switch (type) {
    case 'info':
      return [theme.colors.gray[0], theme.colors.primary[400]];
    case 'error':
      return [theme.colors.gray[0], theme.colors.danger[400]];
    case 'warning':
      return [theme.colors.gray[0], theme.colors.warning[400]];
    case 'success':
      return [theme.colors.gray[0], theme.colors.success[400]];
    case 'default':
    default:
      return [theme.colors.gray[500], theme.colors.gray[50]];
  }
};
