import { memo } from 'react';
import { css, type Theme } from '@emotion/react';

const gradientStyle = (theme: Theme) => css`
  background: linear-gradient(
    90deg,
    ${theme.colors.gray[25]} -41.3%,
    ${theme.colors.gray[100]} 211.82%,
    ${theme.colors.gray[25]} 486.82%
  );
`;

export const NotificationListItemSkeletonBase = () => (
  <div
    aria-hidden
    css={css`
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    `}
  >
    <div
      css={[
        gradientStyle,
        css`
          height: 46px;
          width: 46px;
          border-radius: 8px;
          flex: none;
        `,
      ]}
    />

    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      `}
    >
      <div
        css={[
          gradientStyle,
          css`
            width: 100%;
            border-radius: 4px;
            height: 12px;
          `,
        ]}
      />

      <div
        css={[
          gradientStyle,
          css`
            width: 120px;
            border-radius: 4px;
            height: 12px;
          `,
        ]}
      />
    </div>
  </div>
);

NotificationListItemSkeletonBase.displayName = 'NotificationListItemSkeleton';

export const NotificationListItemSkeleton = memo(
  NotificationListItemSkeletonBase,
);
