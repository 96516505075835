/**
 * Download a file given its href.
 *
 * @param href - Href.
 * @param fileName - Name of the downloaded file. Will be ignored if href is not the same origin.
 */
export const downloadHref = (href: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  return link.click();
};

/**
 * Download a file given its blob content.
 *
 * @param blob - Blob.
 * @param fileName - Name of the downloaded file.
 */
export const downloadBlob = (blob: Blob, fileName: string) =>
  downloadHref(window.URL.createObjectURL(blob), fileName);

/**
 * Download a file given its content and content type.
 *
 * @param fileContent - File content.
 * @param fileType - Mime type.
 * @param fileName - Name of the downloaded file.
 */
export const downloadFile = (
  fileContent: Blob,
  fileType: string,
  fileName: string,
) => downloadBlob(new Blob([fileContent], { type: fileType }), fileName);
