import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHash } from './useHash';

export type UseScrollSpy = {
  activeTitle: string | null;
  onTitleEnterView: (title: string) => void;
  onTitleExitView: (title: string) => void;
};

export const useScrollSpy = (titles: string[]): UseScrollSpy => {
  const navigate = useNavigate();

  const anchor = useHash();

  const [titlesInView, setTitlesInView] = useState<string[]>([]);
  const [activeTitle, setCurrentActiveTitle] = useState(anchor);

  const onTitleEnterView = useCallback(
    (title: string) =>
      setTitlesInView((currentTitlesInView) =>
        !currentTitlesInView.includes(title)
          ? [...currentTitlesInView, title]
          : currentTitlesInView,
      ),
    [setTitlesInView],
  );

  const onTitleExitView = useCallback(
    (title: string) =>
      setTitlesInView((currentTitlesInView) =>
        currentTitlesInView.includes(title)
          ? currentTitlesInView.filter((id) => id !== title)
          : currentTitlesInView,
      ),
    [setTitlesInView],
  );

  // Current selected section is the first title in view.
  // We cannot use useMemo bc we need to default to the current value if no titles are in view.
  useEffect(() => {
    const firstTitleInView = titles.find((id) => titlesInView.includes(id));
    setCurrentActiveTitle(
      (currentActiveTitle) => firstTitleInView || currentActiveTitle,
    );
  }, [titles, titlesInView]);

  useEffect(() => {
    if (anchor) {
      // Remove anchor to make possible navigation to previous title.
      navigate(
        {
          hash: '',
          search: window.location.search,
        },
        { replace: true },
      );
    }
  }, [anchor, navigate]);

  return {
    activeTitle,
    onTitleEnterView,
    onTitleExitView,
  };
};
