import {
  type UndefinedInitialDataInfiniteOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type ResponseError } from 'superagent';

// Helper to make typing useQueryParams less verbose.
export type UseQueryParams<
  TReturnType = unknown,
  TError = ResponseError,
> = Omit<
  UseQueryOptions<TReturnType, TError>,
  'queryKey' | 'queryFn' | 'initialData'
> & {
  initialData?: () => undefined;
};

// Helper to make typing useQueryParams less verbose when using useInfiniteQuery.
export type UseInfiniteQueryParams<
  TReturnType = unknown,
  TError = ResponseError,
> = Omit<
  UndefinedInitialDataInfiniteOptions<TReturnType, TError>,
  'queryKey' | 'queryFn'
>;

// Helper to make typing useMutationParams less verbose.
export type UseMutationParams<
  TReturnType = unknown,
  TParams = unknown,
  TError = ResponseError,
> = Omit<UseMutationOptions<TReturnType, TError, TParams>, 'mutationFn'>;

export const isResponseError = (error: Error): error is ResponseError => {
  return error && 'status' in error;
};
