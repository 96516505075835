import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';

export type UseWizard = [
  stepProps: {
    isCurrentStep: (step: string | string[]) => boolean;
    onGoToNextStep: () => void;
    onGoToPreviousStep: () => void;
    isNextStepDisabled: boolean;
    isPreviousStepDisabled: boolean;
  },
  currentStep: string | undefined,
  onChangeCurrentStep: Dispatch<SetStateAction<string>>,
];

export const useWizard = (steps: string[], initialStep?: string): UseWizard => {
  const [currentStep, onChangeCurrentStep] = useState(initialStep || steps[0]);

  const stepIndex = steps.indexOf(currentStep);
  const isNextStepDisabled = stepIndex < 0 || stepIndex >= steps.length - 1;
  const isPreviousStepDisabled = stepIndex < 1;

  // If the steps array changes, reinit the current step.
  // If the next step doesn't work, this might be because the steps ref keeps changing, so use
  // useMemo before passing it to useWizard.
  useUpdateEffect(() => {
    onChangeCurrentStep(steps[0]);
  }, [steps]);

  const onGoToNextStep = useCallback(
    () => !isNextStepDisabled && onChangeCurrentStep(steps[stepIndex + 1]),
    [steps, isNextStepDisabled, stepIndex],
  );

  const onGoToPreviousStep = useCallback(
    () => !isPreviousStepDisabled && onChangeCurrentStep(steps[stepIndex - 1]),
    [steps, isPreviousStepDisabled, stepIndex],
  );

  const isCurrentStep = useCallback(
    (step: string | string[]) =>
      Array.isArray(step) ? step.includes(currentStep) : step === currentStep,
    [currentStep],
  );

  const returnValue = useMemo<UseWizard>(
    () => [
      {
        isCurrentStep,
        onGoToNextStep,
        onGoToPreviousStep,
        isNextStepDisabled,
        isPreviousStepDisabled,
      },
      currentStep,
      onChangeCurrentStep,
    ],
    [
      isCurrentStep,
      onGoToNextStep,
      onGoToPreviousStep,
      isNextStepDisabled,
      isPreviousStepDisabled,
      currentStep,
      onChangeCurrentStep,
    ],
  );

  return returnValue;
};
