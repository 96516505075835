import { useMemo } from 'react';
import { uniqueId } from 'lodash';

/**
 * If the given id is falsy, create a unique id for this component.
 */
export const useUniqueId = ({
  id,
  prefix,
}: {
  id?: string;
  prefix?: string;
} = {}) => {
  const idOrRandomId = useMemo(() => id || uniqueId(prefix), [id, prefix]);

  return idOrRandomId;
};
