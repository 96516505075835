import { defineMessages } from 'react-intl';

export default defineMessages({
  SELECT_ALL: {
    defaultMessage: 'tout sélectionner',
  },

  UNSELECT_ALL: {
    defaultMessage: 'tout désélectionner',
  },
} as const);
