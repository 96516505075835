import PropTypes from 'prop-types';

export const baseNotificationShape = {
  id: PropTypes.string.isRequired,
  notificationType: PropTypes.string,
  user: PropTypes.string.isRequired,
  isSeen: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  params: PropTypes.shape({}).isRequired,
};

export const notificationPropTypes = PropTypes.shape(baseNotificationShape);
