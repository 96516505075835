import { cloneElement, memo, type ReactElement } from 'react';
import { useTheme } from '@emotion/react';
import { Picture } from '@icon-park/react';
import PropTypes from 'prop-types';

import * as styles from './CardMedia.styles';

export type CardMediaProps = {
  imageSrc?: string;
  altText?: string;
  icon?: ReactElement;
};

export const CardMediaBase = ({
  imageSrc = null,
  altText = null,
  icon = <Picture />,
}: CardMediaProps) => {
  const theme = useTheme();

  return imageSrc ? (
    <img
      src={imageSrc}
      alt={altText}
      css={styles.image}
    />
  ) : (
    <div css={styles.cover}>
      <div css={styles.placeholder}>
        <div css={styles.placeholderImage}>
          {cloneElement(icon, {
            size: 50,
            fill: [theme.colors.primary[200], theme.colors.primary[50]],
          })}
        </div>
      </div>
    </div>
  );
};

CardMediaBase.displayName = 'CardCover';

CardMediaBase.propTypes = {
  /** Image src. If not provided, will use the icon. */
  imageSrc: PropTypes.string,
  /** Icon to display if no image is provided. */
  icon: PropTypes.node,
};

export const CardMedia = memo(CardMediaBase);
