import escapeStringRegexp from 'escape-string-regexp';
import { flatten, last } from 'lodash';

export const CALENDLY_BASE_URL = 'https://calendly.com/';
const CALENDLY_ALLOWED_CHARACTERS_REGEXP = '[a-z0-9_-]+';

/**
 * Tests if a calendly URL is a valid user link.
 * A valid user link should look something like https://calendly.com/username
 *
 * @param url - Calendly URL.
 * @returns True if valid.
 */
export const isValidCalendlyURL = (url: string) => {
  const calendlyRegexp = new RegExp(
    `^${escapeStringRegexp(
      CALENDLY_BASE_URL,
    )}(${CALENDLY_ALLOWED_CHARACTERS_REGEXP})$`,
  );

  return calendlyRegexp.test(url);
};

/**
 * Get the username from a valid Calendly URL.
 *
 * @param url - Calendly URL.
 * @returns Username.
 */
export const getUsernameFromCalendlyURL = (url: string): string | null => {
  if (!url || !isValidCalendlyURL(url)) {
    return null;
  }

  return url.split(CALENDLY_BASE_URL)[1];
};

/**
 * Assemble a valid Calendly URL from a Calendly username.
 *
 * @param username - Calendly username.
 * @returns Valid Calendly URL.
 */
export const makeCalendlyURLFromUsername = (
  username?: string,
): string | null => {
  if (!username) {
    return null;
  }

  return `${CALENDLY_BASE_URL}${username}`;
};

/**
 * Tries to find a username from a string if it somewhat looks like a calendly URL.
 *
 * @param url - Calendly URL.
 * @returns Possible username found.
 */
export const suggestUsernameFromCalendlyURL = (
  url: string,
): string | undefined => {
  const { hostname } = new URL(CALENDLY_BASE_URL);

  const partialRegexp = new RegExp(
    `(${escapeStringRegexp(
      hostname,
    )}\\/)+(${CALENDLY_ALLOWED_CHARACTERS_REGEXP})`,
    'g',
  );

  const matches = flatten([...url.matchAll(partialRegexp)]);

  return last(matches);
};
