import 'cropperjs/dist/cropper.css';

import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactCropper, {
  type ReactCropperElement,
  type ReactCropperProps,
} from 'react-cropper';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useBoolState } from '@eversity/ui/utils';
import { getImageMeta } from '@eversity/utils/web';

import {
  CROPPER_VIEW_MODES,
  CROPPER_VIEW_MODES_MAPPING,
} from './Cropper.constants';
import * as styles from './Cropper.styles';

export type CropperProps = Omit<
  ReactCropperProps,
  'crop' | 'data' | 'viewMode' | 'value' | 'onChange'
> & {
  hasWarning?: boolean;
  hasError?: boolean;
  value?: ReactCropperProps['data'];
  onChange?: (data: Parameters<ReactCropperProps['crop']>[0]['detail']) => void;
  viewMode?: CROPPER_VIEW_MODES;
  fitHeight?: boolean;
  maxHeight?: number;

  // Omitted.
  crop?: any;
  data?: any;
};

export const CropperBase = forwardRef(
  (
    {
      crop,
      data,
      value,
      onChange,
      hasWarning,
      hasError,
      viewMode,
      fitHeight,
      maxHeight,
      ...props
    }: CropperProps,
    ref: ForwardedRef<ReactCropperElement>,
  ) => {
    const [targetContainerHeight, onChangeTargetContainerHeight] =
      useState<number>(undefined);
    const [isReady, onSetReady, onSetNotReady] = useBoolState(!fitHeight);
    const containerRef = useRef<HTMLDivElement>(null);

    const onCrop = useCallback(
      ({ detail }) => {
        onChange(detail);
      },
      [onChange],
    );

    const style = useMemo(
      () => ({
        ...props.style,
        height: props.style?.height ?? targetContainerHeight,
      }),
      [props.style, targetContainerHeight],
    );

    useLayoutEffect(() => {
      (async () => {
        if (props.src && fitHeight) {
          const { width: imgWidth, height: imgHeight } = await getImageMeta(
            props.src,
          );

          const { width: containerWidth } =
            containerRef.current?.getBoundingClientRect() || {};

          onChangeTargetContainerHeight(
            Math.min(maxHeight, imgHeight * (containerWidth / imgWidth)),
          );

          onSetReady();
        } else {
          onSetNotReady();
        }
      })();
    }, [props.src, fitHeight, maxHeight, onSetReady, onSetNotReady]);

    return (
      <div ref={containerRef}>
        {
          // React-cropper needs the height when mounting.
          isReady && (
            <ReactCropper
              {...props}
              ref={ref}
              data={value}
              crop={onCrop}
              className={cn({
                hasWarning: !!hasWarning && !hasError,
                hasError,
              })}
              viewMode={CROPPER_VIEW_MODES_MAPPING[viewMode]}
              css={styles.cropper}
              style={style}
            />
          )
        }
      </div>
    );
  },
);

CropperBase.displayName = 'Cropper';

const valuePropTypes = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  rotate: PropTypes.number,
  scaleX: PropTypes.number,
  scaleY: PropTypes.number,
});

CropperBase.propTypes = {
  /** Cropper data. Positions. */
  value: valuePropTypes,
  /** Called with { dataUrl, detail: { x, y, height, width, rotate, scaleX, scaleY } }. */
  onChange: PropTypes.func,
  /** Omitted. Use value. */
  data: valuePropTypes,
  /** Omitted. Use onChange. */
  crop: PropTypes.func,
  /** Field has warning. */
  hasWarning: PropTypes.bool,
  /** Field has error. */
  hasError: PropTypes.bool,
  /** View mode. https://github.com/fengyuanchen/cropperjs#viewmode. */
  viewMode: PropTypes.oneOf(Object.values(CROPPER_VIEW_MODES)),
  /** Try to fit the height of the canvas to the height of the image. */
  fitHeight: PropTypes.bool,
  /** Height limit if fitHeight is true. */
  maxHeight: PropTypes.number,
};

CropperBase.defaultProps = {
  value: null,
  onChange: noop,
  data: undefined,
  crop: undefined,
  hasWarning: false,
  hasError: false,
  /** Prevent cropbox from leaving canvas. */
  viewMode: CROPPER_VIEW_MODES.CANVAS_FILLS_CONTAINER,
  fitHeight: true,
  maxHeight: Infinity,
};

export const Cropper: MemoExoticComponent<typeof CropperBase> & {
  VIEW_MODES?: typeof CROPPER_VIEW_MODES;
} = memo(CropperBase);

Cropper.VIEW_MODES = CROPPER_VIEW_MODES;
