import { useQuery } from '@tanstack/react-query';

import { type LEGAL_DOCUMENT_TYPES } from '@eversity/domain/constants';
import { LegalDocumentsRepository } from '@eversity/domain/data/repositories';
import { type UseQueryParams } from '@eversity/types/web';

import http from '../../../services/http/api';

const legalDocumentsRepository = new LegalDocumentsRepository(http);

export const useLegalDocuments = (
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<LegalDocumentsRepository['getLegalDocuments']>>
  >,
) =>
  useQuery({
    queryKey: ['legal-documents'],
    queryFn: () => legalDocumentsRepository.getLegalDocuments(),
    ...useQueryParams,
  });

export const useLegalDocument = (
  legalDocumentType?: LEGAL_DOCUMENT_TYPES,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<LegalDocumentsRepository['getLegalDocument']>>
  >,
) =>
  useQuery({
    queryKey: ['legal-document', legalDocumentType],
    queryFn: () => legalDocumentsRepository.getLegalDocument(legalDocumentType),
    enabled: !!legalDocumentType,
    ...useQueryParams,
  });
