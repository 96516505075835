import { type FILE_UPLOAD_CONTEXTS } from '@eversity/domain/constants';
import { type Upload } from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class FileRepository extends HttpRepository {
  /**
   * Post an image.
   *
   * @param file - File blob object.
   * @param options - Options.
   * @param options.onProgress - Progress event handler. Progress between 0 and 1.
   * @returns Object containing upload metadata for the resized and original image.
   */
  async postImage(
    file: File,
    {
      onProgress,
    }: {
      onProgress?: (progress: number) => void;
    } = {},
  ): Promise<{ resized: Upload; original: Upload }> {
    const req = this.http
      .post('/api/v1/file/images')
      // @ts-ignore -- Conflict between node Buffer and web Buffer, but only in typings.
      .attach('file', file)
      .set('enctype', 'multipart/form-data');

    if (onProgress) {
      req.on('progress', ({ percent }) => onProgress(percent / 100));
    }

    const { body } = await req;
    return body;
  }

  /**
   * Upload a raw file.
   *
   * @param context - UPLOAD_FILE_CONTEXTS enum.
   * @param file - File object.
   * @param options - Options.
   * @param options.onProgress - Progress event handler. Progress between 0 and 1.
   */
  async uploadRawFile(
    context: FILE_UPLOAD_CONTEXTS,
    file: Blob,
    {
      onProgress,
    }: {
      onProgress?: (progress: number) => void;
    } = {},
  ): Promise<Upload> {
    const req = this.http
      .post(`/api/v1/file/raw/contexts/${e(context)}/files`)
      // @ts-ignore -- Conflict between node Buffer and web Buffer, but only in typings.
      .attach('file', file);

    if (onProgress) {
      req.on('progress', ({ percent }) => onProgress?.(percent / 100));
    }

    const { body } = await req;

    return body;
  }
}
