import { memo } from 'react';
import {
  type ClearIndicatorProps,
  components,
  type GroupBase,
} from 'react-select';
import { CloseSmall } from '@icon-park/react';

export const ClearIndicatorBase = <
  TOption,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  props: ClearIndicatorProps<TOption, TIsMulti, TGroup>,
) => (
  <components.ClearIndicator {...props}>
    <CloseSmall />
  </components.ClearIndicator>
);

ClearIndicatorBase.displayName = 'ClearIndicator';

ClearIndicatorBase.propTypes = {};

export default memo(ClearIndicatorBase);
