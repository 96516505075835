import { memo, type MemoExoticComponent } from 'react';
import { useTheme } from '@emotion/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { IMPULSE_SPINNER_SIZES, IMPULSE_SPINNER_VARIANTS } from './constants';
import styles from './ImpulseSpinner.styles';

const SIZES_MAPPING = {
  [IMPULSE_SPINNER_SIZES.SMALL]: 8,
  [IMPULSE_SPINNER_SIZES.MEDIUM]: 12,
  [IMPULSE_SPINNER_SIZES.LARGE]: 15,
};

export type ImpulseSpinnerProps = {
  className?: string;
  size?: IMPULSE_SPINNER_SIZES;
  variant?: IMPULSE_SPINNER_VARIANTS;
};

export const ImpulseSpinnerBase = ({
  className = undefined,
  size = IMPULSE_SPINNER_SIZES.MEDIUM,
  variant = IMPULSE_SPINNER_VARIANTS.PRIMARY,
}: ImpulseSpinnerProps) => {
  const theme = useTheme();

  return (
    <div
      css={styles.container(theme, SIZES_MAPPING[size])}
      className={cn(className, size)}
    >
      {[1, 2, 3].map((index) => (
        <div
          key={index}
          className={cn(size, variant)}
          css={styles.ball(theme, SIZES_MAPPING[size], variant, index)}
        />
      ))}
    </div>
  );
};

ImpulseSpinnerBase.displayName = 'ImpulseSpinner';

ImpulseSpinnerBase.propTypes = {
  /** Root element class name. */
  className: PropTypes.string,
  /** Loader size. */
  size: PropTypes.oneOf(Object.values(IMPULSE_SPINNER_SIZES)),
  /** Loader color variant. */
  variant: PropTypes.oneOf(Object.values(IMPULSE_SPINNER_VARIANTS)),
};

export const ImpulseSpinner: MemoExoticComponent<typeof ImpulseSpinnerBase> & {
  VARIANTS?: typeof IMPULSE_SPINNER_VARIANTS;
} & { SIZES?: typeof IMPULSE_SPINNER_SIZES } = memo(ImpulseSpinnerBase);

ImpulseSpinner.VARIANTS = IMPULSE_SPINNER_VARIANTS;
ImpulseSpinner.SIZES = IMPULSE_SPINNER_SIZES;
