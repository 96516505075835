import { css, type Theme } from '@emotion/react';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { TABLE_VARIANTS } from './constants';

export default {
  table: (theme: Theme) => css`
    .rc-table-content {
      overflow-x: auto;
    }

    &.${TABLE_VARIANTS.DARK} {
      td {
        color: ${theme.colors.gray[700]};
      }

      th {
        color: ${theme.colors.gray[700]};
      }
    }

    &.${TABLE_VARIANTS.LIGHT} {
      td {
        color: ${theme.colors.gray[0]};
      }

      th {
        color: ${theme.colors.gray[0]};
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    .rc-table-placeholder {
      color: ${theme.colors.gray[500]};
    }

    td {
      border-top: 1px solid ${theme.colors.gray[200]};

      padding: 10px;

      background-color: transparent;
      vertical-align: center;

      ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]};
    }

    th {
      text-align: left;
      vertical-align: bottom;

      padding: 10px;

      background-color: transparent;
    }
  `,

  error: (theme: Theme) => css`
    color: ${theme.colors.danger[500]};
  `,
};
