import { type ForwardedRef, useCallback, useMemo, useState } from 'react';
import { type ReactCropperElement } from 'react-cropper';
import { noop, throttle } from 'lodash';

import { useForwardedRef } from '@eversity/ui/utils';

export const useCropperPreview = ({
  ref,
  onChange = noop,
  throttleMs = 10,
}: {
  ref?: ForwardedRef<ReactCropperElement>;
  onChange?: (detail: Cropper.Data) => void;
  throttleMs?: number;
} = {}): {
  cropperRef: ForwardedRef<ReactCropperElement>;
  onChange: (detail: Cropper.Data) => void;
  previewDataUrl: string | null;
} => {
  // Use the given ref or create a new one.
  const cropperRef = useForwardedRef(ref);
  const [previewDataUrl, onSetPreviewDataUrl] = useState<string>(null);

  /**
   * Throttle the update of the preview.
   * It can cause lags if computed on every update of the cropbox.
   */
  const onChangeThrottle = useMemo(
    () =>
      throttle(
        () =>
          onSetPreviewDataUrl(
            cropperRef.current?.cropper?.getCroppedCanvas().toDataURL() || null,
          ),
        throttleMs,
      ),
    [cropperRef, throttleMs],
  );

  const onChangeProxy = useCallback(
    (detail: Cropper.Data) => {
      onChangeThrottle();
      return onChange(detail);
    },
    [onChange, onChangeThrottle],
  );

  return {
    cropperRef,
    onChange: onChangeProxy,
    previewDataUrl,
  };
};
