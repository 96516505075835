import { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

type BorderContainerProps = {
  isActive: boolean;
};

const BorderContainer = styled.div<BorderContainerProps>`
  display: flex;
  align-items: flex-end;

  transition: ${(props) => props.theme.transitions.default()};

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary[500] : theme.colors.gray[100]};
`;

export type SeparatorProps = {
  isLeftActive: boolean;
  isRightActive: boolean;
};

export const SeparatorBase = ({
  isLeftActive = false,
  isRightActive = false,
}: SeparatorProps) => (
  <div
    css={css`
      display: flex;
      align-items: flex-end;
      flex: 1;
    `}
  >
    <BorderContainer isActive={isLeftActive}>
      <div
        css={css`
          width: 4px;
        `}
      />
      <div
        css={css`
          width: 8px;
          border-bottom: 1px solid currentColor;
        `}
      />
      <div
        css={css`
          width: 4px;
        `}
      />
      <div
        css={css`
          width: 4px;
          border-bottom: 1px solid currentColor;
        `}
      />
    </BorderContainer>

    <div
      css={css`
        flex: 1;
      `}
    />

    <BorderContainer isActive={isRightActive}>
      <div
        css={css`
          width: 4px;
          border-bottom: 1px solid currentColor;
        `}
      />
      <div
        css={css`
          width: 4px;
        `}
      />
      <div
        css={css`
          width: 8px;
          border-bottom: 1px solid currentColor;
        `}
      />
      <div
        css={css`
          width: 4px;
        `}
      />
    </BorderContainer>
  </div>
);

SeparatorBase.displayName = 'Separator';

SeparatorBase.propTypes = {
  /** Is the last tab on the left side active. */
  isLeftActive: PropTypes.bool,
  /** Is the first tab on the right side active. */
  isRightActive: PropTypes.bool,
};

export const Separator = memo(SeparatorBase);
