import { css, type Theme } from '@emotion/react';

import { LESSON_CARD_THUMBNAIL_ASPECT_RATIO } from '@eversity/domain/constants';

export const thumbnailImage = css`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export const placeholderImage = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: ${LESSON_CARD_THUMBNAIL_ASPECT_RATIO};

  background-color: ${theme.colors.primary[15]};

  border-radius: 8px;
`;
