import { type ComponentProps, memo } from 'react';
import { css } from '@emotion/react';
import { Widget } from '@typeform/embed-react';
import { noop } from 'lodash';

import { type FeedbackCampaign } from '@eversity/types/domain';
import { Popup, type PopupProps } from '@eversity/ui/design-system';

import config from '../../../../../config/env';

export type FeedbackTypeformPopupProps = Pick<
  PopupProps,
  'isOpen' | 'onRequestClose'
> &
  Pick<ComponentProps<typeof Widget>, 'onSubmit' | 'onClose'> & {
    feedbackCampaign?: FeedbackCampaign;
  };

const widgetStyle = {
  width: '100%',
  height: '100%',
};

const iframeProps = {
  // Display block otherwise it overflows in the dialog.
  style: 'display: block;',
};

export const FeedbackTypeformPopupBase = ({
  isOpen = false,
  onRequestClose = noop,
  feedbackCampaign,
  onSubmit = noop,
  onClose = noop,
}: FeedbackTypeformPopupProps) => {
  return (
    <Popup
      size={Popup.SIZES.MEDIUM}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      position={Popup.POSITIONS.BOTTOM_LEFT}
    >
      <div
        css={css`
          height: 370px;
        `}
      >
        <Widget
          id={feedbackCampaign.campaignId}
          style={widgetStyle}
          iframeProps={iframeProps}
          hidden={feedbackCampaign.hiddenFields}
          onSubmit={onSubmit}
          onClose={onClose}
          enableSandbox={config.app.env === 'test'}
        />
      </div>
    </Popup>
  );
};

export const FeedbackTypeformPopup = memo(FeedbackTypeformPopupBase);
