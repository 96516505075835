export enum SECTION_STATES {
  NONE = 'NONE',
  COMPLETED = 'COMPLETED',
  UNCOMPLETED = 'UNCOMPLETED',
}

export enum SECTION_VARIANTS {
  NEUTRAL = 'NEUTRAL',
  PRIMARY = 'PRIMARY',
  FADED = 'FADED',
}
