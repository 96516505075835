import { defineMessages } from 'react-intl';

export default defineMessages({
  CLOSE_BUTTON: {
    defaultMessage: 'Fermer (Échap)',
  },

  TOGGLE_FULLSCREEN: {
    defaultMessage: 'Entrer/sortir du plein écran',
  },

  ZOOM_BUTTON: {
    defaultMessage: 'Zoomer/dézoomer',
  },

  PREVIOUS: {
    defaultMessage: 'Précédent (flèche gauche)',
  },

  NEXT: {
    defaultMessage: 'Suivant (flèche droite)',
  },
});
