/**
 * Wait for a given amount of time in milliseconds.
 *
 * @param ms - Time in milliseconds to wait.
 * @returns A promise that resolves after the given time.
 */
export async function wait(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
