import { defineMessages } from 'react-intl';

export default defineMessages({
  TEACHING_UNIT: {
    defaultMessage: '{code} - {title}',
  },

  PROGRESS: {
    defaultMessage: '{progress}%',
  },
});
