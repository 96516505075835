export enum HTTP_STATUSES {
  BAD_REQUEST = 'BAD_REQUEST',
  CONFLICT = 'CONFLICT',
  PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
}

export const HTTP_STATUS_CODES: Record<HTTP_STATUSES, number> = {
  [HTTP_STATUSES.BAD_REQUEST]: 400,
  [HTTP_STATUSES.CONFLICT]: 409,
  [HTTP_STATUSES.PAYLOAD_TOO_LARGE]: 413,
};

export const httpRegexp =
  /(?:http[s]?:\/\/.)(?:www\.)?[-a-zA-Z0-9@%._+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&//=]*)/gm;
