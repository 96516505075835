import { defineMessages } from 'react-intl';

export default defineMessages({
  PREVIEW_ALT: {
    defaultMessage: 'Prévisualisation',
  },

  CLEAR: {
    defaultMessage: "Retirer l'image",
  },
});
