import { css, type Theme } from '@emotion/react';

export const containerStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: left;

  transition: ${theme.transitions.default()};

  padding: 6.5px 12px;

  color: ${theme.colors.gray[700]};
  background-color: ${theme.colors.gray[0]};

  cursor: pointer;

  &:disabled {
    color: ${theme.colors.gray[300]};
    background-color: ${theme.colors.gray[0]};
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover,
    &.isActive {
      background-color: ${theme.colors.gray[25]};
    }

    &.isSelected {
      color: ${theme.colors.primary[500]};
    }
  }
`;

export const getIconFills = (
  theme: Theme,
  isDisabled: boolean,
  isSelected: boolean,
) => {
  if (isDisabled) {
    return [theme.colors.gray[200], theme.colors.gray[25]];
  }

  if (isSelected) {
    return [theme.colors.primary[400], theme.colors.primary[50]];
  }

  return [theme.colors.gray[500], theme.colors.gray[50]];
};
