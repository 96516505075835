import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { type TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { Typography } from '../../general/typography/Typography';
import { TEXT_MAX_LENGTH_SIZES } from './constants';
import messages from './TextMaxLength.messages';

export const TYPOGRAPHY_SIZE_MAPPING: Record<
  TEXT_MAX_LENGTH_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [TEXT_MAX_LENGTH_SIZES.SMALL]: Typography.VARIANTS.BODY_SMALL_REGULAR,
  [TEXT_MAX_LENGTH_SIZES.MEDIUM]: Typography.VARIANTS.BODY_MEDIUM_REGULAR,
  [TEXT_MAX_LENGTH_SIZES.LARGE]: Typography.VARIANTS.BODY_LARGE_REGULAR,
};

export type TextMaxLengthProps = {
  size: TEXT_MAX_LENGTH_SIZES;
  maxLength: number;
  currentLength: number;
  isFocused?: boolean;
  className?: string;
};

export const TextMaxLengthBase = ({
  size,
  maxLength,
  currentLength,
  isFocused = false,
  className = null,
}: TextMaxLengthProps) => (
  <Typography
    variant={TYPOGRAPHY_SIZE_MAPPING[size]}
    className={className}
    css={(theme) => css`
      color: ${isFocused ? theme.colors.primary[500] : theme.colors.gray[300]};
      transition: ${theme.transitions.default()};
    `}
  >
    <FormattedMessage
      {...messages.MAX_LENGTH}
      values={{
        maxLength,
        currentLength,
      }}
    />
  </Typography>
);

TextMaxLengthBase.displayName = 'TextMaxLength';

TextMaxLengthBase.propTypes = {
  /** Text area size. */
  size: PropTypes.oneOf(Object.values(TEXT_MAX_LENGTH_SIZES)).isRequired,
  /** Max number of characters. */
  maxLength: PropTypes.number.isRequired,
  /** Current number of characters in input. */
  currentLength: PropTypes.number.isRequired,
  /** Is the input focused. */
  isFocused: PropTypes.bool,
  /** Root element class name. */
  className: PropTypes.string,
};

export const TextMaxLength = memo(TextMaxLengthBase);
