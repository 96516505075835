import { css } from '@emotion/react';

export const container = css`
  height: 600px;
  display: flex;
  align-items: stretch;
`;

export const dialogBody = css`
  flex: 1 1 0;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const dialogBodyContent = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 25%;
`;

export const dialogBodyButtons = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const hamsterImage = (hamsterImageUrl: string) => css`
  background-image: url(${hamsterImageUrl});
  background-size: cover;
  background-position: center;
  flex: 1 1 0;
`;
