import { css, type Theme } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const header = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`;

export const type = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const points = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const label = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const question = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const answersForm = (theme: Theme) => css`
  @media (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
`;

export const answersCorrected = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  min-width: 300px;
`;
