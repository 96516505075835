import { memo, type MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { css, useTheme } from '@emotion/react';
import { CheckSmall, CloseSmall, Correct } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { MCQ_ANSWER_TYPES } from '@eversity/domain/constants';
import {
  removeButtonAppearance,
  Tag,
  Typography,
} from '@eversity/ui/design-system';
import { mcqMessages, questionnaireMessages } from '@eversity/ui/intl';

export type McqTableOfContentsItemProps = {
  questionPoints: number;
  questionNumber: number;
  answerType: MCQ_ANSWER_TYPES;
  isCorrected?: boolean;
  isCorrect?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const McqTableOfContentsItemBase = ({
  questionPoints,
  questionNumber,
  answerType,
  isCorrected = false,
  isCorrect = false,
  isActive = false,
  isCompleted = false,
  onClick = noop,
}: McqTableOfContentsItemProps) => {
  const theme = useTheme();

  return (
    <li
      css={css`
        list-style: none;
      `}
    >
      <button
        type="button"
        onClick={onClick}
        className={cn({ isActive })}
        css={[
          removeButtonAppearance,
          css`
            border-left: 1px solid ${theme.colors.gray[100]};
            transition: ${theme.transitions.default('border-color')};

            &.isActive {
              border-color: ${theme.colors.primary[500]};
            }
          `,
        ]}
      >
        <div
          className={cn({ isActive })}
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 10px 15px 10px 40px;
            border-left: 1px solid transparent;
            transition: ${theme.transitions.default('border-color')};

            &.isActive {
              border-color: ${theme.colors.primary[500]};
            }
          `}
        >
          {!!(isCorrected && isCorrect) && (
            <CheckSmall
              size={16}
              theme="outline"
              fill={
                isActive ? theme.colors.success[500] : theme.colors.success[300]
              }
            />
          )}

          {!!(isCorrected && !isCorrect) && (
            <CloseSmall
              size={16}
              theme="outline"
              fill={
                isActive ? theme.colors.danger[500] : theme.colors.danger[300]
              }
            />
          )}

          {!!(!isCorrected && isCompleted) && (
            <Correct
              size={16}
              theme="outline"
              fill={
                isActive ? theme.colors.primary[500] : theme.colors.gray[300]
              }
            />
          )}

          {!!(!isCorrected && !isCompleted) && (
            <CloseSmall
              size={16}
              theme="outline"
              fill={
                isActive ? theme.colors.primary[500] : theme.colors.gray[300]
              }
            />
          )}

          <div
            className={cn({ isActive })}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 2px;

              transition: ${theme.transitions.default('color')};
              color: ${theme.colors.gray[300]};

              &.isActive {
                color: ${theme.colors.primary[500]};
              }
            `}
          >
            <Typography
              variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
              element="div"
            >
              <FormattedMessage
                {...questionnaireMessages.QUESTION_NUMBER}
                values={{
                  questionNumber,
                }}
              />
            </Typography>

            <Typography
              variant={Typography.VARIANTS.BODY_SMALL_ITALIC}
              element="div"
            >
              <FormattedMessage
                {...questionnaireMessages.QUESTION_POINTS}
                values={{
                  questionPoints,
                }}
              />
            </Typography>

            <Tag
              variant={Tag.VARIANTS.PRIMARY}
              light={!isActive}
              label={<FormattedMessage {...mcqMessages[answerType]} />}
            />
          </div>
        </div>
      </button>
    </li>
  );
};

McqTableOfContentsItemBase.displayName = 'McqTableOfContentsItem';

McqTableOfContentsItemBase.propTypes = {
  /** Number of points. */
  questionPoints: PropTypes.number.isRequired,
  /** Index in questions list. */
  questionNumber: PropTypes.number.isRequired,
  /** Question answer type. */
  answerType: PropTypes.oneOf(Object.values(MCQ_ANSWER_TYPES)).isRequired,
  /** Display correction mode. */
  isCorrected: PropTypes.bool,
  /** If isCorrected is true, is the answer correct. */
  isCorrect: PropTypes.bool,
  /** Is the question active in the window. */
  isActive: PropTypes.bool,
  /** If isCorrected is false, is the question completed. */
  isCompleted: PropTypes.bool,
  /** OnClick handler. */
  onClick: PropTypes.func,
};

export const McqTableOfContentsItem = memo(McqTableOfContentsItemBase);
