/** @description Type guard to check if error has a status property
 * @param error unknown
 * @see https://docs.sentry.io/platforms/javascript/guides/react/migration/v7-to-v8/#updated-error-types-to-be-unknown-instead-of-error
 * @returns boolean
 */
export const isErrorWithStatus = (
  error: unknown,
): error is { status: number } => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as any).status === 'number'
  );
};
