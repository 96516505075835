import {
  type HTMLProps,
  memo,
  type NamedExoticComponent,
  type ReactNode,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { css, useTheme } from '@emotion/react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useDelayedClosingState, useMemoizedBundle } from '@eversity/ui/utils';

import { useDrawer } from '../../../utils/hooks/useDrawer';
import { MODAL_ROOT_ID } from '../../meta/dialogs-provider/constants';
import { LayerContext } from '../layer/Layer.context';
import { LayerOverlay } from '../layer-overlay/LayerOverlay';
import { DRAWER_POSITIONS, DRAWER_SIZES } from './constants';
import * as styles from './Drawer.styles';

export type DrawerProps = Omit<HTMLProps<HTMLDivElement>, 'ref' | 'size'> & {
  isOpen?: boolean;
  size?: DRAWER_SIZES;
  position?: DRAWER_POSITIONS;
  shouldCloseOnEscape?: boolean;
  shouldCloseOnClickOverlay?: boolean;
  containerClassName?: string;
  onRequestClose?: () => void;
  onDidClose?: () => void;
  children?: ReactNode;
};

export const DrawerBase = ({
  isOpen = false,
  size = DRAWER_SIZES.MEDIUM,
  position = DRAWER_POSITIONS.RIGHT,
  shouldCloseOnEscape = true,
  shouldCloseOnClickOverlay = true,
  containerClassName = undefined,
  onRequestClose = noop,
  onDidClose = noop,
  children = undefined,
  ...props
}: DrawerProps) => {
  const theme = useTheme();
  const drawerRef = useRef();

  // Handle closing last open dialog on escape and background blur.
  const { numberOfLayersAbove, layerZIndex } = useDrawer({
    isOpen,
    shouldCloseOnEscape,
    onRequestClose,
  });

  const [isVisible, isClosing] = useDelayedClosingState({
    isOpen,
    onDidClose,
    animationDuration: theme.transitions.default.duration * 2,
  });

  const contextValue = useMemoizedBundle({
    isInLayer: true,
    layerZIndex,
    shouldCloseOnClickOverlay,
    onRequestClose,
  });

  return (
    <LayerContext.Provider value={contextValue}>
      <LayerOverlay
        isVisible={isVisible}
        isClosing={isClosing}
        hasLayersAbove={numberOfLayersAbove > 0}
        containerClassName={containerClassName}
      >
        <div
          ref={drawerRef}
          className={cn(size, position, {
            isOpen: isVisible && !isClosing,
            isClosing,
          })}
          css={styles.drawerStyle}
        >
          {isVisible && (
            <div
              {...props}
              css={css`
                border: none;
                width: 100%;
                height: 100%;
                background-color: ${theme.colors.gray[0]};
                overflow: auto;
              `}
            >
              {children}
            </div>
          )}
        </div>
      </LayerOverlay>
    </LayerContext.Provider>
  );
};

DrawerBase.displayName = 'Drawer';

DrawerBase.propTypes = {
  /** Is the layer open. */
  isOpen: PropTypes.bool,
  /** Width of the drawer. */
  size: PropTypes.oneOf(Object.values(DRAWER_SIZES)),
  /** Position of the drawer. */
  position: PropTypes.oneOf(Object.values(DRAWER_POSITIONS)),
  /** Should request to close the layer when pressing escape. */
  shouldCloseOnEscape: PropTypes.bool,
  /** Should close the modal when clicking on the overlay. */
  shouldCloseOnClickOverlay: PropTypes.bool,
  /** Root element className. */
  containerClassName: PropTypes.string,
  /** Called when requesting to close the layer. */
  onRequestClose: PropTypes.func,
  /** Called when the exit animation is done and the content is unmounted. */
  onDidClose: PropTypes.func,
  /** Drawer content. */
  children: PropTypes.node,
};

export const Drawer: NamedExoticComponent<DrawerProps> & {
  SIZES?: typeof DRAWER_SIZES;
  POSITIONS?: typeof DRAWER_POSITIONS;
} = memo((props) =>
  createPortal(
    <DrawerBase {...props} />,
    document.getElementById(MODAL_ROOT_ID),
  ),
);

Drawer.SIZES = DRAWER_SIZES;
Drawer.POSITIONS = DRAWER_POSITIONS;
