import {
  type ChangeEventHandler,
  createContext,
  memo,
  type ReactNode,
  useCallback,
} from 'react';
import { noop, without } from 'lodash';
import PropTypes from 'prop-types';

import { useMemoizedBundle } from '@eversity/ui/utils';

type CheckboxGroupContextValue = {
  value: string[];
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const CheckboxGroupContext = createContext<CheckboxGroupContextValue>({
  value: [],
  onChange: noop,
});

const DEFAULT_VALUE = [];

export type CheckboxGroupContextProviderProps = {
  value?: string[];
  onChange?: (newValue: string[]) => void;
  children?: ReactNode;
};

export const CheckboxGroupContextProviderBase = ({
  value = DEFAULT_VALUE,
  onChange = noop,
  children = null,
}: CheckboxGroupContextProviderProps) => {
  const onChangeProxy: CheckboxGroupContextValue['onChange'] = useCallback(
    (event) =>
      onChange(
        event.target.checked
          ? [...value, event.target.value]
          : without(value, event.target.value),
      ),
    [value, onChange],
  );

  const contextValue = useMemoizedBundle({
    value,
    onChange: onChangeProxy,
  });

  return (
    <CheckboxGroupContext.Provider value={contextValue}>
      {children}
    </CheckboxGroupContext.Provider>
  );
};

CheckboxGroupContextProviderBase.displayName = 'CheckboxGroupContextProvider';

CheckboxGroupContextProviderBase.propTypes = {
  /** Selected value. */
  value: PropTypes.arrayOf(PropTypes.string),
  /** On change function. */
  onChange: PropTypes.func,
  /** Content. */
  children: PropTypes.node,
};

export const CheckboxGroupContextProvider = memo(
  CheckboxGroupContextProviderBase,
);
