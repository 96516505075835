/**
 * Add a className to given element if not already included.
 *
 * @param el - The DOM Node Element.
 * @param name - The className to add if not already included.
 * @returns The className property.
 */
export const addClassname = (el: Element, name: string) => {
  el.classList.add(name);
  return el.className;
};

/**
 * Remove a className to given element.
 *
 * @param el - The DOM Node Element.
 * @param name - The className to remove.
 * @returns The className property.
 */
export const removeClassname = (el: Element, name: string) => {
  el.classList.remove(name);
  return el.className;
};

/**
 * Remove a array of classNames.
 *
 * @param el - The DOM Node Element.
 * @param names - The classNames to remove.
 * @returns The className property.
 */
export const removeClassnames = (el: Element, names: string) => {
  names.split(' ').forEach((name) => removeClassname(el, name));
  return el.className;
};
