import PropTypes from 'prop-types';

import {
  ASSIGNMENT_ASSESSMENTS,
  ASSIGNMENT_GRADING_TYPES,
} from '@eversity/domain/constants';

export const academicTranscriptAssignmentPropTypes = PropTypes.shape({
  /** Coefficient of the assignment in the teaching unit. */
  coefficient: PropTypes.number.isRequired,
  /** Assignment metadata. */
  assignment: PropTypes.shape({
    /** Assignment title. */
    title: PropTypes.string.isRequired,
    /** Assignment code. */
    code: PropTypes.string.isRequired,
    /** Assignment grading type. */
    gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
      .isRequired,
    /** Assignment grading scale if gradingType is GRADE. */
    gradingScale: PropTypes.number,
  }).isRequired,
  /** Assignment submission. */
  submission: PropTypes.shape({
    /** grade if assignment.gradingType is GRADE. */
    grade: PropTypes.number,
    /** Assessment if assignment.gradingType is ASSESSMENT. */
    assessment: PropTypes.oneOf(Object.values(ASSIGNMENT_ASSESSMENTS)),
    /** Correction by corrector (or automatically) date. */
    correctionDate: PropTypes.string,
    /** Submission by student date. */
    submissionDate: PropTypes.string,
  }),
});
