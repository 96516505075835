import { useCallback } from 'react';

export const useToggleSort = (
  currentSort: string,
  setCurrentSort: (newSort: string | null) => void,
) => {
  const toggleSort = useCallback(
    (column: string) => {
      if (currentSort === `+${column}`) {
        setCurrentSort(`-${column}`);
      } else if (currentSort === `-${column}`) {
        setCurrentSort(null);
      } else {
        setCurrentSort(`+${column}`);
      }
    },
    [currentSort, setCurrentSort],
  );

  return toggleSort;
};
