import 'swiper/swiper-bundle.css';

import {
  Children,
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useUpdate } from 'react-use';
import { useTheme } from '@emotion/react';
import { Left, Right } from '@icon-park/react';
import cn from 'classnames';
import { times } from 'lodash';
import PropTypes from 'prop-types';
import { Swiper, type SwiperProps, SwiperSlide } from 'swiper/react';

import { removeButtonAppearance } from '../../../utils/style';
import { Button } from '../../general/button/Button';
import { Card } from '../card/Card';
import messages from './Carousel.messages';
import * as styles from './Carousel.styles';

type SwiperClass = Parameters<SwiperProps['onSwiper']>[0];

export type CarouselProps = Omit<
  SwiperProps,
  'speed' | 'onSwiper' | 'onProgress' | 'ref'
> & {
  children?: ReactNode;
};

// There an issue on pagination if we upgrade swiperjs to any next version
// If you try an upgrade please check with attention the pagination bullets
export const CarouselBase = forwardRef(
  ({ children, ...props }: CarouselProps, ref: ForwardedRef<SwiperClass>) => {
    const theme = useTheme();
    const intl = useIntl();
    const update = useUpdate();
    const [swiper, setSwiper] = useState<SwiperClass>(null);

    const slidesCount = Children.count(children);
    const currentSlideIndex = swiper?.realIndex;

    // Pass swiper as ref for this component.
    useImperativeHandle(ref, () => swiper, [swiper]);

    const onClickPrev = useCallback(() => {
      swiper?.slidePrev(theme.transitions.default.duration);
    }, [swiper, theme]);

    const onClickNext = useCallback(() => {
      swiper?.slideNext(theme.transitions.default.duration);
    }, [swiper, theme]);

    // Generate quick access bullets.
    const pagination = useMemo(
      () =>
        times(slidesCount, (index) => (
          <button
            key={index}
            type="button"
            className={cn({ isActive: currentSlideIndex === index })}
            css={[removeButtonAppearance, styles.paginationBullet]}
            aria-label={intl.formatMessage(messages.SLIDE_X, {
              number: index + 1,
            })}
            onClick={() =>
              swiper?.slideTo(index + 1, theme.transitions.default.duration)
            }
          />
        )),
      [slidesCount, currentSlideIndex, swiper, theme, intl],
    );

    return (
      <Card css={styles.card}>
        <Card.Body css={styles.cardBody}>
          <div css={styles.container}>
            <Button
              icon={<Left />}
              onClick={onClickPrev}
              aria-label={intl.formatMessage(messages.PREV_SLIDE)}
            />

            <Swiper
              {...props}
              css={styles.swiper}
              speed={theme.transitions.default.duration}
              onSwiper={setSwiper}
              onProgress={update}
              loop
            >
              {children}
            </Swiper>

            <Button
              icon={<Right />}
              onClick={onClickNext}
              aria-label={intl.formatMessage(messages.NEXT_SLIDE)}
            />
          </div>
        </Card.Body>

        <Card.Footer css={styles.paginationContainer}>{pagination}</Card.Footer>
      </Card>
    );
  },
);

CarouselBase.displayName = 'Carousel';

CarouselBase.propTypes = {
  /** Loop between slides. */
  loop: PropTypes.bool,
  /** Autoplay feature. */
  autoplay: PropTypes.shape({
    /** Time before next slide. */
    delay: PropTypes.number,
    /** Stop autoplay on hover. */
    pauseOnMouseEnter: PropTypes.bool,
  }),
  /** List of CardsCarousel.Slide. */
  children: PropTypes.node,
};

CarouselBase.defaultProps = {
  loop: true,
  autoplay: {
    delay: 10000,
    pauseOnMouseEnter: true,
  },
  children: null,
};

export const Carousel: MemoExoticComponent<typeof CarouselBase> & {
  Slide?: typeof SwiperSlide;
} = memo(CarouselBase);

Carousel.Slide = SwiperSlide;
