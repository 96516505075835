import { Fragment, memo } from 'react';
import { Video } from '@icon-park/react';
import propTypes from 'prop-types';

import { Button } from '@eversity/ui/design-system';
import { useBoolState } from '@eversity/ui/utils';

import VideoResourceItemDialog from './dialog/VideoResourceItemDialog';

export type VideoResourceItemProps = {
  videoHref: string;
};

export const VideoResourceItemBase = ({
  videoHref,
}: VideoResourceItemProps) => {
  const [isVideoDialogOpen, onVideoDialogOpen, onVideoDialogClose] =
    useBoolState(false);

  return (
    <Fragment>
      <Button
        icon={<Video />}
        size={Button.SIZES.LARGE}
        onClick={onVideoDialogOpen}
        outline
      />

      <VideoResourceItemDialog
        isOpen={isVideoDialogOpen}
        onRequestClose={onVideoDialogClose}
        videoHref={videoHref}
      />
    </Fragment>
  );
};

VideoResourceItemBase.displayName = 'VideoResourceItem';

VideoResourceItemBase.propTypes = {
  videoHref: propTypes.string.isRequired,
};

export default memo(VideoResourceItemBase);
