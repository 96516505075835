import {
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { Attention, CheckOne, CloseOne, Info } from '@icon-park/react';
import PropTypes from 'prop-types';

import { COLOR_GROUPS } from '../../../config/colors/constants';
import { HtmlFormatter } from '../../data-display/html-formatter/HtmlFormatter';
import { WaveCard } from '../../data-display/wave-card/WaveCard';
import { type VARIANTS as WAVE_CARD_VARIANTS } from '../../data-display/wave-card/WaveCard.constants';
import { IconShadow } from '../../general/icon-shadow/IconShadow';
import { Typography } from '../../general/typography/Typography';
import * as styles from './AlertBanner.styles';
import { ALERT_BANNER_VARIANTS } from './constants';

const ALERT_BANNER_ICON_MAPPING: Record<ALERT_BANNER_VARIANTS, ReactElement> = {
  [ALERT_BANNER_VARIANTS.INFO]: <Info />,
  [ALERT_BANNER_VARIANTS.DANGER]: <CloseOne />,
  [ALERT_BANNER_VARIANTS.WARNING]: <Attention />,
  [ALERT_BANNER_VARIANTS.SUCCESS]: <CheckOne />,
};

const ALERT_BANNER_ICON_COLOR_GROUP_MAPPING: Record<
  ALERT_BANNER_VARIANTS,
  COLOR_GROUPS
> = {
  [ALERT_BANNER_VARIANTS.INFO]: COLOR_GROUPS.PRIMARY,
  [ALERT_BANNER_VARIANTS.DANGER]: COLOR_GROUPS.DANGER,
  [ALERT_BANNER_VARIANTS.WARNING]: COLOR_GROUPS.WARNING,
  [ALERT_BANNER_VARIANTS.SUCCESS]: COLOR_GROUPS.SUCCESS,
};

const ALERT_BANNER_WAVE_CARD_VARIANTS_MAPPING: Record<
  ALERT_BANNER_VARIANTS,
  WAVE_CARD_VARIANTS
> = {
  [ALERT_BANNER_VARIANTS.INFO]: WaveCard.VARIANTS.PRIMARY,
  [ALERT_BANNER_VARIANTS.DANGER]: WaveCard.VARIANTS.DANGER,
  [ALERT_BANNER_VARIANTS.WARNING]: WaveCard.VARIANTS.WARNING,
  [ALERT_BANNER_VARIANTS.SUCCESS]: WaveCard.VARIANTS.SUCCESS,
};

export type AlertBannerProps = {
  variant?: ALERT_BANNER_VARIANTS;
  icon?: ReactElement;
  title?: ReactNode;
  children?: ReactNode;
};

export const AlertBannerBase = ({
  variant = ALERT_BANNER_VARIANTS.INFO,
  icon = null,
  title = null,
  children = null,
}: AlertBannerProps) => (
  <WaveCard variant={ALERT_BANNER_WAVE_CARD_VARIANTS_MAPPING[variant]}>
    <div css={styles.container}>
      <HtmlFormatter css={styles.text}>
        {title && (
          <Typography variant={Typography.VARIANTS.HEADING_4}>
            {title}
          </Typography>
        )}

        {children}
      </HtmlFormatter>

      <IconShadow
        icon={icon || ALERT_BANNER_ICON_MAPPING[variant]}
        colorGroup={ALERT_BANNER_ICON_COLOR_GROUP_MAPPING[variant]}
        hasCircleBorder={!icon}
      />
    </div>
  </WaveCard>
);

AlertBannerBase.displayName = 'AlertBanner';

AlertBannerBase.propTypes = {
  /** Content of the banner. */
  children: PropTypes.node,
  /** Text title of the banner. */
  title: PropTypes.node,
  /** Type of alert banner. */
  variant: PropTypes.oneOf(Object.values(ALERT_BANNER_VARIANTS)),
  /** Override default icon. */
  icon: PropTypes.node,
};

export const AlertBanner: MemoExoticComponent<typeof AlertBannerBase> & {
  VARIANTS?: typeof ALERT_BANNER_VARIANTS;
} = memo(AlertBannerBase);

AlertBanner.VARIANTS = ALERT_BANNER_VARIANTS;
