import { cloneElement, memo, type ReactElement, type ReactNode } from 'react';
import { type IconProps } from 'react-toastify';
import { css, useTheme } from '@emotion/react';
import { Attention, CheckOne, CloseOne, Info } from '@icon-park/react';

import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import * as styles from './Toast.styles';

const TOAST_TYPE_ICON_MAPPING = {
  error: <CloseOne />,
  info: <Info />,
  warning: <Attention />,
  success: <CheckOne />,
};

export type ToastProps = {
  icon?: ReactElement;
  toastProps?: IconProps;
  children?: ReactNode;
};

export const ToastBase = ({
  toastProps = undefined,
  icon = undefined,
  children = undefined,
}: ToastProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
      `}
    >
      {
        // Show a default icon if icon is undefined,
        // but don't show anything if icon is null or default icon does not exist.
        icon !== null &&
          (icon !== undefined || !!TOAST_TYPE_ICON_MAPPING[toastProps.type]) &&
          cloneElement(icon || TOAST_TYPE_ICON_MAPPING[toastProps.type], {
            size: ICON_SIZES.MEDIUM,
            fill: styles.getIconFills(theme, toastProps.type),
          })
      }

      <Typography
        variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
        element="div"
        css={css`
          overflow-wrap: anywhere;
        `}
      >
        {children}
      </Typography>
    </div>
  );
};

ToastBase.displayName = 'Toast';

export const Toast = memo(ToastBase);
