import PropTypes from 'prop-types';

import {
  MCQ_ANSWER_STYLES,
  MCQ_ANSWER_TYPES,
  MCQ_MEDIA_RESOURCES_TYPES,
  MCQ_PAGE_TYPES,
} from '@eversity/domain/constants';
import { uploadPropTypes } from '@eversity/ui/design-system';

export const mcqAnswerPropTypes = PropTypes.shape({
  /** Question id. */
  questionId: PropTypes.string.isRequired,
  /** Answers in the order they were displayed in the first time. */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      /** Answer id. */
      answerId: PropTypes.string.isRequired,
      /** Did the user select the answer. */
      isSelected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
});

export const answerPropTypes = PropTypes.shape({
  /** Id of the answer. */
  id: PropTypes.string.isRequired,
  /** Answer label. */
  label: PropTypes.string.isRequired,
  /** Flags a valid answer. */
  isCorrectAnswer: PropTypes.bool,
  /** Text displayed when the student answered correctly. */
  successText: PropTypes.string,
  /** Text displayed when the student aswered incorrectly. */
  errorText: PropTypes.string,
  /** Media associated to the answer. */
  media: uploadPropTypes,
  /** Alternative text for the media (only images). */
  mediaAlt: PropTypes.string,
});

export const mcqResourcesPropTypes = PropTypes.shape({
  /** Id of the resource in the question. */
  id: PropTypes.string.isRequired,
  /** Type of the resource. */
  type: PropTypes.oneOf(Object.values(MCQ_MEDIA_RESOURCES_TYPES)).isRequired,
  /** Data of the media resource. */
  params: PropTypes.oneOfType([
    PropTypes.shape({
      /** Data of the uploaded media resource. */
      upload: uploadPropTypes.isRequired,
      /** Alt text when required for a11y purpose. */
      alt: PropTypes.string,
    }),
    PropTypes.shape({
      /** Link to the resource (not uploaded). */
      href: PropTypes.string.isRequired,
    }),
  ]).isRequired,
});

export const questionPropTypes = PropTypes.shape({
  /** Question id. */
  id: PropTypes.string.isRequired,
  /** Question label. */
  label: PropTypes.string.isRequired,
  /** Type of question (single answer or multiple answers). */
  answerType: PropTypes.oneOf(Object.values(MCQ_ANSWER_TYPES)).isRequired,
  /** Type for the media associated to the answers. */
  answerStyle: PropTypes.oneOf(Object.values(MCQ_ANSWER_STYLES)).isRequired,
  /** Number of points gained for this question. */
  points: PropTypes.number,
  /** Array of answers. */
  answers: PropTypes.arrayOf(answerPropTypes).isRequired,
  /** Array of resources. */
  resources: PropTypes.arrayOf(mcqResourcesPropTypes),
});

export const questionViewStudentPropTypes = PropTypes.shape({
  /** Question id. */
  id: PropTypes.string.isRequired,
  /** Type of question (single answer or multiple answers). */
  answerType: PropTypes.oneOf(Object.values(MCQ_ANSWER_TYPES)).isRequired,
  /** Number of points gained for this question. */
  points: PropTypes.number,
});

export const mcqPagePropTypes = PropTypes.shape({
  /** Page type from MCQ_PAGE_TYPES enum. */
  pageType: PropTypes.oneOf(Object.values(MCQ_PAGE_TYPES)),
  /** If pageType is CONTENT or MCQ_CONTENT, HTML string. */
  content: PropTypes.string,
  /** If pageType is MCQ or MCQ_CONTENT, list of questions metadata. */
  questions: PropTypes.arrayOf(questionPropTypes).isRequired,
});

export const mcqPageViewStudentPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  /** Page type from MCQ_PAGE_TYPES enum. */
  pageType: PropTypes.oneOf(Object.values(MCQ_PAGE_TYPES)).isRequired,
  /** If pageType is MCQ or MCQ_CONTENT, list of questions metadata. */
  questions: PropTypes.arrayOf(questionViewStudentPropTypes).isRequired,
});

export const mcqPageViewFullPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  /** Page type from MCQ_PAGE_TYPES enum. */
  pageType: PropTypes.oneOf(Object.values(MCQ_PAGE_TYPES)).isRequired,
  /** If pageType is CONTENT or MCQ_CONTENT, HTML string. */
  content: PropTypes.string,
  /** If pageType is MCQ or MCQ_CONTENT, list of questions metadata. */
  questions: PropTypes.arrayOf(questionPropTypes).isRequired,
});
