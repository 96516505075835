import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

export type FormattedTextProps = {
  text?: string;
};

export const FormattedTextBase = ({ text = '' }: FormattedTextProps) => (
  <Fragment>
    {text
      .split('\n')
      .filter(Boolean) // Remove duplicate \n.
      .map((line, index, array) => (
        // eslint-disable-next-line react/no-array-index-key -- Text should be static anyway.
        <Fragment key={index}>
          {line}
          {index < array.length - 1 && <br />}
        </Fragment>
      ))}
  </Fragment>
);

FormattedTextBase.displayName = 'FormattedText';

FormattedTextBase.propTypes = {
  text: PropTypes.string,
};

export const FormattedText = memo(FormattedTextBase);
