import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { POPUP_POSITIONS, POPUP_SIZES } from './constants';

export const popupStyle = (theme: Theme) => css`
  position: absolute;

  z-index: 10;

  box-shadow: 0px 2px 4px 0px
    ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

  border-radius: 4px;

  &.${POPUP_POSITIONS.CENTER} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.${POPUP_POSITIONS.TOP_LEFT} {
    top: 10px;
    left: 10px;
  }

  &.${POPUP_POSITIONS.TOP_RIGHT} {
    top: 10px;
    right: 10px;
  }

  &.${POPUP_POSITIONS.BOTTOM_RIGHT} {
    bottom: 10px;
    right: 10px;
  }

  &.${POPUP_POSITIONS.BOTTOM_LEFT} {
    bottom: 10px;
    left: 10px;
  }

  &.${POPUP_POSITIONS.TOP} {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.${POPUP_POSITIONS.RIGHT} {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &.${POPUP_POSITIONS.BOTTOM} {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.${POPUP_POSITIONS.LEFT} {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &.${POPUP_SIZES.MEDIUM} {
    width: 370px;
  }
`;

export const closeButtonAbsoluteStyle = css`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

export const cardContent = css`
  border: none;
`;
