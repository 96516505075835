import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  type LEGAL_DOCUMENT_TYPES,
  VIRTUAL_CLASSROOM_VIEWS,
} from '@eversity/domain/constants';
import { UsersRepository } from '@eversity/domain/data/repositories';
import {
  type StudentViewFull,
  type VirtualClassroomViewStudent,
} from '@eversity/types/domain';
import { type UseMutationParams } from '@eversity/types/web';

import http from '../../../services/http/api';

const usersRepository = new UsersRepository(http);

export const useUpdateCurrentUserMutation = (
  useMutationParams?: UseMutationParams<
    StudentViewFull,
    Parameters<UsersRepository['updateSelfUser']>[0]
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => usersRepository.updateSelfUser(params),
    onSuccess: (user) => {
      queryClient.setQueryData<StudentViewFull>(
        ['user'],
        user as StudentViewFull,
      );
    },
    ...useMutationParams,
  });
};

export const useUpdateUserProfilePictureMutation = (
  useMutationParams?: UseMutationParams<StudentViewFull, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (pictureId) =>
      usersRepository.updateSelfUser({ picture: pictureId }),
    onSuccess: (user) => {
      queryClient.setQueryData<StudentViewFull>(
        ['user'],
        user as StudentViewFull,
      );
    },
    ...useMutationParams,
  });
};

export const useAcceptLegalDocumentMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['acceptLegalDocument']>>,
    LEGAL_DOCUMENT_TYPES
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (legalDocumentType) =>
      usersRepository.acceptLegalDocument(legalDocumentType),
    onSuccess: (user) => {
      queryClient.setQueryData<StudentViewFull>(['user'], user);
    },
    ...useMutationParams,
  });
};

export const useUpdateUserAssignmentSubmissionDepositMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['patchUserAssignmentSubmission']>>,
    {
      submissionId: string;
      depositId: string;
      params: Parameters<UsersRepository['patchUserAssignmentSubmission']>[2];
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ submissionId, depositId, params }) =>
      usersRepository.patchUserAssignmentSubmission(
        submissionId,
        depositId,
        params,
      ),
    onSuccess: (updatedSubmission) => {
      queryClient.invalidateQueries({ queryKey: ['user-assignments'] });
      queryClient.invalidateQueries({ queryKey: ['assignments-summary'] });
      queryClient.invalidateQueries({
        queryKey: [
          'user-assignment',
          updatedSubmission.assignment.id,
          updatedSubmission.course.id,
          updatedSubmission.class.id,
          updatedSubmission.teachingUnit.id,
          updatedSubmission.lesson?.id,
        ],
      });
    },
    ...useMutationParams,
  });
};

export const useCreateUserAssignmentSubmissionMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['createAssignmentSubmission']>>,
    {
      assignmentId: string;
      courseId: string;
      classId: string;
      teachingUnitId: string;
      lessonId?: string | null;
      params?: Parameters<UsersRepository['createAssignmentSubmission']>[5];
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      assignmentId,
      courseId,
      classId,
      teachingUnitId,
      lessonId,
      params,
    }) =>
      usersRepository.createAssignmentSubmission(
        assignmentId,
        courseId,
        classId,
        teachingUnitId,
        lessonId,
        params,
      ),
    onSuccess: (submission) => {
      queryClient.invalidateQueries({ queryKey: ['user-assignments'] });
      queryClient.invalidateQueries({ queryKey: ['assignments-summary'] });
      queryClient.invalidateQueries({
        queryKey: [
          'user-assignment',
          submission.assignment.id,
          submission.course.id,
          submission.class.id,
          submission.teachingUnit.id,
          submission.lesson?.id,
        ],
      });
    },
    ...useMutationParams,
  });
};

export const useSignInToVirtualClassroomMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['signInToVirtualClassroom']>>,
    {
      virtualClassroomId: string;
      acceptedImageRights: boolean;
    }
  >,
) =>
  useMutation({
    mutationFn: ({ virtualClassroomId, acceptedImageRights }) =>
      usersRepository.signInToVirtualClassroom(
        virtualClassroomId,
        acceptedImageRights,
      ),
    ...useMutationParams,
  });

export const useSetUserCourseOnboardingMeetingMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['updateStudentCourse']>>,
    {
      courseId: string;
      classId: string;
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ courseId, classId }) =>
      usersRepository.updateStudentCourse(courseId, classId, {
        needsOnboardingMeeting: false,
      }),
    onSuccess: (_, { courseId, classId }) => {
      queryClient.setQueryData<StudentViewFull>(
        ['user'],
        (student) =>
          student && {
            ...student,
            courses: student.courses.map((course) =>
              course.course.id === courseId && course.class.id === classId
                ? {
                    ...course,
                    needsOnboardingMeeting: false,
                  }
                : course,
            ),
          },
      );
    },
    ...useMutationParams,
  });
};

export const useSetUserFirstConnectionDateMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['updateStudentCourse']>>,
    {
      courseId: string;
      classId: string;
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ courseId, classId }) =>
      usersRepository.updateStudentCourse(courseId, classId, {
        firstConnection: true,
      }),
    onSuccess: (_, { courseId, classId }) => {
      queryClient.setQueryData<StudentViewFull>(
        ['user'],
        (student) =>
          student && {
            ...student,
            courses: student.courses.map((course) =>
              course.course.id === courseId && course.class.id === classId
                ? {
                    ...course,
                    firstConnectionDate: new Date().toISOString(),
                  }
                : course,
            ),
          },
      );
    },
    ...useMutationParams,
  });
};

export const useDeleteUserProfilePictureMutation = (
  useMutationParams?: UseMutationParams<StudentViewFull, { pictureId: string }>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ pictureId }) =>
      usersRepository.deleteProfilePicture(
        pictureId,
      ) as Promise<StudentViewFull>,
    onSuccess: (user) =>
      queryClient.setQueryData<StudentViewFull>(['user'], user),
    ...useMutationParams,
  });
};

export const useOrderWelcomePackMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['orderWelcomePack']>>,
    {
      courseId: string;
      classId: string;
      params: Parameters<UsersRepository['orderWelcomePack']>[2];
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ courseId, classId, params }) =>
      usersRepository.orderWelcomePack(courseId, classId, params),
    onSuccess: (_, { courseId, classId }) => {
      queryClient.invalidateQueries({
        queryKey: ['welcome-pack', courseId, classId],
      });
    },
    ...useMutationParams,
  });
};

export const usePostVirtualClassroomReplayViewMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['postVirtualClassroomReplayView']>>,
    { virtualClassroomId: string }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ virtualClassroomId }) =>
      usersRepository.postVirtualClassroomReplayView(virtualClassroomId),
    onSuccess: (_, { virtualClassroomId }) => {
      queryClient.setQueryData<VirtualClassroomViewStudent>(
        [
          'virtual-classroom',
          virtualClassroomId,
          { view: VIRTUAL_CLASSROOM_VIEWS.STUDENT },
        ],
        (virtualClassroom) => ({
          ...virtualClassroom,
          hasWatchedReplay: true,
        }),
      );
    },
    ...useMutationParams,
  });
};

export const useCreateNewDepositMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['createDeposit']>>,
    {
      assignmentId: string;
      submissionId: string;
    }
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ assignmentId, submissionId }) =>
      usersRepository.createDeposit(assignmentId, submissionId),
    onSuccess: (submission) => {
      queryClient.invalidateQueries({ queryKey: ['user-assignments'] });
      queryClient.invalidateQueries({ queryKey: ['assignments-summary'] });
      queryClient.invalidateQueries({
        queryKey: ['user-assignment', submission.assignment.id],
      });
    },
    ...useMutationParams,
  });
};

export const usePostStudentCardPictureMutation = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<UsersRepository['postStudentCardPicture']>>,
    { courseId: string; classId: string; file: Blob }
  >,
) => {
  return useMutation({
    mutationFn: ({ courseId, classId, file }) =>
      usersRepository.postStudentCardPicture(courseId, classId, {
        file,
      }),

    ...useMutationParams,
  });
};
