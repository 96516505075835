import { keyframes } from '@emotion/react';

export const pulseAnimation = keyframes`
0% {
  transform: scale(1);
}

55% {
  transform: scale(1.6);
}
`;
