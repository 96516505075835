import { defineMessages } from 'react-intl';

export default defineMessages({
  ACCEPTED_EXTENSIONS: {
    defaultMessage:
      '{count, plural, one {Format accepté} other {Formats acceptés}} : {extensions}.',
  },

  MAXIMUM_FILES: {
    defaultMessage:
      '{count, plural, one {# fichier} other {# fichiers}} maximum.',
  },

  MAXIMUM_SIZE: {
    defaultMessage: 'Taille max. : {maxSize}.',
  },
});
