import { memo } from 'react';

type MedalIconProps = {
  size: string;
  circleFill: string;
  circleStroke: string;
  innerCircleFill: string;
  pathStroke1: string;
  pathStroke2: string;
  pathStroke3: string;
  pathStroke4: string;
};

const MedalIconBase = ({
  size,
  circleFill,
  circleStroke,
  innerCircleFill,
  pathStroke1,
  pathStroke2,
  pathStroke3,
  pathStroke4,
}: MedalIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="19.5"
      fill={circleFill}
      stroke={circleStroke}
    />
    <path
      d="M37 20C37 22.2325 36.5603 24.4431 35.706 26.5056C34.8516 28.5682 33.5994 30.4422 32.0208 32.0208C30.4422 33.5994 28.5682 34.8516 26.5056 35.706C24.4431 36.5603 22.2325 37 20 37"
      stroke={pathStroke1}
      strokeLinecap="round"
    />
    <path
      d="M3 20C3 17.7675 3.43972 15.5569 4.29405 13.4944C5.14838 11.4318 6.40059 9.55778 7.97919 7.97919C9.55778 6.40059 11.4318 5.14838 13.4944 4.29405C15.5569 3.43972 17.7675 3 20 3"
      stroke={pathStroke2}
      strokeLinecap="round"
    />
    <circle
      cx="20"
      cy="20"
      r="14.5"
      fill={innerCircleFill}
      stroke={circleStroke}
    />
    <path
      d="M32 20C32 21.5759 31.6896 23.1363 31.0866 24.5922C30.4835 26.0481 29.5996 27.371 28.4853 28.4853C27.371 29.5996 26.0481 30.4835 24.5922 31.0866C23.1363 31.6896 21.5759 32 20 32"
      stroke={pathStroke3}
      strokeLinecap="round"
    />
    <path
      d="M8 20C8 18.4241 8.31039 16.8637 8.91345 15.4078C9.5165 13.9519 10.4004 12.629 11.5147 11.5147C12.629 10.4004 13.9519 9.5165 15.4078 8.91344C16.8637 8.31039 18.4241 8 20 8"
      stroke={pathStroke4}
      strokeLinecap="round"
    />
  </svg>
);

export default memo(MedalIconBase);
