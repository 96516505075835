import PropTypes from 'prop-types';

import {
  ASSIGNMENT_GRADING_TYPES,
  ASSIGNMENT_STYLES,
  ASSIGNMENT_TAGS,
  ASSIGNMENT_TYPES,
  MCQ_ANSWER_TYPES,
} from '@eversity/domain/constants';
import { uploadWithoutHrefPropTypes } from '@eversity/ui/design-system';

import { assignmentCorrectionFeePropTypes } from './assignmentCorrectionFees';
import { assignmentSubmissionPropTypes } from './assignmentSubmissions';
import { schoolObjectBasePropTypes } from './common';
import { lessonVersionViewMinimalPropTypes } from './lessons';
import {
  mcqPageViewStudentPropTypes,
  mcqResourcesPropTypes,
  questionPropTypes,
} from './mcq';
import { commonUserPropTypes } from './users';

export const assignmentViewMinimalPropTypes = PropTypes.shape({
  /** Assignment id. */
  id: PropTypes.string.isRequired,
  /** Assignment title. */
  title: PropTypes.string.isRequired,
  /** Assignment code. */
  code: PropTypes.string.isRequired,
  /** Assignment type. */
  assignmentType: PropTypes.oneOf(Object.values(ASSIGNMENT_TYPES)).isRequired,
  /** Assignment style. */
  assignmentStyle: PropTypes.oneOf(Object.values(ASSIGNMENT_STYLES)).isRequired,
  /** Assignment tags. */
  assignmentTags: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ASSIGNMENT_TAGS)),
  ).isRequired,
  /** Assignment grading type. */
  gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
    .isRequired,
  /** Assignment grading scale. */
  gradingScale: PropTypes.number,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update date. */
  updatedAt: PropTypes.string.isRequired,
});

export const assignmentViewFullPropTypes = PropTypes.shape({
  /** Assignment id. */
  id: PropTypes.string.isRequired,
  /** Assignment title. */
  title: PropTypes.string.isRequired,
  /** Assignment code. */
  code: PropTypes.string.isRequired,
  /** Assignment type. */
  assignmentType: PropTypes.oneOf(Object.values(ASSIGNMENT_TYPES)).isRequired,
  /** Assignment style. */
  assignmentStyle: PropTypes.oneOf(Object.values(ASSIGNMENT_STYLES)).isRequired,
  /** Assignment tags. */
  assignmentTags: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ASSIGNMENT_TAGS)),
  ).isRequired,
  /** Assignment grading type. */
  gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
    .isRequired,
  /** Assignment grading scale. */
  gradingScale: PropTypes.number,
  /** Users affected as correctors for this assignment. */
  correctors: PropTypes.arrayOf(commonUserPropTypes).isRequired,
  /** List of assignment versions. */
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Assignment description (not HTML, with new lines). */
      description: PropTypes.string,
      /** Estimated time required to complete the assignment in minutes. */
      estimatedCompletionTime: PropTypes.number,
      /** Assignment instructions for the students. */
      documents: PropTypes.arrayOf(uploadWithoutHrefPropTypes),
      /** List of questions for MCQ assignments. */
      questions: PropTypes.arrayOf(questionPropTypes),
      /** Version publication date. */
      createdAt: PropTypes.string,
      /** User who published the version. */
      publishedBy: commonUserPropTypes,
      /** Reason for new version. */
      changelog: PropTypes.string,
    }),
  ),
  /** Draft containing current modifications to the assignment. */
  draft: PropTypes.shape({
    /** Assignment description (not HTML, with new lines). */
    description: PropTypes.string,
    /** Estimated time required to complete the assignment in minutes. */
    estimatedCompletionTime: PropTypes.number,
    /** Assignment instructions for the students. */
    documents: PropTypes.arrayOf(uploadWithoutHrefPropTypes),
    /** List of questions for MCQ assignments. */
    questions: PropTypes.arrayOf(questionPropTypes),
  }),
  /** Correction fee. Only if not MCQ. */
  correctionFee: assignmentCorrectionFeePropTypes,
  /** Creation date. */
  createdAt: PropTypes.string,
  /** Last update date. */
  updatedAt: PropTypes.string,
});

export const assignmentViewStudentPropTypes = PropTypes.shape({
  /** Assignment id. */
  id: PropTypes.string.isRequired,
  /** Assignment title. */
  title: PropTypes.string.isRequired,
  /** Assignment code. */
  code: PropTypes.string.isRequired,
  /** Assignment type. */
  assignmentType: PropTypes.oneOf(Object.values(ASSIGNMENT_TYPES)).isRequired,
  /** Assignment style. */
  assignmentStyle: PropTypes.oneOf(Object.values(ASSIGNMENT_STYLES)).isRequired,
  /** Assignment tags. */
  assignmentTags: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ASSIGNMENT_TAGS)),
  ).isRequired,
  /** Assignment grading type. */
  gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
    .isRequired,
  /** Assignment grading scale. */
  gradingScale: PropTypes.number,
  /** Assignment description (plain text). */
  description: PropTypes.string,
  /** Estimated time required to complete the assignment in minutes. */
  estimatedCompletionTime: PropTypes.number,
  /** Users affected as correctors for this assignment. */
  correctors: PropTypes.arrayOf(commonUserPropTypes).isRequired,
  /** Assignment instructions for the students. */
  documents: PropTypes.arrayOf(uploadWithoutHrefPropTypes).isRequired,
  /** List of questions for MCQ assignments. */
  pages: PropTypes.arrayOf(mcqPageViewStudentPropTypes),
  /** Creation date. */
  createdAt: PropTypes.string,
  /** Last update date. */
  updatedAt: PropTypes.string,
});

export const studentAssignmentPropTypes = PropTypes.shape({
  /** Assignment data. */
  assignment: assignmentViewStudentPropTypes.isRequired,
  /** Course data. */
  course: schoolObjectBasePropTypes.isRequired,
  /** Course data. */
  class: schoolObjectBasePropTypes.isRequired,
  /** Teaching unit data. */
  teachingUnit: schoolObjectBasePropTypes.isRequired,
  /** Lesson data if assignment linked to lesson. */
  lesson: schoolObjectBasePropTypes,
  /** Availability of the assignment. */
  availableAfterDays: PropTypes.number,
  /** Student submission if any. */
  submission: assignmentSubmissionPropTypes,
});

export const answerViewAssignmentPropTypes = PropTypes.shape({
  /** Id used as value in questionnaire. */
  id: PropTypes.string.isRequired,
  /** Answer label. */
  label: PropTypes.string.isRequired,
});

export const questionViewAssignmentPropTypes = PropTypes.shape({
  /** Question id. */
  id: PropTypes.string.isRequired,
  /** Question label. */
  label: PropTypes.string.isRequired,
  /** Type of question (single answer or multiple answers). */
  answerType: PropTypes.oneOf(Object.values(MCQ_ANSWER_TYPES)).isRequired,
  /** Number of points gained for this question. */
  points: PropTypes.number,
  /** Array of answers without info if the answer is correct. */
  answers: PropTypes.arrayOf(answerViewAssignmentPropTypes).isRequired,
  /** Array of resources (image, video, audio) */
  resources: PropTypes.arrayOf(mcqResourcesPropTypes),
});

export const assignmentLessonPropTypes = PropTypes.shape({
  /** Lesson info. */
  lesson: PropTypes.shape({
    /** Lesson unique id. */
    id: PropTypes.string.isRequired,
    /** Lesson title. */
    title: PropTypes.string.isRequired,
    /** Lesson code. */
    code: PropTypes.string.isRequired,
    /** All available versions of the lesson. */
    versions: PropTypes.arrayOf(lessonVersionViewMinimalPropTypes).isRequired,
  }).isRequired,
  /** List of major versions of the lesson linked to the assignment. */
  forVersionsMajor: PropTypes.arrayOf(PropTypes.number).isRequired,
});
