import type { HTMLBlocksSelection } from '@eversity/types/misc';

/**
 * Get the corresponding block character index for the container character index.
 * We're running through all block's children nodes's text to increment
 * the index until we're reaching the selected node.
 *
 * @param block - The block including the container.
 * @param container - The selected node.
 * @param containerIndex - The index inside the container node.
 * @returns The block's corresponding index.
 */
export function getBlockIndexFromContainerIndex(
  block: Node,
  container: Node,
  containerIndex: number,
) {
  const treeWalker = document.createTreeWalker(
    block,
    NodeFilter.SHOW_TEXT,
    null,
  );
  let charCount = 0;
  let currentNode = treeWalker.nextNode();

  while (currentNode !== null) {
    if (currentNode === container) {
      return charCount + containerIndex;
    }
    charCount += currentNode.textContent.length;
    currentNode = treeWalker.nextNode();
  }
  return -1;
}

/**
 * Getting the nodes that contains text only to encapsulate them
 * later on with a span tag.
 *
 * @param range - The starting selection.
 * @returns The text nodes included in selection.
 */
export function getTextNodesInRange(range: globalThis.Range) {
  const textNodes: Node[] = [];

  function getTextNodes(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      for (let child = node.firstChild; child; child = child.nextSibling) {
        getTextNodes(child);
      }
    }
  }

  getTextNodes(range.commonAncestorContainer);

  /**
   * Since we retrieved all the textNodes of the range's parent,
   * we then filter on nodes that intersects with the selection
   * only.
   */
  return textNodes.filter((node) => range.intersectsNode(node));
}

/**
 * Surround the range with a styled span tag.
 * Only use this function as a template for future developments,
 * as we won't necessarily want to add a yellow background to our selection.
 *
 * @param range - The group of nodes and parts of nodes to highlight.
 * @returns Created span elements.
 */
export function applyHighlight(range: globalThis.Range, color: string) {
  const { startContainer, endContainer, startOffset, endOffset } = range;
  const createdSpans: HTMLSpanElement[] = [];

  if (startContainer === endContainer) {
    if (startOffset === endOffset) {
      // empty selection
      return createdSpans;
    }

    // If a single block is selected
    const span = document.createElement('span');
    span.style.backgroundColor = color;
    range.surroundContents(span);

    createdSpans.push(span);
  } else {
    // If multiple blocks are selected
    const startRange = document.createRange();
    startRange.setStart(startContainer, range.startOffset);
    startRange.setEnd(startContainer, startContainer.textContent.length);

    const spanStart = document.createElement('span');
    spanStart.style.backgroundColor = color;
    startRange.surroundContents(spanStart);

    const endRange = document.createRange();
    endRange.setStart(endContainer, 0);
    endRange.setEnd(endContainer, range.endOffset);

    const spanEnd = document.createElement('span');
    spanEnd.style.backgroundColor = color;
    endRange.surroundContents(spanEnd);

    const middleRange = document.createRange();
    middleRange.setStartAfter(spanStart);
    middleRange.setEndBefore(spanEnd);

    createdSpans.push(spanStart, spanEnd);

    const nodes = getTextNodesInRange(middleRange);

    nodes
      .filter((node) => !!node.textContent.trim().length) // avoid generating empty<span></span>
      .forEach((node) => {
        const spanMiddle = document.createElement('span');
        spanMiddle.style.backgroundColor = color;
        const nodeRange = document.createRange();
        nodeRange.selectNodeContents(node);
        nodeRange.surroundContents(spanMiddle);

        // keep a reference to the span to update the DOM later on
        createdSpans.push(spanMiddle);
      });
  }

  return createdSpans;
}

/**
 * Highlight a block
 *
 * @param block - Block we want to highlight.
 * @param from - Starting character index.
 * @param to - Ending character index.
 * @param color - Color of the highlight.
 * @returns Created span elements.
 */
export function highlightBlock(
  block: HTMLElement,
  from: number,
  to: number,
  color: string,
) {
  const range = document.createRange();
  let charIndex = 0;
  let startNode = null;
  let endNode = null;
  let startOffset = 0;
  let endOffset = 0;

  function findNodes(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const nodeTextLength = node.textContent.length;

      if (charIndex + nodeTextLength >= from && !startNode) {
        startNode = node;
        startOffset = from - charIndex;
      }

      if (charIndex + nodeTextLength >= to) {
        endNode = node;
        endOffset = to - charIndex;
        return true;
      }

      charIndex += nodeTextLength;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (findNodes(node.childNodes[i])) {
          return true;
        }
      }
    }
    return false;
  }

  findNodes(block);

  if (startNode && endNode) {
    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    return applyHighlight(range, color);
  }

  return [];
}

/**
 * Get the highest parent with an id attribute.
 * We're looking for the highest one in order to handle the nested blocks.
 *
 * @param node  - The child node we want to find the parent block to.
 * @param limitId  - The highest ancestor Id where we want to stop. Will be excluded.
 * @returns The highest parent with an id, or the parent that has the limitElementId as an id. null if limitElementId is defined and element not found.
 */
export function getHighestParentWithId(node: Node, limitElementId?: string) {
  let nodeAsHTMLElement = node as HTMLElement;
  let highestParentWithId = node as HTMLElement;

  while (nodeAsHTMLElement) {
    if (limitElementId && nodeAsHTMLElement.id?.includes(limitElementId))
      return highestParentWithId;
    if (nodeAsHTMLElement.id) highestParentWithId = nodeAsHTMLElement;
    nodeAsHTMLElement = nodeAsHTMLElement.parentNode as HTMLElement;
  }

  return !limitElementId || highestParentWithId.id === limitElementId
    ? highestParentWithId
    : null;
}

/**
 * Get formatted selected blocks with their respective offsets.
 * Recently added limitElementId parameter, so we know we shouldn't seek
 * for Nodes that are higher than the limit element.
 *
 * @param selection - HTML DOM selection.
 * @param limitElementId - Highest element Id where we want to stop.
 * @returns Map of the selected blocks.
 */
export function getRangeFromSelection(
  selection: Selection,
  limitElementId?: string,
) {
  if (!selection.rangeCount) return null;

  const range = selection.getRangeAt(0);
  const {
    startContainer,
    endContainer,
    startOffset,
    endOffset,
    commonAncestorContainer,
  } = range;
  const ranges = {} as HTMLBlocksSelection;
  const startParent = getHighestParentWithId(startContainer, limitElementId);
  const endParent = getHighestParentWithId(endContainer, limitElementId);

  if (!startParent || !endParent) return ranges;

  function addRangeForBlock(block: Node, from: number, to: number) {
    if (from === -1 || to === -1) return; // Skip invalid ranges

    const { id } = block as HTMLElement;

    ranges[id] = { from, to };
  }

  if (startParent === endParent) {
    // If the selection only contains one block

    const offsetWithinStart = getBlockIndexFromContainerIndex(
      startParent,
      startContainer,
      startOffset,
    );
    const offsetWithinEnd = getBlockIndexFromContainerIndex(
      endParent,
      endContainer,
      endOffset,
    );
    addRangeForBlock(startParent, offsetWithinStart, offsetWithinEnd);
  } else {
    // If the selection contains multiple blocks

    // Adding the first block to the blocks map
    const offsetWithinStart = getBlockIndexFromContainerIndex(
      startParent,
      startContainer,
      startOffset,
    );
    addRangeForBlock(
      startParent,
      offsetWithinStart,
      startParent.textContent.length,
    );

    // Adding the last block to the blocks map
    const offsetWithinEnd = getBlockIndexFromContainerIndex(
      endParent,
      endContainer,
      endOffset,
    );
    addRangeForBlock(endParent, 0, offsetWithinEnd);

    // Adding intermediate blocks to the blocks map
    const middleRange = document.createRange();
    middleRange.setStartAfter(startParent);
    middleRange.setEndBefore(endParent);

    const treeWalker = document.createTreeWalker(
      commonAncestorContainer,
      NodeFilter.SHOW_ELEMENT,
      null,
    );
    let currentNode = treeWalker.nextNode() as HTMLElement;

    /**
     * Running through all intermediate blocks.
     * Since they are intermediate, the whole block has to be selected.
     * Therefore, from index is 0 and to index is the block's content length itself.
     */
    while (currentNode !== null) {
      if (
        middleRange.intersectsNode(currentNode) &&
        currentNode.id &&
        !currentNode.id.includes(limitElementId)
      ) {
        // Then we know the node is a block and is included in selection
        addRangeForBlock(currentNode, 0, currentNode.textContent.length);
      }
      currentNode = treeWalker.nextNode() as HTMLElement;
    }
  }

  return ranges;
}

/**
 * Applies highlight to the selection ranges.
 * Might have to change it later on once we implement
 * the actual highlighting feature.
 *
 * @param ranges - Map of blocks to highlight.
 * @param color - Color of the blocks to highlight.
 * @returns Created span elements.
 */
export function highlightByRanges(ranges: HTMLBlocksSelection, color: string) {
  const createdSpans: HTMLSpanElement[] = [];

  Object.keys(ranges).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(ranges, key)) {
      const block = document.getElementById(key);

      if (block) {
        const { from, to } = ranges[key];
        createdSpans.push(...highlightBlock(block, from, to, color));
      }
    }
  });

  return createdSpans;
}
