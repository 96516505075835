import {
  memo,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { type To } from 'react-router-dom';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { Link } from '../../link/Link';
import { VARIANTS } from '../constants';
import StepperItemContent from './content/StepperItemContent';
import * as styles from './StepperItem.styles';

export type StepperItemProps = {
  index?: number;
  href?: To;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isLast?: boolean;
  isDone?: boolean;
  isCurrent?: boolean;
  isIncomplete?: boolean;
  isDisabled?: boolean;
  variant?: VARIANTS;
  icon?: ReactElement;
  children?: ReactNode;
};

export const StepperItemBase = ({
  index = 0,
  href = undefined,
  onClick = noop,
  isLast = false,
  isDone = false,
  isCurrent = false,
  isIncomplete = false,
  isDisabled = false,
  variant = VARIANTS.VERTICAL,
  icon = undefined,
  children = undefined,
}: StepperItemProps) =>
  href ? (
    <Link
      to={href}
      isDisabled={isDisabled}
      css={(theme) => styles.container(theme, variant)}
      className={cn({ isDisabled })}
    >
      <StepperItemContent
        variant={variant}
        index={index}
        isLast={isLast}
        isDone={isDone}
        isCurrent={isCurrent}
        isIncomplete={isIncomplete}
        isDisabled={isDisabled}
        icon={icon}
      >
        {children}
      </StepperItemContent>
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      css={(theme) => styles.container(theme, variant)}
      className={cn({ isDisabled })}
    >
      <StepperItemContent
        variant={variant}
        index={index}
        isLast={isLast}
        isDone={isDone}
        isCurrent={isCurrent}
        isIncomplete={isIncomplete}
        isDisabled={isDisabled}
        icon={icon}
      >
        {children}
      </StepperItemContent>
    </button>
  );

StepperItemBase.displayName = 'StepperItem';

StepperItemBase.propTypes = {
  /** Use href when changing steps is handled by links (e.g. step in query string). */
  href: linkPathPropTypes,
  /** Use onClick when changing steps is handled by click events (e.g. step in state). */
  onClick: PropTypes.func,
  /** Step number. Starts at 1. */
  index: PropTypes.number.isRequired,
  /** Is last step. */
  isLast: PropTypes.bool,
  /** Step is completed. Replaces number with a checkmark. */
  isDone: PropTypes.bool,
  /** Step is not fully completed. Replaces number with a cross. */
  isIncomplete: PropTypes.bool,
  /** Step is currently active. */
  isCurrent: PropTypes.bool,
  /** Step is disabled. */
  isDisabled: PropTypes.bool,
  /** Stepper variant (vertical/horizontal). */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** Stepper icon. */
  icon: PropTypes.node,
  children: PropTypes.node,
};

export const StepperItem = memo(StepperItemBase);
