import { defineMessages } from 'react-intl';

export default defineMessages({
  PREV_SLIDE: {
    defaultMessage: "Voir l'élément précédent",
  },

  NEXT_SLIDE: {
    defaultMessage: "Voir l'élément suivant",
  },

  SLIDE_X: {
    defaultMessage: "Accéder à l'élément {number, number}",
  },
});
