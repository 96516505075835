import { memo, type ReactNode, type Ref } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { refPropTypes } from '@eversity/ui/utils';

import { useLayerClickAway } from '../../../utils/hooks/useLayerClickAway';
import { useLayerContext } from '../layer/Layer.context';
import * as styles from './LayerOverlay.styles';

export type LayerOverlayProps = {
  isVisible?: boolean;
  isClosing?: boolean;
  hasLayersAbove?: boolean;
  containerRef?: Ref<HTMLDivElement>;
  containerClassName?: string;
  contentContainerClassName?: string;
  children?: ReactNode;
};

export const LayerOverlayBase = ({
  isVisible = false,
  isClosing = false,
  hasLayersAbove = false,
  containerRef = undefined,
  containerClassName = undefined,
  contentContainerClassName = undefined,
  children = undefined,
}: LayerOverlayProps) => {
  const {
    layerZIndex,
    onRequestClose,
    shouldCloseOnClickOverlay,
    preventClosing,
  } = useLayerContext();

  const { triedToClickAway, onClickAway } = useLayerClickAway({
    shouldCloseOnClickOverlay,
    preventClosing,
    onRequestClose,
  });

  return (
    <div
      ref={containerRef}
      className={cn(containerClassName, {
        isOpen: isVisible,
        isClosing,
        triedToClickAway,
      })}
      css={(theme) => styles.layer(theme, hasLayersAbove, layerZIndex)}
    >
      {/**
       * Can't use a button since that would make it worse.
       * Accessibility-wise it shouldn't be a problem to not be focusable
       * because there is always the close button inside the Drawer/Dialog.
       */
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */}
      <div
        /* eslint-enable jsx-a11y/click-events-have-key-events */
        /* eslint-enable jsx-a11y/no-static-element-interactions */
        onClick={onClickAway}
        css={styles.overlay}
      />

      <div
        css={styles.layerContentContainer(hasLayersAbove)}
        className={cn(contentContainerClassName, { triedToClickAway })}
      >
        {children}
      </div>
    </div>
  );
};

LayerOverlayBase.displayName = 'LayerOverlay';

LayerOverlayBase.propTypes = {
  isVisible: PropTypes.bool,
  isClosing: PropTypes.bool,
  hasLayersAbove: PropTypes.bool,
  containerRef: refPropTypes,
  containerClassName: PropTypes.string,
  contentContainerClassName: PropTypes.string,
  children: PropTypes.node,
};

export const LayerOverlay = memo(LayerOverlayBase);
