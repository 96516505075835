import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '../../../utils/style';

export const container = (theme: Theme, shouldDisplayBorder: boolean) => css`
  display: flex;
  align-items: flex-end;
  width: 100%;
  border-bottom: ${shouldDisplayBorder
    ? `1px solid ${theme.colors.gray[100]}`
    : ''};
`;

export const verticalContainer = css`
  display: flex;
  height: 100%;
`;

export const horizontalTab = (
  theme: Theme,
  hasMultipleTabGroups: boolean,
  shouldDisplayBorder: boolean,
) => css`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  border-bottom: ${!shouldDisplayBorder
    ? `1px solid ${theme.colors.gray[100]}`
    : ''};
  width: ${!hasMultipleTabGroups ? '100%' : 'auto'};
  white-space: nowrap;
  list-style-type: none;
`;

export const verticalTab = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  border-right: 1px solid ${theme.colors.gray[100]};

  list-style-type: none;
`;

export const dragStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  cursor: grab;

  padding: 2px;
`;

export const seeMore = (theme: Theme) => css`
  ${removeButtonAppearance};
  display: inline-flex;
  align-items: center;
  padding: 7px 0px 7px 20px;
  position: relative;
  gap: 10px;
  min-width: 96px;

  &:hover {
    color: ${theme.colors.primary[500]};
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: ${theme.colors.gray[100]};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 20px;
    right: 0;
    height: 1px;
    background-color: ${theme.colors.gray[100]};
    transition: background-color 0.3s ease;
  }

  &:hover::after {
    background-color: ${theme.colors.primary[500]};
  }
`;
