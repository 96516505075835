import { defineMessages } from 'react-intl';

export default defineMessages({
  UNSUPPORTED_MEDIA_TYPE: {
    defaultMessage: "Le format de fichier {mimeType} n'est pas accepté.",
  },

  BAD_REQUEST: {
    defaultMessage: 'Fichier invalide.',
  },
});
