import { PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS } from '@eversity/domain/constants';
import {
  type AssignmentSubmission,
  type GetAssignmentSubmissionsQuery,
  type UpdateSubmissionCommentBody,
  type UpdateSubmissionEvaluationBody,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const ASSIGNMENT_SUBMISSIONS_API_URI = '/api/v1/school/assignment-submissions';
const ASSIGNMENT_SUBMISSIONS_FILES_API_URI =
  '/api/v1/file/downloads/assignment-submissions';

const e = encodeURIComponent;

export class AssignmentSubmissionsRepository extends HttpRepository {
  /**
   * Get submissions assigned to a corrector.
   *
   * @param query - Query params.
   * @param query.q - Query text.
   * @param query.limit - Limit number of results.
   * @param query.offset - Number of results to skip.
   * @param query.view - View of submissions to return.
   * @returns Submissions.
   */
  async getSubmissions(query?: GetAssignmentSubmissionsQuery): Promise<{
    total: number;
    items: AssignmentSubmission[];
  }> {
    const { body } = await this.http
      .get(ASSIGNMENT_SUBMISSIONS_API_URI)
      .query(query);

    return body;
  }

  /**
   * Get a submission by its id.
   *
   * @param submissionId - Submission id.
   * @param query - Query.
   * @param query.view - View to return.
   * @returns Submission.
   */
  async getSubmission(submissionId: string): Promise<AssignmentSubmission> {
    const { body } = await this.http.get(
      `${ASSIGNMENT_SUBMISSIONS_API_URI}/${submissionId}`,
    );

    return body;
  }

  /**
   * Get an assignment submission attachment.
   *
   * @param submissionId - Assignment submission id.
   * @param uploadId - Attachment upload id.
   * @returns File.
   */
  async getAssignmentSubmissionAttachment(
    submissionId: string,
    uploadId: string,
    depositId: string,
  ): Promise<{ type: string; body: Blob }> {
    const { body, type } = await this.http
      .get(
        `${ASSIGNMENT_SUBMISSIONS_FILES_API_URI}/${e(
          submissionId,
        )}/deposits/${e(depositId)}/attachments/${e(uploadId)}`,
      )
      .responseType('blob');

    return { body, type };
  }

  /**
   * Get an assignment submission correction.
   *
   * @param submissionId - Assignment submission id.
   * @param uploadId - Correction upload id.
   * @returns File.
   */
  async getAssignmentSubmissionCorrection(
    submissionId: string,
    uploadId: string,
    depositId: string,
  ): Promise<{ type: string; body: Blob }> {
    const { body, type } = await this.http
      .get(
        `${ASSIGNMENT_SUBMISSIONS_FILES_API_URI}/${e(
          submissionId,
        )}/deposits/${e(depositId)}/correction/attachments/${e(uploadId)}`,
      )
      .responseType('blob');

    return { body, type };
  }

  /**
   * Lock a submission for current user.
   *
   * @param submissionId - Id of the submission to lock.
   * @returns The updated submission.
   */
  async lockSubmission(
    submissionId: string,
    depositId: string,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .patch(
        `${ASSIGNMENT_SUBMISSIONS_API_URI}/${e(submissionId)}/deposits/${e(
          depositId,
        )}`,
      )
      .send({
        action: PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS.LOCK,
      });

    return body;
  }

  /**
   * Send the correction of a submission.
   *
   * @param submissionId - Submission id.
   * @param params - Parameters of the correction.
   * @param params.grade - Grade of the submission.
   * @param params.comment - Comment of the submission.
   * @param params.corrections - Array of upload id.
   * @returns The updated submission.
   */
  async markSubmission(
    submissionId: string,
    depositId: string,
    params: UpdateSubmissionEvaluationBody,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .patch(
        `${ASSIGNMENT_SUBMISSIONS_API_URI}/${e(submissionId)}/deposits/${e(
          depositId,
        )}`,
      )
      .send({
        action: PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS.EVALUATION,
        ...params,
      });

    return body;
  }

  /**
   * Send a comment on a submission.
   *
   * @param submissionId - Submission id.
   * @param depositId - Deposit id.
   * @param params - Parameters of the comment.
   * @param params.comment - Comment of the submission.
   * @param params.reason - Reason of the comment.
   * @param params.attachments - Array of upload id.
   * @returns - The updated submission.
   */
  async commentSubmission(
    submissionId: string,
    depositId: string,
    params: UpdateSubmissionCommentBody,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .patch(
        `${ASSIGNMENT_SUBMISSIONS_API_URI}/${e(submissionId)}/deposits/${e(
          depositId,
        )}`,
      )
      .send({
        action: PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS.COMMENT,
        ...params,
      });

    return body;
  }

  /**
   * Unlock the submission.
   *
   * @param submissionId - Submission id.
   * @param depositId - Deposit id.
   * @returns The updated submission.
   */
  async unlockSubmission(
    submissionId: string,
    depositId: string,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .patch(
        `${ASSIGNMENT_SUBMISSIONS_API_URI}/${e(submissionId)}/deposits/${e(
          depositId,
        )}`,
      )
      .send({
        action: PATCH_ASSIGNMENT_SUBMISSIONS_ACTIONS.UNLOCK,
      });

    return body;
  }
}
