import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

export const useDebouncedState = <T = string>(
  value: T,
  onChange: (newValue: T) => void,
  timeoutMs: number = 250,
): [debouncedState: T, onChangeDebouncedState: Dispatch<SetStateAction<T>>] => {
  const [debouncedState, onChangeDebouncedState] = useState<T>(value);

  // Sync state with context search text and debounce onChange to throttle API calls.
  useEffect(() => {
    onChangeDebouncedState(value);
  }, [value]);

  useDebounce(
    () => {
      onChange(debouncedState);
    },
    timeoutMs,
    [debouncedState, onChange],
  );

  return [debouncedState, onChangeDebouncedState];
};
