import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type ReactNode,
} from 'react';
import PropTypes from 'prop-types';

import { AudioPlayer } from '../../../audio-player/AudioPlayer';

export type AudioProps = Omit<HTMLProps<HTMLElement>, 'ref'> & {
  href: string;
  caption?: ReactNode;
};

export const AudioBase = forwardRef(
  ({ href, caption, ...props }: AudioProps, ref: ForwardedRef<HTMLElement>) => (
    <figure
      {...props}
      ref={ref}
    >
      <AudioPlayer src={href} />

      {!!caption && <figcaption>{caption}</figcaption>}
    </figure>
  ),
);

AudioBase.displayName = 'Audio';

AudioBase.propTypes = {
  href: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

AudioBase.defaultProps = {
  caption: null,
};

export const Audio = memo(AudioBase);
