import { type Element as HastElement } from 'hast';
import { last } from 'lodash';

import { type ContentOutlineElement } from '@eversity/types/domain';

import { transformHtmlToHast } from './parse';
import { type SanitizeSchema } from './schemas';
import { getHastPlainText } from './text';

const headingRegExp = /^h([1-9])$/;

/**
 * Find the target parent node where to push the current node.
 * E.g. for an h3 node, the target parent node is the last h2 in the outline tree.
 *
 * @param tree - Tree node.
 * @param headingLevel - Current node heading level.
 * @returns Target parent node, or null if no parent found.
 */
const findTargetParentNode = (
  tree: ContentOutlineElement,
  headingLevel: number,
): ContentOutlineElement => {
  if (!tree) {
    return null;
  }

  if (tree.level === headingLevel - 1) {
    return tree;
  }

  return findTargetParentNode(last(tree.children), headingLevel);
};

/**
 * Parse the content and return the requested hierarchy of headings.
 *
 * @param content - HTML string.
 * @param options - Options.
 * @param options.schema - Sanitize schema.
 * @param options.minHeadingLevel - Lowest heading level to add in outline.
 * @param options.maxHeadingLevel - Highest heading level to add in outline.
 * @returns Outline.
 */
export const getContentOutline = (
  html: string,
  {
    schema,
    minHeadingLevel,
    maxHeadingLevel,
  }: {
    schema: SanitizeSchema;
    minHeadingLevel: number;
    maxHeadingLevel: number;
  },
): ContentOutlineElement[] => {
  const body = transformHtmlToHast(html, {
    sanitizeSchema: schema,
    // Prevent empty title nodes from being removed.
    minify: false,
  });

  return body.children.reduce((outline, element: HastElement) => {
    // Get heading level from tagName.
    const [, match] = element.tagName.match(headingRegExp) || [];
    const headingLevel = match && parseInt(match, 10);

    // Ignore non-heading nodes and headings not in the requested range.
    if (
      headingLevel === undefined ||
      headingLevel < minHeadingLevel ||
      headingLevel > maxHeadingLevel
    ) {
      return outline;
    }

    // Find the target parent node where to push the current heading.
    // If there is a gap in the headings (aka h4 -> h6 with no h5), the current heading will not
    // be added to the outline (neither will all subsequent headings).
    const targetParentNode = findTargetParentNode(
      {
        title: '',
        slug: '',
        // Start at minHeadingLevel - 1 so the top heading node is added to the outline root.
        level: minHeadingLevel - 1,
        children: outline,
      },
      headingLevel,
    );

    // Push the current heading into the children of the parent node found above.
    if (targetParentNode) {
      targetParentNode.children.push({
        level: headingLevel,
        title: getHastPlainText(element),
        slug: element.properties.id as string,
        children: [],
      });
    }

    return outline;
  }, [] as ContentOutlineElement[]);
};
