import PropTypes from 'prop-types';

/** Generic shape for object title/code. */
export const schoolObjectBasePropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Human label. */
  title: PropTypes.string.isRequired,
  /** Reference code. */
  code: PropTypes.string.isRequired,
});

/** Shape for HTML content outline. Recursive. */
const contentOutlineElement = {
  /** Heading level. E.g. h1 has a level of 1. */
  level: PropTypes.number,
  /** Text content of the heading. */
  title: PropTypes.node.isRequired,
  /** Slug (node id) of the heading. */
  slug: PropTypes.string,
  children: undefined,
};

/** Children are contentOutlineElement shapes. */
contentOutlineElement.children = PropTypes.arrayOf(
  PropTypes.shape(contentOutlineElement),
).isRequired;

export const contentOutlinePropTypes = PropTypes.arrayOf(
  PropTypes.shape(contentOutlineElement),
);
