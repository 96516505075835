import { type ComponentProps, memo } from 'react';
import { css } from '@emotion/react';
import { Widget } from '@typeform/embed-react';
import { noop } from 'lodash';

import { type FeedbackCampaign } from '@eversity/types/domain';
import { Dialog, type DialogProps } from '@eversity/ui/design-system';
import { useBoolState } from '@eversity/ui/utils';

import config from '../../../../../config/env';
import { FeedbackTypeformDialogIntro } from './intro/FeedbackTypeformDialogIntro';

export type FeedbackTypeformDialogProps = Pick<
  DialogProps,
  'isOpen' | 'onRequestClose'
> &
  Pick<ComponentProps<typeof Widget>, 'onSubmit' | 'onClose'> & {
    feedbackCampaign?: FeedbackCampaign;
    onAskLater?: () => void;
    onNeverAskAgain?: () => void;
    showIntro?: boolean;
  };

const widgetStyle = {
  width: '100%',
  height: '100%',
};

const iframeProps = {
  // Display block otherwise it overflows in the dialog.
  style: 'display: block;',
};

export const FeedbackTypeformDialogBase = ({
  isOpen = false,
  onRequestClose = noop,
  feedbackCampaign,
  onSubmit = noop,
  onClose = noop,
  onAskLater = noop,
  onNeverAskAgain = null,
  showIntro = false,
}: FeedbackTypeformDialogProps) => {
  const [shouldShowTypeform, onShowTypeform] = useBoolState(!showIntro);

  return (
    <Dialog
      size={Dialog.SIZES.VERY_LARGE}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnClickOverlay
      shouldCloseOnEscape
      outlineCloseButton
      closeButtonPosition={Dialog.CLOSE_BUTTON_POSITIONS.ABSOLUTE}
    >
      {shouldShowTypeform ? (
        <div
          css={css`
            height: 600px;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            `}
          >
            <Widget
              id={feedbackCampaign.campaignId}
              style={widgetStyle}
              iframeProps={iframeProps}
              hidden={feedbackCampaign.hiddenFields}
              onSubmit={onSubmit}
              onClose={onClose}
              enableSandbox={config.app.env === 'test'}
            />
          </div>
        </div>
      ) : (
        <FeedbackTypeformDialogIntro
          onShowTypeform={onShowTypeform}
          onAskLater={onAskLater}
          onNeverAskAgain={onNeverAskAgain}
        />
      )}
    </Dialog>
  );
};

export const FeedbackTypeformDialog = memo(FeedbackTypeformDialogBase);
