import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  memo,
  type MouseEventHandler,
} from 'react';
import { css } from '@emotion/react';
import { Close } from '@icon-park/react';
import { noop } from 'lodash';

import { Button } from '../../../general/button/Button';
import { Input, type InputProps } from '../../input/Input';

const INPUT_BUTTON_SIZES_MAPPING = {
  [Input.SIZES.LARGE]: Button.SIZES.MEDIUM,
  [Input.SIZES.MEDIUM]: Button.SIZES.SMALL,
  [Input.SIZES.SMALL]: Button.SIZES.SMALL,
};

export type CustomInputProps = Partial<
  Omit<InputProps, 'size' | 'onChange'>
> & {
  inputSize?: InputProps['size'];
  hasValue?: boolean;
  isClearable?: boolean;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CustomInputBase = forwardRef(
  (
    {
      onClear = noop,
      hasValue = false,
      inputSize = Input.SIZES.MEDIUM,
      isClearable = false,
      onChange = noop,
      ...props
    }: CustomInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const onChangeProxy = (
      value: string,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      onChange(event);
    };
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <Input
          size={inputSize}
          ref={ref}
          {...props}
          onChange={onChangeProxy}
        />
        {isClearable && hasValue && (
          <Button
            icon={<Close />}
            onClick={onClear}
            size={INPUT_BUTTON_SIZES_MAPPING[inputSize]}
            css={css`
              position: absolute;
              top: 4px;
              right: ${props.iconRight ? '35px' : '15px'};
            `}
          />
        )}
      </div>
    );
  },
);

CustomInputBase.displayName = 'CustomInput';

export const CustomInput = memo(CustomInputBase);
