import {
  type InternshipContract,
  type InternshipContractBody,
} from '@eversity/types/domain';

import { HttpRepository } from '../../httpRepository';

const e = encodeURIComponent;

const BASE_USERS_URI = '/api/v1/users/users';

export class UserInternshipContractsRepository extends HttpRepository {
  /**
   * Get the list of internship contracts for a course.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @returns List of internship contracts for this course.
   */
  async getInternshipContracts(
    courseId: string,
    classId: string,
  ): Promise<InternshipContract[]> {
    const { body } = await this.http.get(
      `${BASE_USERS_URI}/me/internship-contracts/${e(courseId)}/${e(classId)}`,
    );

    return body;
  }

  /**
   * Create a new internship contract for a course.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param contract - Internship contract to create.
   * @returns Internship contract created.
   */
  async createInternshipContract(
    courseId: string,
    classId: string,
    contract: InternshipContractBody,
  ): Promise<InternshipContract> {
    const { body } = await this.http
      .post(
        `${BASE_USERS_URI}/me/internship-contracts/${e(courseId)}/${e(
          classId,
        )}`,
      )
      .send(contract);

    return body;
  }

  /**
   * Update an internship contract.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param contractId - Contract id.
   * @param contract - Internship contract to update.
   * @returns Updated internship contract.
   */
  async updateInternshipContract(
    courseId: string,
    classId: string,
    contractId: number,
    contract: InternshipContractBody,
  ): Promise<InternshipContract> {
    const { body } = await this.http
      .put(
        `${BASE_USERS_URI}/me/internship-contracts/${e(courseId)}/${e(
          classId,
        )}/${e(contractId)}`,
      )
      .send(contract);

    return body;
  }
}
