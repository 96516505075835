import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, do not retry queries.
      retry: false,
      throwOnError: true,
    },
  },
});
