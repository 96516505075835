import { memo } from 'react';
import {
  components,
  type DropdownIndicatorProps,
  type GroupBase,
} from 'react-select';
import { Down } from '@icon-park/react';

export const DropdownIndicatorBase = <
  TOption,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  props: DropdownIndicatorProps<TOption, TIsMulti, TGroup>,
) => (
  <components.DropdownIndicator {...props}>
    <Down />
  </components.DropdownIndicator>
);

DropdownIndicatorBase.displayName = 'DropdownIndicator';

DropdownIndicatorBase.propTypes = {};

export default memo(DropdownIndicatorBase);
