// We only load the weights / style of fonts that we use in this file.
// Make sure to keep font imports in styles.jsx in sync with typography usage.

import { TYPOGRAPHY_VARIANTS } from '../../config/typography/constants';

const lilyScriptOneFontFamily = 'Lily Script One, cursive';
const nunitoFontFamily = 'Nunito, sans-serif';
const montserratFontFamily = 'Montserrat, sans-serif';
const sourceCodeProFontFamily = "'Source Code Pro', monospace";
const caveatFontFamily = 'Caveat';

export const TYPOGRAPHY = {
  [TYPOGRAPHY_VARIANTS.BRAND]: {
    fontSize: 28,
    lineHeight: '28px',
    fontWeight: 400,
    fontFamily: lilyScriptOneFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.MOBILE_APP_CATCHPHRASE]: {
    fontSize: 22,
    lineHeight: '22px',
    fontWeight: 700,
    fontFamily: caveatFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.HEADING_1]: {
    fontSize: 38,
    lineHeight: '57px',
    fontWeight: 600,
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.HEADING_2]: {
    fontSize: 30,
    lineHeight: '45px',
    fontWeight: 600,
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.HEADING_3]: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 600,
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.HEADING_4]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 600,
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.HEADING_5]: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 700,
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '0.16em',
  },

  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_REGULAR]: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_ITALIC]: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_BOLD]: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_BOLD_ITALIC]: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR]: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_SMALL_ITALIC]: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD]: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD_ITALIC]: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_ITALIC]: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
    fontStyle: 'italic',
    fontFamily: montserratFontFamily,
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD]: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD_ITALIC]: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    fontStyle: 'italic',
    fontFamily: montserratFontFamily,
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_LARGE_REGULAR]: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_LARGE_ITALIC]: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_LARGE_BOLD]: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BODY_LARGE_BOLD_ITALIC]: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: montserratFontFamily,
    fontStyle: 'italic',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_SMALL_REGULAR]: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_SMALL_BOLD]: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_MEDIUM_REGULAR]: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 400,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_MEDIUM_BOLD]: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 700,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_LARGE_REGULAR]: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 400,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.BUTTON_LARGE_BOLD]: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 700,
    letterSpacing: '0.04em',
    fontFamily: nunitoFontFamily,
    fontStyle: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.MONOSPACE_SMALL_REGULAR]: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 500,
    fontFamily: sourceCodeProFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.MONOSPACE_SMALL_BOLD]: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 600,
    fontFamily: sourceCodeProFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_REGULAR]: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    fontFamily: sourceCodeProFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_BOLD]: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 700,
    fontFamily: sourceCodeProFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },

  [TYPOGRAPHY_VARIANTS.PDF_FOOTER]: {
    fontSize: 8,
    lineHeight: '12px',
    fontWeight: 400,
    fontFamily: montserratFontFamily,
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },
};
