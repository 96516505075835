import { useCallback, useMemo } from 'react';
import {
  createFilter,
  type GroupBase,
  type Props as ReactSelectProps,
} from 'react-select';
import { useTheme } from '@emotion/react';

import { type SelectOption } from '@eversity/types/web';
import { useMemoizedBundle } from '@eversity/ui/utils';

import { useLayerContext } from '../../../general/layer/Layer.context';
import ClearIndicator from '../components/ClearIndicator';
import DropdownIndicator from '../components/DropdownIndicator';
import MultiValueRemove from '../components/MultiValueRemove';
import { type SELECT_SIZES } from '../constants';
import { makeCustomStyles, makeCustomTheme } from '../Select.styles';
import { orderValues } from './orderValues';
import {
  type UseReactSelectValue,
  useReactSelectValue,
} from './useReactSelectValueWrapper';

const customComponents = {
  DropdownIndicator,
  ClearIndicator,
  MultiValueRemove,
};

export const useReactSelectCustomization = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
  TUseOptionAsValue extends boolean,
>({
  value,
  onChange,
  isMulti,
  options,
  useOptionAsValue,
  hasError,
  hasWarning,
  size,
  filterOption,
  menuPortalTarget,
}: {
  isMulti?: TIsMulti;
  useOptionAsValue?: TUseOptionAsValue;
  value?: Parameters<
    typeof useReactSelectValue<TOption, TGroup, TIsMulti, TUseOptionAsValue>
  >[0]['value'];
  onChange?: Parameters<
    typeof useReactSelectValue<TOption, TGroup, TIsMulti, TUseOptionAsValue>
  >[0]['onChange'];
  options?: Parameters<
    typeof useReactSelectValue<TOption, TGroup, TIsMulti, TUseOptionAsValue>
  >[0]['options'];
  hasError?: boolean;
  hasWarning?: boolean;
  size?: SELECT_SIZES;
  filterOption?: ReactSelectProps<TOption, TIsMulti, TGroup>['filterOption'];
  menuPortalTarget?: HTMLElement;
}) => {
  const theme = useTheme();

  const { isInLayer, layerZIndex } = useLayerContext();

  const [reactSelectValue, reactSelectOnChange] = useReactSelectValue<
    TOption,
    TGroup,
    TIsMulti,
    TUseOptionAsValue
  >(
    {
      value,
      isMulti,
      options,
      onChange,
    },
    useOptionAsValue,
  );

  const customTheme = useMemo(
    () =>
      makeCustomTheme({
        theme,
        hasError,
        hasWarning,
        size,
      }),
    [theme, size, hasError, hasWarning],
  );

  const customStylesProps = useMemoizedBundle({
    theme,
    size,
    hasError,
    hasWarning,
    isInLayer,
    layerZIndex,
  });

  const customStyles = useMemo(
    () => makeCustomStyles<TOption, TIsMulti, TGroup>(customStylesProps),
    [customStylesProps],
  );

  // Override option rendering.
  // For isMulti values, it is possible to render a Tag in the option and a simplified version in
  // the select value.
  const formatOptionLabel = useCallback<
    ReactSelectProps<TOption, TIsMulti, TGroup>['formatOptionLabel']
  >(
    (option, { context }) => {
      switch (context) {
        case 'value':
          return isMulti
            ? option.multiValueLabel || option.label
            : option.singleValueLabel || option.label;
        case 'menu':
        default:
          return option.label;
      }
    },
    [isMulti],
  );

  // Override the default filterOption if it's not specified.
  // This allows us to declare options as { value, label, stringifiedLabel }.
  // This way, we can use react-select's default filtering when labels are React nodes.
  const filterOptionOverride = useMemo(
    () =>
      filterOption ||
      createFilter<TOption>({
        stringify: ({ data: option }) =>
          `${option.stringifiedLabel || option.label || ''} ${option.value}`,
      }),
    [filterOption],
  );

  // Override onChange to handle fixed values.
  // Taken from https://react-select.com/home#fixed-options
  const onChangeMulti = useCallback<
    ReactSelectProps<TOption, TIsMulti, TGroup>['onChange']
  >(
    (newValue, actionMeta) => {
      let nextValue = newValue as TOption[];

      switch (actionMeta.action) {
        // If trying to remove a non clearable value, ignore action.
        case 'remove-value':
        case 'pop-value':
          if (actionMeta.removedValue?.isFixed) {
            return;
          }
          break;

        // When clearing all values, keep fixed values.
        case 'clear':
          nextValue = actionMeta.removedValues?.filter(
            (option) => option.isFixed,
          );
          break;

        // By default ignore.
        default:
          break;
      }

      nextValue = orderValues<TOption>(nextValue);
      (reactSelectOnChange as UseReactSelectValue<TOption, true, TGroup>[1])(
        nextValue,
        actionMeta,
      );
    },
    [reactSelectOnChange],
  );

  return {
    value: reactSelectValue,
    onChange: reactSelectOnChange,
    onChangeMulti,
    filterOption: filterOptionOverride,
    formatOptionLabel,
    theme: customTheme,
    styles: customStyles,
    components: customComponents,
    menuPortalTarget:
      menuPortalTarget ?? (isInLayer ? document.body : undefined),
  };
};
