import {
  cloneElement,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
  useCallback,
} from 'react';
import { useTheme } from '@emotion/react';
import { Close } from '@icon-park/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Typography } from '../typography/Typography';
import { TAG_HUES, TAG_SIZES, TAG_VARIANTS } from './constants';
import * as styles from './Tag.styles';

export type TagProps = Omit<
  HTMLProps<HTMLDivElement>,
  'ref' | 'children' | 'size' | 'label' | 'value'
> & {
  label?: ReactNode;
  icon?: ReactElement;
  variant?: TAG_VARIANTS;
  hue?: TAG_HUES;
  size?: TAG_SIZES;
  light?: boolean;
  value?: any;
  withShadow?: boolean;
  onRemove?: (value: any) => void;
};

export const TagBase = ({
  label = undefined,
  icon = undefined,
  variant = TAG_VARIANTS.PRIMARY,
  hue = undefined,
  size = TAG_SIZES.MEDIUM,
  light = false,
  value = undefined,
  withShadow = false,
  onRemove = undefined,
  className = undefined,
  ...props
}: TagProps) => {
  const theme = useTheme();

  const onRemoveProxy = useCallback(() => onRemove(value), [onRemove, value]);

  return (
    <div
      css={styles.tag}
      className={cn(className, hue || variant, { light, withShadow })}
      {...props}
    >
      {!!icon &&
        cloneElement(icon, {
          style: { marginRight: 8 },
          size: 16,
          fill: styles.getIconFill[hue || variant](theme, light),
        })}

      <Typography
        variant={
          size === TAG_SIZES.SMALL
            ? Typography.VARIANTS.BUTTON_SMALL_BOLD
            : Typography.VARIANTS.BUTTON_MEDIUM_BOLD
        }
      >
        {label}
      </Typography>

      {!!onRemove && (
        <button
          type="button"
          css={styles.removeButton}
          onClick={onRemoveProxy}
        >
          <Close size="12" />
        </button>
      )}
    </div>
  );
};

TagBase.displayName = 'Tag';

TagBase.propTypes = {
  /** Tag label. */
  label: PropTypes.node,
  /** Icon (left side of the text) from IconPark. */
  icon: PropTypes.element,
  /** Tag color variant. */
  variant: PropTypes.oneOf(Object.values(TAG_VARIANTS)),
  /** Tag color variant independent from theme. Do not use variant and hue at the same time. */
  hue: PropTypes.oneOf(Object.values(TAG_HUES)),
  /** Tag size variant. */
  size: PropTypes.oneOf(Object.values(TAG_SIZES)),
  /** Light variant of the tag (lighter background color with dark text). */
  light: PropTypes.bool,
  // Value does not matter, only passed to onRemove.
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  /** Show a shadow. */
  withShadow: PropTypes.bool,
  /** Tag deletion function. */
  onRemove: PropTypes.func,
  /** Style applied to the component. */
  className: PropTypes.string,
};

export const Tag: MemoExoticComponent<typeof TagBase> & {
  VARIANTS?: typeof TAG_VARIANTS;
} & { HUES?: typeof TAG_HUES } & { SIZES?: typeof TAG_SIZES } & {
  VARIANT_COLOR_GROUP_MAPPING?: typeof styles.VARIANT_COLOR_GROUP_MAPPING;
} = memo(TagBase);

Tag.VARIANTS = TAG_VARIANTS;
Tag.HUES = TAG_HUES;
Tag.VARIANT_COLOR_GROUP_MAPPING = styles.VARIANT_COLOR_GROUP_MAPPING;
Tag.SIZES = TAG_SIZES;
