import { type ForwardedRef, forwardRef, memo } from 'react';
import { useIntl } from 'react-intl';
import {
  type CloseButton,
  type IconProps,
  type TypeOptions,
} from 'react-toastify';
import { css } from '@emotion/react';
import { Close } from '@icon-park/react';

import { Button } from '../../../general/button/Button';
import { type BUTTON_VARIANTS } from '../../../general/button/constants';
import messages from './ToastCloseButton.messages';

const TOAST_TYPE_VARIANT_MAPPING: Record<TypeOptions, BUTTON_VARIANTS> = {
  default: Button.VARIANTS.NEUTRAL,
  info: Button.VARIANTS.PRIMARY,
  error: Button.VARIANTS.DANGER,
  warning: Button.VARIANTS.WARNING,
  success: Button.VARIANTS.SUCCESS,
};

export type CloseButtonProps = IconProps & {
  closeToast: Parameters<typeof CloseButton>[0]['closeToast'];
};

export const ToastCloseButtonBase = forwardRef(
  (
    { closeToast, type }: CloseButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        size={Button.SIZES.SMALL}
        variant={TOAST_TYPE_VARIANT_MAPPING[type]}
        icon={<Close />}
        onClick={closeToast}
        aria-label={intl.formatMessage(messages.CLOSE_TOAST)}
        css={css`
          align-self: flex-start;
        `}
      />
    );
  },
);

ToastCloseButtonBase.displayName = 'ToastCloseButton';

ToastCloseButtonBase.defaultProps = {};

export const ToastCloseButton = memo(ToastCloseButtonBase);
