import { useMutation } from '@tanstack/react-query';

import { TrackingEventsRepository } from '@eversity/domain/data/repositories';
import { type UseMutationParams } from '@eversity/types/web';

import http from '../../../services/http/api';

const trackingEventsRepository = new TrackingEventsRepository(http);

export const useTrackingEvent = (
  useMutationParams?: UseMutationParams<
    Awaited<ReturnType<TrackingEventsRepository['addTrackingEvent']>>,
    Parameters<TrackingEventsRepository['addTrackingEvent']>[0]
  >,
) =>
  useMutation({
    mutationFn: (params) => trackingEventsRepository.addTrackingEvent(params),
    ...useMutationParams,
  });
