import { DRAWER_LAYER_TYPE } from '../../components/general/drawer/constants';
import { type ILayer } from '../../components/meta/layers-provider/LayersContext';
import { useLayer } from './useLayer';

export interface DrawerLayer extends ILayer {
  type: typeof DRAWER_LAYER_TYPE;
}

export const useDrawer = ({
  isOpen,
  shouldCloseOnEscape,
  onRequestClose,
}: {
  isOpen: boolean;
  shouldCloseOnEscape?: boolean;
  onRequestClose?: () => void;
}) =>
  useLayer<DrawerLayer>({
    isOpen,
    shouldCloseOnEscape,
    onRequestClose,
    type: DRAWER_LAYER_TYPE,
  });
