import { css, type Theme } from '@emotion/react';

export const studentInfo = (theme: Theme) => css`
  padding: 20px;

  background-color: ${theme.colors.primary[15]};

  border-bottom: 1px solid ${theme.colors.gray[100]};
  border-top: 1px solid ${theme.colors.gray[100]};

  @media print {
    background-color: transparent;
  }
`;

export const studentCardBody = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  row-gap: 14px;

  padding: 10px;
`;

export const card = css`
  margin-top: 10px;
`;

export const cardItem = css`
  display: flex;
  flex-direction: column;
`;
