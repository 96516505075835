import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { css, useTheme } from '@emotion/react';
import { FileFailed, LoadingOne } from '@icon-park/react';

import { type FileUploadingShape } from '@eversity/types/web';

import { TYPOGRAPHY_VARIANTS } from '../../../../config/typography/constants';
import { fileUploadingPropTypes } from '../../../../types';
import { LinearProgress } from '../../../data-display/linear-progress/LinearProgress';
import { ICON_SIZES } from '../../../general/icon/constants';
import { Typography } from '../../../general/typography/Typography';
import messages from './FileUploading.messages';

export type FileUploadingProps = {
  fileUploading: FileUploadingShape;
};

export const FileUploadingBase = ({
  fileUploading: { file, error, progress },
}: FileUploadingProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 10px;

        border-radius: 4px;
        border: 1px solid ${theme.colors.gray[200]};
        background-color: ${theme.colors.gray[0]};
      `}
    >
      {!error ? (
        <LoadingOne
          size={ICON_SIZES.LARGE}
          fill={[theme.colors.gray[500], theme.colors.gray[25]]}
          spin
        />
      ) : (
        <FileFailed
          size={ICON_SIZES.LARGE}
          fill={[theme.colors.gray[500], theme.colors.gray[25]]}
        />
      )}

      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow-wrap: anywhere;
        `}
      >
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
          {file.name}
        </Typography>

        {error ? (
          <Typography
            variant={Typography.VARIANTS.BODY_SMALL_BOLD}
            css={css`
              color: ${theme.colors.danger[500]};
            `}
          >
            {error.more?.type && messages[error.more.type] ? (
              <FormattedMessage
                {...messages[error.more.type]}
                values={error.more.args}
              />
            ) : (
              error.message
            )}
          </Typography>
        ) : (
          <div
            css={css`
              height: ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD]
                .lineHeight};
              display: flex;
              align-items: center;
              width: 100%;
            `}
          >
            <LinearProgress progress={progress} />
          </div>
        )}
      </div>
    </div>
  );
};

FileUploadingBase.displayName = 'FileUploading';

FileUploadingBase.propTypes = {
  fileUploading: fileUploadingPropTypes.isRequired,
};

export const FileUploading = memo(FileUploadingBase);
