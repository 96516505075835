import { defineMessages } from 'react-intl';

export default defineMessages({
  STUDENT_INFO: {
    defaultMessage: "Informations de l'étudiant",
  },

  STUDENT_NAME: {
    defaultMessage: "Nom de l'étudiant",
  },

  STUDENT_NUMBER: {
    defaultMessage: "Numéro d'étudiant",
  },

  COURSE: {
    defaultMessage: 'Formation',
  },

  ENROLMENT_DATE: {
    defaultMessage: "Date d'inscription",
  },
});
