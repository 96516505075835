export enum VIRTUAL_CLASSROOM_TYPES {
  THEMATIC = 'THEMATIC',
  METHODOLOGY = 'METHODOLOGY',
  PRACTICE = 'PRACTICE',
  INTENSIVE_REVIEWS = 'INTENSIVE_REVIEWS',
  MOCK_ORAL = 'MOCK_ORAL',
  INTERNSHIP = 'INTERNSHIP',
  ENTERTAINMENT = 'ENTERTAINMENT',
  COACHING = 'COACHING',
  TUTORIAL = 'TUTORIAL',
  EXAM = 'EXAM',
  PRIVATE = 'PRIVATE',
}

export enum VIRTUAL_CLASSROOM_VIEWS {
  MIN = 'MIN',
  FULL = 'FULL',
  STUDENT = 'STUDENT',
}

export const VIRTUAL_CLASSROOM_DURATION_STEP_MINUTES = 15;

export const VIRTUAL_CLASSROOM_TITLE_MAX_LENGTH = 150;

export const VIRTUAL_CLASSROOM_DESCRIPTION_MAX_LENGTH = 500;

export const VIRTUAL_CLASSROOM_TAG_MAX_LENGTH = 25;

export const VIRTUAL_CLASSROOM_EVENT_PREFIX = 'Évènement';

export const VIRTUAL_CLASSROOM_PICTURE_ASPECT_RATIO = 235 / 120;

export enum VIRTUAL_CLASSROOM_PATCH_TYPE {
  REPLAY = 'REPLAY',
  OTHER = 'OTHER',
  CANCEL = 'CANCEL',
}
