import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { COLOR_GROUPS, HUE_GROUPS } from '../../../config/colors/constants';
import { removeButtonAppearance } from '../../../utils/style';
import { TAG_HUES, TAG_VARIANTS } from './constants';

export const VARIANT_COLOR_GROUP_MAPPING = {
  [TAG_VARIANTS.PRIMARY]: COLOR_GROUPS.PRIMARY,
  [TAG_VARIANTS.SECONDARY]: COLOR_GROUPS.SECONDARY,
  [TAG_VARIANTS.TERTIARY]: COLOR_GROUPS.TERTIARY,
  [TAG_VARIANTS.SUCCESS]: COLOR_GROUPS.SUCCESS,
  [TAG_VARIANTS.WARNING]: COLOR_GROUPS.WARNING,
  [TAG_VARIANTS.DANGER]: COLOR_GROUPS.DANGER,
  [TAG_VARIANTS.NEUTRAL]: COLOR_GROUPS.GRAY,
  [TAG_VARIANTS.CLEAR]: COLOR_GROUPS.GRAY,
  [TAG_HUES.BLUE]: HUE_GROUPS.BLUE,
  [TAG_HUES.ORANGE]: HUE_GROUPS.ORANGE,
  [TAG_HUES.PURPLE]: HUE_GROUPS.PURPLE,
  [TAG_HUES.GREEN]: HUE_GROUPS.GREEN,
  [TAG_HUES.YELLOW]: HUE_GROUPS.YELLOW,
  [TAG_HUES.RED]: HUE_GROUPS.RED,
  [TAG_HUES.GRAY]: HUE_GROUPS.GRAY,
};

const LIGHT_TEXT_COLOR_STRENGTH_MAPPING = {
  [TAG_VARIANTS.PRIMARY]: 500,
  [TAG_VARIANTS.SECONDARY]: 500,
  [TAG_VARIANTS.TERTIARY]: 500,
  [TAG_VARIANTS.SUCCESS]: 700,
  [TAG_VARIANTS.WARNING]: 500,
  [TAG_VARIANTS.DANGER]: 500,
  [TAG_VARIANTS.NEUTRAL]: 500,
  [TAG_VARIANTS.CLEAR]: 500,
  [TAG_HUES.BLUE]: 500,
  [TAG_HUES.ORANGE]: 500,
  [TAG_HUES.PURPLE]: 500,
  [TAG_HUES.GREEN]: 700,
  [TAG_HUES.YELLOW]: 500,
  [TAG_HUES.RED]: 500,
  [TAG_HUES.GRAY]: 500,
};

export const tag = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;

  width: max-content;

  padding: 2px 8px;

  border-radius: 8px;

  /* All variants (except neutral) use the same color strengths. */
  ${Object.keys(VARIANT_COLOR_GROUP_MAPPING).map((variant) => {
    const themeVariant =
      theme.colors[VARIANT_COLOR_GROUP_MAPPING[variant]] ||
      theme.hues[VARIANT_COLOR_GROUP_MAPPING[variant]];

    return css`
      &.${variant} {
        background-color: ${themeVariant[400]};

        color: ${theme.contrastColors[
          VARIANT_COLOR_GROUP_MAPPING[variant]
        ][400]};

        &.light {
          background-color: ${themeVariant[25]};

          color: ${themeVariant[LIGHT_TEXT_COLOR_STRENGTH_MAPPING[variant]]};
        }
      }
    `;
  })}

  &.withShadow {
    box-shadow:
      0px 1px 2px
        ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
      0px 0px 5px 1px
        ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};
  }

  &.${TAG_VARIANTS.CLEAR}, &.${TAG_VARIANTS.CLEAR}.light {
    color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[0]};

    border: 1px solid ${theme.colors.gray[100]};
  }
`;

export const removeButton = css`
  ${removeButtonAppearance};

  margin-left: 8px;

  cursor: pointer;
`;

export const getIconFill = Object.keys(VARIANT_COLOR_GROUP_MAPPING).reduce(
  (acc, variant) => {
    acc[variant] = (theme: Theme, light: boolean) => {
      const themeVariant =
        theme.colors[VARIANT_COLOR_GROUP_MAPPING[variant]] ||
        theme.hues[VARIANT_COLOR_GROUP_MAPPING[variant]];

      return light || variant === TAG_VARIANTS.CLEAR
        ? [
            themeVariant[LIGHT_TEXT_COLOR_STRENGTH_MAPPING[variant]],
            themeVariant[50],
          ]
        : [
            theme.contrastColors[VARIANT_COLOR_GROUP_MAPPING[variant]][400],
            themeVariant[400],
          ];
    };

    return acc;
  },
  {},
);
