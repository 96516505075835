import { css } from '@emotion/react';

import { removeButtonAppearance } from '../../../../utils/style';

export default {
  header: css`
    display: flex;
    align-items: center;
    width: 100%;

    &.alignRight {
      justify-content: flex-end;
    }
  `,

  sort: css`
    ${removeButtonAppearance};
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  `,
};
