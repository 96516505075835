import {
  type Element as HastElement,
  type Root as HastRoot,
  type Text as HastText,
} from 'hast';
import hastUtilToString from 'hast-util-to-string';

import { transformHtmlToHast } from './parse';
import { type SanitizeSchema } from './schemas';

export const getHastPlainText: (
  hast: HastRoot | HastElement | HastText,
) => string = hastUtilToString;

/**
 * Get the content title node from its HTML content.
 * This assumes that the content content is valid and there is always a H3 as first element.
 *
 * @param content - Content content as HTML string.
 * @returns The title HAST node.
 */
export const getContentTitleNode = (
  html: string,
  {
    schema,
  }: {
    schema: SanitizeSchema;
  },
): HastRoot | null => {
  // Find the h3 tag in the content.
  const startH3 = html.indexOf('<h3');
  const endH3 = html.indexOf('</h3>');

  if (startH3 < 0 || endH3 < 0) {
    return null;
  }

  // Extract the h3 tag to a string like "<h3>content</h3>".
  const h3Html = html.substring(startH3, endH3 + 5);

  // Parse HTML (sanitize + make sure HTML is valid.).
  const parsed = transformHtmlToHast(h3Html, { sanitizeSchema: schema });

  // Return the parsed hast node.
  return parsed;
};

/**
 * Get the content title from its HTML content.
 * This assumes that the content content is valid and there is always a H3 as first element.
 *
 * @param html - Content as HTML string.
 * @returns The title extracted from the HTML.
 */
export const getContentTitle = (
  html: string,
  {
    schema,
  }: {
    schema: SanitizeSchema;
  },
): string | null => {
  const titleNode = getContentTitleNode(html, { schema });

  // Return the text content of the HTML.
  return titleNode ? getHastPlainText(titleNode) : null;
};

/**
 * Get the content title id from its HTML content.
 * This assumes that the content content is valid and there is always a H3 as first element.
 *
 * @param html - Content as HTML string.
 * @returns Content title id.
 */
export const getContentTitleId = (
  html: string,
  {
    schema,
  }: {
    schema: SanitizeSchema;
  },
): string | undefined => {
  const titleNode = getContentTitleNode(html, { schema });

  // Return the content title id.
  return (titleNode?.children?.[0] as HastElement)?.properties?.id as string;
};
