import dayjs from '@eversity/services/dayjs';

import { type ProductTour } from './types';

/**
 * Checks if the current path matches the tour's path.
 *
 * @param tourPathname - The regular expression for the tour's path.
 * @param currentPathname - The current path being visited.
 * @returns `true` if the current path matches the tour's path, otherwise `false`.
 */
export const isTourActiveForRoute = (
  tourPathname: RegExp,
  currentPathname: string,
): boolean => new RegExp(tourPathname).test(currentPathname);

/**
 * Checks if the tour is within the valid date range.
 *
 * @param tour - The tour object containing the date information.
 * @returns `true` if the current date is between the tour's start and end date, otherwise `false`.
 */
export const isTourWithinDateRange = (tour: ProductTour): boolean =>
  dayjs(tour.startDate).isSameOrBefore(dayjs()) &&
  dayjs(tour.endDate).isAfter(dayjs());

/**
 * Checks if the tour is enabled for mobile devices.
 *
 * @param isMobile - A flag indicating if the user is on a mobile device.
 * @param tour - The tour object containing the mobile compatibility information.
 * @returns `true` if the tour is enabled for mobile devices or if the device is not mobile, otherwise `false`.
 */
export const isTourEnabledForMobileDevice = (
  isMobile: boolean,
  tour: ProductTour,
): boolean => !(isMobile && tour.isMobileDisabled);
