import { css, type Theme } from '@emotion/react';

export const footer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: 40px;
`;

export const coloredTextPrimary = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const overallAverage = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding-top: 10px;

  border-top: 1px solid ${theme.colors.gray[300]};
`;
