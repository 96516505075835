import { memo, type MemoExoticComponent, type ReactNode } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { McqTableOfContentsItem } from './item/McqTableOfContentsItem';

export type McqTableOfContentsProps = {
  children?: ReactNode;
};

export const McqTableOfContentsBase = ({ children = null }) => (
  <ul
    css={css`
      list-style: none;
    `}
  >
    {children}
  </ul>
);

McqTableOfContentsBase.displayName = 'McqTableOfContents';

McqTableOfContentsBase.propTypes = {
  /** `McqTableOfContents.Item` components. */
  children: PropTypes.node,
};

export const McqTableOfContents: MemoExoticComponent<
  typeof McqTableOfContentsBase
> & {
  Item?: typeof McqTableOfContentsItem;
} = memo(McqTableOfContentsBase);

McqTableOfContents.Item = McqTableOfContentsItem;
