import { defineMessages } from 'react-intl';

export const intellectualPropertyMessages = defineMessages({
  LAW_TEXT: {
    defaultMessage:
      "Aux termes du Code de la propriété intellectuelle, toute reproduction ou représentation, intégrale ou partielle de la présente publication, faite par quelque procédé que ce soit (reprographie, microfilmage, scannérisation, numérisation...) sans le consentement de l'auteur ou de ses ayants droit ou ayants cause est illicite et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. L'autorisation d'effectuer des reproductions par reprographie doit être obtenue auprès du Centre Français d'exploitation du droit de Copie (CFC) : 20, rue des Grands-Augustins 75006 PARIS - 01.44.07.47.70.",
  },

  COPYRIGHT: {
    defaultMessage: 'Copyright Enaco',
  },
});
