import { CUSTOM_HTML } from '@eversity/domain/constants';

export const CUSTOM_HTML_ATTRIBUTES: Record<CUSTOM_HTML, readonly string[]> = {
  [CUSTOM_HTML.VIDEO]: ['src', 'caption'],
  [CUSTOM_HTML.CAROUSEL]: ['caption', 'dataSelectedSize'],
  [CUSTOM_HTML.CAROUSEL_SLIDE]: [
    'dataRatio',
    'dataAlt',
    'dataResizedUploadId',
    'dataResizedUploadHref',
    'dataResizedUploadMimeType',
    'dataResizedUploadFileName',
    'dataResizedUploadSize',
    'dataOriginalUploadId',
    'dataOriginalUploadHref',
    'dataOriginalUploadMimeType',
    'dataOriginalUploadFileName',
    'dataOriginalUploadSize',
  ],
  [CUSTOM_HTML.LINKEDIN_EMBEDDED]: ['src', 'title'],
  [CUSTOM_HTML.ATTACHMENT]: [
    'dataUploadId',
    'dataHref',
    'dataFileName',
    'dataSize',
  ],
  [CUSTOM_HTML.AUDIO]: [
    'dataUploadId',
    'dataHref',
    'dataFileName',
    'dataSize',
    'dataCaption',
  ],
} as const;

export const CUSTOM_HTML_ALLOWED_DOMAINS: {
  [key: string]: Record<string, string | string[]>;
} = {
  [CUSTOM_HTML.LINKEDIN_EMBEDDED]: {
    src: 'www.linkedin.com',
  },
} as const;
