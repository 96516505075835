import { type HTMLProps } from 'react';
import { css, useTheme } from '@emotion/react';

export type DotProps = Omit<HTMLProps<HTMLDivElement>, 'ref'>;

export const Dot = (props: DotProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        background-color: ${theme.colors.secondary[500]};
        border: 2px solid ${theme.colors.gray[0]};
        border-radius: 7px;
      `}
      {...props}
    />
  );
};

Dot.displayName = 'Dot';
