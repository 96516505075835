import { defineMessages } from 'react-intl';

export default defineMessages({
  INTERNAL_SERVER_ERROR_TITLE: {
    defaultMessage: "Oups ! Quelque chose s'est mal passé...",
  },

  INTERNAL_SERVER_ERROR_DESCRIPTION: {
    defaultMessage: 'Nous travaillons actuellement pour régler le problème.',
  },

  RETRY: {
    defaultMessage: 'Veuillez réessayer ultérieurement.',
  },
});
