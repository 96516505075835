import { defineMessages } from 'react-intl';

export default defineMessages({
  MARK_AS_READ: {
    defaultMessage: 'Marquer comme lu',
  },

  MARK_AS_UNREAD: {
    defaultMessage: 'Marquer comme non lu',
  },

  GO_TO_LINK: {
    defaultMessage: 'Se rendre à la page concernée',
  },
});
