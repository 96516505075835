import PropTypes from 'prop-types';

import { USER_LESSON_PROGRESS_SEQUENCE_STATUSES } from '@eversity/domain/constants';

export const userLessonProgressSequenceStatusPropTypes = PropTypes.oneOf(
  Object.values(USER_LESSON_PROGRESS_SEQUENCE_STATUSES),
);

export const lessonSequenceProgressPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  sequenceVersionId: PropTypes.string.isRequired,
  status: userLessonProgressSequenceStatusPropTypes.isRequired,
  completionDate: PropTypes.string,
  lastActiveTimestamp: PropTypes.string,
  timeSpent: PropTypes.number,
});

export const userLessonProgressPropTypes = PropTypes.shape({
  id: PropTypes.string,
  course: PropTypes.string.isRequired,
  class: PropTypes.string.isRequired,
  teachingUnit: PropTypes.string.isRequired,
  lesson: PropTypes.string.isRequired,
  lastVisitedSequenceVersionId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  sequences: PropTypes.objectOf(lessonSequenceProgressPropTypes).isRequired,
});
