import { memo, type MemoExoticComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ASSIGNMENT_TAGS } from '@eversity/domain/constants';
import { Tag, type TAG_HUES } from '@eversity/ui/design-system';
import { assignmentTagsMessages } from '@eversity/ui/intl';

const ASSIGNMENT_TAG_COLOR_MAPPING: Record<ASSIGNMENT_TAGS, TAG_HUES> = {
  [ASSIGNMENT_TAGS.CONTINUOUS_ASSESSMENT]: Tag.HUES.RED,
  [ASSIGNMENT_TAGS.PROFESSIONAL_FILE]: Tag.HUES.PURPLE,
};

export type AssignmentTagProps = {
  assignmentTag: ASSIGNMENT_TAGS;
};

export const AssignmentTagBase = ({ assignmentTag }: AssignmentTagProps) => (
  <Tag
    hue={ASSIGNMENT_TAG_COLOR_MAPPING[assignmentTag]}
    label={<FormattedMessage {...assignmentTagsMessages[assignmentTag]} />}
    light
  />
);

AssignmentTagBase.displayName = 'AssignmentTag';

AssignmentTagBase.propTypes = {
  assignmentTag: PropTypes.oneOf(Object.values(ASSIGNMENT_TAGS)).isRequired,
};

export const AssignmentTag: MemoExoticComponent<typeof AssignmentTagBase> & {
  TAG_COLOR_MAPPING?: typeof ASSIGNMENT_TAG_COLOR_MAPPING;
} = memo(AssignmentTagBase);

AssignmentTag.TAG_COLOR_MAPPING = ASSIGNMENT_TAG_COLOR_MAPPING;
