import { css, type Theme } from '@emotion/react';

export const timePicker = (theme: Theme) => css`
  .react-time-picker {
    display: inline-flex;
    position: relative;
    width: 100%;
  }

  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: 4px;
    height: 32px;
  }

  .react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 10px;
    box-sizing: content-box;
  }

  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.5em);
  }

  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 0px;
  }

  .react-time-picker__clear-button {
    padding-right: 4px;
  }

  .react-time-picker__clock-button {
    padding-right: 10px;
  }

  .react-time-picker__button:focus-visible {
    outline: none;
  }

  .react-time-picker__clock {
    margin-top: 10px;
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: 4px;
    z-index: 1;
  }

  .react-time-picker__inputGroup input:focus-visible {
    outline: none;
  }
`;

export const clock = (theme: Theme) => css`
  .react-clock,
  .react-clock *,
  .react-clock *:before,
  .react-clock *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
  }

  .react-clock__hand__body {
    position: absolute;
    background-color: ${theme.colors.primary[500]};
    transform: translateX(-50%);
  }

  .react-clock__mark__body {
    position: absolute;
    background-color: ${theme.colors.primary[500]};
    transform: translateX(-50%);
  }

  .react-clock__second-hand__body {
    background-color: transparent;
  }

  .react-clock__minute-mark__body {
    width: 0px !important;
  }

  .react-clock__minute-hand__body {
    background-color: ${theme.colors.primary[50]};
  }
`;
