import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

export const box = (theme: Theme) => css`
  flex: none;

  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 1rem;

  transition: ${theme.transitions.default()};

  &.isFocused {
    border-color: ${theme.colors.primary[500]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &.isChecked {
    border-color: ${theme.colors.primary[500]};
  }

  &.isDisabled {
    background-color: ${theme.colors.gray[25]};
    border-color: ${theme.colors.gray[200]};
  }
`;

export const checked = (theme: Theme) => css`
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.primary[500]};
  border-radius: 1rem;

  position: absolute;
  left: 3px;
  top: 3px;

  &.isDisabled {
    background-color: ${theme.colors.gray[300]};
  }
`;
