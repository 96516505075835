import { cloneElement, memo, type ReactElement } from 'react';
import { css, useTheme } from '@emotion/react';
import cn from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { Dot } from '../dot/Dot';
import { ICON_SIZES } from '../icon/constants';
import { Typography } from '../typography/Typography';
import { itemStyle } from './Accordion.styles';

export type AccordionItemProps = {
  title: string;
  icon?: ReactElement;
  isSelected?: boolean;
  onClick?: () => void;
  withDot?: boolean;
};

export const AccordionItemBase = ({
  title,
  icon = undefined,
  isSelected = false,
  onClick = noop,
  withDot = false,
}: AccordionItemProps) => {
  const theme = useTheme();

  return (
    <button
      type="button"
      css={itemStyle}
      className={cn(isSelected && 'SELECTED')}
      onClick={onClick}
    >
      {icon && (
        <span className="icon">
          {withDot && <Dot className="dot" />}
          {cloneElement(icon, {
            size: ICON_SIZES.MEDIUM,
            fill: isSelected ? theme.colors.gray[0] : theme.colors.primary[500],
          })}
        </span>
      )}
      <div
        css={css`
          padding: 9px 10px;
        `}
      >
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
          {title}
        </Typography>
      </div>
    </button>
  );
};

AccordionItemBase.displayName = 'AccordionItem';

AccordionItemBase.propTypes = {
  /** Title of the item. */
  title: PropTypes.node.isRequired,
  /** Icon displayed on the left of the item. */
  icon: PropTypes.node,
  /** Define if this item is the one currently selected. */
  isSelected: PropTypes.bool,
  /** Callback triggered on the click of the item. */
  onClick: PropTypes.func,
  /** Add a dot on the top right corner of the left icon. */
  withDot: PropTypes.bool,
};

export const AccordionItem = memo(AccordionItemBase);
