import { css, type Theme } from '@emotion/react';

export const teachingUnitHeader = (theme: Theme) => css`
  padding-bottom: 10px;
  margin-bottom: 10px;

  border-bottom: 1px solid ${theme.colors.gray[100]};

  > * {
    display: flex;
    justify-content: space-between;
  }
`;

export const coefficient = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const assignmentsList = css`
  list-style: none;

  margin: 10px 0;

  &.examList {
    margin: 0 0 10px 0;
  }
`;

export const lessonsList = css`
  list-style: none;
`;

export const lessonTitle = css`
  padding-left: 20px;
`;
