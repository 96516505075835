import { forwardRef, memo } from 'react';
import { type BeaconRenderProps } from 'react-joyride';

import * as styles from './CustomBeacon.styles';

const CustomBeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>(
  (props, ref) => {
    return (
      <span
        css={styles.beacon}
        ref={ref}
        {...props}
      />
    );
  },
);

export const CustomBeacon = memo(CustomBeaconComponent);
