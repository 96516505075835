import { type ValueOf } from 'type-fest';

import { type AI_PROMPT_TYPES } from '@eversity/domain/constants';
import {
  type AiGenerateQuestions,
  type AiInteraction,
  type GetAiInteractionQuery,
  type GetUserAiInteractionsQuery,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const BASE_INTERACTION_URI = '/api/v1/ai/interactions';

export class AiInteractionsRepository extends HttpRepository {
  /**
   * Get all students AI interactions.
   *
   * @param query - Query.
   * @param query.limit - Limit number of results.
   * @param query.offset - Skip results.
   * @param query.startDate - Filter on AI interaction after this date.
   * @param query.endDate - Filter on AI interaction before this date.
   * @param query.types - Filter on AI interaction types.
   * @returns Object with count and AI interactions list.
   */
  async getAiInteractions(
    query?: GetAiInteractionQuery,
  ): Promise<{ count: number; aiInteractions: AiInteraction[] }> {
    const {
      body: { count, aiInteractions },
    } = await this.http.get(BASE_INTERACTION_URI).query(query);

    return {
      count,
      aiInteractions,
    };
  }

  async getUserAiInteractions(
    query?: GetUserAiInteractionsQuery,
  ): Promise<AiInteraction[]> {
    const { body: aiInteractions } = await this.http
      .get('/api/v1/users/users/me/interactions')
      .query(query);

    return aiInteractions;
  }

  /**
   * Ask a question to the AI
   *
   * @param courseId - student course id.
   * @param classId - student class id.
   * @param text - the AI question
   * @returns the AI answer and his AI interaction object
   */
  async askAi(params: {
    courseId: string;
    classId: string;
    text: string;
  }): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/search`)
      .send(params);

    return aiInteraction;
  }

  /**
   *
   * @param interactionId - the AI interaction Id
   * @param isPositive - student feedback on the AI answer
   * @returns AI interaction
   */
  async postAiInteractionfeedback(
    interactionId: string,
    params: { isPositive: boolean },
  ): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/${interactionId}/feedback`)
      .send(params);

    return aiInteraction;
  }

  /**
   * Ask the AI to summarize the sequence
   *
   * @param lessonId - the lesson ID of the sequence.
   * @param editableVersionId - the editable version ID of the sequence.
   * @param sequenceVersionId - the sequence version ID.
   * @returns the AI answer and its AI interaction object.
   */
  async summarizeSequence(params: {
    lessonId: string;
    editableVersionId: string;
    sequenceVersionId: string;
  }): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/summarize-sequence`)
      .send(params);

    return aiInteraction;
  }

  /**
   * Generate questions from sequence content with Vertex AI
   * @param lessonId - Id of the lesson
   * @param editableVersionId - Id of the editable version
   * @param sequenceVersionId - Id of the sequence version
   * @param nbOfQuestions - Nb of questions to ask
   * @returns Array of questions
   */
  async postGenerateQuestionsFromEditableSequence(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
    nbOfQuestions: number,
    type: ValueOf<typeof AI_PROMPT_TYPES>,
  ): Promise<AiGenerateQuestions[]> {
    // Complicated to type AI response correctly
    const { body: generatedQuestions } = await this.http
      .post(`${BASE_INTERACTION_URI}/generate-questions`)
      .send({
        lessonId,
        editableVersionId,
        sequenceVersionId,
        nbOfQuestions,
        type,
      });

    return generatedQuestions;
  }

  /**
   * Ask question to AI about a lesson content
   *
   * @param courseId - student course id.
   * @param classId - student class id.
   * @param question - student question.
   * @param lessonId - the lesson id.
   * @param versionMajor - the major version of the lesson.
   * @param versionMinor - the minor version of the lesson.
   * @returns the AI answer and its AI interaction object.
   */
  async askQuestionAiAboutLessonContent(
    courseId: string,
    classId: string,
    question: string,
    lessonId: string,
    versionMajor: number,
    versionMinor: number,
  ): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/ask-question`)
      .send({
        courseId,
        classId,
        question,
        lessonId,
        versionMajor,
        versionMinor,
      });

    return aiInteraction;
  }
}
