import {
  createContext,
  Fragment,
  memo,
  type ReactEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useBoolState, useMemoizedBundle } from '@eversity/ui/utils';

export type LessonAudioContextValue = {
  isAudioActivated: boolean;
  isAudioPlaying: boolean;
  isAudioEnabled: boolean;
  activeAudioBlockId: string | null;
  onAudioPreviousBlock: () => void;
  onAudioNextBlock: () => void;
  onAudioTogglePlayback: () => void;
  onAudioStop: () => void;
  isAudioPreviousDisabled: boolean;
  isAudioNextDisabled: boolean;
};

export const LessonAudioContext = createContext<LessonAudioContextValue>({
  isAudioActivated: false,
  isAudioPlaying: false,
  isAudioEnabled: false,
  activeAudioBlockId: null,
  onAudioPreviousBlock: noop,
  onAudioNextBlock: noop,
  onAudioTogglePlayback: noop,
  onAudioStop: noop,
  isAudioPreviousDisabled: false,
  isAudioNextDisabled: false,
});

export type LessonAudioProgressContextValue = {
  timePositionSeconds: number;
  durationSeconds?: number;
  timePositionRatio: number;
};

export const LessonAudioProgressContext =
  createContext<LessonAudioProgressContextValue>({
    timePositionSeconds: 0,
    durationSeconds: 0,
    timePositionRatio: 0,
  });

// After 1s into the block audio file, clicking on "previous" will reset the current block audio
// position to 0 instead of going to the previous block.
const RESET_MINIMUM_DURATION_SECONDS = 1;

const DEFAULT_BLOCK_IDS: string[] = [];
const DEFAULT_AUDIO_MAP: Record<string, { href: string }> = {};

export type LessonAudioContextProviderProps = {
  sequenceVersionId?: string;
  blockIds?: string[];
  audioMap?: Record<string, { href?: string }>;
  children?: ReactNode;
};

export const LessonAudioContextProviderBase = ({
  sequenceVersionId = null,
  blockIds = DEFAULT_BLOCK_IDS,
  audioMap = DEFAULT_AUDIO_MAP,
  children = null,
}) => {
  const audioRef = useRef<HTMLAudioElement>();

  // Has the audio been started on this sequence.
  // This is used to display the playing indicator next to each block.
  const [isAudioActivated, onActivateAudio, onDeactivateAudio] =
    useBoolState(false);

  // Current playback state.
  const [isAudioPlaying, onAudioPlaying, onAudioPaused] = useBoolState(false);
  // Id of the block being played.
  const [activeAudioBlockId, onSetActiveAudioBlockId] = useState<string | null>(
    null,
  );
  // Time position in the current audio file in seconds.
  const [timePositionSeconds, onSetTimePositionSeconds] = useState(0);

  const audioHref = audioMap[activeAudioBlockId]?.href;

  // Only keep block ids that have a audio track.
  // This makes it easier to go to the previous/next block with an audio track.
  const availableBlockIds = useMemo(
    () => blockIds.filter((blockId) => audioMap[blockId]?.href),
    [blockIds, audioMap],
  );

  const activeAudioBlockIndex = availableBlockIds.indexOf(activeAudioBlockId);

  const onAudioPreviousBlock = useCallback(() => {
    const currentTimePosition = audioRef.current?.currentTime || 0;

    if (currentTimePosition > RESET_MINIMUM_DURATION_SECONDS) {
      audioRef.current!.currentTime = 0;
    } else {
      onSetActiveAudioBlockId((currentActiveBlockId) => {
        const index = availableBlockIds.indexOf(currentActiveBlockId);
        return availableBlockIds[index - 1] || null;
      });
    }
  }, [availableBlockIds]);

  const onAudioNextBlock = useCallback(() => {
    onSetActiveAudioBlockId((currentActiveBlockId) => {
      const index = availableBlockIds.indexOf(currentActiveBlockId);
      return availableBlockIds[index + 1] || null;
    });
  }, [availableBlockIds]);

  const onAudioTogglePlayback = useCallback(() => {
    // Activate audio feature (show playback icon on each block etc.).
    onActivateAudio();

    // If there is an active audio block, play or pause.
    if (activeAudioBlockId) {
      if (isAudioPlaying) {
        audioRef.current?.pause();
      } else {
        audioRef.current?.play();
      }
    } else {
      // If no active audio block, read the first block with audio.
      onAudioNextBlock();
    }
  }, [activeAudioBlockId, isAudioPlaying, onAudioNextBlock, onActivateAudio]);

  // When the audio changes, reset all states.
  const onAudioStop = useCallback(() => {
    onSetActiveAudioBlockId(null);
    onAudioPaused();
    onDeactivateAudio();
    onSetTimePositionSeconds(0);
  }, [onDeactivateAudio, onAudioPaused]);

  const onTimeUpdate: ReactEventHandler<HTMLAudioElement> = useCallback(
    (event) =>
      onSetTimePositionSeconds((event.target as HTMLAudioElement).currentTime),
    [],
  );

  // When the sequence changes, reset all states.
  useEffect(() => {
    onAudioStop();
  }, [sequenceVersionId, onAudioStop]);

  useLayoutEffect(() => {
    onSetTimePositionSeconds(0);
  }, [activeAudioBlockId]);

  const contextValue = useMemoizedBundle<LessonAudioContextValue>({
    isAudioActivated,
    isAudioPlaying,
    isAudioEnabled: !!availableBlockIds.length,
    activeAudioBlockId,
    onAudioPreviousBlock,
    onAudioNextBlock,
    onAudioTogglePlayback,
    onAudioStop,
    isAudioPreviousDisabled: !(activeAudioBlockIndex > 0),
    isAudioNextDisabled: !!(
      activeAudioBlockIndex < 0 ||
      activeAudioBlockIndex >= availableBlockIds.length - 1
    ),
  });

  const progressContextValue =
    useMemoizedBundle<LessonAudioProgressContextValue>({
      timePositionSeconds,
      durationSeconds: audioRef.current?.duration,
      // Progress between 0 and 1.
      timePositionRatio: audioRef.current?.duration
        ? timePositionSeconds / audioRef.current.duration
        : 0,
    });

  return (
    <LessonAudioContext.Provider value={contextValue}>
      <LessonAudioProgressContext.Provider value={progressContextValue}>
        <Fragment>
          {!!audioHref && (
            // Can't generate a caption track...
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio
              hidden
              autoPlay
              ref={audioRef}
              key={activeAudioBlockId}
              onPlay={onAudioPlaying}
              onPause={onAudioPaused}
              onEnded={onAudioNextBlock}
              onTimeUpdate={onTimeUpdate}
            >
              <source
                src={audioHref}
                type="audio/mpeg"
              />
            </audio>
          )}

          {children}
        </Fragment>
      </LessonAudioProgressContext.Provider>
    </LessonAudioContext.Provider>
  );
};

LessonAudioContextProviderBase.displayName = 'LessonAudioContextProvider';

LessonAudioContextProviderBase.propTypes = {
  /** Sequence version id. Used to reset states. Cannot use a key or it resets the whole page. */
  sequenceVersionId: PropTypes.string,
  /** List of block ids of the sequence in the order in which they appear. */
  blockIds: PropTypes.arrayOf(PropTypes.string),
  /** Map blockId -> audio url. */
  audioMap: PropTypes.objectOf(
    PropTypes.shape({
      href: PropTypes.string,
    }),
  ),
  /** Context provider descendants. */
  children: PropTypes.node,
};

export const LessonAudioContextProvider = memo(LessonAudioContextProviderBase);
