import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
} from 'react';
import { type To } from 'react-router-dom';
import PropTypes from 'prop-types';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { removeLinkAppearance } from '../../../utils/style';
import { Button, type ButtonProps } from '../../general/button/Button';
import { Link } from '../link/Link';

export type ButtonLinkProps = ButtonProps & {
  to: To;
  isExternal?: boolean;
  target?: string;
};

export const ButtonLinkBase = forwardRef(
  (
    { to, isExternal, target, className, children, ...props }: ButtonLinkProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Link
      to={!isExternal ? to : undefined}
      href={isExternal ? (to as string) : undefined}
      target={target}
      isExternal={isExternal}
      css={removeLinkAppearance}
      isDisabled={props.disabled}
      className={className}
    >
      <Button
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    </Link>
  ),
);

ButtonLinkBase.displayName = 'ButtonLink';

ButtonLinkBase.propTypes = {
  /** Link href. */
  to: linkPathPropTypes.isRequired,
  /** External link. */
  isExternal: PropTypes.bool,
  /** Target for external links (used with "_blank" most of the time). */
  target: PropTypes.string,
  /** CSS className. */
  className: PropTypes.string,
  /** Is the button disabled. */
  disabled: PropTypes.bool,
  /** Button text. */
  children: PropTypes.node,
};

ButtonLinkBase.defaultProps = {
  isExternal: false,
  target: undefined,
  className: null,
  disabled: false,
  children: null,
};

export const ButtonLink: MemoExoticComponent<typeof ButtonLinkBase> & {
  VARIANTS?: typeof Button.VARIANTS;
  HUES?: typeof Button.HUES;
  SIZES?: typeof Button.SIZES;
} = memo(ButtonLinkBase);

ButtonLink.VARIANTS = Button.VARIANTS;
ButtonLink.HUES = Button.HUES;
ButtonLink.SIZES = Button.SIZES;
