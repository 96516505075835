import { TinyColor } from '@ctrl/tinycolor';
import { css, keyframes, type Theme } from '@emotion/react';

import { DIALOG_SIZES } from './constants';

const dialogEntrance = keyframes`
  0% {
    opacity: 0;
    top: -5vh;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
`;

const dialogExit = keyframes`
  0% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: 5vh;
  }
`;

// From animate.css
// https://github.com/animate-css/animate.css/blob/main/source/attention_seekers/headShake.css
const headShake = keyframes`
  0% {
    transform: translateX(0);
  }

  13% {
    transform: translateX(-12px) rotateY(-9deg);
  }

  37% {
    transform: translateX(10px) rotateY(7deg);
  }

  63% {
    transform: translateX(-6px) rotateY(-5deg);
  }

  87% {
    transform: translateX(4px) rotateY(3deg);
  }

  100% {
    transform: translateX(0);
  }
`;

export const dialogStyle = (theme: Theme) => css`
  animation-fill-mode: forwards;
  animation-duration: ${theme.transitions.default.duration}ms;
  animation-timing-function: ${theme.transitions.default.easing};

  margin: auto;

  position: relative;

  overflow: auto;

  border-radius: 8px;

  border: none;

  box-shadow:
    0px 2px 4px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
    0px 0px 11px 1px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

  &.isOpen {
    animation-name: ${dialogEntrance};
  }

  &.triedToClickAway {
    animation-name: ${headShake};
  }

  &.isClosing {
    animation-name: ${dialogExit};
  }

  &.${DIALOG_SIZES.SMALL} {
    width: clamp(300px, 80vw, 450px);
  }

  &.${DIALOG_SIZES.MEDIUM} {
    width: clamp(300px, 80vw, 600px);
  }

  &.${DIALOG_SIZES.LARGE} {
    width: clamp(300px, 80vw, 800px);
  }

  &.${DIALOG_SIZES.VERY_LARGE} {
    width: clamp(300px, 80vw, 1200px);
  }
`;

export const closeButtonFloatStyle = css`
  margin: 14px 10px 10px;
  float: right;
  z-index: 1;
`;

export const closeButtonAbsoluteStyle = css`
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 1;
`;
