import React, { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { type IconProps } from 'react-toastify';

import { globalMessages } from '@eversity/ui/intl';

import { Toast } from '../Toast';

export type UnexpectedErrorToastProps = {
  errorMessage?: ReactNode;
  toastProps?: IconProps;
};

export const UnexpectedErrorToastBase = ({
  errorMessage = null,
  toastProps = undefined,
}: UnexpectedErrorToastProps) => (
  <Toast toastProps={toastProps}>
    {
      // Check errorMessage type to prevent crashes when using with body.more.
      // Keep the errorMessage propType as node because it is still a dev error to pass an array.
      typeof errorMessage === 'string' || React.isValidElement(errorMessage) ? (
        <FormattedMessage
          {...globalMessages.UNEXPECTED_ERROR_MESSAGE}
          values={{ message: errorMessage }}
        />
      ) : (
        <FormattedMessage {...globalMessages.UNEXPECTED_ERROR} />
      )
    }
  </Toast>
);

UnexpectedErrorToastBase.displayName = 'UnexpectedErrorToast';

export const UnexpectedErrorToast = memo(UnexpectedErrorToastBase);
