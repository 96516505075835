import {
  getContentTitle,
  lessonSequenceSanitizeSchema,
} from '@eversity/domain/html';

/**
 * Compute the sequence number of each sequence in the sequences list, and set it in the
 * `sequenceNumber` field.
 *
 * @param sequences - Sequences.
 * @param sequences[].isNumbered - Is the sequence numbered.
 * @returns Sequences with computed sequence number.
 */
export const computeSequenceNumbers = <T extends { isNumbered?: boolean }>(
  sequences?: T[],
): (T & { sequenceNumber?: number })[] => {
  let sequenceNumber = 1;

  return sequences?.map((sequence) => ({
    ...sequence,
    sequenceNumber: sequence.isNumbered ? sequenceNumber++ : undefined,
  }));
};

/**
 * Make sure that all sequences have a title.
 *
 * @param sequences - Sequences.
 * @returns True if all sequences have a title.
 */
export const allSequencesHaveTitle = (sequences: { content: string }[]) =>
  sequences.every((sequence) =>
    getContentTitle(sequence.content, { schema: lessonSequenceSanitizeSchema }),
  );
