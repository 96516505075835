import { makeThemeTransition } from '../../config/transitions';

export const defaultTransition = makeThemeTransition({
  duration: 200,
  easing: 'cubic-bezier(.1,1.1,.76,.83)',
});

export const infiniteTransition = makeThemeTransition({
  duration: 900,
  easing: 'ease-in-out infinite',
});
