import {
  CheckboxGroupContextProvider,
  type CheckboxGroupContextProviderProps,
} from '@eversity/ui/design-system';

import { McqAnswerCheckbox } from '../mcq-answer/mcq-answer-checkbox/McqAnswerCheckbox';

// Wrap CheckboxGroupContextProvider so we can set McqAnswerCheckbox as a property.
export const McqAnswerCheckboxGroupBase = (
  props: CheckboxGroupContextProviderProps,
) => <CheckboxGroupContextProvider {...props} />;

McqAnswerCheckboxGroupBase.displayName = 'McqAnswerCheckboxGroup';

export const McqAnswerCheckboxGroup: typeof McqAnswerCheckboxGroupBase & {
  Checkbox?: typeof McqAnswerCheckbox;
} = McqAnswerCheckboxGroupBase;

McqAnswerCheckboxGroup.Checkbox = McqAnswerCheckbox;
