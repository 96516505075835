import { type Transition, type TransitionRules } from '@eversity/types/web';

export const makeThemeTransition = (rules: TransitionRules): Transition => {
  const transition = (...properties: string[]) =>
    (properties.length ? properties : ['all'])
      .map((property) => `${property} ${rules.easing} ${rules.duration}ms`)
      .join(', ');

  transition.duration = rules.duration;
  transition.easing = rules.easing;

  return transition;
};
