import { type VersionObjectBase } from '@eversity/types/domain';

/**
 * Serialize a version number to a single string.
 * It is guaranteed to be a valid value to be passed to deserializeVersion.
 *
 * @param version - Version.
 * @param version.versionMajor - Major version number.
 * @param version.versionMinor - Minor version number.
 * @returns A string that can be passed to deserializeVersion.
 */
export const serializeVersion = ({
  versionMajor,
  versionMinor,
}: VersionObjectBase<false>) =>
  versionMinor !== undefined
    ? `${versionMajor}.${versionMinor}`
    : `${versionMajor}`;

/**
 * Deserialize a version number serialized by serializeVersion.
 *
 * @param versionStr - Serialized version number.
 * @param options - Options.
 * @param options.strict - Recognize only full version numbers (X.Y).
 * @returns Version object.
 */
export const deserializeVersion = <TStrict extends boolean = false>(
  versionStr: string,
  {
    strict = false as TStrict,
  }: {
    strict?: TStrict;
  } = {},
): VersionObjectBase<TStrict> => {
  const [matched, versionMajor, versionMinor] =
    /^(\d+)(?:\.(\d+))?$/.exec(versionStr) || [];

  if (!matched || (strict && versionMinor === undefined)) {
    return null;
  }

  return {
    versionMajor: parseInt(versionMajor, 10),
    versionMinor: Number.isFinite(parseInt(versionMinor, 10))
      ? parseInt(versionMinor, 10)
      : undefined,
  };
};
