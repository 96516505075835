import {
  type AddAssignmentCorrectorBody,
  type AddAssignmentDocumentBody,
  type AssignmentClass,
  type AssignmentLesson,
  type AssignmentViewFull,
  type AssignmentViewMinimal,
  type AssignmentViewStudent,
  type CreateAssignmentBody,
  type CreateAssignmentQuery,
  type EmployeeViewMinimal,
  type GetAssignmentQuery,
  type GetAssignmentsQuery,
  type McqPage,
  type McqQuestion,
  type McqQuestionRequest,
  type UpdateAssignmentBody,
  type UpdateAssignmentPageBody,
  type UpdateAssignmentPublishBody,
  type UploadWithoutHref,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const ASSIGNMENTS_API_URI = '/api/v1/school/assignments';
const ASSIGNMENTS_FILES_API_URI = '/api/v1/file/downloads/assignments';

const e = encodeURIComponent;

export class AssignmentsRepository extends HttpRepository {
  /**
   * Fetch assignments.
   *
   * @param query - Query.
   * @param query.view - View to fetch (ASSIGNMENT_VIEWS enum).
   * @param query.sort - Field to sort (field is ASC, -field is DESC).
   * @param query.limit - Limit number of results.
   * @param query.assignmentType - Type filter.
   * @returns Array of assignments.
   */
  async getAssignments(query?: GetAssignmentsQuery): Promise<{
    count: number;
    assignments:
      | AssignmentViewMinimal[]
      | AssignmentViewFull[]
      | AssignmentViewStudent[];
  }> {
    const {
      body: { assignments, count },
    } = await this.http.get(ASSIGNMENTS_API_URI).query(query);

    return { assignments, count };
  }

  /**
   * Fetch an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param query - Query param.
   * @param query.view - View to fetch (ASSIGNMENT_VIEWS enum).
   * @returns Assignment.
   */
  async getAssignment(
    assignmentId: string,
    query?: GetAssignmentQuery,
  ): Promise<
    AssignmentViewMinimal | AssignmentViewFull | AssignmentViewStudent
  > {
    const { body } = await this.http
      .get(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}`)
      .query(query);

    return body;
  }

  /**
   * Update an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param params - Updates.
   * @returns Updated assignment.
   */
  async updateAssignment(
    assignmentId: string,
    params: UpdateAssignmentBody,
  ): Promise<AssignmentViewFull> {
    const { body: assignment } = await this.http
      .patch(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}`)
      .send(params);

    return assignment;
  }

  /**
   * Create a new draft to change assignment attributes.
   *
   * @param assignmentId - Assignment id.
   * @returns Updated assignment.
   */
  async createAssignmentDraft(
    assignmentId: string,
  ): Promise<AssignmentViewFull> {
    const { body: assignment } = await this.http.post(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/draft`,
    );

    return assignment;
  }

  /**
   * Publish the assignment draft to create a new version.
   *
   * @param assignmentId - Assignment id.
   * @param params - Parameters.
   * @param params.changelog - Reason for new version.
   * @returns Updated assignment.
   */
  async publishAssignmentDraft(
    assignmentId: string,
    {
      changelog,
    }: {
      changelog: UpdateAssignmentPublishBody['changelog'];
    },
  ): Promise<AssignmentViewFull> {
    const { body: assignment } = await this.http
      .patch(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}`)
      .send({
        publish: true,
        changelog,
      });

    return assignment;
  }

  /**
   * Delete an assignemnt's draft.
   *
   * @param assignmentId - Assignment id.
   * @returns Success status.
   */
  async deleteAssignmentDraft(assignmentId: string): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/draft`,
    );

    return status === 204;
  }

  /**
   * Remove a corrector from an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param userId - User id.
   * @returns Success status.
   */
  async removeCorrectorFromAssignment(
    assignmentId: string,
    userId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/correctors/${e(userId)}`,
    );

    return status === 204;
  }

  /**
   * Add a new corrector to an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param params - Data to post.
   * @param params.userId - Corrector user id.
   * @returns Added user as corrector.
   */
  async addCorrectorToAssignment(
    assignmentId: string,
    params: AddAssignmentCorrectorBody,
  ): Promise<EmployeeViewMinimal> {
    const { body: user } = await this.http
      .post(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}/correctors`)
      .send(params);

    return user;
  }

  /**
   * Create an assignment.
   *
   * @param assignment - The assignment to create.
   * @param query - Query param.
   * @param query.view - View to fetch (ASSIGNMENT_VIEWS enum).
   * @returns The new assignment object.
   */
  async postAssignment(
    assignment: CreateAssignmentBody,
    query?: CreateAssignmentQuery,
  ): Promise<
    AssignmentViewMinimal | AssignmentViewFull | AssignmentViewStudent
  > {
    const { body } = await this.http
      .post(`${ASSIGNMENTS_API_URI}`)
      .query(query)
      .send(assignment);

    return body;
  }

  /**
   * Add a new document to an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param params - Params.
   * @param params.uploadId - Upload id.
   * @returns Upload.
   */
  async addDocumentToAssignment(
    assignmentId: string,
    params: AddAssignmentDocumentBody,
  ): Promise<UploadWithoutHref> {
    const { body: upload } = await this.http
      .post(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}/documents`)
      .send(params);

    return upload;
  }

  /**
   * Remove a document from an assignment.
   *
   * @param assignmentId - Assignment id.
   * @param uploadId - Upload id.
   * @returns Success.
   */
  async removeDocumentFromAssignment(
    assignmentId: string,
    uploadId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/documents/${e(uploadId)}`,
    );

    return status === 204;
  }

  /**
   * Get associated classes for an exam.
   *
   * @param assignmentId - Assignment Id.
   * @returns List of classes with their corresponding course and teaching unit.
   */
  async getAssignmentClasses(assignmentId: string): Promise<AssignmentClass[]> {
    const { body } = await this.http.get(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/classes`,
    );

    return body;
  }

  /**
   * Get associated lessons for an assignment.
   *
   * @param assignmentId - Assignment Id.
   * @returns List of lessons associated with this assignment
   */
  async getAssignmentLessons(
    assignmentId: string,
  ): Promise<AssignmentLesson[]> {
    const { body } = await this.http.get(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/lessons`,
    );

    return body;
  }

  /**
   * Download an assignment's document.
   *
   * @param assignmentId - Assignment id.
   * @param uploadId - Document upload id.
   * @returns File content.
   */
  async getAssignmentDocument(
    assignmentId: string,
    uploadId: string,
  ): Promise<{ type: string; body: Blob }> {
    const { body, type } = await this.http
      .get(
        `${ASSIGNMENTS_FILES_API_URI}/${e(assignmentId)}/documents/${e(
          uploadId,
        )}`,
      )
      .responseType('blob');

    return { body, type };
  }

  /**
   * Post a new question to an MCQ.
   *
   * @param assignmentId - Assignment id.
   * @param pageId - Page id.
   * @param question - Question to add.
   * @returns Added question.
   */
  async createAssignmentQuestion(
    assignmentId: string,
    pageId: string,
    question: Omit<McqQuestionRequest, 'id'>,
  ): Promise<McqQuestion> {
    const { body } = await this.http
      .post(
        `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages/${e(
          pageId,
        )}/questions`,
      )
      .send(question);

    return body;
  }

  /**
   * Update an existing question in an MCQ.
   *
   * @param assignmentId - Assignment id.
   * @param pageId - Page id.
   * @param question - Question id.
   * @param question - Question to add.
   * @returns Updated question.
   */
  async updateAssignmentQuestion(
    assignmentId: string,
    pageId: string,
    questionId: string,
    question: Omit<McqQuestionRequest, 'id'>,
  ): Promise<McqQuestion> {
    const { body } = await this.http
      .put(
        `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages/${e(
          pageId,
        )}/questions/${e(questionId)}`,
      )
      .send(question);

    return body;
  }

  /**
   * Delete an existing question in an MCQ.
   *
   * @param assignmentId - Assignment id.
   * @param pageId - Page id.
   * @param questionId - Question id.
   * @returns True if the question was deleted.
   */
  async removeAssignmentQuestion(
    assignmentId: string,
    pageId: string,
    questionId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages/${e(
        pageId,
      )}/questions/${e(questionId)}`,
    );

    return status === 204;
  }

  /**
   * Add a new page to an MCQ
   *
   * @param assignmentId - Assignment id.
   * @returns Updated assignment.
   */
  async addAssignmentPage(assignmentId: string): Promise<McqPage> {
    const { body } = await this.http.post(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages`,
    );

    return body;
  }

  /**
   * Add a new page to an MCQ
   *
   * @param assignmentId - Assignment id.
   * @param pageId - Page id.
   * @param params - Params.
   * @param params.pageType - Page type.
   * @param params.questions - Questions in page.
   * @param params.content - Page content text.
   * @returns Updated assignment.
   */
  async updateAssignmentPage(
    assignmentId: string,
    pageId: string,
    params: UpdateAssignmentPageBody,
  ): Promise<McqPage> {
    const { body } = await this.http
      .patch(`${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages/${e(pageId)}`)
      .send(params);

    return body;
  }

  /**
   * Add a new page to an MCQ
   *
   * @param assignmentId - Assignment id.
   * @param pageId - Page id.
   * @returns Updated assignment.
   */
  async removeAssignmentPage(
    assignmentId: string,
    pageId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}/pages/${e(pageId)}`,
    );

    return status === 200;
  }

  /**
   * Delete an assignment.
   *
   * @param assignmentId - Assignment id.
   * @returns True if the assignment was deleted.
   */
  async deleteAssignment(assignmentId: string): Promise<boolean> {
    const { status } = await this.http.delete(
      `${ASSIGNMENTS_API_URI}/${e(assignmentId)}`,
    );

    return status === 204;
  }
}
