import { Fragment, type HTMLProps, memo, useCallback, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { head, last, noop } from 'lodash';
import PropTypes from 'prop-types';

import { useBoolState } from '@eversity/ui/utils';

import { tabKeyPropTypes, tabPropTypes } from '../../../types';
import SeeMoreDropDown from './SeeMoreDropdown';
import { Separator } from './Separator';
import { TabGroup } from './TabGroup';
import * as styles from './Tabs.styles';
import { type TabKey, type TabShape } from './types';

export type TabsProps<TTabKey extends TabKey = TabKey> = Omit<
  HTMLProps<HTMLDivElement>,
  'ref' | 'onChange' | 'value'
> & {
  value: TTabKey | null;
  onChange: (key: TTabKey) => void;
  onChangeOrder?: (newOrder: TabShape<TTabKey>[], index: number) => void;
  vertical?: boolean;
  tabs: TabShape<TTabKey>[] | TabShape<TTabKey>[][];
  isDraggable?: boolean;
  isDragDisabled?: boolean;
  /**
   * The `maxNbTabsBeforeGrouping` prop defines the maximum number of tabs to display
   * before grouping the remaining tabs into a dropdown (for cases when there are too many tabs to show).
   *
   * This value must be manually managed based on the screen width to ensure proper responsive behavior.
   * For example, on smaller screens, you may want to display fewer tabs and move the rest into the dropdown.
   * This means you should dynamically adjust `maxNbTabsBeforeGrouping` according to the viewport size to provide
   * an optimal user experience across different devices.
   */
  maxNbTabsBeforeGrouping?: number;
};

const DEFAULT_TABS = [];

export const TabsBase = <TTabKey extends TabKey = TabKey>({
  value = null,
  onChange = noop,
  onChangeOrder = noop,
  vertical = false,
  tabs = DEFAULT_TABS,
  isDraggable = false,
  isDragDisabled = false,
  maxNbTabsBeforeGrouping = null,
  ...props
}: TabsProps<TTabKey>) => {
  const theme = useTheme();

  const [isVisible, , , onToggle] = useBoolState();

  const sortTabs = useCallback(
    (group: TabShape<TTabKey>[], index: number, key: string) => {
      const newIndexForOldItem = group.findIndex((tab) => tab.key === key);

      if (newIndexForOldItem < index || newIndexForOldItem === -1) {
        return group;
      }

      // uses destructuring to exchange the positions of two elements in an array
      [group[index], group[newIndexForOldItem]] = [
        group[newIndexForOldItem],
        group[index],
      ];

      return group;
    },
    [],
  );

  const tabsGroups = useMemo(
    () =>
      (tabs.length > 0 && !Array.isArray(tabs[0])
        ? [
            maxNbTabsBeforeGrouping && maxNbTabsBeforeGrouping < tabs.length
              ? sortTabs(
                  tabs as TabShape<TTabKey>[],
                  maxNbTabsBeforeGrouping - 1,
                  value.toString(),
                )
              : (tabs as TabShape<TTabKey>[]),
          ]
        : (tabs as TabShape<TTabKey>[][])
      ).filter((tabGroup) => !!tabGroup.length),
    [maxNbTabsBeforeGrouping, sortTabs, tabs, value],
  );

  const dropdownItems = useMemo(
    () =>
      maxNbTabsBeforeGrouping && tabsGroups.length
        ? tabsGroups[0].slice(maxNbTabsBeforeGrouping)
        : null,
    [maxNbTabsBeforeGrouping, tabsGroups],
  );

  const onChangeItemDropdown = useCallback(
    (key: string) => {
      onToggle();
      onChange(key as TTabKey);
    },
    [onChange, onToggle],
  );

  return (
    <div
      {...props}
      css={
        vertical
          ? styles.verticalContainer
          : styles.container(theme, !!maxNbTabsBeforeGrouping)
      }
    >
      {tabsGroups.map((groupTabs, index) => (
        <Fragment key={groupTabs[0].key}>
          <TabGroup<TTabKey>
            tabs={groupTabs.slice(
              0,
              maxNbTabsBeforeGrouping ?? groupTabs.length,
            )}
            index={index}
            tabGroupsCount={tabsGroups.length}
            value={value}
            onChange={onChange}
            onChangeOrder={onChangeOrder}
            isDraggable={isDraggable}
            isDragDisabled={isDragDisabled}
            vertical={vertical}
            maxNbTabsBeforeGrouping={maxNbTabsBeforeGrouping}
          />

          {!vertical && index < tabsGroups.length - 1 && (
            <Separator
              isLeftActive={last(groupTabs).key === value}
              isRightActive={head(tabsGroups[index + 1]).key === value}
            />
          )}
        </Fragment>
      ))}
      {!!dropdownItems?.length && (
        <SeeMoreDropDown
          isVisible={isVisible}
          dropdownItems={dropdownItems}
          onChangeItemDropdown={onChangeItemDropdown}
          onToggle={onToggle}
        />
      )}
    </div>
  );
};

TabsBase.displayName = 'Tabs';

TabsBase.propTypes = {
  /** Currently active tab key. */
  value: tabKeyPropTypes,
  /** On change handler, called with the new active tab key. */
  onChange: PropTypes.func,
  /** On drag end change handler, called with the new tabs order. */
  onChangeOrder: PropTypes.func,
  /** Should display tabs vertically. */
  vertical: PropTypes.bool,
  /** Tabs or tabs groups. */
  tabs: PropTypes.oneOfType([
    /** Either pass all tabs directly (they will be considered part of a single group). */
    PropTypes.arrayOf(tabPropTypes),
    /** Or pass tabs divided by groups. Empty groups are ignored. */
    PropTypes.arrayOf(PropTypes.arrayOf(tabPropTypes)),
  ]),
  isDraggable: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
};

export const Tabs = memo(TabsBase) as unknown as typeof TabsBase;
