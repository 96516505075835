import { ErrorCode as DropzoneErrorCode } from 'react-dropzone';
import { defineMessages } from 'react-intl';

export default defineMessages({
  PLACEHOLDER: {
    defaultMessage: 'Cliquez ou faites glisser un fichier dans cette zone.',
  },

  ACCEPTED_EXTENSIONS: {
    defaultMessage:
      '{count, plural, one {Format accepté} other {Formats acceptés}} : {extensions}.',
  },

  MAXIMUM_FILES: {
    defaultMessage:
      '{count, plural, one {# fichier} other {# fichiers}} maximum.',
  },

  MAXIMUM_SIZE: {
    defaultMessage: 'Taille max. : {maxSize}.',
  },

  FILE_REJECTED: {
    defaultMessage: '<b>{fileName}</b> : {error}.',
  },
});

export const fileRejectMessages = defineMessages<DropzoneErrorCode>({
  [DropzoneErrorCode.FileTooLarge]: {
    defaultMessage: 'fichier trop large',
  },

  [DropzoneErrorCode.FileTooSmall]: {
    defaultMessage: 'fichier trop petit',
  },

  [DropzoneErrorCode.FileInvalidType]: {
    defaultMessage: 'format de fichier invalide',
  },

  [DropzoneErrorCode.TooManyFiles]: {
    defaultMessage: 'trop de fichiers déjà téléchargés',
  },
});
