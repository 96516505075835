import { Fragment, memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import prettyBytes from 'pretty-bytes';
import PropTypes from 'prop-types';

import { useFileUploadAccept, useLocaleContext } from '@eversity/ui/utils';

import messages from './FileUploadRules.messages';

export type FileUploadRulesProps = {
  accept?: string;
  maxSize?: number;
  maxFiles?: number;
  children?: (props: {
    extensionsRule: ReactNode | null;
    maxSizeRule: ReactNode | null;
    maxFilesRule: ReactNode | null;
  }) => ReactNode;
};

const DEFAULT_CHILDREN = () => null;

export const FileUploadRulesBase = ({
  accept = undefined,
  maxSize = undefined,
  maxFiles = undefined,
  children = DEFAULT_CHILDREN,
}: FileUploadRulesProps) => {
  const { locale } = useLocaleContext();
  const acceptedExtensions = useFileUploadAccept(accept);

  return (
    <Fragment>
      {children({
        extensionsRule: acceptedExtensions.length ? (
          <FormattedMessage
            {...messages.ACCEPTED_EXTENSIONS}
            values={{
              count: acceptedExtensions.length,
              extensions: new Intl.ListFormat(locale, {
                style: 'narrow',
              }).format(acceptedExtensions),
            }}
          />
        ) : null,

        maxSizeRule: Number.isFinite(maxSize) ? (
          <FormattedMessage
            {...messages.MAXIMUM_SIZE}
            values={{ maxSize: prettyBytes(maxSize) }}
          />
        ) : null,

        maxFilesRule: Number.isFinite(maxFiles) ? (
          <FormattedMessage
            {...messages.MAXIMUM_FILES}
            values={{ count: maxFiles }}
          />
        ) : null,
      })}
    </Fragment>
  );
};

FileUploadRulesBase.displayName = 'FileUploadRules';

FileUploadRulesBase.propTypes = {
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  children: PropTypes.func,
};

export const FileUploadRules = memo(FileUploadRulesBase);
