import { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { type UseWizard } from '../../../hooks/useWizard';
import { WizardContext } from '../contexts/Wizard.context';
import { WizardStep, type WizardStepProps } from './WizardStep';

type WizardStepWithContextProps = Omit<WizardStepProps, keyof UseWizard[0]>;

export const WizardStepWithContextBase = (
  props: WizardStepWithContextProps,
) => {
  const [stepProps] = useContext(WizardContext);

  return (
    <WizardStep
      {...props}
      {...stepProps}
    />
  );
};

WizardStepWithContextBase.displayName = 'WizardStepWithContextBase';

WizardStepWithContextBase.propTypes = {
  /** Name of the step. */
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export const WizardStepWithContext = memo(WizardStepWithContextBase);
