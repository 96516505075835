export enum BUTTON_VARIANTS {
  NEUTRAL = 'NEUTRAL',
  PRIMARY = 'PRIMARY',
  TERTIARY = 'TERTIARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
}

export enum BUTTON_HUES {
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export enum BUTTON_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum BUTTON_ICON_POSITIONS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
