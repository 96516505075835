import { css, type Theme } from '@emotion/react';

export const cropper = (theme: Theme) => css`
  .cropper-point {
    background-color: ${theme.colors.primary[400]};
    opacity: 1;
  }

  .cropper-view-box {
    outline-color: ${theme.colors.primary[400]};
  }

  &.hasError {
    .cropper-point {
      background-color: ${theme.colors.danger[400]};
    }

    .cropper-view-box {
      outline-color: ${theme.colors.danger[400]};
    }
  }

  &.hasWarning {
    .cropper-point {
      background-color: ${theme.colors.warning[400]};
    }

    .cropper-view-box {
      outline-color: ${theme.colors.warning[400]};
    }
  }
`;

export const container = css`
  position: relative;
`;

export const button = css`
  position: absolute;
  top: 10px;
  right: 10px;
`;
