import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '../../../../utils/style/index';
import { SECTION_VARIANTS } from './constants';

export const header = (theme: Theme) => css`
  display: flex;
  align-items: center;

  border-radius: 8px 0 0 8px;
  border-right: 2px solid transparent;
  padding: 4px 8px 4px 4px;

  transition: ${theme.transitions.default()};

  &.${SECTION_VARIANTS.NEUTRAL} {
    &:hover,
    &.isSelected {
      background-color: ${theme.colors.gray[25]};
      color: ${theme.colors.gray[700]};
    }

    &.isSelected {
      &.${SECTION_VARIANTS.NEUTRAL} {
        border-right-color: ${theme.colors.gray[500]};
      }
    }
  }

  &.${SECTION_VARIANTS.PRIMARY} {
    &:hover,
    &.isSelected {
      background-color: ${theme.colors.primary[25]};
      color: ${theme.colors.primary[500]};
    }

    &.isSelected {
      &.${SECTION_VARIANTS.NEUTRAL} {
        border-right-color: ${theme.colors.primary[500]};
      }
    }
  }

  &.${SECTION_VARIANTS.FADED} {
    color: ${theme.colors.gray[500]};
  }
`;

export const icon = (theme: Theme, backgroundColor: string) => css`
  padding: 8px 5px;
  border-radius: 4px;

  display: flex;
  align-items: center;

  &.isSelected {
    background-color: ${backgroundColor};
  }

  > div {
    position: relative;
  }
`;

export const iconState = css`
  position: absolute;
  right: -4px;
  bottom: -4px;
`;

export const link = css`
  text-decoration: none;
  color: inherit;
`;

export const label = css`
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;

  margin-left: 10px;
`;

export const arrow = (theme: Theme) => css`
  ${removeButtonAppearance};

  padding: 5px;

  align-self: center;

  margin-left: auto;

  transition: ${theme.transitions.default()};

  &.isOpen {
    transform: rotate(90deg);
  }
`;

export const list = css`
  list-style-type: none;

  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
`;

export const getIconFills = (
  theme: Theme,
  variant: SECTION_VARIANTS,
  isFinished: boolean,
  isSelected: boolean,
) => {
  switch (variant) {
    case SECTION_VARIANTS.FADED:
      return ['transparent', theme.colors.gray[300], theme.colors.gray[25]];
    case SECTION_VARIANTS.NEUTRAL:
      return isSelected
        ? [theme.colors.gray[500], theme.colors.gray[0], theme.colors.gray[400]]
        : ['transparent', theme.colors.gray[700], theme.colors.gray[25]];
    case SECTION_VARIANTS.PRIMARY:
    default:
      return isSelected
        ? [
            theme.colors.primary[500],
            theme.colors.gray[0],
            theme.colors.primary[400],
          ]
        : ['transparent', theme.colors.primary[500], theme.colors.primary[25]];
  }
};
