import { type Theme } from '@emotion/react';

import { BREAKPOINTS } from '../../config/breakpoints/breakpoints';
import { pulseAnimation } from './animations';
import { COLORS, CONTRAST_COLORS, HUES } from './colors';
import ThemeGlobalStyles from './styles';
import { defaultTransition, infiniteTransition } from './transitions';
import { TYPOGRAPHY } from './typography';

export const DEFAULT_THEME: Theme = {
  colors: COLORS,
  contrastColors: CONTRAST_COLORS,
  hues: HUES,
  typography: TYPOGRAPHY,

  globalStylesComponent: ThemeGlobalStyles,

  breakpoints: BREAKPOINTS,
  transitions: {
    default: defaultTransition,
    infinite: infiniteTransition,
  },
  animations: {
    pulse: pulseAnimation,
  },
};
