import { css, type Theme } from '@emotion/react';

export const beacon = (theme: Theme) => css`
  animation: ${theme.animations.pulse} 1s ${theme.transitions.infinite.easing};
  background-color: ${theme.colors.primary[200]};
  opacity: 0.6;
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;
