import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { SELECT_SIZES } from '../constants';
import messages from '../Select.messages';

export const commonSelectDefaultProps = {
  value: null,
  onChange: noop,
  isMulti: false,
  size: SELECT_SIZES.MEDIUM,
  openMenuOnFocus: true,
  menuPlacement: 'auto',
  disabled: false,
  hasError: false,
  hasWarning: false,
  id: null,

  placeholder: <FormattedMessage {...messages.PLACEHOLDER} />,

  // eslint-disable-next-line react/prop-types
  noOptionsMessage: ({ inputValue }) => (
    <FormattedMessage
      {...messages.NO_OPTIONS}
      values={{ inputValue, hasValue: !!inputValue }}
    />
  ),

  loadingMessage: () => <FormattedMessage {...messages.LOADING} />,

  filterOption: undefined,
  menuPortalTarget: undefined,
} as const;

export const selectDefaultProps = {
  useOptionAsValue: false,
};

export const creatableSelectDefaultProps = {
  formatCreateLabel: (inputValue?: string) => (
    <FormattedMessage
      {...messages.CREATE_VALUE}
      values={{
        inputValue,
      }}
    />
  ),
} as const;
