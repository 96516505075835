import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import { type GroupBase, type SelectInstance } from 'react-select';
import ReactAsyncSelect, { type AsyncProps } from 'react-select/async';

import { type SelectOption } from '@eversity/types/web';

import { type CommonSelectProps, SELECT_SIZES } from './constants';
import { commonSelectDefaultProps } from './utils/defaultProps';
import { commonSelectPropTypes } from './utils/propTypes';
import { useReactSelectCustomization } from './utils/useReactSelectCustomizations';
import { type useReactSelectValue } from './utils/useReactSelectValueWrapper';

export type AsyncSelectProps<
  TOption extends SelectOption<any> = SelectOption<any>,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = Omit<
  AsyncProps<TOption, TIsMulti, TGroup>,
  'isDisabled' | 'formatOptionLabel' | 'value' | 'onChange' | 'options'
> &
  CommonSelectProps & {
    onChange: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['onChange'];
    value: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['value'];
  };

const AsyncSelectInner = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  {
    id,
    size,
    disabled,
    hasError,
    hasWarning,
    ...props
  }: AsyncSelectProps<TOption, TIsMulti, TGroup>,
  ref: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>,
) => {
  const { onChange, onChangeMulti, ...customProps } =
    useReactSelectCustomization<TOption, TIsMulti, TGroup, true>({
      hasError,
      hasWarning,
      size,
      useOptionAsValue: true,
      ...props,
    });

  return (
    <ReactAsyncSelect<TOption, TIsMulti, TGroup>
      {...props}
      {...customProps}
      ref={ref}
      inputId={id}
      aria-labelledby={id}
      onChange={props.isMulti ? onChangeMulti : onChange}
      isDisabled={disabled}
    />
  );
};

export const AsyncSelectBase = forwardRef(AsyncSelectInner) as {
  <
    TOption extends SelectOption<any> = SelectOption<any>,
    TIsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  >(
    props: AsyncSelectProps<TOption, TIsMulti, TGroup> & {
      ref?: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>;
    },
  ): ReactElement | null;

  propTypes?: any;
  defaultProps?: any;
  displayName?: any;
};

AsyncSelectBase.displayName = 'AsyncSelect';

AsyncSelectBase.propTypes = {
  ...commonSelectPropTypes,
};

AsyncSelectBase.defaultProps = {
  ...commonSelectDefaultProps,
};

export const AsyncSelect = memo(AsyncSelectBase) as typeof AsyncSelectBase & {
  SIZES?: typeof SELECT_SIZES;
};

AsyncSelect.SIZES = SELECT_SIZES;
