import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';
import PropTypes from 'prop-types';

import { TABLE_BACKGROUND_COLORS } from '@eversity/domain/constants';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { getThemeTableBackgroundColor } from '../hast-renderer/constants';

export const htmlFormatterStyle = (theme: Theme) => css`
  > * {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > * + h2,
  > * + h3,
  > * + h4,
  > * + h5,
  > * + .h2,
  > * + .h3,
  > * + .h4,
  > * + .h5 {
    margin-top: 1.5em;
  }

  h5 {
    padding-bottom: 4px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    color: ${theme.colors.gray[500]};
  }

  ul {
    list-style-type: none;
    padding-inline-start: 21px;

    > li {
      &::before {
        content: '\\2022';
        color: ${theme.colors.primary[500]};
        display: inline-block;
        width: 0;
        position: relative;
        left: -12px;
      }

      > p {
        display: inline;
      }
    }
  }

  ol {
    padding-inline-start: 16px;
  }

  p > code {
    background-color: ${theme.colors.gray[25]};
    padding: 4px;
    border-radius: 4px;
  }

  blockquote {
    border-radius: 4px;
    border-left: 6px solid ${theme.colors.primary[500]};
    background-color: ${theme.colors.primary[25]};

    > p {
      padding: 10px 20px;
    }
  }

  pre {
    background-color: ${theme.colors.gray[25]};
    padding: 8px;
    border-radius: 8px;
    white-space: pre-wrap;

    code {
      display: block;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    height: 1px;
    background-color: ${theme.colors.gray[0]};

    tr {
      height: 100%;

      &,
      td {
        ${Object.values(TABLE_BACKGROUND_COLORS).map(
          (variant) => css`
            &.${variant} {
              background-color: ${new TinyColor(
                getThemeTableBackgroundColor(variant, theme),
              )
                .setAlpha(0.4)
                .toRgbString()};
            }
          `,
        )}
      }

      td,
      th {
        position: relative;
        height: inherit;
        border: 1px solid ${theme.colors.gray[100]};
        overflow-wrap: anywhere;
        vertical-align: top;
        text-align: left;

        > div.cellContent {
          position: relative;
          height: 100%;
          padding: 9px;
          transition: ${theme.transitions.default('border-color')};
          border: 1px solid transparent;

          &.isFocused {
            border-color: ${theme.colors.primary[500]};
          }

          p {
            margin-bottom: 0.5em;

            &:last-child {
              margin-bottom: 0;
            }

            /**
             * This adds an non breaking space (&nbsp; doesn't work here).
             * Otherwise, rows with only empty cells are flat because the p does not take its height.
             */
            &:empty:before {
              content: '\\00a0';
            }
          }
        }
      }

      /**
       * In edition mode, headers are a td with a th className (see CellElement).
       */
      td:not(.th) {
        > div.cellContent {
          p {
            ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]};
          }
        }
      }

      th,
      td.th {
        background-color: ${theme.colors.gray[25]};

        > div.cellContent {
          p {
            ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD]};
          }
        }
      }
    }
  }

  .eversity-audio {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .eversity-video,
  .eversity-carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    figcaption,
    .figcaption {
      ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]};
      color: ${theme.colors.gray[500]};

      text-align: center;
    }
  }
`;

export type HtmlFormatterProps = Omit<HTMLProps<HTMLDivElement>, 'ref'>;

export const HtmlFormatterBase = forwardRef(
  (
    { children, ...props }: HtmlFormatterProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      {...props}
      ref={ref}
      css={htmlFormatterStyle}
    >
      {children}
    </div>
  ),
);

HtmlFormatterBase.displayName = 'HtmlFormatter';

HtmlFormatterBase.propTypes = {
  /** Html content (as JSX). Children must not be wrapped in a container. */
  children: PropTypes.node,
};

HtmlFormatterBase.defaultProps = {
  children: null,
};

export const HtmlFormatter = memo(HtmlFormatterBase);
