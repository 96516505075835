import { useEffect } from 'react';

import { useBoolState } from './useBoolState';

export const useWindowFocus = () => {
  const [windowHasFocus, setFocused, setBlurred] = useBoolState(
    document?.hasFocus(),
  );

  useEffect(() => {
    window.addEventListener('focus', setFocused);
    window.addEventListener('blur', setBlurred);

    return () => {
      window.removeEventListener('focus', setFocused);
      window.removeEventListener('blur', setBlurred);
    };
  }, [setFocused, setBlurred]);

  return !!windowHasFocus;
};
