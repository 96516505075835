import { defineMessages } from 'react-intl';

import {
  ASSIGNMENT_ASSESSMENTS,
  ASSIGNMENT_COMMENT_REASON,
  SUBMISSION_STATUSES,
} from '@eversity/domain/constants';

export const submissionStatusMessages = defineMessages<SUBMISSION_STATUSES>({
  [SUBMISSION_STATUSES.IN_PROGRESS]: {
    defaultMessage: 'Devoir commencé',
  },
  [SUBMISSION_STATUSES.SUBMITTED]: {
    defaultMessage: 'En attente de correction',
  },
  [SUBMISSION_STATUSES.CORRECTION_IN_PROGRESS]: {
    defaultMessage: 'En cours de correction',
  },
  [SUBMISSION_STATUSES.CORRECTED]: {
    defaultMessage: 'Corrigé',
  },
  [SUBMISSION_STATUSES.COMMENTED]: {
    defaultMessage: 'Commenté',
  },
});

export const submissionAssessmentMessages =
  defineMessages<ASSIGNMENT_ASSESSMENTS>({
    [ASSIGNMENT_ASSESSMENTS.VERY_SATISFACTORY]: {
      defaultMessage: 'Très satisfaisant',
    },
    [ASSIGNMENT_ASSESSMENTS.SATISFACTORY]: {
      defaultMessage: 'Satisfaisant',
    },
    [ASSIGNMENT_ASSESSMENTS.UNSATISFACTORY]: {
      defaultMessage: 'Insatisfaisant',
    },
    [ASSIGNMENT_ASSESSMENTS.VERY_UNSATISFACTORY]: {
      defaultMessage: 'Très insatisfaisant',
    },
  });

export const submissionCommentReasonMessages =
  defineMessages<ASSIGNMENT_COMMENT_REASON>({
    [ASSIGNMENT_COMMENT_REASON.DEPOSIT_ISSUE]: {
      defaultMessage: 'Problème avec le rendu',
    },
    [ASSIGNMENT_COMMENT_REASON.TECHNICAL_ISSUE]: {
      defaultMessage: 'Problème technique',
    },
    [ASSIGNMENT_COMMENT_REASON.MISUNDERSTANDING_OF_INSTRUCTIONS]: {
      defaultMessage: 'Incompréhension des consignes',
    },
    [ASSIGNMENT_COMMENT_REASON.OTHER_REASON]: {
      defaultMessage: 'Autre',
    },
  });
