import { css, type Theme } from '@emotion/react';

import { LESSON_RESOURCE_PICTURE_ASPECT_RATIO } from '@eversity/domain/constants';

export const container =
  (width: string | number, pictureHref?: string) => (theme: Theme) => css`
    aspect-ratio: ${LESSON_RESOURCE_PICTURE_ASPECT_RATIO};
    width: ${Number.isFinite(width) ? `${width}px` : width};
    background-color: ${theme.colors.primary[15]};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.gray[100]};
    border-radius: 4px;
    position: relative;

    ${pictureHref &&
    css`
      background-image: url(${pictureHref});
      background-position: center;
      background-size: cover;
    `}
  `;
