export enum EMAIL_TEMPLATE_ID {
  CLOSE_STUDENT_COURSE_ACCESS_SOON = 535,
  CLOSE_STUDENT_COURSE_ACCESS_NOW = 533,
}

export type CLOSE_STUDENT_COURSE_ACCESS_NOW_EMAIL_PARAMS = {
  firstName: string;
  courseTitle: string;
  accessStartDate: string;
};

export type CLOSE_STUDENT_COURSE_ACCESS_SOON_EMAIL_PARAMS =
  CLOSE_STUDENT_COURSE_ACCESS_NOW_EMAIL_PARAMS & {
    nbDays: number;
    accessEndDate: string;
  };

export type EmailParams =
  | CLOSE_STUDENT_COURSE_ACCESS_NOW_EMAIL_PARAMS
  | CLOSE_STUDENT_COURSE_ACCESS_SOON_EMAIL_PARAMS;

export const NB_DAYS_BEFORE_SENDING_COURSE_END_ACCESS_EMAIL = 30;
