import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { noop } from 'lodash';

export const useLayerClickAway = ({
  shouldCloseOnClickOverlay = true,
  preventClosing = false,
  onRequestClose = noop,
}: {
  shouldCloseOnClickOverlay?: boolean;
  preventClosing?: boolean;
  onRequestClose?: () => void;
}) => {
  const theme = useTheme();
  const [triedToClickAway, onSetTriedToClickAway] = useState(false);

  const onClickAway = useCallback(() => {
    // If should close, close.
    // If not, set triedToClickAway to true.
    if (shouldCloseOnClickOverlay && !preventClosing) {
      onRequestClose();
    } else {
      onSetTriedToClickAway(true);
    }
  }, [shouldCloseOnClickOverlay, preventClosing, onRequestClose]);

  // When triedToClickAway passes true, start a timeout of the duration of the animation to pass it
  // false again. This is so another click after the animation ends triggers the animation again,
  // because we need the class to be removed from the div before being added again.
  useEffect(() => {
    if (triedToClickAway) {
      const timeout = setTimeout(
        () => onSetTriedToClickAway(false),
        theme.transitions.default.duration * 2,
      );

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [triedToClickAway, theme]);

  return {
    triedToClickAway,
    onClickAway,
  };
};
