import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { getTextContrastColor } from '../../../config/colors';
import { AVATAR_SIZES } from './constants';

export const containerStyle = (borderColor: string) => (theme: Theme) => css`
  display: flex;

  border-radius: 4px;
  transition: ${theme.transitions.default()};

  &.isShadowed {
    box-shadow: 0 0 0 4px
      ${new TinyColor(borderColor).setAlpha(0.15).toRgbString()};
    position: relative;
  }

  &.${AVATAR_SIZES.SMALL} {
    height: 24px;
    width: 24px;
  }

  &.${AVATAR_SIZES.MEDIUM} {
    height: 40px;
    width: 40px;
  }

  &.${AVATAR_SIZES.LARGE} {
    height: 46px;
    width: 46px;
  }

  &.${AVATAR_SIZES.OVERSIZED} {
    height: 80px;
    width: 80px;
  }
`;

export const profilePictureShadowContainerStyle = css`
  border-radius: 8px;
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  overflow: hidden;
`;

export const profilePictureShadowStyle = (profilePicture: string) => css`
  background-image: url(${profilePicture});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(4px);
`;

export const profilePictureShadowOverlayStyle = (theme: Theme) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${new TinyColor(theme.colors.gray[0])
    .setAlpha(0.8)
    .toRgbString()};
`;

export const profilePictureStyle = (profilePicture: string) => css`
  border-radius: 4px;
  background-image: url(${profilePicture});
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
`;

export const defaultAvatarStyle =
  (backgroundColor: string) => (theme: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    border-radius: 4px;

    color: ${getTextContrastColor(backgroundColor, theme.colors)};
    background-color: ${backgroundColor};
  `;
