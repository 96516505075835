import { defineMessages } from 'react-intl';

export default defineMessages({
  DELETE_PROFIL_PICTURE: {
    defaultMessage: 'Supprimer la photo',
  },

  DEFINE_PROFIL_PICTURE: {
    defaultMessage: 'Définir comme photo de profil',
  },
});
