import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
} from 'react';
import { css, type Theme, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import { Badge, type BadgeProps } from '../badge/Badge';
import { type BADGE_SIZES, type BADGE_VARIANTS } from '../badge/constants';

const ICON_BADGE_SIZE_MAPPING: Record<BADGE_SIZES, number> = {
  [Badge.SIZES.SMALL]: 9,
  [Badge.SIZES.MEDIUM]: 14,
};

const ICON_VARIANT_MAPPING = (
  theme: Theme,
): Record<BADGE_VARIANTS, [string, string]> => ({
  [Badge.VARIANTS.NEUTRAL]: [theme.colors.gray[0], theme.colors.gray[400]],
  [Badge.VARIANTS.PRIMARY]: [
    theme.colors.primary[0],
    theme.colors.primary[400],
  ],
  [Badge.VARIANTS.SECONDARY]: [
    theme.colors.secondary[0],
    theme.colors.secondary[400],
  ],
});

export type IconBadgeProps = BadgeProps & {
  icon: ReactElement;
};

export const IconBadgeBase = ({
  icon,
  variant = Badge.VARIANTS.NEUTRAL,
  size = Badge.SIZES.MEDIUM,
  ...props
}: IconBadgeProps) => {
  const theme = useTheme();

  return (
    <Badge
      {...props}
      variant={variant}
      size={size}
      css={css`
        padding: 0;
      `}
    >
      {cloneElement(icon, {
        size: ICON_BADGE_SIZE_MAPPING[size],
        fill: ICON_VARIANT_MAPPING(theme)[variant],
      })}
    </Badge>
  );
};

IconBadgeBase.displayName = 'IconBadge';

IconBadgeBase.propTypes = {
  /** Badge color. */
  variant: PropTypes.oneOf(Object.values(Badge.VARIANTS)),
  /** Text's size. */
  size: PropTypes.oneOf(Object.values(Badge.SIZES)),
  /** Icon to render. */
  icon: PropTypes.node.isRequired,
};

export const IconBadge: MemoExoticComponent<typeof IconBadgeBase> & {
  VARIANTS?: typeof Badge.VARIANTS;
  SIZES?: typeof Badge.SIZES;
} = memo(IconBadgeBase);

IconBadge.VARIANTS = Badge.VARIANTS;
IconBadge.SIZES = Badge.SIZES;
