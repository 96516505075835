import { memo, type MemoExoticComponent } from 'react';
import { css, useTheme } from '@emotion/react';
import { Info } from '@icon-park/react';
import PropTypes from 'prop-types';

import { ICON_SIZES } from '../icon/constants';
import { Tooltip, type TooltipProps } from '../tooltip/Tooltip';

export type HelpProps = Omit<TooltipProps, 'content'> & {
  iconSize?: number;
  message?: TooltipProps['content'];
};

export const HelpBase = ({
  iconSize = ICON_SIZES.SMALL,
  message = undefined,
  ...props
}: HelpProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      content={message}
    >
      <div
        css={css`
          display: inline-flex;
        `}
      >
        <Info
          size={iconSize}
          fill={[theme.colors.gray[700], theme.colors.gray[25]]}
          css={css`
            display: flex;
          `}
        />
      </div>
    </Tooltip>
  );
};

HelpBase.displayName = 'Help';

HelpBase.propTypes = {
  /** Icon size. */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
  /** Tooltip content. */
  message: PropTypes.node,
};

export const Help: MemoExoticComponent<typeof HelpBase> & {
  SIZES?: typeof ICON_SIZES;
} = memo(HelpBase);

Help.SIZES = ICON_SIZES;
