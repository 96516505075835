import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

type ArbitraryObj = { [key: string]: any };

const defaultGetKey = <T extends ArbitraryObj>(obj: T) => obj.id;

const defaultGetData = <T extends ArbitraryObj, U extends ArbitraryObj = T>(
  obj: T,
) => obj as unknown as U;

export type DraggableListItem<T extends {}> = {
  id: string | number;
  data: T;
};

export type UseSortableList<U> = [
  draggableList: DraggableListItem<U>[],
  onChangeDraggableList: (newValue: DraggableListItem<U>[]) => void,
  onEnd: () => void,
];

export const useSortableList = <
  T extends ArbitraryObj,
  U extends ArbitraryObj = T,
>(
  value: T[],
  onChange: (newList: U[]) => void,
  {
    getKey = defaultGetKey,
    getData = defaultGetData,
  }: {
    getKey?: (item: T) => string | number;
    getData?: (item: T) => U;
  } = {},
): UseSortableList<U> => {
  const [draggableList, onChangeDraggableList] = useState<
    DraggableListItem<U>[]
  >([]);

  // onEnd is called in the same synchronous tick as onChangeDraggableList, so the value of
  // draggableList inside our onEnd is always the value of the previous tick, because onEnd captures
  // the value by closure and is not recalculated for the new value of draggableList until after
  // it is called.
  // To fix this problem, we keep the current value of draggableList in a reference (the ref is
  // captured by closure too but it doesn't matter, draggableListRef.current will hold the actual
  // current value in onEnd).
  const draggableListRef = useRef(draggableList);

  const onChangeProxy = useCallback((newValue: DraggableListItem<U>[]) => {
    draggableListRef.current = newValue;
    onChangeDraggableList(newValue);
  }, []);

  useEffect(() => {
    onChangeProxy(
      (value || []).map((item) => ({
        id: getKey(item),
        data: getData(item),
      })),
    );
  }, [value, getKey, getData, onChangeProxy]);

  const onEnd = useCallback(() => {
    const previousOrder = value.map((item) => getKey(item));
    const nextOrder = draggableListRef.current.map((item) => item.id);

    if (!isEqual(nextOrder, previousOrder)) {
      onChange(draggableListRef.current.map((item) => item.data));
    }
  }, [value, onChange, getKey]);

  return [draggableList, onChangeProxy, onEnd];
};
