import { defineMessages } from 'react-intl';

import { MCQ_ANSWER_TYPES } from '@eversity/domain/constants';

export const expectedAnswersMessages = defineMessages<MCQ_ANSWER_TYPES>({
  [MCQ_ANSWER_TYPES.SINGLE]: {
    defaultMessage: 'Une seule réponse attendue',
  },

  [MCQ_ANSWER_TYPES.MULTIPLE]: {
    defaultMessage: 'Plusieurs réponses attendues',
  },
});
