import { transform } from 'lodash';

import { type Color } from '@eversity/types/web';

import { getTextContrastColor } from '../../config/colors';
import { COLOR_GROUPS, HUE_GROUPS } from '../../config/colors/constants';

export const HUES = {
  [HUE_GROUPS.BLUE]: {
    15: '#f9fdff',
    25: '#eef6f9',
    50: '#ddedf4',
    100: '#cce4ee',
    200: '#99c8dc',
    300: '#66adcb',
    400: '#3391b9',
    500: '#0076a8',
    600: '#005e86',
    700: '#004765',
    800: '#002f43',
    900: '#001822',
  },

  [HUE_GROUPS.ORANGE]: {
    25: '#fdf3ee',
    50: '#fbe8de',
    100: '#f9dccd',
    200: '#f4ba9b',
    300: '#ee9769',
    400: '#e97537',
    500: '#e35205',
    600: '#b64204',
    700: '#883103',
    800: '#5b2102',
    900: '#2d1001',
  },

  [HUE_GROUPS.PURPLE]: {
    25: '#f3f3f7',
    50: '#e8e6ef',
    100: '#dcdae7',
    200: '#b9b5d0',
    300: '#9790b8',
    400: '#746ba1',
    500: '#514689',
    600: '#41386e',
    700: '#312a52',
    800: '#201c37',
    900: '#100e1b',
  },

  [HUE_GROUPS.GREEN]: {
    25: '#f2faf1',
    50: '#e6f4e2',
    100: '#d9efd4',
    200: '#b4dfaa',
    300: '#8ed07f',
    400: '#69c055',
    500: '#43b02a',
    600: '#368d22',
    700: '#286a19',
    800: '#1b4611',
    900: '#0d2308',
  },

  [HUE_GROUPS.YELLOW]: {
    25: '#fef9ee',
    50: '#fdf4dd',
    100: '#fceecc',
    200: '#fadd99',
    300: '#f7cb66',
    400: '#f5ba33',
    500: '#f2a900',
    600: '#c28700',
    700: '#916500',
    800: '#614400',
    900: '#302200',
  },

  [HUE_GROUPS.RED]: {
    25: '#faeff1',
    50: '#f6dee3',
    100: '#f1ced5',
    200: '#e39eac',
    300: '#d66d82',
    400: '#c83d59',
    500: '#ba0c2f',
    600: '#950a26',
    700: '#70071c',
    800: '#4a0513',
    900: '#250209',
  },

  [HUE_GROUPS.GRAY]: {
    0: '#ffffff',
    25: '#f4f5f5',
    50: '#eaeaec',
    100: '#dfe0e2',
    200: '#bfc2c5',
    300: '#9fa3a7',
    400: '#7f858a',
    500: '#5f666d',
    600: '#4c5257',
    700: '#393d41',
    800: '#26292c',
    900: '#131416',
  },
};

export const COLORS = {
  [COLOR_GROUPS.PRIMARY]: HUES[HUE_GROUPS.BLUE],
  [COLOR_GROUPS.SECONDARY]: HUES[HUE_GROUPS.ORANGE],
  [COLOR_GROUPS.TERTIARY]: HUES[HUE_GROUPS.PURPLE],
  [COLOR_GROUPS.SUCCESS]: HUES[HUE_GROUPS.GREEN],
  [COLOR_GROUPS.WARNING]: HUES[HUE_GROUPS.YELLOW],
  [COLOR_GROUPS.DANGER]: HUES[HUE_GROUPS.RED],
  [COLOR_GROUPS.GRAY]: HUES[HUE_GROUPS.GRAY],
};

/**
 * For each color in the palette above, define a contrast color to make sure that text is readable.
 * The contrast color is only in shades of gray.
 *
 * It uses the same shape as the COLORS constant above.
 *
 * @example
 * <div
 *   css={{
 *     backgroundColor: COLORS.orange[400],
 *     color: CONTRAST_COLORS.orange[400],
 *   }}
 * >
 *   Coucou
 * </div>
 */
export const CONTRAST_COLORS = transform(
  {
    ...HUES,
    ...COLORS,
  },
  (
    acc: Record<string, Color>,
    colorGroupColors: Color,
    colorGroupName: string,
  ) => {
    acc[colorGroupName] = transform(
      colorGroupColors,
      (colorGroupAcc, colorCode, colorStrength) => {
        colorGroupAcc[colorStrength] = getTextContrastColor(colorCode, COLORS);
      },
    );
  },
);
