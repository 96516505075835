import {
  type ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  type SanitizeSchema,
  transformHtmlToHast,
} from '@eversity/domain/html';
import {
  HastRenderer,
  type HastRendererProps,
} from '@eversity/ui/design-system';
import { sanitizeSchemaPropTypes } from '@eversity/ui/utils';

import ContentRenderRootElement from './render-root-element/ContentRenderRootElement';

export type ContentProps = {
  content: string;
  sanitizeSchema: SanitizeSchema;
  headerHeightInPx?: number;
};

export const ContentBase = forwardRef(
  (
    { content, sanitizeSchema, headerHeightInPx }: ContentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const contentHast = useMemo(
      () => transformHtmlToHast(content, { sanitizeSchema }),
      [content, sanitizeSchema],
    );

    const renderElementContent: HastRendererProps['renderElement'] =
      useCallback(
        ({ depth, ...props }) =>
          depth === 0 ? (
            <ContentRenderRootElement
              {...props}
              headerHeightInPx={headerHeightInPx}
            />
          ) : (
            <HastRenderer.DefaultRenderElement
              {...props}
              depth={depth}
            />
          ),
        [headerHeightInPx],
      );

    return (
      <HastRenderer
        ref={ref}
        hast={contentHast}
        renderElement={renderElementContent}
      />
    );
  },
);

ContentBase.displayName = 'Content';

ContentBase.propTypes = {
  content: PropTypes.string.isRequired,
  sanitizeSchema: sanitizeSchemaPropTypes.isRequired,
  headerHeightInPx: PropTypes.number,
};

ContentBase.defaultProps = {
  headerHeightInPx: 0,
};

export default memo(ContentBase);
