import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Down, Up } from '@icon-park/react';

import { globalMessages } from '@eversity/ui/intl';

import { Typography } from '../../general/typography/Typography';
import { Dropdown } from '../../navigation/dropdown/Dropdown';
import * as styles from './Tabs.styles';
import { type TabKey, type TabShape } from './types';

type SeeMoreDropDownType = {
  isVisible: boolean;
  dropdownItems: TabShape<TabKey>[];
  onChangeItemDropdown: (key: string) => void;
  onToggle: () => void;
};

const SeeMoreDropDown = memo(
  ({
    isVisible,
    dropdownItems,
    onChangeItemDropdown,
    onToggle,
  }: SeeMoreDropDownType) => {
    return (
      <Dropdown
        placement="bottom-end"
        visible={isVisible}
        menu={
          <Dropdown.List>
            {dropdownItems.map((item) => (
              <Dropdown.ListItem
                key={item.key}
                value={item.key as string}
                label={item.label}
                onClick={onChangeItemDropdown}
              />
            ))}
          </Dropdown.List>
        }
      >
        <Typography variant={Typography.VARIANTS.BODY_SMALL_BOLD}>
          <button
            type="button"
            css={styles.seeMore}
            onClick={onToggle}
          >
            <FormattedMessage {...globalMessages.SEE_MORE} />
            {!isVisible ? <Down strokeWidth={6} /> : <Up strokeWidth={6} />}
          </button>
        </Typography>
      </Dropdown>
    );
  },
);

export default SeeMoreDropDown;
