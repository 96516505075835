/**
 * Get metadata on an image.
 *
 * @param url - Url to the image.
 * @returns Image metadata.
 */
export const getImageMeta = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Unable to resolve image.'));
    img.src = url;
  });
