import { type FocusEventHandler, Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { css, useTheme } from '@emotion/react';
import { UploadTwo } from '@icon-park/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { type Upload } from '@eversity/types/domain';
import { useFocus } from '@eversity/ui/utils';

import {
  fileUploadingPropTypes,
  fileWithIdPropTypes,
  uploadPropTypes,
} from '../../../types';
import { Typography } from '../../general/typography/Typography';
import {
  FileUploaded,
  type FileUploadedProps,
} from './file-uploaded/FileUploaded';
import { FileUploading } from './file-uploading/FileUploading';
import messages from './FileUpload.messages';
import * as styles from './FileUpload.styles';
import {
  FileUploadContainer,
  type FileUploadContainerProps,
} from './FileUploadContainer';
import { FileUploadRules } from './rules/FileUploadRules';
import { type FileWithId } from './types';

export type FileUploadProps = Omit<FileUploadContainerProps, 'children'> & {
  hasError?: boolean;
  hasWarning?: boolean;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
};

export const FileUploadBase = ({
  hasError = false,
  hasWarning = false,
  onFocus: onFocusProps = undefined,
  onBlur: onBlurProps = undefined,
  ...props
}: FileUploadProps) => {
  const theme = useTheme();

  const { isFocused, onFocus, onBlur } = useFocus<HTMLDivElement>({
    onFocus: onFocusProps,
    onBlur: onBlurProps,
  });

  // Not destructuring in function params because they all need to be passed to FileUploadContainer.
  const { value, onChange, maxFiles } = props;

  // Remove the file.
  // If multiple files, remove from the value array.
  // If 1 file, set value to null.
  const onRemoveFile: FileUploadedProps['onRemove'] = useCallback(
    (fileId) =>
      onChange(
        maxFiles > 1
          ? ((value as { id: string }[]).filter(
              (file: FileWithId | Upload) => file.id !== fileId,
            ) as FileWithId[] | Upload[])
          : null,
      ),
    [value, onChange, maxFiles],
  );

  return (
    <FileUploadContainer {...props}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        isDropzoneDisabled,
        filesUploading,
        arrayValue,
      }) => (
        <Fragment>
          <div
            {...getRootProps({ onFocus, onBlur })}
            className={cn({
              isFocused,
              isDropzoneDisabled,
              isDragActive,
              isDragAccept,
              isDragReject,
              hasError,
              hasWarning: !hasError && !!hasWarning,
            })}
            css={styles.dropzone}
          >
            <input {...getInputProps()} />

            <UploadTwo
              size={30}
              fill={styles.getIconFills(
                theme,
                isDropzoneDisabled,
                isFocused,
                isDragReject,
                isDragActive,
                hasError,
                hasWarning,
              )}
            />

            <Typography
              variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
              css={css`
                margin-top: 15px;
              `}
            >
              <FormattedMessage {...messages.PLACEHOLDER} />
            </Typography>

            <FileUploadRules
              maxFiles={props.maxFiles}
              maxSize={props.maxSize}
              accept={props.accept}
            >
              {({ extensionsRule, maxSizeRule, maxFilesRule }) =>
                !!(extensionsRule || maxSizeRule || maxFilesRule) && (
                  <Typography
                    variant={Typography.VARIANTS.BODY_SMALL_ITALIC}
                    css={css`
                      text-align: center;
                      margin-top: 10px;
                    `}
                  >
                    <div>{extensionsRule}</div>
                    <div>{maxSizeRule}</div>
                    <div>{maxFilesRule}</div>
                  </Typography>
                )
              }
            </FileUploadRules>
          </div>

          {!!(arrayValue.length || filesUploading.length) && (
            <ul css={styles.assetList}>
              {arrayValue.map((file: FileWithId | Upload) => (
                <li key={file.id}>
                  <FileUploaded
                    file={file}
                    onRemove={onRemoveFile}
                    useFileAsValue={props.useFileAsValue}
                    disabled={props.disabled}
                  />
                </li>
              ))}

              {filesUploading.map((fileUploading) => (
                <li key={fileUploading.id}>
                  <FileUploading fileUploading={fileUploading} />
                </li>
              ))}
            </ul>
          )}
        </Fragment>
      )}
    </FileUploadContainer>
  );
};

FileUploadBase.displayName = 'FileUpload';

FileUploadBase.propTypes = {
  /**
   * Current value of the file upload.
   * These are all successfully uploaded assets.
   * If maxFiles is > 1, this is a list of files, if it is === 1, it is a single file.
   */
  value: PropTypes.oneOfType([
    uploadPropTypes,
    PropTypes.arrayOf(uploadPropTypes),
    fileWithIdPropTypes,
    PropTypes.arrayOf(fileWithIdPropTypes),
  ]),
  /** Set the new value. Depends on the value of maxFiles. */
  onChange: PropTypes.func,
  /** Max number of files that can be uploaded. */
  maxFiles: PropTypes.number,
  /** List of files being uploaded, or with a failure. */
  filesUploading: PropTypes.arrayOf(fileUploadingPropTypes),
  /** Callback with a single file to upload. Required if useFileAsValue is false. */
  onUploadFile: PropTypes.func,
  /** The dropzone has an error. */
  hasError: PropTypes.bool,
  /** The dropzone has a warning. */
  hasWarning: PropTypes.bool,
  /** Is the field disabled. Prevents drops / removals. */
  disabled: PropTypes.bool,
  /** List of accepted mime-types, separated by commas. */
  accept: PropTypes.string,
  /** Max size of each file, in bytes. */
  maxSize: PropTypes.number,
};

export const FileUpload = memo(FileUploadBase);
