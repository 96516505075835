import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { type Theme, useTheme } from '@emotion/react';
import { ArrowRight } from '@icon-park/react';
import cn from 'classnames';

import {
  OVERALL_AVERAGE_GRADING_SCALE,
  SUBMISSION_STATUSES,
} from '@eversity/domain/constants';
import { type StudentAcademicTranscriptAssignment } from '@eversity/types/domain';
import { ICON_SIZES, Typography } from '@eversity/ui/design-system';
import {
  assignmentStatusMessages,
  submissionAssessmentMessages,
} from '@eversity/ui/intl';

import { academicTranscriptAssignmentPropTypes } from '../../../../types/academicTranscripts';
import { FormattedGrade } from '../../../assignments/formatted-grade/FormattedGrade';
import messages from './AcademicTranscriptAssignmentItem.messages';
import * as styles from './AcademicTranscriptAssignmentItem.styles';

const getGradeMessage = (
  assignment: StudentAcademicTranscriptAssignment,
  theme: Theme,
) => {
  switch (true) {
    case assignment.submission?.status === SUBMISSION_STATUSES.CORRECTED &&
      Number.isFinite(assignment.submission?.grade): {
      const {
        assignment: { gradingScale },
        submission: { grade },
      } = assignment;

      return (
        <div css={styles.gradeContainer}>
          {
            // If the grade is not on the default grading scale, show the actual grade, then
            // the grade on the default scale.
            assignment.assignment.gradingScale !==
              OVERALL_AVERAGE_GRADING_SCALE && (
              <Fragment>
                <span css={styles.originalGrade}>
                  <FormattedGrade
                    grade={grade}
                    gradingScale={gradingScale}
                    renderGrade={(chunks) => (
                      <Typography
                        variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}
                      >
                        {chunks}
                      </Typography>
                    )}
                    renderGradingScale={(chunks) => (
                      <Typography
                        variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                      >
                        {chunks}
                      </Typography>
                    )}
                  />
                </span>

                <ArrowRight
                  size={ICON_SIZES.MEDIUM}
                  fill={[theme.colors.gray[500]]}
                />
              </Fragment>
            )
          }

          <span css={styles.coloredText}>
            <FormattedGrade
              grade={(grade / gradingScale) * OVERALL_AVERAGE_GRADING_SCALE}
              gradingScale={OVERALL_AVERAGE_GRADING_SCALE}
              renderGrade={(chunks) => (
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                  {chunks}
                </Typography>
              )}
              renderGradingScale={(chunks) => (
                <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
                  {chunks}
                </Typography>
              )}
            />
          </span>
        </div>
      );
    }
    case assignment.submission?.status === SUBMISSION_STATUSES.CORRECTED &&
      !!assignment.submission?.assessment:
      return (
        <Typography
          variant={Typography.VARIANTS.BODY_SMALL_BOLD}
          css={styles.coloredText}
        >
          <FormattedMessage
            {...submissionAssessmentMessages[assignment.submission.assessment]}
          />
        </Typography>
      );
    case assignment.submission?.status === SUBMISSION_STATUSES.IN_PROGRESS ||
      assignment.submission?.status === SUBMISSION_STATUSES.COMMENTED:
      return (
        <Typography
          variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
          css={styles.coloredText}
        >
          <FormattedMessage {...assignmentStatusMessages.IN_PROGRESS} />
        </Typography>
      );
    case assignment.submission?.status === SUBMISSION_STATUSES.SUBMITTED:
      return (
        <Typography
          variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
          css={styles.coloredText}
        >
          <FormattedMessage {...assignmentStatusMessages.AWAITING_GRADE} />
        </Typography>
      );
    default:
      return '';
  }
};

export type AcademicTranscriptAssignmentItemProps = {
  assignment: StudentAcademicTranscriptAssignment;
};

export const AcademicTranscriptAssignmentItemBase = ({
  assignment,
}: AcademicTranscriptAssignmentItemProps) => {
  const theme = useTheme();

  return (
    <li
      css={styles.assignmentItem}
      className={cn({
        greyed: !assignment.submission,
      })}
    >
      <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
        <FormattedMessage
          {...messages.ASSIGNMENT_TITLE}
          values={{
            assignmentTitle: assignment.assignment.title,
            coefficient: assignment.coefficient,
            b: (chunks) => (
              <span
                css={styles.assignmentCoefficient}
                className={cn({
                  greyed: !assignment.submission,
                })}
              >
                {chunks}
              </span>
            ),
          }}
        />
      </Typography>

      <div css={styles.gradeSeparator} />

      {getGradeMessage(assignment, theme)}
    </li>
  );
};

AcademicTranscriptAssignmentItemBase.displayName =
  'AcademicTranscriptAssignmentItem';

AcademicTranscriptAssignmentItemBase.propTypes = {
  assignment: academicTranscriptAssignmentPropTypes.isRequired,
};

export const AcademicTranscriptAssignmentItem = memo(
  AcademicTranscriptAssignmentItemBase,
);
