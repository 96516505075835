import { css } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: flex-start;
  padding: 9px 3px 19px 3px;
  border-radius: 4px;
  gap: 20px;
`;

export const text = css`
  flex: 1;
`;
