import { memo, type MemoExoticComponent, type ReactNode } from 'react';
import { css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import { CheckboxGroupCheckbox } from './checkbox/Checkbox';
import {
  CheckboxGroupContextProvider,
  type CheckboxGroupContextProviderProps,
} from './contexts/CheckboxGroup.context';

export type CheckboxGroupProps = CheckboxGroupContextProviderProps & {
  className?: string;
  children?: ReactNode;
};

export const CheckboxGroupBase = ({
  className = null,
  children = null,
  ...props
}: CheckboxGroupProps) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        padding: 14px;

        border: 1px solid ${theme.colors.gray[50]};
        border-radius: 4px;
      `}
    >
      <CheckboxGroupContextProvider {...props}>
        {children}
      </CheckboxGroupContextProvider>
    </div>
  );
};

CheckboxGroupBase.displayName = 'CheckboxGroup';

CheckboxGroupBase.propTypes = {
  /** Selected value. */
  value: PropTypes.arrayOf(PropTypes.string),
  /** On change function. */
  onChange: PropTypes.func,
  /** Add className to override default style. */
  className: PropTypes.string,
  /** Content. */
  children: PropTypes.node,
};

export const CheckboxGroup: MemoExoticComponent<typeof CheckboxGroupBase> & {
  Checkbox?: typeof CheckboxGroupCheckbox;
} = memo(CheckboxGroupBase);

CheckboxGroup.Checkbox = CheckboxGroupCheckbox;
