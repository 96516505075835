import PropTypes from 'prop-types';

export const lessonStudentNotePropTypes = PropTypes.shape({
  id: PropTypes.string,
  /** Id of the lesson block to which the note is attached. */
  blockId: PropTypes.string,
  /** Content of the note. */
  content: PropTypes.string,
  /** Note creation date. */
  createdAt: PropTypes.string,
});
