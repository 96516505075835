import { useMemo } from 'react';
import { intersection, without } from 'lodash';

import { TAG_VARIANTS } from '../../components/general/tag/constants';
import { getDeterministicTextColor } from '../../config/colors';

/**
 * Get a deterministic color for a tag.
 *
 * @param tag - Tag text.
 * @param [options] - Options.
 * @param [options.include] - Use only these variants.
 * @param [options.exclude] - Use all but these variants.
 * @returns Color code.
 */
export const useTagVariant = (
  tag: string,
  {
    include,
    exclude,
  }: {
    include?: TAG_VARIANTS[];
    exclude?: TAG_VARIANTS[];
  } = {},
) => {
  const availableColors = useMemo(
    () =>
      without(
        intersection(
          Object.values(TAG_VARIANTS),
          include || Object.values(TAG_VARIANTS),
        ),
        ...(exclude || []),
      ),
    [include, exclude],
  );

  const color = useMemo(
    () => getDeterministicTextColor(tag, availableColors),
    [tag, availableColors],
  );

  return color;
};
