import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import {
  CAROUSEL_LIGHTBOX_HEIGHT,
  CAROUSEL_SIZE_WIDTHS,
  CAROUSEL_SIZES,
  CAROUSEL_SLIDE_MARGIN,
} from '@eversity/domain/constants';
import { type CarouselSlideHastElement } from '@eversity/types/domain';

import { carouselSlideHastElementPropTypes } from '../../../../../types';
import { removeButtonAppearance } from '../../../../../utils/style/index';
import { Lightbox } from '../../../../general/lightbox/Lightbox';

const getSlideWidth = (
  ratio: string,
  slides: CarouselSlideHastElement[],
  selectedSize: CAROUSEL_SIZES,
) => {
  const sumRatio = slides.reduce(
    (acc, curr) => acc + parseFloat(curr.properties.dataRatio),
    0,
  );

  return (
    (parseFloat(ratio) / sumRatio) * CAROUSEL_SIZE_WIDTHS[selectedSize] -
    (slides.length - 1) * (CAROUSEL_SLIDE_MARGIN / 2)
  );
};

export type CarouselProps = Omit<HTMLProps<HTMLElement>, 'ref'> & {
  caption?: string;
  selectedSize: CAROUSEL_SIZES;
  slideNodes?: CarouselSlideHastElement[];
};

export const CarouselBase = forwardRef(
  (
    { caption, selectedSize, slideNodes, ...props }: CarouselProps,
    ref: ForwardedRef<HTMLElement>,
  ) => (
    <figure
      {...props}
      ref={ref}
    >
      <Lightbox>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: ${CAROUSEL_SLIDE_MARGIN}px;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          {slideNodes.length
            ? slideNodes.map((child, index) => {
                const {
                  dataAlt,
                  dataResizedUploadHref,
                  dataOriginalUploadHref,
                  dataRatio,
                } = child.properties;

                return (
                  <div
                    // The sequence content does not change between renders
                    // so the index is safe.
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    css={css`
                      max-width: ${getSlideWidth(
                        dataRatio,
                        slideNodes,
                        selectedSize,
                      )}px;
                    `}
                  >
                    <Lightbox.Item
                      original={dataOriginalUploadHref}
                      thumbnail={dataResizedUploadHref}
                      width={CAROUSEL_LIGHTBOX_HEIGHT * parseFloat(dataRatio)}
                      height={CAROUSEL_LIGHTBOX_HEIGHT}
                    >
                      {({ ref: imgRef, open }) => (
                        <button
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            open(event);
                          }}
                          css={[
                            removeButtonAppearance,
                            css`
                              display: block;
                              cursor: zoom-in;
                              width: 100%;
                            `,
                          ]}
                        >
                          <img
                            ref={imgRef}
                            src={dataResizedUploadHref}
                            alt={dataAlt || caption}
                            css={css`
                              width: 100%;
                              height: 100%;
                              display: block;
                            `}
                          />
                        </button>
                      )}
                    </Lightbox.Item>
                  </div>
                );
              })
            : null}
        </div>
      </Lightbox>

      {!!caption && <figcaption>{caption}</figcaption>}
    </figure>
  ),
);

CarouselBase.displayName = 'Carousel';

CarouselBase.propTypes = {
  caption: PropTypes.string,
  selectedSize: PropTypes.oneOf(Object.values(CAROUSEL_SIZES)).isRequired,
  slideNodes: PropTypes.arrayOf(carouselSlideHastElementPropTypes),
};

CarouselBase.defaultProps = {
  caption: null,
  slideNodes: [],
};

export const Carousel = memo(CarouselBase);
