import PropTypes from 'prop-types';

export const internshipContractPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internship: PropTypes.shape({
    courseId: PropTypes.string,
    subject: PropTypes.string,
    businessSector: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  company: PropTypes.shape({
    name: PropTypes.string,
    siret: PropTypes.string,
    address: PropTypes.shape({
      streetAddress: PropTypes.string,
      supplement: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    representative: PropTypes.shape({
      title: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      function: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    tutor: PropTypes.shape({
      title: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      function: PropTypes.string,
      service: PropTypes.string,
    }),
  }),
  conditions: PropTypes.shape({
    isAbroad: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isEnglishContract: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    organisation: PropTypes.string,
    weeklyHours: PropTypes.number,
    isNightJob: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isWorkingSundays: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isWorkingHolidays: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hasBTSDerogation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hourlyWage: PropTypes.number,
    currency: PropTypes.string,
    benefits: PropTypes.string,
    daysOff: PropTypes.number,
    absenceConditions: PropTypes.string,
  }),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  commentary: PropTypes.string,
  url: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});
