/**
 * Open an external link in a new tab.
 * Adds noopener and noreferrer.
 *
 * @param href - URL to open in a new tab.
 * @returns Reference to the open tab.
 */
export function openLinkInNewTab(href: string) {
  return window.open(href, '_blank', 'noopener,noreferrer');
}
