import { useEffect, useState } from 'react';

export const useCropperFile = (file: Blob | MediaSource) => {
  const [imageUrl, onSetImageUrl] = useState(null);

  // We use useEffect instead of useMemo because we need to cleanup the previous value
  // when computing the new one.
  useEffect(() => {
    const objectUrl = file ? URL.createObjectURL(file) : null;

    onSetImageUrl(objectUrl);

    return objectUrl ? () => URL.revokeObjectURL(objectUrl) : undefined;
  }, [file]);

  return imageUrl;
};
