import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type ReactNode,
  useContext,
} from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import { type Element as HastElement, type Root as HastRoot } from 'hast';
import { last } from 'lodash';
import PropTypes from 'prop-types';

import { TABLE_BACKGROUND_COLORS } from '@eversity/domain/constants';
import {
  type TableHastElement,
  type TableRowHastElement,
} from '@eversity/types/domain';
import { findParent } from '@eversity/utils/misc';

import { HastRendererContext } from '../../HastRenderer.context';

export type CellProps = Omit<HTMLProps<HTMLTableCellElement>, 'ref'> & {
  tagName: 'th' | 'td';
  path: number[];
  backgroundColor?: TABLE_BACKGROUND_COLORS;
  children?: ReactNode;
};

export const CellBase = forwardRef(
  (
    {
      tagName: Component,
      backgroundColor,
      path,
      children,
      ...props
    }: CellProps,
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const hast = useContext(HastRendererContext);

    const [tr] = findParent<HastRoot | HastElement>(
      hast,
      path,
      (node) => node.type === 'element' && node.tagName === 'tr',
    ) as [TableRowHastElement, number[]];

    const [table] = findParent<HastRoot | HastElement>(
      hast,
      path,
      (node) => node.type === 'element' && node.tagName === 'table',
    ) as [TableHastElement, number[]];

    const colCount = tr.children?.length || 1;
    const colIndex = last(path);
    const colRatio =
      JSON.parse(table.properties?.dataColRatios || '[]')[colIndex] || 1;

    return (
      <Component
        {...props}
        ref={ref}
        className={cn(props.className, backgroundColor)}
        css={css`
          width: ${(100 * colRatio) / colCount}%;
        `}
      >
        <div className="cellContent">{children}</div>
      </Component>
    );
  },
);

CellBase.displayName = 'Cell';

CellBase.propTypes = {
  tagName: PropTypes.oneOf(['th', 'td'] as const).isRequired,
  /** Path to the element in the hast. */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Root element class name. */
  className: PropTypes.string,
  /** Background color enum. */
  backgroundColor: PropTypes.oneOf(Object.values(TABLE_BACKGROUND_COLORS)),
  /** Children. */
  children: PropTypes.node,
};

CellBase.defaultProps = {
  className: null,
  backgroundColor: null,
  children: null,
};

export const Cell = memo(CellBase);
