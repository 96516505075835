import { createContext, memo, type ReactNode } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useMemoizedBundle } from '@eversity/ui/utils';

type RadioButtonGroupContextValue = {
  value: string;
  onChange: (newValue?: string) => void;
};

export const RadioButtonGroupContext =
  createContext<RadioButtonGroupContextValue>({
    value: null,
    onChange: noop,
  });

export type RadioButtonGroupContextProviderProps = {
  value?: string;
  onChange?: (newValue?: string) => void;
  children?: ReactNode;
};

export const RadioButtonGroupContextProviderBase = ({
  value = null,
  onChange = noop,
  children = null,
}: RadioButtonGroupContextProviderProps) => {
  const contextValue = useMemoizedBundle({
    value,
    onChange,
  });

  return (
    <RadioButtonGroupContext.Provider value={contextValue}>
      {children}
    </RadioButtonGroupContext.Provider>
  );
};

RadioButtonGroupContextProviderBase.displayName =
  'RadioButtonGroupContextProvider';

RadioButtonGroupContextProviderBase.propTypes = {
  /** Selected value. */
  value: PropTypes.string,
  /** On change function. */
  onChange: PropTypes.func,
  /** Content. */
  children: PropTypes.node,
};

export const RadioButtonGroupContextProvider = memo(
  RadioButtonGroupContextProviderBase,
);
