import { useContext, useEffect, useState } from 'react';

import {
  type ILayer,
  LayersContext,
} from '../../components/meta/layers-provider/LayersContext';

export const useLayer = <T extends ILayer>({
  isOpen,
  ...layer
}: {
  isOpen: boolean;
} & T) => {
  const [layerId, setLayerId] = useState<string | null>(null);

  const { layers, onAddLayer, onUpdateLayer, onRemoveLayer } =
    useContext(LayersContext);

  useEffect(
    () => {
      if (isOpen && !layerId) {
        // If the layer was not already open, add a layer.
        setLayerId(onAddLayer(layer));
      } else if (!isOpen && layerId) {
        // If the layer was but is no longer open, remove it.
        onRemoveLayer(layerId);
        setLayerId(null);
      } else if (isOpen && layerId) {
        // If the layer is still open, update its props.
        onUpdateLayer(layerId, layer);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- The layer values are not static.
    [
      isOpen,
      layerId,
      onAddLayer,
      onUpdateLayer,
      onRemoveLayer,
      // eslint-disable-next-line react-hooks/exhaustive-deps -- The layer values are not static.
      ...Object.values(layer),
    ],
  );

  // Remove dialog on unmount.
  useEffect(() => () => onRemoveLayer(layerId), [onRemoveLayer, layerId]);

  const layerIndex = layers.findIndex(({ id }) => id === layerId);
  const isTopMostLayer = isOpen && layerIndex === layers.length - 1;

  return {
    layerId,
    layerIndex,
    isTopMostLayer,
    numberOfLayersAbove: layerIndex >= 0 ? layers.length - layerIndex - 1 : 0,
    layerZIndex: layerIndex >= 0 ? layers[layerIndex].zIndex : undefined,
  };
};
