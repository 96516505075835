import { defineMessages } from 'react-intl';

export default defineMessages({
  ASSESSMENT_LABEL: {
    defaultMessage: 'Appréciation',
  },

  ASSIGNMENTS_COMPLETED: {
    defaultMessage:
      "{value, plural, =0 {Aucune évaluation requise n'a encore été terminée} =1 {Toutes les évaluations requises ont été terminées} other {{value, number, ::percent} des évaluations requises ont été terminées}}.",
  },
});
