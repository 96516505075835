import PropTypes from 'prop-types';

import { VIRTUAL_CLASSROOM_TYPES } from '@eversity/domain/constants';
import { uploadPropTypes } from '@eversity/ui/design-system';

import { schoolObjectBasePropTypes } from './common';
import { courseViewMinimalPropTypes } from './courses';
import { commonUserPropTypes } from './users';

export const virtualClassroomViewMinimalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
  /** Virtual classroom type. */
  type: PropTypes.oneOf(Object.values(VIRTUAL_CLASSROOM_TYPES)).isRequired,
  /** Presenter. */
  presenter: commonUserPropTypes.isRequired,
  /** Tags (arbitrary, plain text). */
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Start date/time. */
  startDate: PropTypes.string.isRequired,
  /** End date/time. */
  endDate: PropTypes.string.isRequired,
  /** Cancelled flag. */
  isCancelled: PropTypes.bool.isRequired,
});

export const virtualClassroomViewStudentPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
  /** Title. */
  description: PropTypes.string.isRequired,
  /** Presenter. */
  presenter: commonUserPropTypes.isRequired,
  /** Tags (arbitrary, plain text). */
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Virtual classroom type. */
  type: PropTypes.oneOf(Object.values(VIRTUAL_CLASSROOM_TYPES)).isRequired,
  /** Start date/time. */
  startDate: PropTypes.string.isRequired,
  /** End date/time. */
  endDate: PropTypes.string.isRequired,
  /** Cancelled flag. */
  isCancelled: PropTypes.bool.isRequired,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
  /** Student google calendar event id. */
  studentEventId: PropTypes.string.isRequired,
  /** Google meets URL. */
  meetingUrl: PropTypes.string.isRequired,
  /** Replay url if any. */
  replayUrl: PropTypes.string,
  /** Virtual classroom attachments. */
  attachments: PropTypes.arrayOf(uploadPropTypes).isRequired,
});

export const virtualClassroomViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
  /** Title. */
  description: PropTypes.string.isRequired,
  /** Presenter. */
  presenter: commonUserPropTypes.isRequired,
  /** Tags (arbitrary, plain text). */
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Virtual classroom type. */
  type: PropTypes.oneOf(Object.values(VIRTUAL_CLASSROOM_TYPES)).isRequired,
  /** Start date/time. */
  startDate: PropTypes.string.isRequired,
  /** End date/time. */
  endDate: PropTypes.string.isRequired,
  /** Cancelled flag. */
  isCancelled: PropTypes.bool.isRequired,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
  /** Present google calendar event id. */
  presenterEventId: PropTypes.string.isRequired,
  /** Student google calendar event id. */
  studentEventId: PropTypes.string.isRequired,
  /** Google meets URL. */
  meetingUrl: PropTypes.string.isRequired,
  /** Replay url if any. */
  replayUrl: PropTypes.string,
  /** Virtual classroom attachments. */
  attachments: PropTypes.arrayOf(uploadPropTypes).isRequired,
  /** All classes in the virtual classroom. */
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      course: courseViewMinimalPropTypes.isRequired,
      class: schoolObjectBasePropTypes.isRequired,
    }),
  ).isRequired,
  imageRights: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      accepted: PropTypes.bool.isRequired,
      student: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ).isRequired,
});
