import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { Check, Minus } from '@icon-park/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useFocus } from '@eversity/ui/utils';

import * as styles from './CheckboxControl.styles';

const getIcon = (checked: boolean, indeterminate: boolean) => {
  if (indeterminate) {
    return Minus;
  }

  if (checked) {
    return Check;
  }

  return null;
};

export type CheckboxControlProps = Omit<HTMLProps<HTMLInputElement>, 'ref'> & {
  indeterminate?: boolean;
};

export const CheckboxControlBase = forwardRef(
  (
    { checked, indeterminate, disabled, ...props }: CheckboxControlProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();
    const Icon = getIcon(checked, indeterminate);
    const { isFocused, ...focusProps } = useFocus(props);

    return (
      <div
        css={styles.box}
        className={cn({
          isChecked: checked || indeterminate,
          isDisabled: disabled,
          isFocused,
        })}
      >
        <input
          {...props}
          {...focusProps}
          ref={ref}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          css={css`
            position: fixed;
            top: -1000px;
            left: -1000px;
          `}
        />

        {Icon && (
          <Icon
            theme="filled"
            size="12"
            fill={!disabled ? theme.colors.gray[0] : theme.colors.gray[200]}
          />
        )}
      </div>
    );
  },
);

CheckboxControlBase.displayName = 'CheckboxControl';

CheckboxControlBase.propTypes = {
  /** Is the input checked. */
  checked: PropTypes.bool,
  /** Is the input indeterminate. */
  indeterminate: PropTypes.bool,
  /** Is the input disabled. */
  disabled: PropTypes.bool,
};

CheckboxControlBase.defaultProps = {
  checked: false,
  indeterminate: false,
  disabled: false,
};

export const CheckboxControl = memo(CheckboxControlBase);
