export const INTERNSHIP_CONTRACTS_STATUSES = {
  // For contract coming from legacy system.
  OLD_PROCEDURE: 0,
  // Contract validated by student and being verified by Intern Service.
  BEING_PROCESSED: 1,
  // Contract needs to be signed by student.
  WAITING_STUDENT_SIGNATURE: 2,
  // Contract needs to be signed by tutor.
  WAITING_TUTOR_SIGNATURE: 3,
  // Contract needs to be signed by company.
  WAITING_COMPANY_SIGNATURE: 4,
  // Contract needs to be signed by Enaco.
  WAITING_ENACO_SIGNATURE: 5,
  // Intern service has signed the contract.
  VALIDATED: 6,
  // Intern service has refused the contract (from status 1).
  REJECTED: 7,
  // Contract has been broken by the company, the student...
  BROKEN: 8,
  // Student, tutor, company or Enaco has rejected the signature.
  SIGNATURE_REJECTED: 9,
  // Archived contract of a student who is not in Enaco anymore.
  TERMINATE_AWAITING_STUDENT_SIGNATURE: 10,
  // Archived contract of a student who is not in Enaco anymore.
  TERMINATE_AWAITING_TUTOR_SIGNATURE: 11,
  // Archived contract of a student who is not in Enaco anymore.
  TERMINATE_AWAITING_COMPANY_SIGNATURE: 12,
  // Archived contract of a student who is not in Enaco anymore.
  STUDENT_TERMINATE: 13,
} as const;

export const SIRET_NUMBER_LENGTH = 14;
export const OCCUPATION_MIN_LENGTH = 2;
export const OCCUPATION_MAX_LENGTH = 85;
export const WEEKLY_HOURS_MIN = 1;
export const FIRST_TASKS_INPUT_LENGTH = 62;
export const OTHERS_TASKS_INPUT_LENGTH = 87;
