/**
 * Theme color groups are agnostic of their hues.
 * For example, when using the theme, you want to use the primary color, regardless of the fact that
 * the primary color can be blue, orange, purple or else.
 */
export enum COLOR_GROUPS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  GRAY = 'gray',
}

/**
 * In some very rare occasions, it can be useful to use the actual color you want.
 * A simple example is the text color switch in the content editor. You need blue, not primary, etc.
 */
export enum HUE_GROUPS {
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
  YELLOW = 'yellow',
  GREEN = 'green',
  PURPLE = 'purple',
  GRAY = 'gray',
}
